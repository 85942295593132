import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Loader from '../../../components/Loader';
import ContentContainer from '../../../components/ContentContainer';
import { privateDashboardRequest } from '../../../../../Networking';
import { Modal, ModalInputText, ModalText } from "../../../business/manage/components/Modal";
import './Cards.scss';
import DropdownFilter from '../../../components/DropdownFilter';
import IconFiltersDots from '../../../assets/Icon_Filters_Dots.svg'
import {Environment} from "../../../../../Utils";



const BlockUnblockCard = connect(
    state => ({
        login: state.login,
        translation: state.translation.overview.creditCards.blockUnblockCard,
    }),
    dispatch => ({ dashboardRequest: (body, success, error) => privateDashboardRequest(dispatch, body, success, error) }))(
        class extends PureComponent {
            constructor(props) {
                super(props);
                this.state = { isLoaderVisible: false, isDone: false, isError: false, result: null };
            }

            onAction = () => {
                const userId = this.props.login && this.props.login.currentRole && this.props.login.currentRole.userId;
                if (!userId) {
                    return; //not in private role
                }
                if (this.state.isLoaderVisible) {
                    return;
                }
                this.setState({ isLoaderVisible: true }, async () => {
                    // await new Promise(x => setTimeout(x, 500000));
                    this.props.dashboardRequest(
                        {
                            "action": "toggleIdCredit",
                            "userId": userId,
                            "token": this.props.login.token,
                            "block": this.props.id === 'block' ? 1 : 0
                        },
                        data => {
                            const _userId = this.props.login && this.props.login.currentRole && this.props.login.currentRole.userId;
                            if (!_userId || _userId !== userId) {
                                return; //role changed
                            }
                            this.setState({
                                result: data.records.result,
                                isDone: data.records.statusCode === 200,
                                isError: data.records.statusCode !== 200,
                                isLoaderVisible: false
                            });
                        }, error => {
                            this.setState({ isLoaderVisible: false });
                        });
                })
            }

            render() {
                return (
                    <Modal {...{
                        title: this.props.title,
                        onCloseButtonClick: this.props.onModalClose,
                        content: <ModalText {...{
                          text: this.props.confirmationText,
                        }} />,
                        isSaveButton: this.props.id === 'block',
                        onActionButtonClick: () => this.onAction(),
                        actionButtonTitle: this.props.id === 'block' ? this.props.translation.blockButton : this.props.translation.unblockButton,
                        isLoaderVisible: this.state.isLoaderVisible,
                        isDone: this.state.isDone,
                        isError: this.state.isError,
                        result: this.state.result,
                        height: 112,
                    }} />
                )
            }
        });
const ChangePin = connect(
  state => ({ login: state.login, translation: state.translation.overview.creditCards.changePin }),
  dispatch => ({ dashboardRequest: (body, success, error) => privateDashboardRequest(dispatch, body, success, error) })
  )(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            pin1: '', pin2: '', isLoaderVisible: false,
            isDone: false, isError: false, result: null
        };
    }

    onAction = () => {
      const userId = this.props.login && this.props.login.currentRole && this.props.login.currentRole.userId;
        if (!userId) {
          return; //not in private role
        }
        if (this.state.isLoaderVisible) {
            return;
        }
        if (this.state.pin1 !== this.state.pin2) {
                    this.setState({
                        result: this.props.translation.pinNotEqual,
                        isDone: false,
                        isError: true,
                        isLoaderVisible: false
                    });
                    return;
        }
        if (this.state.pin1.length < 4) {
                    this.setState({
                        result: this.props.translation.pinTooShort,
                        isDone: false,
                        isError: true,
                        isLoaderVisible: false
                    });
                    return;
        }
        this.setState({ isLoaderVisible: true }, () => {
            this.props.dashboardRequest(
                {
                    "action": "editIdPin",
                    "userId": userId,
                    "token": this.props.login.token,
                    "pin1": this.state.pin1,
                    "pin2": this.state.pin2
                },
                data => {
                    const _userId = this.props.login && this.props.login.currentRole && this.props.login.currentRole.userId;
                    if (!_userId || _userId !== userId) {
                                return; //role changed
                    }
                    this.setState({
                        result: data.records.result,
                        isDone: data.records.statusCode === 200,
                        isError: data.records.statusCode !== 200,
                        isLoaderVisible: false
                    });
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        });
    }

    onPinChange = (type, e) => {
      const val = e.target.value;
      if (isNaN(val) || val.length > 4) {
        return;
      }
      this.setState({ [type]: e.target.value });
    }

    render() {
        return (
            <Modal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onModalClose,
                content:
                  [
                    <ModalInputText {...{
                        inputType: 'password',
                        key: 'pin1',
                        text: this.props.translation.enterPin,
                        id: "pin1",
                        value: this.state.pin1,
                        required: true,
                        maxLength: 4,
                        onChange: (val) => this.onPinChange('pin1', val)
                    }} />,
                    <ModalInputText {...{
                        inputType: 'password',
                        key: 'pin2',
                        text: this.props.translation.enterPinAgain,
                        id: "pin2",
                        value: this.state.pin2,
                        required: true,
                        maxLength: 4,
                        onChange: (val) => this.onPinChange('pin2', val)
                    }} />],
                isSaveButton: true,
                onActionButtonClick: () => this.onAction(),
                actionButtonTitle: this.props.translation.confirmButton,
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 196
            }} />
        )
    }
});

const RemoveCard = connect(
    state => ({ login: state.login }),
    dispatch => ({
        dashboardRequest: (body, success, error) => privateDashboardRequest(dispatch, body, success, error),
    })
)(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { isLoaderVisible: false, isDone: false, isError: false, result: null };
    }

    onAction = () => {
        const userId = this.props.login && this.props.login.currentRole && this.props.login.currentRole.userId;
        if (!userId) {
            return; //not in private role
        }
        if (this.state.isLoaderVisible) {
            return;
        }
        this.setState({ isLoaderVisible: true }, async () => {
            this.props.dashboardRequest(
                {
                    action: 'removeCreditCard',
                    userId: userId,
                    creditCardId: this.props.cardId,
                    token: this.props.login.token,
                    cardType: this.props.cardType,
                },
                data => {
                    const _userId = this.props.login && this.props.login.currentRole && this.props.login.currentRole.userId;
                    if (!_userId || _userId !== userId) {
                        return; //role changed
                    }
                    this.setState({
                        result: data.records.result,
                        isDone: data.records.statusCode === 200,
                        isError: data.records.statusCode !== 200,
                        isLoaderVisible: false
                    });
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        })
    }

    render() {
        return (
            <Modal {...{
                title: this.props.title,
                onCloseButtonClick: this.props.onModalClose,
                content: <ModalText {...{ text: this.props.confirmationText }} />,
                isSaveButton: this.props.id === 'block',
                onActionButtonClick: () => this.onAction(),
                actionButtonTitle: this.props.confirmationBtnText,
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 112,
            }} />
        )
    }
});

const CardItem = React.memo(props => {
    const buttons = props.buttons.filter(b => b.show).map(b => ({...b,
        onClick: () => {
            if (b.id === 'remove') {
                return props.onModalOpen(<RemoveCard {...{
                    ...b,
                    cardId: props.cardId,
                    cardType: props.cardType,
                    onModalClose: props.onModalClose,
                }} />);
            }

            return b.askConfirmation ?
                props.onModalOpen(<BlockUnblockCard {...{ ...b, onModalClose: props.onModalClose }} />) :
                props.onModalOpen(<ChangePin {...{ ...b, onModalClose: props.onModalClose }} />);
        },
    }));

    return (
        <div className="person-overview-cards_collection_item">
            <img className="person-overview-cards_collection_item_card-img img-cred" src={props.icon} alt="" />
            <p className="person-overview-cards_collection_item_card-number">{props.mainText}</p>
            <p className="person-overview-cards_collection_item_text">{props.additionalText}</p>
            {buttons.length > 0 && <DropdownFilter {...{
                items: buttons,
                className: "person-overview-cards_collection_item_dropdown",
                text: <img src={IconFiltersDots} alt="" />,
                rightImg: null,
                disabled: false,
                distance: -56
            }} />}
        </div>);
});

const Cards = React.memo(props => {

    const onEditingToggle = () => {
        window.webViewBridge.send('showCardsWindow', {'environment': 'self_service', 'type': 'payment'});
    };

    return (
        <ContentContainer className="person-overview-cards" {...{
            ...(props.login?.environmentId === Environment.OlxApp ? (
                {action: <button className={`user-details_edit-button `}
                                 onClick={onEditingToggle}>{props.translation.editButton}</button>}
            ) : {}),
            title:
                <div className="person-overview-cards_title">{props.translation.title}</div>,
            content:
                <div className="person-overview-cards_content">
                    <TransitionGroup component={null}>
                        {props.isLoaderVisible &&
                            <CSSTransition key={"loader-fade"} timeout={{enter: 250, exit: 250}}
                                           classNames={"fast-fade"}>
                                <div className="person-overview-cards_loader_container">
                                    <Loader {...{className: "person-overview-cards_loader"}} /></div>
                            </CSSTransition>}
                        {!props.isLoaderVisible &&
                            <CSSTransition key={"content-fade"} timeout={{enter: 250, exit: 250}}
                                           classNames={"fast-fade"}>
                                <div className="person-overview-cards_collection">
                                    {props.creditCards && props.creditCards.map((item, index) =>
                                        <CardItem {...{
                                            ...item,
                                            key: index,
                                            onModalOpen: props.onModalOpen,
                                            onModalClose: props.onModalClose
                                        }} />)}
                                </div>
                            </CSSTransition>}
                    </TransitionGroup>
                </div>
        }} />)
    }
);

export default connect(state => ({ translation: state.translation.overview.creditCards, login: state.login }))(Cards);
