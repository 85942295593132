import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { withScrollingHeader } from '../../../components/withScrollingHeader';
import TransactionsFilters from '../../../person/transactions/components/TransactionsFilters';
import ImageButton24px from '../../components/ImageButton24px';
import ToolbarSeparator from '../../../components/ToolbarSeparator';
import BillingStats from '../../../components/BillingStats';
import MobileFiltersButton from '../../../components/MobileFiltersButton';
import IdCreditContent from '../../../person/idcredit/components/IdCreditContent';
import Loader from '../../../components/Loader';
import NoContent from '../../../components/NoContent';
import { buildClassList, roleOrLanguageChanged } from '../../../../../Utils';
import { url, businessInvoicesRequest, businessBanklinksRequest } from '../../../../../Networking';
import BankLinks from '../../../modal/banklinks/BankLinks';
import styles from './Invoices.module.scss';
import IconDownload from '../../../assets/Icon_Download.svg';
import IconBillDarker20px from '../../../assets/Icon_Bill_Darker_20px.svg';
import IconStatusDarker20px from '../../../assets/Icon_Status_Darker_20px.svg';
import IconTimeDarker20px from '../../../assets/Icon_Time_Darker_20px.svg';
import {FilterTypeEnum} from "../../../person/transactions/helpers/FilterTypeEnum";
import {dateToString} from "../../../helpers";

class Invoices extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            periods: null,
            selectedPeriodId: null,
            invoiceTypes: null,
            selectedInvoiceTypeId: null,
            invoiceStatuses: null,
            selectedInvoiceStatusId: null,
            joined: null,
            isLoaderVisible: false,
            dateRange: {
                startDate: null,
                endDate: null,
                isVisible: false,
                selectsStart: false,
                selectionStart: true,
                selectedPeriodId: "1",
                text: ''
            }
        };
    }

    handlePeriodSelected = (selectedPeriodId) => {
        this.setState({ selectedPeriodId: selectedPeriodId }, () => this.downloadData());
    };

    handleInvoiceTypeSelected = (selectedInvoiceTypeId) => {
        this.setState({ selectedInvoiceTypeId: selectedInvoiceTypeId }, () => this.downloadData());
    };

    handleInvoiceStatusSelected = (selectedInvoiceStatusId) => {
        this.setState({ selectedInvoiceStatusId: selectedInvoiceStatusId }, () => this.downloadData());
    };

    downloadExcel = () => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        window.open(`${url}&act=olerexweb.business.invoices&requestType=getAll&token=${this.props.login.token}&accNr=${accnr}&periodId=${this.state.selectedPeriodId}&invoiceTypeId=${this.state.selectedInvoiceTypeId}&invoiceStatusId=${this.state.selectedInvoiceStatusId}&excel=1`);
    };

    downloadPDf = (invoiceNumber, isLink, pdfUrl) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        if (isLink && pdfUrl) {
            window.open(pdfUrl);
        } else {
            window.open(`${url}&act=olerexweb.business.invoices&requestType=invoicePdf&token=${this.props.login.token}&accNr=${accnr}&invoiceNo=${invoiceNumber}&pdf=1`);
        }
    };

    downloadData = () => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        const request = {
            "requestType": "getAll",
            "accNr": this.props.login.currentRole.accnr,
            "token": this.props.login.token,
            "invoiceTypeId": this.state.selectedInvoiceTypeId,
            "invoiceStatusId": this.state.selectedInvoiceStatusId
        };
        if (this.state.selectedPeriodId || !this.state.dateRange.endDate) {
            request.periodId = this.state.selectedPeriodId;
        } else {
            request.fromPeriod = dateToString(this.state.dateRange.startDate);
            request.toPeriod = dateToString(this.state.dateRange.endDate);
        }

        this.setState({ isLoaderVisible: true }, async () => {
            this.props.invoicesRequest( request,
                data => {
                    const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                    if (!_accnr || _accnr !== accnr) {
                        return; //role changed
                    }
                    const response = data.records && data.records.response;
                    const invoices = response.invoices && response.invoices[0] !== this.props.translation.noInvoices ? response.invoices : {};
                    const incomings = response.incomings && response.incomings[0] !== this.props.translation.noIncomings ? response.incomings : {};
                    const joinedKeys = Array.from(new Set(Object.keys(invoices).concat(...Object.keys(incomings)))); //filter duplicates with set
                    let joined = joinedKeys.map(x => ({ text: x, invoices: invoices[x], incomings: incomings[x] }));
                    joined = joined.map(x => ({ ...x, invoices: { ...x.invoices, rows: x.invoices ? x.invoices.rows.map(y => ({ ...y, onDownloadButtonClick: () => this.downloadPDf(y.number, y.isLink, y.pdfUrl) })) : null } }));
                    this.setState(prevState => ({
                        joined: joined,
                        periods: Object.entries(data.records.response.periods).map(x => ({
                            text: x[1],
                            value: x[0],
                            from: new Date(data.records.response.periodsFromTo[x[0]].from + 'T00:00:00'),
                            to: new Date(data.records.response.periodsFromTo[x[0]].to + 'T00:00:00'),
                            onClick: () => this.handlePeriodSelected(x[0])
                        })),
                        selectedPeriodId: String(data.records.response.selectedPeriodId),
                        invoiceTypes: Object.entries(data.records.response.invoiceTypes).map(x => ({ text: x[1], value: x[0], onClick: () => this.handleInvoiceTypeSelected(x[0]) })),
                        selectedInvoiceTypeId: String(data.records.response.selectedInvoiceTypeId),
                        invoiceStatuses: Object.entries(data.records.response.invoiceStatuses).map(x => ({ text: x[1], value: x[0], onClick: () => this.handleInvoiceStatusSelected(x[0]) })),
                        selectedInvoiceStatusId: String(data.records.response.selectedInvoiceStatusId),
                        unpaidTotal: data.records.response.unpaidTotal,
                        overDeadlineTotal: data.records.response.overDeadlineTotal,
                        balance: data.records.response.balance,
                        referenceNumber: data.records.response.referenceNumber,
                        isLoaderVisible: false,
                        dateRange: {
                            startDate: String(data.records.response.selectedPeriodId) ? new Date(data.records.response.periodsFromTo[String(data.records.response.selectedPeriodId)].from + 'T00:00:00') : null,
                            endDate: String(data.records.response.selectedPeriodId) ? new Date(data.records.response.periodsFromTo[String(data.records.response.selectedPeriodId)].to + 'T00:00:00') : null,
                            isVisible: prevState.dateRange.isVisible,
                            selectsStart: false,
                            selectionStart: true,
                            selectedPeriodId: String(data.records.response.selectedPeriodId) || null,
                        }
                    }));
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        });
    };

    openBankLink = (invoiceId) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        this.props.banklinksRequest(
            {
                "accNr": this.props.login.currentRole.accnr,
                "token": this.props.login.token,
                "invoiceId": invoiceId,
            },
            data => {
                const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                if (!_accnr || _accnr !== accnr) {
                    return; //role changed
                }
                this.props.onModalOpen(<BankLinks {...{ items: data.records.response.banklink, onCloseButtonClick: this.props.onModalClose }} />);
            },
            error => {
            }
        )
    };

    componentDidMount() {
        this.downloadData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.isMobileLayout && this.props.isMobileLayout) {
            if (this.state.lastScrollEvent) { //recalculate scroll hiding header
                this.onScrollListener(this.state.lastScrollEvent);
            }
        }
        if (roleOrLanguageChanged(prevProps, this.props)) {
            this.downloadData();
        }
    };

    currentFilterTitle = (filters, filterValue) => {
        if (filters) {
            const filter = filters.find(x => String(x.value) === String(filterValue));
            if (filter) {
                return filter.text;
            }
        }
        return "";
    };

    get invoiceTypeFilterTitle() {
        return this.currentFilterTitle(this.state.invoiceTypes, this.state.selectedInvoiceTypeId);
    };

    get invoiceStatusFilterTitle() {
        return this.currentFilterTitle(this.state.invoiceStatuses, this.state.selectedInvoiceStatusId);
    };

    get periodFilterTitle() {
        return this.currentFilterTitle(this.state.periods, this.state.selectedPeriodId);
    };

    handleDateChange = (dateRange) => {
        this.setState({
            ...this.state,
            dateRange: dateRange,
            selectedPeriodId: dateRange.selectedPeriodId
        }, () => {
            if (dateRange.selectedPeriodId || dateRange.endDate) {
                this.downloadData()
            }
        });
    };

    render() {
        const headerClass = buildClassList({
            [styles.fixed]: this.props.isHeaderFixed,
            [styles.hiding]: this.props.isHeaderHiding,
            [styles.animated]: this.props.isHeaderAnimated,
        });

        return (
            <div className={styles.invoices}>
                <div className={styles.headerPlaceholder}></div>
                <div className={`${styles.headerStatic} ${headerClass}`}>
                    <div className={styles.background}></div>
                    <div className={styles.header}>
                        <div className={`${styles.items} ${styles.left}`}>
                            <TransactionsFilters {...{
                                items: [
                                    {
                                        leftImg: IconBillDarker20px,
                                        text: this.invoiceTypeFilterTitle,
                                        items: this.state.invoiceTypes,
                                        type: FilterTypeEnum.dropdown
                                    },
                                    {
                                        leftImg: IconStatusDarker20px,
                                        text: this.invoiceStatusFilterTitle,
                                        items: this.state.invoiceStatuses,
                                        type: FilterTypeEnum.dropdown
                                    },
                                    {
                                        leftImg: IconTimeDarker20px,
                                        text: this.periodFilterTitle,
                                        items: this.state.periods,
                                        type: FilterTypeEnum.calendar,
                                        onChange: this.handleDateChange,
                                        dateRange: this.state.dateRange
                                    },
                                ],
                                alignToStart: true
                            }} />
                        </div>
                        <div className={`${styles.items} ${styles.right}`}>
                            <a
                                className={styles.termsOfSaleLink}
                                href={this.props.translation.termsOfSaleUrl}
                                target="_blank"
                                rel="noopener noreferrer">{this.props.translation.termsOfSaleLink}</a>

                            <ToolbarSeparator />

                            <ImageButton24px {...{
                                src: IconDownload,
                                alt: 'download',
                                onClick: this.downloadExcel,
                                tooltip: this.props.generalTranslation.downloadFile,
                            }} />
                        </div>
                    </div>
                    <MobileFiltersButton {...{
                        className: styles.mobileFilters,
                        onClick: () => this.props.onMobileFiltersOpen([{
                            text: this.invoiceTypeFilterTitle,
                            items: this.state.invoiceTypes
                        }, {
                            text: this.invoiceStatusFilterTitle,
                            items: this.state.invoiceStatuses
                        }, {
                            text: this.periodFilterTitle,
                            items: this.state.periods
                        }])
                    }} />
                </div>
                <TransitionGroup component={null}>
                    {(this.state.unpaidTotal || this.state.overDeadlineTotal || this.state.balance || this.state.referenceNumber) && (
                        <div className={styles.stats}>
                            <div className={styles.inner}>
                                <CSSTransition key={"stats"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                    <BillingStats {...{
                                        unpaidTotal: this.state.unpaidTotal,
                                        overDeadlineTotal: this.state.overDeadlineTotal,
                                        balance: this.state.balance,
                                        referenceNumber: this.state.referenceNumber
                                    }} />
                                </CSSTransition>
                            </div>
                        </div>
                    )}
                    {this.state.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <Loader className={styles.loader} />
                    </CSSTransition>}
                    {(!this.state.isLoaderVisible || this.state.joined !== null) &&
                        <CSSTransition key={"content"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <div className={styles.body}>
                                {!this.state.joined || this.state.joined.length <= 0 ?
                                    <NoContent {...{ text: this.props.translation.noContent }} /> :
                                    this.state.joined.map((item, index) => <IdCreditContent {...{
                                        className: styles.content,
                                        key: index,
                                        text: item.text,
                                        invoices: item.invoices,
                                        incomings: item.incomings,
                                        payButtonClick: this.openBankLink
                                    }} />)}
                            </div>
                        </CSSTransition>}
                </TransitionGroup>
            </div >
        );
    }
}

export default connect(
    state => ({
        router: state.router,
        login: state.login,
        isMobileLayout: state.size.isMobileLayout,
        translation: state.translation.invoicing.invoices,
        generalTranslation: state.translation.general
    }),
    dispatch => ({
        invoicesRequest: (body, success, error) => businessInvoicesRequest(dispatch, body, success, error),
        banklinksRequest: (body, success, error) => businessBanklinksRequest(dispatch, body, success, error)
    }))(withScrollingHeader(Invoices, 80, 72, 80, 52));
