import React from 'react';
import { connect } from 'react-redux';
import {OutlineButton} from "./OutlineButton";
import {ExpandableSection} from "./ExpandableSection";

import IconElectricityGray from '../../../assets/Icon_Electricity_Gray.svg';
import PlaceholderChart from '../PlaceholderChart.png';

import IconDocument from '../../../assets/Icon_Document.svg';
import TagEuro from '../../../assets/tag-euro_SVG.svg';
import TimeClockFileCheck from '../../../assets/time-clock-file-check_SVG.svg';
import TimeClockFileRemove from '../../../assets/time-clock-file-remove_SVG.svg';


import './ContractItem.scss';




const RightSideHeader = (props) => {
    return (
        <React.Fragment>
            <div className="contract-item-col-package">
                <div className="contract-item-top-row">
                    <div>{props.translation.package}</div>
                </div>
                <div className="contract-item-bottom-row">
                    <div>{props.electricityPackage}</div>
                </div>
            </div>
            <div className="contract-item-col-metering-point">
                <div className="contract-item-top-row">
                    <div>{capitalize(props.translation.meteringPoint)}</div>
                </div>
                <div className="contract-item-bottom-row">
                    <div>{props.meteringPoints.length > 1 ? `${props.meteringPoints.length} ${props.translation.meteringPoints}`: props.meteringPoints[0].code}</div>
                </div>
            </div>
            <div className="contract-item-col-address">
                <div className="contract-item-top-row">
                    <div>{props.translation.consumptionAddress}</div>
                </div>
                <div className="contract-item-bottom-row">
                    <div>{props.meteringPoints[0].title}</div>
                </div>
            </div>
        </React.Fragment>
    )
};

const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const calculateHeight = (itemCount, isMobileLayout) => {
    const boxHeight = {
        true: 195,
        false: 98
    };
    const rowHeight = {
        true: 101,
        false: 90
    };
    const box = boxHeight[isMobileLayout];
    const row = rowHeight[isMobileLayout];
    return (box + (itemCount * row));
};

const ContractItem = connect(state => ({ translation: state.translation.electricity })) (React.memo(props => {
    const [state, setState] = React.useState({
        isExpanded: false,
    });

    const toggle = (field) => (event) => {
        let contains = false;
        const blockClasses = ['contract-item-button', 'co-dropdown-filter_dropdown', 'co-dropdown-filter_dropdown_option-button'];
        const classList = event.target.classList;
        for (const className of blockClasses) {
            if (classList.contains(className)){
                contains = true;
            }
        }
        if (!contains) {
            setState({...state, [field]: !state[field]});
        }
    };

    const height = calculateHeight(props.meteringPoints.length, props.isMobileLayout);
    const {isMobileLayout} = props;

    const contractOnclick = (type) =>() => {
        switch(type) {
            case 1:
                props.editContract([parseInt(props.id, 10)], [], 4);
                break;
            case 2:
                props.editContract([], [], 3);
                break;
            case 3:
                props.endContractHandler([parseInt(props.id, 10)], props.meteringPoints.map(mp => mp.code));
                break;
            default:
                return;
        }
    };

    const outlineButtonItems = [
        {text: props.translation.editContract, onClick: contractOnclick(1)},
        {text: props.translation.endContract, onClick: contractOnclick(3)}
    ];

    return (
        <div className={`contract-item ${state.isExpanded ? 'contract-item-open': 'contract-item-closed'}`}>
            <div className="contract-item-header" onClick={toggle('isExpanded')}>
                <div className="contract-item-icon">
                    <img src={IconDocument} alt=""/>
                </div>
                <div className="contract-item-information">
                    <div className="contract-item-col-name">
                        <div className="contract-item-top-row">
                            <div>{props.id}</div>
                        </div>
                        <div className="contract-item-bottom-row">
                            <div>{props.name}</div>
                        </div>
                    </div>
                    { !props.isMobileLayout && <RightSideHeader {...props}/> }
                </div>
                {
                    !isMobileLayout &&
                    <div className="contract-item-button-container">
                        <OutlineButton items={outlineButtonItems} {...props} className='contract-item-button' />
                    </div>
                }
            </div>
            {
                isMobileLayout &&
                <React.Fragment>
                    <div className="contract-item-body" onClick={toggle('isExpanded')}>
                        <img src={PlaceholderChart} alt=""/>
                    </div>
                    <div className="contract-item-header-mobile-row" onClick={toggle('isExpanded')}>
                        <RightSideHeader {...props}/>
                    </div>
                </React.Fragment>

            }
            <ExpandableSection height={height} className='contract-item-middle' isExpanded={state.isExpanded}>
                <div className="contract-item-mid-header">
                    <div className="contract-item-col-price">
                        <img className='contract-item-col-icon' alt="" src={TagEuro}/>
                        <div className='contract-item-col-rows'>
                            <div className="contract-item-top-row">
                                {props.translation.packagePrice}
                            </div>
                            <div className="contract-item-bottom-row">
                                {props.electricityPackagePrice}
                            </div>
                        </div>
                    </div>
                    <div className="contract-item-col-start">
                        <img className='contract-item-col-icon' alt="" src={TimeClockFileCheck}/>
                        <div className='contract-item-col-rows'>
                            <div className="contract-item-top-row">
                                {props.translation.contractBeginning}
                            </div>
                            <div className="contract-item-bottom-row">
                                {props.contractFrom}
                            </div>
                        </div>
                    </div>
                    <div className='contract-item-col-end'>
                        <img className='contract-item-col-icon' alt="" src={TimeClockFileRemove}/>
                        <div className='contract-item-col-rows'>
                            <div className="contract-item-top-row">
                                {props.translation.contractEnd}
                            </div>
                            <div className="contract-item-bottom-row">
                                {props.contractTo}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contract-item-mid-body">
                    {
                        props.meteringPoints.map((item, index) => {
                            return (
                                <div key={index} className='contract-item-metering-point'>
                                    <img className='contract-item-metering-point-img' src={IconElectricityGray} alt={''}/>
                                    <div className='contract-item-metering-point-columns'>
                                        <div className='contract-item-metering-point-name'>
                                            <div className='contract-item-metering-point-top'>
                                                <div>{item.title}</div>
                                            </div>
                                            <div className='contract-item-metering-point-bottom'>
                                                <div>{item.info}</div>
                                            </div>
                                        </div>
                                        {
                                            !isMobileLayout &&
                                            <div className='contract-item-metering-point-code'>
                                                <div className='contract-item-metering-point-top'>
                                                    <div>{props.translation.meteringPointModal.meteringPointCode}</div>
                                                </div>
                                                <div className='contract-item-metering-point-bottom'>
                                                    <div>{item.code}</div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </ExpandableSection>
            { !isMobileLayout &&
                <div className="contract-item-body" onClick={toggle('isExpanded')}>
                    <img src={PlaceholderChart} alt={''}/>
                </div>
            }
        </div>
    )
}));

export default ContractItem;
