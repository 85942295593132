import React, { Component } from 'react';
import { connect } from 'react-redux';
import RcSlider from 'rc-slider';

import 'rc-slider/assets/index.css';
import styles from './Slider.module.scss';

function fmtNumber(value) {
    if (value > 1000) {
        value = (value / 1000).toFixed(3).replace(/\./, ' ');
    }
    return String(value);
}

function fmtUnit(value, unit) {
    if (unit.indexOf('%') >= 0) {
        return unit.replace(/%/, value);
    }
    return `${value} ${unit}`;
}

const SmallMark = () => (
    <div className={styles.mark}>
        <div className={styles.dot}></div>
    </div>
);

const BigMark = ({ label }) => (
    <div className={`${styles.mark} ${styles.big}`}>
        <div className={styles.dot}></div>
        <div className={styles.label}>{label}</div>
    </div>
);

function buildFuelMarks(values, isMobileLayout) {
    let bigStride = 3;
    let smallStride = 1;

    if (values.length >= 30) {
        bigStride = 10;
        smallStride = 2;

        if (isMobileLayout) {
            bigStride = 16;
            smallStride = 4;
        }
    }

    const marks = {};

    for (const [i, value] of values.entries()) {
        if (i % bigStride === 0) {
            marks[i] = <BigMark label={value} />;
        } else if (i % smallStride === 0) {
            marks[i] = <SmallMark />;
        } else {
            marks[i] = <div />;
        }
    }

    return marks;
}

function buildDayMarks(values) {
    const marks = {};

    for (const [i, value] of values.entries()) {
        if (i === 0 || value % 7 === 0) {
            marks[i] = <BigMark label={value} />;
        } else {
            marks[i] = <SmallMark />;
        }
    }

    return marks;
}

function parseLeft(left, width) {
    if (!left) {
        return 0;
    }
    return parseFloat(left.replace('%' , '')) / 100 * width;
}

class Tooltip extends Component {
    static getDerivedStateFromProps(props, state) {
        const offset = 14; // Tip's offset from the tooltip edge
        const minLeft = (state.width / 2) - offset;
        const maxLeft = props.parentWidth - minLeft;

        let left = parseLeft(props.left, props.parentWidth);
        let tipLeft = state.width / 2;

        if (left < minLeft) {
            tipLeft -= minLeft - left;
            left = minLeft;
        } else if (left > maxLeft) {
            tipLeft -= maxLeft - left;
            left = maxLeft;
        }

        return { left, tipLeft };
    }

    constructor(props) {
        super(props);

        this.state = {
            width: props.kind === 'fuel' ? 69 : 136,
        };
    }

    render() {
        return (
            <div
                className={styles.tooltip}
                style={{
                    left: `${this.state.left}px`,
                    marginLeft: `${Math.round(this.state.width / -2)}px`,
                    width: `${this.state.width}px`,
                }}
            >
                {this.props.children}
                <span className={styles.tip} style={{left: `${this.state.tipLeft}px`}} />
            </div>
        );
    }
}

class Slider extends Component {
    static defaultProps = {
        kind: 'fuel',
    }

    static getDerivedStateFromProps(props, state) {
        const style = props.kind || 'fuel';
        const buildMarks = style === 'fuel' ? buildFuelMarks : buildDayMarks;

        return {
            index: Math.max(0, props.values.indexOf(props.value)),
            marks: buildMarks(props.values, props.isMobileLayout),
        };
    }

    constructor(props) {
        super(props);

        const fmtTooltip = props.unit ?
            (v => fmtUnit(fmtNumber(v), props.unit)) :
            fmtNumber;

        this.handleRender = (handle, p) => (
            <>
                <Tooltip
                    kind={props.kind}
                    left={handle.props.style.left}
                    parentWidth={this.getWidth()}
                >
                    {fmtTooltip(this.props.values[p.value])}
                </Tooltip>
                {handle}
            </>
        );

        this.state = {};
        this.elementRef = React.createRef();
    }

    getWidth() {
        return this.elementRef.current?.getBoundingClientRect().width;
    }

    onChange(index) {
        this.props.onChange(this.props.values[index]);
    }

    render() {
        return (
            <div className={styles.container} ref={this.elementRef}>
                <RcSlider
                    prefixCls="slider"
                    className={styles.slider}
                    min={0}
                    max={this.props.values.length - 1}
                    defaultValue={0}
                    marks={this.state.marks}
                    step={null}
                    handleRender={this.handleRender}
                    value={this.state.index}
                    onChange={index => this.onChange(index)}
                />
            </div>
        );
    }
}

export default connect(state => ({
    isMobileLayout: state.size.isMobileLayout,
}), null)(Slider);
