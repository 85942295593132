import React from 'react';
import { connect } from 'react-redux';
import './ErrorModal.scss';
import IconWrench from '../../assets/Icon_Wrench.svg';
import IconChevronBackGray from '../../assets/Chevron_Back_Gray.svg';

const ErrorModal = React.memo(props =>
    <div className="error-modal">
        <img className="error-modal-img" src={IconWrench} alt="" />
        <h1 className="error-modal-title">{props.translation.title}</h1>
        <p className="error-modal-text">{props.translation.text}</p>
        <button className="error-modal-button" onClick={props.onClose}>
            <img className="error-modal-button-img" src={IconChevronBackGray} alt="" />
            <span className="error-modal-button-text">{props.translation.backButton}</span>
        </button>
    </div>
);

export default connect(state => ({ translation: state.translation.errorModal }))(ErrorModal);