import React from 'react';
import './ImageButton24px.scss';
import Tippy from '@tippy.js/react';

const ImageButton24px = React.memo(props =>
    <Tippy arrow={true}
      isEnabled={props.tooltip !== null}
      content={props.tooltip || ""}
    >
      <button className={`co-btn-image-24px ${props.className ? props.className : ""} ${props.inactive ? 'inactive' : ""}`} onClick={props.onClick}>
        <img className="co-btn-image-24px_img" src={props.src} alt={props.alt} />
      </button>
    </Tippy>

);

export default ImageButton24px;