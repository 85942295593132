import React from 'react';
import './CheckboxField.scss';
import IconCheckWhite16px from "../../overview/assets/Icon_Check_White-16px.svg";

export const CheckboxField = React.memo(props =>
    <div className={`checkbox-field-common ${props.className ? props.className : ""}`}>
        <div className="green-checkbox">
            <input className="green-checkbox_input" id={props.type} type="checkbox" checked={props.checked} onChange={props.onChange} />
            <img className="green-checkbox_img" src={IconCheckWhite16px} alt="" />
        </div>
        <label className="checkbox-field-common-title" htmlFor={props.type}>
            <span className="desktop">{props.title}</span>
            <span className="mobile">{props.titleMobile ? props.titleMobile : props.title}</span>
        </label>
    </div>
);
