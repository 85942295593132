import React, { Component } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { connect } from 'react-redux';

import IconModalCross16px from '../../assets/Icon_Modal-Cross_16px.svg';
import IconChevronSmallBack from '../../assets/Icons_Chevron_Small-Back.svg';
import ContentContainer from '../../components/ContentContainer';
import { parseGeocodeResult } from '../utils.js';
import styles from './AddressModal.module.scss';

class AddressModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            address: '',
            info: null,
        };

        this.searchOptions = {
            region: 'ee',
        };
    }

    onChange = address => {
        this.setState({ address });
    };

    onSelect = address => {
        geocodeByAddress(address)
            .then(results => parseGeocodeResult(results[0]))
            .then(info => this.setState({ address, info }))
            .catch(err => console.error(err));
    };

    onError = status => {
        if (status !== 'ZERO_RESULTS') {
            console.error(`AddressModal: PlacesAutocomplete error: ${status}`);
        }
    };

    onSave = () => {
        this.props.onModalClose();
        this.props.onSave(this.state.info);
    };

    render() {
        return <ContentContainer {...{
            className: styles.modal,
            title: <h1>{this.props.translation.title}</h1>,
            action:
                <button className={styles.close} onClick={this.props.onModalClose}>
                    <img src={IconModalCross16px} alt="close" />
                </button>,
            content:
                <div className={styles.content}>
                    <PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.onChange}
                        onSelect={this.onSelect}
                        onError={this.onError}
                        searchOptions={this.searchOptions}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                            <div className={styles.search}>
                                <input {...getInputProps({ placeholder: this.props.translation.address })} />
                                <div className={styles.suggestions}>
                                    {suggestions.map((suggestion, i) => {
                                        return (
                                            <div
                                                key={i}
                                                {...getSuggestionItemProps(suggestion, {
                                                    className: `${styles.item} ${suggestion.active ? styles.active : ''}`,
                                                })}
                                            >
                                                {suggestion.description}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>

                    <button className={styles.back} onClick={this.props.onModalClose}>
                        <img src={IconChevronSmallBack} alt="" />
                        <span className={styles.text}>{this.props.translation.closeButton}</span>
                    </button>

                    <button className={styles.save} onClick={this.onSave} disabled={!this.state.info}>
                        <span className={styles.text}>{this.props.translation.saveButton}</span>
                    </button>
                </div>
        }} />;
    }
}

export default connect(state => ({
    translation: state.translation.wholesale.editAddressModal,
}))(AddressModal);
