import React from 'react';
import "./TransactionsSwitches.scss";
import IconCardsBigActive24px from '../../../assets/Icon_Cards-Big_Active-24px.svg';
import IconCardsSmallActive24px from '../../../assets/Icon_Cards-Small_Active-24px.svg';

const TransactionsSwitches = props => (
    <div className="transactions-header-switches">
        <button className={`transactions-header-switch two list ${props.isListDisplayTypeSelected ? "active" : ""}`} onClick={props.onListClick}>
            <img className="transactions-header-switch_img"
                src={IconCardsBigActive24px}
                alt={props.isListDisplayTypeSelected ? "" : "list view"} />
        </button>
        <button className={`transactions-header-switch table ${props.isTableDisplayTypeSelected ? "active" : ""}`} onClick={props.onTableClick}>
            <img className="transactions-header-switch_img"
                src={IconCardsSmallActive24px}
                alt={props.isTableDisplayTypeSelected ? "" : "table view"} />
        </button>
    </div>
);

export default TransactionsSwitches;