import React from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ExpandableContent from '../../../components/ExpandableContent';
import CardUsersModal from './CardUsersModal';
import { ActivateCard, EditCardName, EditCardLimits, BlockCard, EditParams, UnblockCard } from './Modals';
import BlackCheckbox from '../../../components/BlackCheckbox';
import OrderCard from './OrderCard';
import './CardGroups.scss';
import styles from './CardGroups.module.scss';
import IconCrossCircleYellow16px from '../../../assets/Icon_Cross-Circle_Yellow-16px.svg';
import IconGraphYellow16px from '../../../assets/Icon_Graph_Yellow-16px.svg';
import IconInputYellow16px from '../../../assets/Icon_Input_Yellow-16px.svg';
import IconRefreshYellow16px from '../../../assets/Icon_Refresh_Yellow-16px.svg';
import IconParamsYellow16px from '../../../assets/Icon_Params_Yellow-16px.svg';
import IconPlusYellow from '../../../assets/Icon_Plus_Yellow.svg';
import IconCheckOrange from '../../../assets/Icon_Check_Orange-16px.svg';

const CardItemDropdownCol = React.memo(props => (
    <div className={styles.cardItemDropdownCol}>
        <CardItemDropdownColText label={props.label} text={props.text} />
        {props.children}
    </div>
));

const CardItemDropdownColText = React.memo(props => (
    <div className={`${styles.cardItemDropdownColText} ${props.className || ''}`}>
        <p className={styles.label}>{props.label}</p>
        <p className={styles.text}>{props.text}</p>
    </div>
));

const CardItemDropdownColButton = React.memo(props => (
    <button className={`${styles.cardItemDropdownColButton} ${props.className || ''}`} onClick={props.onClick}>
        <img className={styles.img} src={props.img} alt="" />
        <span className={styles.text}>{props.text}</span>
    </button>
));

const cardItemHasHeader = (item) => {
    return item.cardHeaderTxt_main || item.cardHeaderTxt_additional || item.cardHeaderColor || item.cardHeaderImg;
}

const fmtUserName = ({ firstName, lastName }) => `${firstName} ${lastName}`;

const fmtLimit = (() => {
    const format = new Intl.NumberFormat('en', {
        maximumFractionDigits: 0,
    });
    return price => `${format.format(price).replace(/,/g, ' ')} €`;
})();

const fmtExpiry = (() => {
    const format = new Intl.DateTimeFormat('et-ee', {
        dateStyle: 'short',
    });
    return ts => format.format(new Date(ts * 1000));
})();

const CardItem = connect(state => ({ translation: state.translation.cards.cardItem }))(class extends React.PureComponent {
    onExpandToggle = (e) => {
        const element = e.target.tagName.toLowerCase();
        const parent = e.target.parentElement && e.target.parentElement.tagName.toLowerCase();
        if (element !== "input" && element !== "button" && parent !== "button") {
            this.props.onExpandToggle();
        }
    }

    onClickEditName = () => {
        this.props.onModalOpen(<EditCardName {...{
            name: this.props.name,
            number: this.props.number,
            updateCards: this.props.updateCards,
            onModalClose: this.props.onModalClose
        }} />);
    }

    onClickEditLimits = () => {
        this.props.onModalOpen(<EditCardLimits {...{
            number: this.props.number,
            clientTotalLimit: this.props.clientTotalLimit,
            limit: this.props.totalLimitInt,
            dayLimit: this.props.dayLimitInt,
            fuel: this.props.allowedFuel,
            product: this.props.allowedProduct,
            updateCards: this.props.updateCards,
            onModalClose: this.props.onModalClose
        }} />);
    }

    onClickEditParam = () => {
        this.props.onModalOpen(<EditParams {...{
            number: this.props.number,
            refuelingParameter: this.props.refuelingParameter,
            refuelingParameters: this.props.refuelingParameters,
            updateCards: this.props.updateCards,
            onModalClose: this.props.onModalClose
        }} />);
    }

    onClickExtendCard = () => {
        this.props.onModalOpen(<OrderCard {...{
            extending: true,
            stations: this.props.stations,
            onModalClose: this.props.onModalClose,
            cards: [{
                name: this.props.name,
                limit: this.props.totalLimitInt,
                dayLimit: this.props.dayLimitInt,
                selectedFuelType: this.props.allowedFuel,
                selectedProductType: this.props.allowedProduct,
                number: this.props.number,
            }]
        }} />);
    }

    onClickUnblockCard = () => {
        this.props.onModalOpen(<UnblockCard {...{
            name: this.props.name,
            number: this.props.number,
            updateCards: this.props.updateCards,
            onModalClose: this.props.onModalClose
        }} />);
    }

    onClickBlockCard = () => {
        this.props.onModalOpen(<BlockCard {...{
            name: this.props.name,
            number: this.props.number,
            updateCards: this.props.updateCards,
            onModalClose: this.props.onModalClose
        }} />);
    }

    onClickActivateCard = () => {
        this.props.onModalOpen(<ActivateCard {...{
            name: this.props.name,
            number: this.props.number,
            updateCards: this.props.updateCards,
            onModalClose: this.props.onModalClose,
        }} />);
    }

    render() {
        const hasHeader = cardItemHasHeader(this.props);
        const checkedClass = this.props.checked ? "checked" : "";
        const expandedClass = this.props.expanded ? "expanded" : "";
        const withHeaderClass = hasHeader ? "with-header" : "";
        const flexBasisStyle = this.props.usedLimitPercentage ? `calc(${Math.min(this.props.usedLimitPercentage, 100)}% - 32px)` : "0px";
        const headerStyle = { background: this.props.cardHeaderBackground, color: this.props.cardHeaderTxtColor };
        const cardNameStyle = { color: this.props.cardNameColor };
        const expiresAtStyle = { color: this.props.expiresAtColor };
        const sliderStyle = { background: this.props.sliderBackground, color: this.props.sliderTxtColor };
        const limitClass = this.props.cardLimitBoldRed ? "expiring" : "";
        const checked = this.props.checked || false;

        const showDayLimit =
            typeof this.props.dayLimitInt === 'number' &&
            this.props.dayLimitInt < this.props.totalLimitInt;

        const limitText = (
            <span className="cards-collection-item_limits">
                {showDayLimit && <span>{fmtLimit(this.props.dayLimitInt)}</span>}
                <span>{fmtLimit(this.props.totalLimitInt)}</span>
            </span>
        );

        return (
            <div className={`cards-collection-item-large ${checkedClass} ${expandedClass} ${withHeaderClass} cardType${this.props.cardType}`} onClick={this.onExpandToggle}>
                <BlackCheckbox {...{ checked: checked, onChange: this.props.onChecked }} />
                {hasHeader && <div className="cards-collection-item-large_header" style={headerStyle}>
                    <img className="cards-collection-item-large_header_img" src={this.props.cardHeaderImg} alt="" />
                    <p className="cards-collection-item-large_header_text">{this.props.cardHeaderTxt_main}</p>
                    <p className="cards-collection-item-large_header_text right">{this.props.cardHeaderTxt_additional}</p>
                </div>}
                <p className="cards-collection-item-large_text">
                    <span className="cards-collection-item_large_title" style={cardNameStyle}>{this.props.name}</span>
                    <span className="cards-collection-item_large_number">{this.props.number}</span>
                    <span className="cards-collection-item_large_users">
                        <button {...{
                            onClick: () => this.props.onModalOpen(<CardUsersModal {...{
                                users: this.props.users,
                                cardNumber: this.props.number,
                                isBlockedAddingApp: this.props.isBlockedAddingApp,
                                updateCards: this.props.updateCards,
                                onModalClose: this.props.onModalClose,
                            }} />)
                        }}>
                            {this.props.users.length !== 1 ?
                                this.props.translation.users(this.props.users.length) :
                                fmtUserName(this.props.users[0])}
                        </button>
                    </span>
                </p>
                <div className="cards-collection-item-large_labels">
                    {this.props.showExpiresAt && <p className="cards-collection-item-large_expiry-label">{this.props.translation.expiry}</p>}
                    {this.props.showExpiresAt && <p className="cards-collection-item-large_expiry" style={expiresAtStyle}>{fmtExpiry(this.props.expiresAt)}</p>}

                    {this.props.cardType === 'Credit' && (
                        <>
                            <p className={`cards-collection-item-large_limit-label ${limitClass}`}>{this.props.translation.limit}</p>
                            <p className={`cards-collection-item-large_limit ${limitClass}`}>
                                {showDayLimit && <span>{fmtLimit(this.props.dayLimitInt)}</span>}
                                <span>{fmtLimit(this.props.totalLimitInt)}</span>
                            </p>

                            <p className="cards-collection-item-large_free-label">{this.props.translation.unused}</p>
                            <p className="cards-collection-item-large_free">{this.props.unUsedLimit}</p>
                        </>
                    )}
                </div>
                <div className="cards-collection-item-large_seek-large">
                    <div className="cards-collection-item-large_seek-large_fill" style={{ flexBasis: flexBasisStyle, ...sliderStyle }}></div>
                    {(this.props.usedLimitPercentage && <p className="cards-collection-item-large_seek-large_button" style={sliderStyle}>{this.props.usedLimitPercentage}%</p>) || ""}
                    <div className="cards-collection-item-large_seek-large_nofill"></div>
                </div>
                <TransitionGroup component={null}>
                    {this.props.expanded && <CSSTransition key={"dropdown"} timeout={{ enter: 600, exit: 600 }} classNames={"cards-card-dropdown"}>
                        <>
                            {this.props.cardType === 'Credit' && (
                                <div className="cards-collection-item-large_dropdown">
                                    <CardItemDropdownCol label={this.props.translation.used} text={this.props.usedLimit}>
                                        <CardItemDropdownColButton {...{
                                            text: this.props.translation.editNameButton,
                                            img: IconInputYellow16px,
                                            onClick: this.onClickEditName,
                                        }} />
                                    </CardItemDropdownCol>

                                    <CardItemDropdownCol label={this.props.translation.limitDropdown} text={limitText}>
                                        <CardItemDropdownColButton {...{
                                            text: this.props.translation.editLimitButton,
                                            img: IconGraphYellow16px,
                                            onClick: this.onClickEditLimits,
                                        }} />
                                    </CardItemDropdownCol>

                                    <CardItemDropdownCol label={this.props.translation.param} text={this.props.refuelingParameters[this.props.refuelingParameter]}>
                                        <CardItemDropdownColButton {...{
                                            text: this.props.parameter ?
                                                this.props.translation.addParamButton :
                                                this.props.translation.editParamButton,
                                            img: IconParamsYellow16px,
                                            onClick: this.onClickEditParam,
                                        }} />
                                    </CardItemDropdownCol>

                                    <CardItemDropdownCol label={this.props.translation.expiry} text={this.props.expiresAtPretty}>
                                        {!this.props.needsActivation && (
                                            <CardItemDropdownColButton {...{
                                                text: this.props.translation.extendButton,
                                                img: IconRefreshYellow16px,
                                                onClick: this.onClickExtendCard,
                                            }} />
                                        )}
                                    </CardItemDropdownCol>

                                    <CardItemDropdownCol label={this.props.translation.allowedToBuy} text={this.props.allowedToBuy}>
                                        {!this.props.needsActivation && this.props.isBlocked && this.props.canOpen && (
                                            <CardItemDropdownColButton {...{
                                                text: this.props.translation.unblockButton,
                                                img: IconPlusYellow,
                                                onClick: this.onClickUnblockCard,
                                            }} />
                                        )}

                                        {!this.props.needsActivation && !this.props.isBlocked && (
                                            <CardItemDropdownColButton {...{
                                                text: this.props.translation.blockButton,
                                                img: IconCrossCircleYellow16px,
                                                onClick: this.onClickBlockCard,
                                            }} />
                                        )}

                                        {this.props.needsActivation && (
                                            <CardItemDropdownColButton {...{
                                                text: this.props.translation.activateButton,
                                                img: IconCheckOrange,
                                                onClick: this.onClickActivateCard,
                                            }} />
                                        )}
                                    </CardItemDropdownCol>
                                </div>
                            )}

                            {this.props.cardType === 'Discount' && (
                                <div className={`cards-collection-item-large_dropdown ${styles.discountCardItemDropdown}`}>
                                    <CardItemDropdownColButton {...{
                                        className: styles.editNameButton,
                                        text: this.props.translation.editNameButton,
                                        img: IconInputYellow16px,
                                        onClick: this.onClickEditName,
                                    }} />

                                    {!this.props.needsActivation && (
                                        <>
                                            <CardItemDropdownColText
                                                className={styles.expiryText}
                                                label={this.props.translation.expiry}
                                                text={this.props.expiresAtPretty} />

                                            <CardItemDropdownColButton {...{
                                                className: styles.extendButton,
                                                text: this.props.translation.extendButton,
                                                img: IconRefreshYellow16px,
                                                onClick: this.onClickExtendCard,
                                            }} />
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    </CSSTransition>
                    }
                </TransitionGroup>
            </div>
        );
    }
});

const ExpandableCardsTitle = React.memo(props =>
    <div className="cards_expandable-collection_title">
        <BlackCheckbox {...{ checked: props.checked, onChange: props.onCheck }} />
        <label className="cards_expandable-collection_title_text" htmlFor={props.type}>{props.title}</label>
        <label className="cards_expandable-collection_title_count" style={{ background: props.counterBackground, color: props.counterTxtColor }}>{props.count}</label>
    </div>
);

const CardsCollection = React.memo(props =>
    <div className="cards_collection">{props.items && props.items.map((item, index) =>
        <CardItem {...{
            ...item,
            key: index,
            onChecked: e => props.onCardCheckedChanged(props.title && props.title.title, index, e.target.checked),
            onExpandToggle: e => props.onCardExpandedChanged(props.title && props.title.title, index, !item.expanded),
            onModalOpen: props.onModalOpen,
            onModalClose: props.onModalClose,
            updateCards: props.updateCards,
            clientTotalLimit: props.clientTotalLimit,
            refuelingParameters: props.refuelingParameters,
            stations: props.stations
        }} />)}
    </div>
);

const ExpandableCards = connect(state => ({ isMobileLayout: state.size.isMobileLayout }))(React.memo(props => {
    return <ExpandableContent {...{
        className: "cards_expandable-collection",
        title: <ExpandableCardsTitle {...props.title} />,
        content: <CardsCollection {...props} />,
    }} />
}));

class CardGroups extends React.PureComponent {
    render() {
        if (this.props.cards && this.props.cards.cardsUnGrouped) {
            return <div className="card-groups ungrouped"><CardsCollection {...{
                items: this.props.cards.cardsUnGrouped, status: "normal",
                onCardCheckedChanged: this.props.onCardCheckedChanged,
                onCardExpandedChanged: this.props.onCardExpandedChanged,
                onModalOpen: this.props.onModalOpen,
                onModalClose: this.props.onModalClose,
                refuelingParameters: this.props.refuelingParameters,
                stations: this.props.stations
            }} /></div>
        } else {
            return (
                <div className="card-groups">
                    {this.props.cards && Object.keys(this.props.cards).filter(x => this.props.cards[x].cards).map((x, i) => {
                        const cards = this.props.cards[x];
                        return (<ExpandableCards {...{
                            key: i,
                            title: {
                                ...cards,
                                title: x,
                                checked: cards.cards.every(x => x.checked),
                                onCheck: (e) => this.props.onCardGroupCheckedChanged(x, e.target.checked)
                            },
                            items: cards.cards.map(c => ({...c, isBlocked: ['blocked', 'blokeeritud', 'блокированный'].includes(x)})),
                            onCardCheckedChanged: this.props.onCardCheckedChanged,
                            onCardExpandedChanged: this.props.onCardExpandedChanged,
                            onModalOpen: this.props.onModalOpen,
                            onModalClose: this.props.onModalClose,
                            updateCards: this.props.updateCards,
                            clientTotalLimit: this.props.clientTotalLimit,
                            refuelingParameters: this.props.refuelingParameters,
                            stations: this.props.stations
                        }} />);
                    })}
                </div>
            );
        }
    }
}

export default CardGroups;
