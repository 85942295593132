import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';
import "./Table.scss";
import "./TableTippy.scss";
import IconFiltersDots from "../assets/Icon_Filters_Dots.svg";
import IconFiltersDotsActive from "../assets/Icon_Filters_Dots-Active.svg";
import IconOrderDescending from "../assets/Icon_Order_Descending.svg";
import IconOrderDescendingActive from "../assets/Icon_Order_Descending-Active.svg";
import IconOrderAscending from "../assets/Icon_Order_Ascending.svg";
import IconOrderAscendingActive from "../assets/Icon_Order_Ascending-Active.svg";

const TippyContent = connect(state => ({ translation: state.translation.table }))(React.memo(props => {
    return (<div className={`table-header-cell_filter-tippy ${props.withSearch ? "" : "no-search"}`}>
        {props.withSearch && <input className="table-header-cell_filter-tippy_input"
            type="text" size="1"
            placeholder={props.translation.searchPlaceholder}
            value={props.selectedOrderSearch || ''}
            onChange={e => props.onTableColumnFilterSelected(props.selectedOrderHow, props.selectedSearchOverride ? props.selectedOrderWhat : props.orderWhat, e.target.value)} />}
        <button className={`table-header-cell_filter-tippy_button ${props.ascIsSelected ? "selected" : ""}`} onClick={() => props.onTableColumnFilterSelected("ASC", props.orderWhat, props.selectedOrderSearch)}>
            <img className="table-header-cell_filter-tippy_button-img inactive" src={IconOrderAscending} alt="" />
            <img className="table-header-cell_filter-tippy_button-img active" src={IconOrderAscendingActive} alt="" />
            <span className="table-header-cell_filter-tippy_button-text">{props.translation.asc}</span>
        </button>
        <button className={`table-header-cell_filter-tippy_button ${props.descIsSelected ? "selected" : ""}`} onClick={() => props.onTableColumnFilterSelected("DESC", props.orderWhat, props.selectedOrderSearch)}>
            <img className="table-header-cell_filter-tippy_button-img inactive" src={IconOrderDescending} alt="" />
            <img className="table-header-cell_filter-tippy_button-img active" src={IconOrderDescendingActive} alt="" />
            <span className="table-header-cell_filter-tippy_button-text">{props.translation.desc}</span>
        </button>
    </div>);
}));

TippyContent.propTypes = {
    ascIsSelected: PropTypes.bool,
    descIsSelected: PropTypes.bool,
    onTableColumnFilterSelected: PropTypes.func,
    orderWhat: PropTypes.string,
    selectedOrderSearch: PropTypes.string,
    withSearch: PropTypes.bool,
    selectedOrderWhat: PropTypes.string,
    selectedOrderHow: PropTypes.string,
    selectedSearchOverride: PropTypes.bool
};

export const TableHeaderCell = React.memo(props => {
    const isSelected = props.orderWhat === props.selectedOrderWhat;
    const ascIsSelected = isSelected && props.selectedOrderHow === "ASC";
    const descIsSelected = isSelected && props.selectedOrderHow === "DESC";
    return (
        <th className={`table-header-cell ${props.className ? props.className : ""}`}>
            <Tippy zIndex={1} placement={"bottom"} arrow={true} interactive={true} trigger={"click"} theme={"light-border"}
                content={<TippyContent {...{
                    selectedOrderSearch: (isSelected || props.selectedSearchOverride) ? props.selectedOrderSearch : "",
                    onTableColumnFilterSelected: props.onTableColumnFilterSelected,
                    ascIsSelected: ascIsSelected,
                    descIsSelected: descIsSelected,
                    orderWhat: props.orderWhat,
                    withSearch: props.withSearch,
                    isSelected,
                    selectedSearchOverride: props.selectedSearchOverride,
                    selectedOrderWhat: props.selectedOrderWhat,
                    selectedOrderHow: props.selectedOrderHow
                }} />} >
                <button className={`table-header-cell_filter-button ${isSelected ? "selected" : ""}`} disabled={!props.onTableColumnFilterSelected || props.orderWhat === undefined}>
                    <span className="table-header-cell_filter-button_text">{props.text}</span>
                    <img className="table-header-cell_filter-button_img inactive" src={IconFiltersDots} alt="show column filters" />
                    <img className="table-header-cell_filter-button_img active" src={IconFiltersDotsActive} alt="show column filters" />
                </button>
            </Tippy>
        </th >
    );
});
TableHeaderCell.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    selectedOrderWhat: PropTypes.string,
    selectedOrderHow: PropTypes.string,
    selectedOrderSearch: PropTypes.string,
    onTableColumnFilterSelected: PropTypes.func,
    orderWhat: PropTypes.any,
    withSearch: PropTypes.bool,
    selectedSearchOverride: PropTypes.bool
};
TableHeaderCell.defaultProps = {
    withSearch: true,
    selectedSearchOverride: false
};

export const TableDataCell = React.memo(props =>
    <td className={`table-data-cell ${props.className ? props.className : ""}`}>
        {props.content}
    </td>
);
TableDataCell.propTypes = {
    className: PropTypes.string,
    content: PropTypes.object
}

export const BackgroundTable = React.memo(props =>
    <table className={`table ${props.className ? props.className : ""}`}>
        <thead>
            <tr className={`table-header-row background ${props.headerRowClass || ''} ${props.stickyHeaderClass || ''}`}>
                <th className={`table-header-cell`}></th>
            </tr>
            <tr className={`table-header-row background ${props.headerRowClass || ''}`}>
                <th className={`table-header-cell`}></th>
            </tr>
        </thead>
        <tbody>
            {props.items && props.items.map((item, index) => <tr className={`table-body-row ${props.hovered === index ? "hover" : ""}`} key={index}><td className={`table-data-cell`}></td></tr>)}
        </tbody>
    </table>
);

BackgroundTable.propTypes = {
    className: PropTypes.string,
    stickyHeaderStyle: PropTypes.object,
    items: PropTypes.array,
    hovered: PropTypes.number,
    headerRowClass: PropTypes.string,
};

export const withBackgroundTableHighlighting = (WrappedComponent) => {
    return class extends React.PureComponent {
        constructor(props) {
            super(props);
            this.state = { hoveredIndex: null };
        }
        onMouseOverTableRow = (index) => {
            this.setState({ hoveredIndex: index });
        }
        onMouseOutTableRow = () => {
            this.setState({ hoveredIndex: null });
        }
        render() {
            return (<WrappedComponent {...{ ...this.props, ...this.state, onMouseOverTableRow: this.onMouseOverTableRow, onMouseOutTableRow: this.onMouseOutTableRow }} />);
        }
    }
}
