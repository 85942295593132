import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { businessWholeSaleRequest } from '../../../../Networking';
import { buildClassList, roleOrLanguageChanged } from '../../../../Utils';
import IconTimeDarker20px from '../../assets/Icon_Time_Darker_20px.svg';
import CalendarFilter from '../../components/CalendarFilter';
import Loader from '../../components/Loader';
import MobileFiltersButton from '../../components/MobileFiltersButton';
import NoContent from '../../components/NoContent';
import Pagination from '../../components/Pagination';
import { BackgroundTable, withBackgroundTableHighlighting } from '../../components/Table';
import { withScrollingHeader } from '../../components/withScrollingHeader';
import { dateToString } from '../../helpers';
import { FilterTypeEnum } from '../../person/transactions/helpers/FilterTypeEnum';

import OrderHistoryTable from './OrderHistoryTable.js';
import styles from './OrderHistory.module.scss';

const Transition = props => (
    <CSSTransition timeout={{ enter: 250, exit: 250 }} classNames="fast-fade" {...props} />
);

const OrderHistoryFilters = ({ dateRange, periods, onChange }) => (
    <div className={styles.filterItems}>
        <TransitionGroup component={null}>
            <CSSTransition timeout={{ enter: 600, exit: 600 }} classNames={{
                enterActive: styles.enter,
                exitDone: styles.exit,
            }}>
                <CalendarFilter {...{
                    leftImg: IconTimeDarker20px,
                    text: 'selectedPeriodTitle', // TODO
                    items: periods,
                    type: FilterTypeEnum.calendar,
                    onChange: onChange,
                    dateRange: dateRange,

                    distance: -88,
                    offset: -75,
                    theme: 'dropdown-filter dropdown-calendar-filter'
                }} />
            </CSSTransition>
        </TransitionGroup>
    </div>
);

const mapApiOrder = (res) => {
    const [date, time] = res.datetime.split(' ');
    const amount = res.price * res.quantity;

    return {
        ...res,
        date,
        time,
        amount,
        vat: (amount * res.vat).toFixed(2),
        fullAmount: (amount * (1 + parseFloat(res.vat))).toFixed(2),
    };
};

function createPages(num) {
    const obj = {};
    for (let i = 1; i <= num; i++) {
        obj[i] = i;
    }
    return obj;
}

class OrderHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaderVisible: true,
            isNoContentVisible: false,
            isTableVisible: false,

            selectedPeriodId: null,
            selectedPage: 1,
            selectedOrderHow: null,
            selectedOrderWhat: null,
            selectedOrderSearch: null,

            periods: [],
            dateRange: {
                startDate: null,
                endDate: null,
                isVisible: false,
                selectsStart: false,
                selectionStart: true,
                selectedPeriodId: '1',
                text: '',
            },
            pages: {},
            orders: [],
        };

        this.load = this.load.bind(this);
        this.onSelectPage = this.onSelectPage.bind(this);
        this.onDateRangeChange = this.onDateRangeChange.bind(this);
        this.onTableColumnFilterSelected = this.onTableColumnFilterSelected.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (roleOrLanguageChanged(prevProps, this.props)) {
            this.load();
        }
    }

    load() {
        this.setState({ isLoaderVisible: true }, async () => {
            const request = {
                requestType: 'getOrders',
                page: this.state.selectedPage,
                orderHow: this.state.selectedOrderHow,
                orderWhat: this.state.selectedOrderWhat,
                orderSearch: this.state.selectedOrderSearch,
            };

            if (this.state.selectedPeriodId || !this.state.dateRange.endDate) {
                request.periodId = this.state.selectedPeriodId;
            } else {
                request.fromPeriod = dateToString(this.state.dateRange.startDate);
                request.toPeriod = dateToString(this.state.dateRange.endDate);
            }

            let records;

            try {
                records = await this.request(request);
            } catch (err) {
                console.error(err);
                return;
            }

            this.setState(prevState => ({
                isLoaderVisible: false,
                isTableVisible: records.orders.length > 0,
                isNoContentVisible: !records.orders.length,

                periods: Object.entries(records.periods).map(([value, text]) => ({
                    text,
                    value: String(value),
                    from: new Date(records.periodsFromTo[value].from + 'T00:00:00'),
                    to: new Date(records.periodsFromTo[value].to + 'T00:00:00'),
                    onClick: () => this.onSelectPeriod(value)
                })),

                dateRange: {
                    startDate: prevState.dateRange.startDate || (records.selectedPeriodId ? new Date(records.periodsFromTo[String(records.selectedPeriodId)].from + 'T00:00:00') : null),
                    endDate: prevState.dateRange.endDate || (records.selectedPeriodId ? new Date(records.periodsFromTo[String(records.selectedPeriodId)].to + 'T00:00:00') : null),
                    isVisible: prevState.dateRange.isVisible,
                    selectsStart: false,
                    selectionStart: true,
                    selectedPeriodId: prevState.selectedPeriodId,
                },

                selectedPeriodId: records.selectedPeriodId || this.state.selectedPeriodId,

                pages: createPages(records.pages),
                orders: records.orders.map(mapApiOrder),
            }));
        });
    }

    request(body) {
        return new Promise((resolve, reject) => {
            this.props.businessWholeSaleRequest({
                accNr: this.props.login.currentRole.accnr,
                userId: this.props.login.currentRole.userId,
                token: this.props.login.token,
                ...body,
            }, ({ status, records }) => {
                if (status.error) {
                    reject(new Error(`Error ${status.error}: ${status.message}`));
                } else if (records.statusCode !== 200) {
                    reject(new Error(`Error ${records.statusCode}: ${records.result}`));
                } else {
                    resolve(records);
                }
            }, reject);
        });
    }

    onSelectPeriod(selectedPeriodId) {
        this.setState({
            selectedPeriodId,
            selectedPage: 1,
            dateRange: {
                ...this.state.dateRange,
                selectedPeriodId,
            },
        }, this.load);
    }

    onDateRangeChange({ startDate, endDate }) {
        this.setState({
            selectedPeriodId: null,
            selectedPage: 1,
            dateRange: {
                ...this.state.dateRange,
                startDate,
                endDate,
            },
        }, this.load);
    }

    onSelectPage(selectedPage) {
        if (parseInt(selectedPage, 10) !== this.state.selectedPage) {
            this.setState({ selectedPage }, this.load);
        }
    }

    onTableColumnFilterSelected(selectedOrderHow, selectedOrderWhat, selectedOrderSearch) {
        this.setState({
            selectedOrderHow,
            selectedOrderWhat,
            selectedOrderSearch,
        }, async () => {
            await new Promise(resolve => setTimeout(resolve, 900));

            if (
                this.state.selectedOrderHow === selectedOrderHow &&
                this.state.selectedOrderWhat === selectedOrderWhat &&
                this.state.selectedOrderSearch === selectedOrderSearch
            ) {
                this.load();
            }
        });
    }

    render() {
        const headerClass = buildClassList({
            [styles.fixed]: this.props.isHeaderFixed,
            [styles.hiding]: this.props.isHeaderHiding,
            [styles.animated]: this.props.isHeaderAnimated,
        });

        const tableHeaderClass = buildClassList({
            [styles.fixed]: this.props.isHeaderFixed,
            [styles.hidden]: !this.props.isHeaderFixed,
            [styles.alt]: this.props.isHeaderHiding,
            [styles.animated]: this.props.isHeaderAnimated,
        });

        return (
            <div className={styles.page}>
                <div className={styles.orders}>
                    <div className={styles.headerPlaceholder}></div>
                    <div className={`${styles.headerStatic} ${headerClass}`}>
                        <div className={styles.background} />
                        <div className={styles.header}>
                            <div className={styles.items}>
                                <OrderHistoryFilters
                                    dateRange={this.state.dateRange}
                                    periods={this.state.periods}
                                    onChange={this.onDateRangeChange} />
                            </div>
                        </div>
                        <MobileFiltersButton {...{
                            className: styles.headerMobileFilters,
                            onClick: () => this.props.onMobileFiltersOpen([
                                {
                                    text: this.state.periods.find(p => String(p.value) === String(this.state.selectedPeriodId))?.text,
                                    items: this.state.periods,
                                },
                            ])
                        }} />
                    </div>

                    <TransitionGroup component={null}>
                        {this.state.isTableVisible && (
                            <Transition key="table-background">
                                <div className={styles.bodyBackground}>
                                    <BackgroundTable {...{
                                        items: this.state.orders,
                                        hovered: this.props.hoveredIndex,
                                        headerRowClass: styles.tableHeaderRow,
                                        stickyHeaderClass: tableHeaderClass
                                    }} />
                                </div>
                            </Transition>
                        )}
                        {this.state.isLoaderVisible && (
                            <Transition key="loader-fade">
                                <Loader className={styles.loader} />
                            </Transition>
                        )}

                        <Transition key="body-content">
                            <div className={styles.body}>
                                <TransitionGroup component={null}>
                                    {this.state.isNoContentVisible && (
                                        <Transition key="no-content">
                                            <NoContent {...{ text: this.props.translation.noContent }} />
                                        </Transition>
                                    )}

                                    {this.state.isTableVisible &&
                                        <Transition key="table-content">
                                            <>
                                                <OrderHistoryTable {...{
                                                    items: this.state.orders,
                                                    selectedOrderHow: this.state.selectedOrderHow,
                                                    selectedOrderWhat: this.state.selectedOrderWhat,
                                                    selectedOrderSearch: this.state.selectedOrderSearch,
                                                    onTableColumnFilterSelected: this.onTableColumnFilterSelected,
                                                    onMouseOverElement: this.props.onMouseOverTableRow,
                                                    onMouseOutElement: this.props.onMouseOutTableRow,
                                                    headerRowClass: styles.tableHeaderRow,
                                                    stickyHeaderClass: tableHeaderClass,
                                                }} />

                                                <Pagination {...{
                                                    className: styles.pagination,
                                                    selectedPage: this.state.selectedPage,
                                                    pages: this.state.pages,
                                                    handlePageSelected: this.onSelectPage,
                                                }} />
                                            </>
                                        </Transition>}
                                </TransitionGroup>
                            </div>
                        </Transition>
                    </TransitionGroup>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        login: state.login,
        isMobileLayout: state.size.isMobileLayout,
        translation: state.translation.wholesale.orderHistory,
    }),
    dispatch => ({
        businessWholeSaleRequest: (body, success, error) => businessWholeSaleRequest(dispatch, body, success, error),
    })
)(withBackgroundTableHighlighting(withScrollingHeader(OrderHistory, 0, 80 + 72, 80, 52)));
