import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Loader from '../../../components/Loader';
import { VictoryChart, VictoryAxis, VictoryBar, VictoryLabel } from "victory";
import ContentContainer from '../../../components/ContentContainer';
import DropdownFilter from '../../../components/DropdownFilter';
import './FuelUsage.scss';
import IconChevronDownGray16px from '../../../assets/Icon_Chevron-Down_Gray-16px.svg'

class FuelChart extends PureComponent {
    constructor(props) {
        super(props);
        this.chartScrollRef = React.createRef();
    }

    getAxisLabelColor = (month, value) => {
        return (value === 0) || (this.props.isCurrentYear && month === this.props.currentMonth) ? "black" : "white";
    }

    getBarColor = (month, value) => {
        return (value === 0) ? "clear" : (this.props.isCurrentYear && month === this.props.currentMonth ? "#FFCD00" : "black");
    }

    getBarLabelColor = (month, value) => {
        return (value === 0) ? "clear" : (this.props.isCurrentYear && month === this.props.currentMonth ? "black" : "#FFCD00");
    }

    componentDidMount() {
        if (this.props.isMobileLayout) {
            const current = this.chartScrollRef.current;
            const width = current.clientWidth;
            const scrollWidth = current.scrollWidth;
            const month = new Date().getMonth();
            const scrollWhere = scrollWidth / 12 * month - width;
            if (scrollWhere > 0) {
                current.scrollLeft = scrollWhere;
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.isMobileLayout && this.props.isMobileLayout) {
            const current = this.chartScrollRef.current;
            const width = current.clientWidth;
            const scrollWidth = current.scrollWidth;
            const month = new Date().getMonth();
            const scrollWhere = scrollWidth / 12 * month - width;
            if (scrollWhere > 0) {
                current.scrollLeft = scrollWhere;
            }
        }
    }

    render() {
        return (
            <div className="fuel-chart">
                <div className="fuel-chart-scroll" ref={this.chartScrollRef}>
                    <div className="fuel-chart-svg-scroll"></div>
                    <div className="fuel-chart-wrap">
                        <VictoryChart width={1024} height={210} padding={{ top: 24, bottom: 0 }} domainPadding={{ x: 36, y: 0 }}>
                            <VictoryBar
                                barWidth={78}
                                style={{
                                    data: {
                                        fill: d => this.getBarColor(d._x - 1, d._y)
                                    },
                                    labels: {
                                        fill: d => this.getBarLabelColor(d._x - 1, d._y),
                                        fontSize: "18px",
                                        letterSpacing: "0.7px",
                                        fontFamily: "Bebas Neue",
                                        fontWeight: "bold"
                                    }
                                }}
                                categories={{ x: this.props.months }}
                                data={this.props.dataMin}
                                labels={d => d.y}
                                labelComponent={<VictoryLabel dy={40} text={d => {
                                    let found = this.props.data.find(x => x._x === d._x);
                                    if (found) {
                                        return `${found.y} L`;
                                    }
                                    return `${d.y} L`;
                                }} />}
                            />
                            <VictoryAxis
                                dependentAxis={false}
                                crossAxis={false}
                                style={{
                                    axis: {
                                        stroke: "clear"
                                    },
                                    tickLabels: {
                                        fill: d => this.getAxisLabelColor(d - 1, this.props.getValueForMonth(d)),
                                        fontSize: "14px",
                                        letterSpacing: "0.7px",
                                        fontFamily: "Bebas Neue",
                                        fontWeight: "bold"
                                    }
                                }}
                                tickLabelComponent={<VictoryLabel dy={-40} dx={0} />}
                            />
                        </VictoryChart>
                    </div>
                </div>
            </div>
        );
    }
}

class FuelUsage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = this.getStateFrom(props);
    }

    yearSelected = (year) => {
        this.setState({ selectedYear: year });
    }

    getStateFrom = (props) => {
        const years = (props.saleStatistics && Object.keys(props.saleStatistics).sort((a, b) => parseInt(b) - parseInt(a))) || [];
        const latest = years[0];
        const yearsDropdown = years.map(x => { return { text: x, onClick: () => this.yearSelected(x) } });
        return { selectedYear: latest, yearsDropdown: yearsDropdown };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.saleStatistics !== this.props.saleStatistics) {
            this.setState({ ...this.getStateFrom(this.props) });
        }
    }

    getValueForMonth = (month) => {
        const values = [null, "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        const value = (this.props.saleStatistics && this.state.selectedYear && this.props.saleStatistics[this.state.selectedYear][values[month]]) || null;
        return value ? value : 0;
    }

    convertRange = (oldMin, oldMax, oldVal, newMin, newMax) => {
        const oldRange = oldMax - oldMin;
        if (Math.abs(oldRange) < 0.1) {
            return newMax;
        } else {
            return (((oldVal - oldMin) * (newMax - newMin)) / oldRange) + newMin;
        }
    }

    render() {
        const months = this.props.translation.months;
        const data = months.map((value, index) => { return { x: value, _x: index + 1, y: this.getValueForMonth(index + 1) } }).filter(x => x.y > 0);
        const dataY = data.map(x => x.y);
        const min = Math.min(...dataY);
        const max = Math.max(...dataY);
        const dataMin = data.map(x => ({ ...x, y: this.convertRange(min, max, x.y, 32, 100) })); //32 just above the month name text on the chart
        return (
            <ContentContainer className="fuel-usage" {...{
                title: <h1 className="fuel-usage_title">{this.props.translation.title}</h1>,
                action: <DropdownFilter {...{ className: "fuel-usage-tippy-dropdown", text: this.state.selectedYear, rightImg: IconChevronDownGray16px, items: this.state.yearsDropdown, distance: -56 }} />,
                content:
                    <TransitionGroup component={null}>
                        {this.props.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <div className="fuel-usage-loader-container"><Loader {...{ className: "fuel-usage-loader" }} /></div>
                        </CSSTransition>}
                        {!this.props.isLoaderVisible && <CSSTransition key={"fuel-usage-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <FuelChart {...{
                                months: months,
                                data: data,
                                dataMin: dataMin,
                                getValueForMonth: (month) => this.getValueForMonth(month),
                                isCurrentYear: new Date().getFullYear() === parseInt(this.state.selectedYear),
                                currentMonth: new Date().getMonth(),
                                isMobileLayout: this.props.isMobileLayout
                            }} />
                        </CSSTransition>}
                    </TransitionGroup>
            }} />
        );
    }
}

export default connect(state => ({
    isMobileLayout: state.size.isMobileLayout,
    translation: state.translation.overview.fuelUsage
}))(FuelUsage)