import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withCloseOnEsc } from '../../../components/withCloseOnEsc';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ContentContainer from '../../../components/ContentContainer';
import DropdownFilter from '../../../components/DropdownFilter';
import GoogleMapReact from 'google-map-react';
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import { businessCardsRequest } from '../../../../../Networking';
import './OrderCard.scss';
import IconModalCross16px from "../../../assets/Icon_Modal-Cross_16px.svg";
import IconChevronSmallBack from "../../../assets/Icons_Chevron_Small-Back.svg";
import IconChevronDownWhite from "../../../assets/Icon_Chevron-Down_White.svg";
import IconPlusYellow from '../../../assets/Icon_Plus_Yellow.svg';
import IconChevronSmallForward from "../../../assets/Icon_Chevron_Small-Forward.svg";
import IconMapDotUnselected from "../../../assets/Icon_Map_Dot_Unselected.svg";
import IconMapDotSelected from "../../../assets/Icon_Map_Dot_Selected.svg";
import IconMapRingSelected from "../../../assets/Icon_Map_Ring_Selected.svg";
import { mapStyle } from '../../../../assets/MapStyle';

const Modal = withCloseOnEsc(React.memo(props => (
    <ContentContainer {...{
        className: `order-card-modal ${props.className ? props.className : ""}`,
        title: <h1 className="order-card-modal_title">{props.title}</h1>,
        action:
            <button className="order-card-modal_close-button" onClick={props.onCloseButtonClick}>
                <img className="order-card-modal_close-button_img" src={IconModalCross16px} alt="close" />
            </button>,
        content:
            <div className="order-card-modal-content">
                <div className="order-card-modal-content_content">
                    {props.content}
                    <p className="modal-result">{props.result}</p>
                </div>
            </div>
    }} />
)));

const CardsModalInputName = connect(state => ({ translation: state.translation.cards.orderCard }))(React.memo(props =>
    <div className={`cards-modal-input-name ${props.className ? props.className : ""}`}>
        <label className="cards-modal-input-name_label" htmlFor={props.id}>
            <span className="cards-modal-input-name_label-left">{props.text}</span>
            {props.maxLength && <span className="cards-modal-input-name_label-right">{props.translation.maxLength(props.value.length, props.maxLength)}</span>}
        </label>
        <input className={`cards-modal-input-name_input ${props.backgroundImage}`} type="text" size="1" id={props.id} value={props.value} onChange={props.onChange} maxLength={props.maxLength}
            required={props.required}></input>
    </div>
));

const CardsModalInputLimit = connect(state => ({ translation: state.translation.cards.orderCard }))(React.memo(props =>
    <div className="cards-modal-cards-limit_input-container">
        <input className="cards-modal-cards-limit_input-input" type="text" size={props.size} id={props.id} value={props.value} onChange={props.onChange} maxLength={props.maxLength} required />
        <label className="cards-modal-cards-limit_input-label" htmlFor={props.id}>{props.suffix}</label>
    </div>
));

const CardsModalInput = connect(state => ({ translation: state.translation.cards.orderCard }))(React.memo(props =>
    <div className={`cards-modal-input ${props.className ? props.className : ""}`}>
        <label className="cards-modal-input_label" htmlFor={props.id}>
            <span className="cards-modal-input_label-left">{props.text}</span>
            {props.maxLength && <span className="cards-modal-input_label-right">{props.translation.maxLength(props.value.length, props.maxLength)}</span>}
        </label>
        <input className={`cards-modal-input_input ${props.backgroundImage}`} type="text" size="1" id={props.id} value={props.value} onChange={props.onChange} required={props.required}></input>
    </div>
));

export const CardsModalDropdown = React.memo(props =>
    <div className={`cards-modal-cards-dropdown ${props.type}`}>
        <label className="cards-modal-cards-dropdown_label">{props.title}</label>
        <DropdownFilter {...{ className: `order-card-modal-dropdown ${props.type}`, text: props.text, rightImg: IconChevronDownWhite, items: props.items, distance: -44, disabled: props.disabled }} />
    </div>
);

const CardsModalCardItem = connect(state => ({ translation: state.translation.cards.orderCard }))(React.memo(props => {
    const limitPerMonthSize = Math.max((props.limit && props.limit.toString().length) || 0, 1);
    const limitPerDaySize = Math.max((props.dayLimit && props.dayLimit.toString().length) || 0, 1);

    return (
        <div className="order-card-modal-content-card">
            <CardsModalInputName {...{
                text: props.translation.nameField,
                id: `name-${props.index}`,
                value: props.name,
                onChange: (e) => props.onFieldChange("name", e),
                maxLength: 30,
                required: true,
                backgroundImage: "card"
            }} />

            <div className="cards-modal-cards-limit">
                <label className="cards-modal-cards-limit_top-label" htmlFor={`limit-${props.index}`}>{props.translation.limitField}</label>
                <div className="cards-modal-cards-limit_input-container">
                    <CardsModalInputLimit {...{
                        id: `limit-${props.index}`,
                        value: props.limit,
                        onChange: e => props.onFieldChange('limit', e),
                        maxLength: 9,
                        size: limitPerMonthSize,
                        suffix: props.translation.perMonthLimit,
                    }} />
                </div>
                <div className="cards-modal-cards-limit_input-container">
                    <CardsModalInputLimit {...{
                        id: `dayLimit-${props.index}`,
                        value: props.dayLimit,
                        onChange: e => props.onFieldChange('dayLimit', e),
                        maxLength: 9,
                        size: limitPerDaySize,
                        suffix: props.translation.perDayLimit,
                    }} />
                </div>
            </div>

            <CardsModalDropdown {...{ type: "fuel", title: props.translation.fuelField, text: props.fuelTypes[props.selectedFuelType - 1].text, items: props.fuelTypes }} />
            <CardsModalDropdown {...{ type: "product", title: props.translation.productField, text: props.productTypes[props.selectedProductType - 1].text, items: props.productTypes, disabled: props.selectedFuelType === 1 }} />
            <button className="order-card-modal_delete-button" onClick={props.onDelete} >
                <img className="order-card-modal_delete-button_img" src={IconModalCross16px} alt="delete" />
            </button>
        </div>)
}));

const CardsModalPage1 = connect(state => ({ translation: state.translation.cards.orderCard.page1 }))(React.memo(props =>
    <div className="order-card-modal-page-1">
        <p className="order-card-modal-top-text">{props.translation.topText}</p>
        <div className="order-card-modal-content-cards">
            {props.cards && props.cards.map((item, index) => <CardsModalCardItem {...{
                key: index,
                index: index,
                ...item,
                onFieldChange: (field, e) => props.onFieldChange(index, field, e),
                onDelete: () => props.removeCard(index),
                selectedFuelType: item.selectedFuelType,
                selectedProductType: item.selectedProductType,
                fuelTypes: [{
                    text: props.translation.fuel1,
                    onClick: () => props.onFuelTypeChange(index, 1)
                }, {
                    text: props.translation.fuel2,
                    onClick: () => props.onFuelTypeChange(index, 2)
                }],
                productTypes: [{
                    text: props.translation.product1,
                    onClick: () => props.onProductTypeChange(index, 1)
                }, {
                    text: props.translation.product2,
                    onClick: () => props.onProductTypeChange(index, 2)
                }, {
                    text: props.translation.product3,
                    onClick: () => props.onProductTypeChange(index, 3)
                }]
            }} />)}
        </div>
        <div className="order-card-modal-buttons">
            {!props.extending && <button className="order-card-modal-add-card-button" onClick={props.addCard}>
                <img className="order-card-modal-add-card-button_img" src={IconPlusYellow} alt="" />
                <span className="order-card-modal-add-card-button_text">{props.translation.addCardButton}</span>
            </button>}
            <button className="order-card-modal-next-button" onClick={props.nextPage} disabled={!props.nextButtonActive}>
                <span className="order-card-modal-next-button_text">{props.translation.nextButton}</span>
                <img className="order-card-modal-next-button_img" src={IconChevronSmallForward} alt="" />
            </button>
        </div>
    </div>
));

const MapMarker = React.memo(props =>
    <Tippy arrow={true}
        isEnabled={props.loyaltyInfo && props.loyaltyInfo.loyaltyText !== undefined}
        content={props.name}>
        <div className="order-card-modal-map-marker" onClick={props.onClick} >
            <img className="order-card-modal-map-marker_img" src={props.selectStyle ? (props.isSelected ? IconMapRingSelected : IconMapDotSelected) : IconMapDotUnselected} alt="" />
        </div>
    </Tippy>
);
const CardsModalPage2 = connect(state => ({ translation: state.translation.cards.orderCard.page2 }))(React.memo(props =>
        <div className="order-card-modal-page-2">
            <div className="order-card-modal-page-2-inputs">
                <p className="order-card-modal-page-2-top-text">{props.translation.topText}</p>
                <CardsModalInput {...{
                    className: "name",
                    text: props.translation.pickupField,
                    id: `pickup-name`,
                    value: props.pickup.name,
                    onChange: (e) => props.onFieldChange("name", e.target.value),
                    required: true
                }} />
                <CardsModalInput {...{
                    className: "personal-code",
                    text: props.translation.pickupPersonalCode,
                    id: `pickup-personalcode`,
                    value: props.pickup.personalCode,
                    onChange: (e) => props.onFieldChange("personalCode", e.target.value),
                    required: true
                }} />
                <CardsModalInput {...{
                    className: "personal-email",
                    text: props.translation.pickupEmail,
                    id: `pickup-email`,
                    value: props.pickup.email,
                    onChange: (e) => props.onFieldChange("email", e.target.value),
                    required: true
                }} />
                <CardsModalDropdown {...{
                    type: "station",
                    title: props.translation.pickupLocation,
                    text: (props.pickup.selectedStation && props.pickup.selectedStation.name) || "",
                    items: props.stations.map(x => ({
                        text: x.name,
                        onClick: () => props.onFieldChange("selectedStation", x)
                    }))
                }} />
                <p className="order-card-modal-page-2_location-info-text">{props.translation.locationInfo}</p>
                {props.isError && <p className="order-card-modal-page-2_error">{props.result}</p>}
                <button className="order-card-modal-page-2_back-button" onClick={props.prevPage}>
                    <img className="order-card-modal-page-2_back-button_img" src={IconChevronSmallBack} alt=""/>
                    <span className="order-card-modal-page-2_back-button_text">{props.translation.backButton}</span>
                </button>
                <button className="order-card-modal-page-2_order-button" onClick={props.nextPage}
                        disabled={!props.nextButtonActive}>
                    <span className="order-card-modal-page-2_order-button_text">{props.translation.orderButton}</span>
                </button>
            </div>
            <div className="order-card-modal-page-2-map">
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: "AIzaSyAmZsw-uujy2JfcBeiFezmxLUJFUzwhRpg"
                    }}
                    center={[58.595272, 25.013607]}
                    defaultZoom={6}
                    options={maps => ({styles: mapStyle})}>
                    {props.stations && props.stations.map((item, index) => <MapMarker {...{
                        key: index,
                        lat: item.latitude,
                        lng: item.longitude,
                        name: item.name,
                        onClick: () => props.onFieldChange("selectedStation", item),
                        selectStyle: props.pickup.selectedStation && props.pickup.selectedStation.name !== null,
                        isSelected: (props.pickup.selectedStation && props.pickup.selectedStation.name === item.name) || false
                    }} />)}
                </GoogleMapReact>
            </div>
        </div>
));

const CardsModalPage3 = connect(state => ({translation: state.translation.cards.orderCard.page3}))(React.memo(props =>
    <div className="order-card-modal-page-3">
        <p className="order-card-modal-page-3_info">{props.result}</p>
        <button className="order-card-modal-page-3_close-button"
                onClick={props.close}>{props.translation.closeButton}</button>
    </div>
));

class OrderCard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            cards: props.cards,
            page: 1,
            pickup: {
                name: "",
                personalCode: "",
                email: props.login.currentRole.email,
                selectedStation: {name: ""},
            },
            extending: props.extending
        };
    }

    onFieldChange = (index, field, e) => {
        const cards = this.state.cards.map(x => x);
        cards[index][field] = e.target.value;
        this.setState({cards: cards});
    }

    addCard = () => {
        const cards = this.state.cards.map(x => x);
        const newCard = {
            name: '',
            limit: '500',
            dayLimit: '500',
            selectedFuelType: 1,
            selectedProductType: 1,
        };

        this.setState({
            cards: [...cards, newCard],
        });
    }

    removeCard = (id) => {
        const cards = this.state.cards.map(x => x);
        if (id === 0) {
            cards[id] = {name: "", limit: "0", selectedFuelType: 1, selectedProductType: 1};
        } else {
            cards[id] = undefined;
        }
        this.setState({cards: cards.filter(x => x)});
    }

    onFuelTypeChange = (index, selectedFuelType) => {
        const cards = this.state.cards.map(x => x);
        cards[index] = {
            ...cards[index],
            selectedFuelType: selectedFuelType,
            selectedProductType: selectedFuelType === 1 ? 1 : cards[index].selectedProductType
        };
        this.setState({cards: cards});
    }

    onProductTypeChange = (index, selectedProductType) => {
        const cards = this.state.cards.map(x => x);
        cards[index] = {...cards[index], selectedProductType: selectedProductType};
        this.setState({cards: cards});
    }

    onNavigateToPage = (page) => {
        this.setState({page: page});
    }

    onPickupFieldChange = (field, value) => {
        this.setState({pickup: {...this.state.pickup, [field]: value}})
    }

    addCards = (cards) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        this.setState({isLoaderVisible: true});
        this.props.cardsRequest(
            {
                "requestType": "addCards",
                "accNr": accnr,
                "token": this.props.login.token,
                "cardsData": cards
            },
            data => {
                this.setState({
                    result: data.records.result,
                    isDone: data.records.statusCode === 200,
                    isError: data.records.statusCode !== 200,
                    isLoaderVisible: false
                }, () => {
                    if (this.state.isDone) {
                        this.onNavigateToPage(3);
                    }
                })
            },
            error => {
                this.setState({ isLoaderVisible: false });
            });
    }

    render() {
        const className = this.state.page === 3 ? "small" : "";
        const title = this.state.page === 3 ? this.props.translation.cardsOrdered : this.props.translation.orderCards;
        let height = 0;
        if (this.state.page === 1) {
            height = 228 + 154 * (this.state.cards ? this.state.cards.length : 1);
        } else if (this.state.page === 2) {
            height = this.state.isError ? 516 : 492;
        } else {
            height = 196;
        }

        return (<Modal {...{
            className: className,
            onCloseButtonClick: this.props.onModalClose,
            title: title,
            content:
                <div className="order-card-content-size-anim" style={{ height: `${height}px` }}>
                    <TransitionGroup component={null}>
                        {this.state.page === 1 && <CSSTransition key={"page-1"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <CardsModalPage1 {...{
                                extending: this.state.extending,
                                cards: this.state.cards,
                                onFieldChange: this.onFieldChange,
                                removeCard: this.removeCard,
                                onFuelTypeChange: this.onFuelTypeChange,
                                onProductTypeChange: this.onProductTypeChange,
                                addCard: this.addCard,
                                nextButtonActive: this.state.cards.every(x => x.name),
                                nextPage: () => this.onNavigateToPage(2)
                            }} />
                        </CSSTransition>}
                        {this.state.page === 2 && <CSSTransition key={"page-2"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <CardsModalPage2 {...{
                                pickup: this.state.pickup,
                                onFieldChange: this.onPickupFieldChange,
                                stations: this.props.stations,
                                nextButtonActive: this.state.pickup.name && this.state.pickup.email && this.state.pickup.personalCode && this.state.pickup.selectedStation && this.state.pickup.selectedStation.name && !this.state.isLoaderVisible,
                                isError: this.state.isError,
                                result: this.state.result,
                                prevPage: () => this.onNavigateToPage(1),
                                nextPage: () => this.addCards({
                                    receiverName: this.state.pickup.name,
                                    receiverEmail: this.state.pickup.email,
                                    receiverPersonalCode: this.state.pickup.personalCode,
                                    stationName: this.state.pickup.selectedStation.name,
                                    cards: this.state.cards.map(x => ({
                                        name: x.name,
                                        limit: x.limit,
                                        dayLimit: x.dayLimit,
                                        allowedFuel: x.selectedFuelType,
                                        allowedProduct: x.selectedProductType,
                                        number: x.number
                                    }))
                                })
                            }} />
                        </CSSTransition>}
                        {this.state.page === 3 && <CSSTransition key={"page-3"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <CardsModalPage3 {...{
                                close: this.props.onModalClose,
                                result: this.state.result
                            }} />
                        </CSSTransition>}
                    </TransitionGroup>
                </div>
        }} />);
    }
}
OrderCard.propTypes = {
    cards: PropTypes.array,
    extending: PropTypes.bool,
    stations: PropTypes.array,
    onModalClose: PropTypes.func
};

export default connect(
    state => ({ login: state.login, translation: state.translation.cards.orderCard }),
    dispatch => ({ cardsRequest: (body, success, error) => businessCardsRequest(dispatch, body, success, error) }))(OrderCard);
