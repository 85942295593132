import Tippy from '@tippy.js/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { matchPath } from 'react-router';
import { NavHashLink as NavLink } from 'react-router-hash-link';

import TabLink from '../business/components/TabLink';
import styles from './PageHeaderTabs.module.scss';

const DropdownLink = React.memo(props => (
    <NavLink {...{
        className: styles.link,
        activeClassName: styles.active,
        exact: props.exact,
        to: props.to,
        location: { pathname: props.pathname },
        smooth: true,
        scroll: (e) => window.scrollTo({
            left: 0,
            top: e.offsetTop - 176,
            behavior: 'smooth',
        }),
        onClick: props.onClick,
    }}>
        {props.text}
    </NavLink>
));

export default class PageHeaderTabs extends Component {
    static propTypes = {
        className: PropTypes.string,
        pathname: PropTypes.string,
        links: PropTypes.arrayOf(PropTypes.object),
    };

    static getDerivedStateFromProps(props, state) {
        const links = props.links.map(link => ({
            ...link,
            active: matchPath(props.pathname, { path: link.to, exact: link.exact }),
        }));

        return {
            links,
            activeLink: links.find(link => link.active),
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            links: [],
            activeLink: null,
            isDropdownVisible: false,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize = () => {
        this.setState({ isDropdownVisible: false });
    };

    onToggleDropdown = () => {
        this.setState((prevState) => ({
            isDropdownVisible: !prevState.isDropdownVisible,
        }));
    };

    render() {
        return (
            <div className={this.props.className}>
                <div className={`${styles.tabs} ${styles.desktop}`}>
                    {this.props.links.map((link, i) => <TabLink {...link} key={i} />)}
                </div>

                {this.state.activeLink && (
                    <div className={`${styles.tabs} ${styles.mobile}`}>
                        <Tippy {...{
                            zIndex: 2,
                            placement: 'bottom-end',
                            arrow: false,
                            interactive: true,
                            trigger: 'manual',
                            animateFill: false,
                            distance: 8,
                            animation: 'fade',
                            isVisible: this.state.isDropdownVisible,
                            onHidden: () => {},
                            onHide: () => {},
                            content: (
                                <div className={styles.dropdown}>
                                    {this.state.links.filter(link => !link.active).map((link, i) => (
                                        <DropdownLink {...{
                                            ...link,
                                            key: i,
                                            onClick: () => this.setState({ isDropdownVisible: false }),
                                        }} />
                                    ))}
                                </div>
                            ),
                        }}>
                            <button onClick={this.onToggleDropdown}>{this.state.activeLink.text}</button>
                        </Tippy>
                    </div>
                )}
            </div>
        );
    }
}
