import React from 'react';
import PropTypes from 'prop-types';


export const withCloseOnEsc = (WrappedComponent) => (
    class extends React.PureComponent {
        keyEvent = (e) => {
            if (
                (('key' in e) && ((e.key === 'Escape') || (e.key === 'Esc'))) ||  // Modern browsers
                (('keyCode' in e) && (e.keyCode === 27))) // Legacy browsers
            {
                this.props.onCloseButtonClick();
            }
        };

        componentDidMount() {
            window.addEventListener("keyup", this.keyEvent);
            // window.addEventListener("keypress", this.keyEvent);
        }

        componentWillUnmount() {
            window.removeEventListener("keyup", this.keyEvent);
            // window.removeEventListener("keypress", this.keyEvent);
        }

        render() {
            return (<WrappedComponent {...{ ...this.props, ...this.state }} />);
        }
    }
);

withCloseOnEsc.propTypes = {
    onCloseButtonClick: PropTypes.func.isRequired
}