import React from 'react';
import { connect } from 'react-redux';
import { privateUpdateUserDataRequest } from '../../../../Networking';
import SelectModal from '../../components/SelectModal';

class LanguageChange extends React.PureComponent {
    render() {
        return (
            <SelectModal {...{
                title: this.props.translation.logout,
                onCloseButtonClick: this.props.onCloseButtonClick,
                items: this.props.sessions.map(session => ({
                    text: session.session_name,
                    onClick: () => this.props.onSessionEnd(session.apiCall)
                }))
            }} />
        );
    }
}

export default connect(
    state => ({
        login: state.login,
        translation: state.translation.navbar.userControls
    }),
    dispatch => ({ updateUserDataRequest: (body, success, error) => privateUpdateUserDataRequest(dispatch, body, success, error) }))(LanguageChange);