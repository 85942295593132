import React from "react";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import chevronDownBlack8px from "../../../assets/Icon_Chevron-Down_Black-8px.svg"
import {NavLink} from "react-router-dom";

export const MobileDropdownButton = React.memo(props =>
    <div className={`mobile-dropdown-button ${props.isMobileDropdownOpen ? "dropdown-open" : ""}`}>
        <p className="mobile-dropdown-button-content"  onClick={props.onClick}>
            <span className="mobile-dropdown-button-text">{props.text}</span>
            <img className="mobile-dropdown-button-chevron" src={chevronDownBlack8px} alt="" />
        </p>
    </div>
);


const MobileDropdownItem = React.memo(props => {
    const {to, onClick, text, leftElement} = props;
    return (
        to ? (
            <NavLink className="mobile-dropdown-item" onClick={onClick} to={to}>
                <span className="mobile-dropdown-item-container">
                    {leftElement}
                    <span className="mobile-dropdown-item-title">{text}</span>
                </span>
            </NavLink>
            ) : (
            <span className="mobile-dropdown-item" onClick={onClick}>
                <span className="mobile-dropdown-item-container">
                    {leftElement}
                    <span className="mobile-dropdown-item-title">{text}</span>
                </span>
            </span>
            )
        )
});

export const MobileDropdown = React.memo(props =>
    <div className="mobile-dropdown">
        <div className="mobile-dropdown-white-background">
            <TransitionGroup component={null}>
                {props.items && props.items.map((item, index) => <CSSTransition key={index} timeout={{ enter: 600, exit: 600 }} classNames={"nav-item"} appear>
                    <MobileDropdownItem {...item} />
                </CSSTransition>)}
            </TransitionGroup>
        </div>
        <div className="mobile-dropdown-translucent-backdrop" onClick={props.onClose} />
    </div>
);
