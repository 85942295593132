import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withCloseOnEsc } from '../../../components/withCloseOnEsc';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ContentContainer from '../../../components/ContentContainer';
import Loader from '../../../components/Loader';
import './Modal.scss';
import IconModalCross16px from "../../../assets/Icon_Modal-Cross_16px.svg";
import IconChevronSmallBack from "../../../assets/Icons_Chevron_Small-Back.svg";

export const ModalDone = React.memo(props =>
    <p className="modal-done-text">{props.text}</p>
);

export const ModalText = React.memo(props =>
    <p className="modal-text">{props.text}</p>
);

export const ModalInputTextLimit = connect(state => ({ translation: state.translation.manage.modal }), null)(React.memo(props => {
    const perPeriodText = props.perPeriod === 'day' ?
        props.translation.perDayLimit :
        props.translation.perMonthLimit;

    return (
        <div className={`modal-input-text ${props.className ? props.className : ""}`}>
            <label className="modal-input-text_label" htmlFor={props.id}>
                <span className="modal-input-text_label-left">{props.text}</span>
            </label>
            <div className="modal-input-text_limit">
                <input className={`modal-input-text_limit-input ${props.backgroundImage}`} type="text" size={props.size} id={props.id} value={props.value} onChange={props.onChange} maxLength={props.maxLength}
                    required={props.required}></input>
                <label className="modal-input-text_limit-info" htmlFor={props.id}>
                    <label className="modal-input-text_limit-period" htmlFor={props.id}>{perPeriodText}</label>
                    <label className="modal-input-text_limit-company" htmlFor={props.id}>{props.translation.companyLimit(props.clientTotalLimit)}</label>
                </label>
            </div>
        </div>
    );
}));

ModalInputTextLimit.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    text: PropTypes.string,
    backgroundImage: PropTypes.string,
    size: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.func,
    maxLength: PropTypes.any,
    perMonthLimit: PropTypes.any,
    clientTotalLimit: PropTypes.any,
    perPeriod: PropTypes.oneOf(['day', 'month']),
}

ModalInputTextLimit.defaultProps = {
    perPeriod: 'month',
};

export const ModalInputText = connect(state => ({ translation: state.translation.manage.modal }), null)(React.memo(props =>
    <div className={`modal-input-text ${props.className ? props.className : ""}`}>
        <label className="modal-input-text_label" htmlFor={props.id}>
            <span className="modal-input-text_label-left">{props.text}</span>
            {props.maxLength && <span className="modal-input-text_label-right">{props.translation.maxLength(props.value.length, props.maxLength)}</span>}
        </label>
        <input className={`modal-input-text_input ${props.backgroundImage}`} type={props.inputType || 'text'} size="1" id={props.id} value={props.value} onChange={props.onChange} maxLength={props.maxLength}
            required={props.required}></input>
    </div>
));
ModalInputText.propTypes = {
  inputType: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    text: PropTypes.string,
    value: PropTypes.string,
    maxLength: PropTypes.any,
    onChange: PropTypes.func,
    backgroundImage: PropTypes.string,
    required: PropTypes.bool
}

export const ModalInputSwitch3 = React.memo(props =>
    <div className={`modal-input-switch-3 ${props.className ? props.className : ""}`}>
        <label className="modal-input-switch-3_label">{props.text}</label>
        <button className={`modal-input-switch-3_button ${props.active && parseInt(props.active) === 1 ? "active" : ""}`} onClick={props.item1Click} disabled={props.disabled}>{props.item1}</button>
        <button className={`modal-input-switch-3_button ${props.active && parseInt(props.active) === 2 ? "active" : ""}`} onClick={props.item2Click} disabled={props.disabled}>{props.item2}</button>
        <button className={`modal-input-switch-3_button ${props.active && parseInt(props.active) === 3 ? "active" : ""}`} onClick={props.item3Click} disabled={props.disabled}>{props.item3}</button>
    </div>
);

export const Modal = withCloseOnEsc(connect(state => ({ translation: state.translation.manage.modal }))(React.memo(props => {
    const contentHeight = props.isDone ? "56px" : (props.height === "auto" ? props.height : `${props.height + (props.isError ? 44 : 0)}px`);
    return (<ContentContainer {...{
        className: "manage-modal",
        title: <h1 className="manage-modal_title">{props.title}</h1>,
        action:
            <button className="manage-modal_close-button" onClick={props.onCloseButtonClick}>
                <img className="manage-modal_close-button_img" src={IconModalCross16px} alt="close" />
            </button>,
        content:
            <div className="manage-modal-content" >
                <div className="manage-modal-content_content" style={{ height: contentHeight }}>
                    {!props.isDone && props.content}
                    {props.isDone && <p className="modal-done-text">{props.result}</p>}
                    {props.isError && <p className="modal-error-text">{props.result}</p>}
                </div>
                <button className={`manage-modal_button back ${props.isDone ? "close" : ""}`} onClick={props.onCloseButtonClick}>
                    <img className="manage-modal_button_img" src={IconChevronSmallBack} alt="" />
                    <span className="manage-modal_button_text">{props.isDone ? props.translation.closeButton : props.translation.backButton}</span>
                </button>
                <TransitionGroup component={null}>
                    {props.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <Loader {...{ className: "manage-modal-loader" }} />
                    </CSSTransition>}
                    {!props.isLoaderVisible && !props.isDone && <CSSTransition key={"olerex-plus-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <button className={`manage-modal_button ${props.isSaveButton ? "save" : "delete"}`} onClick={props.onActionButtonClick} disabled={!props.actionButtonActive}>
                            {props.actionButtonImg && <img className="manage-modal_button_img" src={props.actionButtonImg} alt="" />}
                            <span className="manage-modal_button_text">{props.actionButtonTitle}</span>
                        </button>
                    </CSSTransition>}
                </TransitionGroup>
            </div>
    }} />);
})));
Modal.propTypes = {
    isDone: PropTypes.bool,
    height: PropTypes.any,
    isError: PropTypes.bool,
    title: PropTypes.string,
    onCloseButtonClick: PropTypes.func,
    content: PropTypes.any,
    result: PropTypes.any,
    isLoaderVisible: PropTypes.bool,
    isSaveButton: PropTypes.bool,
    onActionButtonClick: PropTypes.func,
    actionButtonImg: PropTypes.string,
    actionButtonTitle: PropTypes.string,
    actionButtonActive: PropTypes.bool
};
Modal.defaultProps = {
    actionButtonActive: true
};
