import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import DropdownFilter from '../../../components/DropdownFilter';
import ToolbarSeparator from '../../../components/ToolbarSeparator';
import "./TransactionsFilters.scss";
import IconChevronDownDarker6px from '../../../assets/Icon_Chevron-Down_Darker-6px.svg';
import {FilterTypeEnum} from "../helpers/FilterTypeEnum";
import CalendarFilter from "../../../components/CalendarFilter";

const TransactionsFilters = React.memo(props => {
    return (
    <div className={`transaction-filter-items ${props.alignToStart ? "align-to-start" : ""}`}>
        <TransitionGroup component={null}>
            {props.items && props.items.map((item, index) =>
                <CSSTransition key={index} timeout={{ enter: 600, exit: 600 }} classNames={props.alignToStart ? "align-to-start-filter" : "filter"}>
                    <>
                        {item.type === FilterTypeEnum.dropdown &&
                            <DropdownFilter {...{
                                ...item,
                                rightImg: IconChevronDownDarker6px,
                                distance: -72
                            }} />
                        }
                        {item.type === FilterTypeEnum.calendar &&
                            <CalendarFilter {...{
                                ...item,
                                rightImg: IconChevronDownDarker6px,
                                distance: -88,
                                offset: -75,
                                onChange: item.onChange,
                                dateRange: item.dateRange,
                                theme: 'dropdown-filter dropdown-calendar-filter'
                            }} />
                        }
                        {index < props.items.length - 1 && <ToolbarSeparator />}
                    </>
                </CSSTransition>)}
        </TransitionGroup>
    </div>
)});

export default TransactionsFilters;
