import { estonian } from '../translations/estonian';
import { english } from '../translations/english';
import { russian } from '../translations/russian';
export { estonian, english, russian }; //export for the parity test

export const TYPE_LANGUAGE_CHANGE = "TYPE_LANGUAGE_CHANGE";
export const TYPE_SWITCH_ROLE = "TYPE_SWITCH_ROLE";
export const TYPE_LOGGED_IN = "TYPE_LOGGED_IN";
export const TYPE_LOGGED_OUT = "TYPE_LOGGED_OUT";

export const languageChange = (newLanguage) => ({
    type: TYPE_LANGUAGE_CHANGE,
    newLanguage: newLanguage
});


export const initialTranslationState = { ...estonian };

export default function translation(state = initialTranslationState, action) {
    switch (action.type) {
        case TYPE_LANGUAGE_CHANGE:
            switch (action.newLanguage) {
                case "et":
                    return { ...estonian };
                case "en":
                    return { ...english };
                case "ru":
                    return { ...russian };
                default:
                    return { ...estonian };
            }
        default:
            return state;
    }
}