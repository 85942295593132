import React from 'react';

import {ChevronGroup} from "./ChevronGroup";
import {ReactComponent as IconModalCross9px} from "../../../assets/Icon_Cross_Gray-9px_SVG.svg";

export const StepsSection = ({
        getElectricityData,
        authentication,
        meteringPoint,
        electricityPackage,
        openSelectMeteringPointModal,
        isMobileLayout,
        selectedMeteringPoints,
        scrollToSliders,
        resetMeteringPoints,
        ...props
    }) => {
    let stepNo = 0;
    if (authentication.done === 1) {
        stepNo = 1;
    } else if (electricityPackage.done === 1) {
        stepNo = 2;
    }

    const authClick = (button) => (event) => {
        if (button.url) {
            window.open(button.url, "_blank");
        } else if (button.requestType && (button.requestType === 'check_authorization')) {
            getElectricityData();
        }
    };
    const selectCount = selectedMeteringPoints.filter(mp => mp.selected).length;
    const selectionName = selectCount === 0 ? '' : selectCount === 1 ? selectedMeteringPoints.find(mp => mp.selected).title : props.translation.meteringPointModal.pointsSelected(selectCount);
    if (selectCount > 0) {
        stepNo = 3;
    }

    return (
        <div className="packet-selection">
            <div className="step-container">
                <div className={`step step-1 active ${ stepNo === 0 ? 'current-step' : 'mobile-hidden-step'}`}>
                    {isMobileLayout ?
                        <div className="step-mobile-header">
                            <div className="step-number">1</div>
                            <h2>{ authentication.title }</h2>
                        </div>
                        :
                        <React.Fragment>
                            <div className="step-number">1</div>
                            <h2>{ authentication.title }</h2>
                        </React.Fragment>
                    }
                    <p>{ authentication.description }</p>
                    { authentication.showButtons ? <div className="buttons">
                        { authentication.buttons.map(
                            b => <button key={b.text} onClick={authClick(b)} className="btn">{b.text}</button>
                        )}
                    </div> : <span><span className="step-done">{ authentication.noBtnsText }</span></span>}
                </div>
            </div>
            <ChevronGroup />
            <div className="step-container">
                <div className={`step step-2 ${stepNo > 0 ? 'active' : ''} ${stepNo === 1 ? 'current-step' : 'mobile-hidden-step'}`}>
                    {isMobileLayout ?
                        <div className="step-mobile-header">
                            <div className="step-number">2</div>
                            <h2>{ meteringPoint.title }</h2>
                        </div>
                        :
                        <React.Fragment>
                            <div className="step-number">2</div>
                            <h2>{ meteringPoint.title }</h2>
                        </React.Fragment>
                    }
                    <p>{ meteringPoint.description }</p>
                    { meteringPoint.showButton ?
                        (
                            (selectCount === 0) ?
                            <button
                                className="btn btn-block btn-chevron-down"
                                onClick={openSelectMeteringPointModal}
                            >
                                {meteringPoint.button.text}
                            </button>
                            :
                                <div className="selected-title-container">
                                    <span className="step-done"/>
                                    <span onClick={openSelectMeteringPointModal} className="selected-title">{selectionName}</span>
                                    <span className="selected-reset" onClick={() => resetMeteringPoints()}><IconModalCross9px/></span>
                                </div>
                        )
                        : <span className="step-done">{ meteringPoint.noBtnText }</span>}
                </div>
            </div>
            <ChevronGroup/>
            <div className="step-container">
                <div className={`step step-3 ${stepNo > 1 ? 'active' : ''} ${stepNo === 2 ? 'current-step' : 'mobile-hidden-step'}`}>
                    {isMobileLayout ?
                        <div className="step-mobile-header">
                            <div className="step-number">3</div>
                            <h2>{ electricityPackage.title }</h2>
                        </div>
                        :
                        <React.Fragment>
                            <div className="step-number">3</div>
                            <h2>{ electricityPackage.title }</h2>
                        </React.Fragment>
                    }
                    <p>{ electricityPackage.description }</p>
                    { stepNo === 3 ?
                        <button onClick={() => scrollToSliders()} className="btn btn-block">{electricityPackage.buttonText}</button>
                        : <span className="step-todo">{ electricityPackage.noBtnText }</span>}
                </div>
            </div>
        </div>
    )
};
