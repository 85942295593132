import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import NavTitle from './NavTitle';
import NavItems from './NavItems';
import NavUserControls from './NavUserControls';
import './NavBar.scss';
import IconMenuBillingActive from './assets/Icon-Menu_Billing_Active.svg';
import IconMenuBillingInactive from './assets/Icon-Menu_Billing_Inactive.svg';
import IconMenuCardActive from './assets/Icon-Menu_Card_Active.svg';
import IconMenuCardInactive from './assets/Icon-Menu_Card_Inactive.svg';
import IconMenuOverviewActive from './assets/Icon-Menu_Overview_Active.svg';
import IconMenuOverviewInactive from './assets/Icon-Menu_Overview_Inactive.svg';
import IconMenuPeopleActive from './assets/Icon-Menu_People_Active.svg';
import IconMenuPeopleInactive from './assets/Icon-Menu_People_Inactive.svg';
import IconMenuSettingsActive from './assets/Icon-Menu_Settings_Active.svg';
import IconMenuSettingsInactive from './assets/Icon-Menu_Settings_Inactive.svg';
import IconMenuVouchersActive from './assets/Icon-Menu_Vouchers_Active.svg';
import IconMenuVouchersInactive from './assets/Icon-Menu_Vouchers_Inactive.svg';
import IconElectricityActive from './assets/Icon-Electricity_Active.svg';
import IconElectricityInactive from './assets/Icon-Electricity_Inactive.svg';
import IconMapInactiveV1 from './assets/Icon-Menu_Map_Inactive_v1.svg';
import IconMapActiveV1 from './assets/Icon-Menu_Map_Active_v1.svg';
import IconMenuWholesaleActive from './assets/Icon-Menu_Wholesale_Active.svg';
import IconMenuWholesaleInactive from './assets/Icon-Menu_Wholesale_Inactive.svg';

const NavBar = React.memo(props =>
    <div className={`nav-bar ${props.isDrawerOpen ? "drawer-open" : ""}`}>
        <NavTitle {...{ location: props.pathname, onDrawerToggle: props.onDrawerToggle, isDrawerOpen: props.isDrawerOpen }} />
        <NavItems
            isBusiness={props.login.currentRole && props.login.currentRole.fullName === undefined}
            isDrawerOpen={props.isDrawerOpen}
            items={props.login.currentRole && props.login.currentRole.fullName === undefined ?
                [
                    {
                        title: props.translation.overview,
                        activeImg: IconMenuOverviewActive,
                        inactiveImg: IconMenuOverviewInactive,
                        to: "/", active:
                            props.pathname === "/"
                    },
                    ...[(props.login.currentRole && props.login.currentRole.showWholeSale === "1") && {
                        title: props.translation.wholesale,
                        activeImg: IconMenuWholesaleActive,
                        inactiveImg: IconMenuWholesaleInactive,
                        to: '/wholesale',
                        active: props.pathname.includes('/wholesale')
                    }],
                    {
                        title: props.translation.cards,
                        activeImg: IconMenuCardActive,
                        inactiveImg: IconMenuCardInactive,
                        to: "/cards",
                        active: props.pathname.includes("/cards")
                    },
                    {
                        title: props.translation.invoicing,
                        activeImg: IconMenuBillingActive,
                        inactiveImg: IconMenuBillingInactive,
                        to: "/invoicing",
                        active: props.pathname.includes("/invoicing"),
                        isDesktopOnly: true
                    },
                    {
                        title: props.translation.invoicingMobile,
                        activeImg: IconMenuBillingActive,
                        inactiveImg: IconMenuBillingInactive,
                        to: "/invoicing",
                        active: props.pathname === "/invoicing",
                        isMobileOnly: true
                    },
                    {
                        title: props.translation.invoicesMobile,
                        activeImg: IconMenuBillingActive,
                        inactiveImg: IconMenuBillingInactive,
                        to: "/invoicing/invoices",
                        active: props.pathname.includes("/invoicing/invoices"),
                        isMobileOnly: true
                    },
                    {
                        title: props.translation.manage,
                        activeImg: IconMenuSettingsActive,
                        inactiveImg: IconMenuSettingsInactive,
                        to: "/manage",
                        active: props.pathname.includes("/manage")
                    },
                    ...[(props.login.currentRole && props.login.currentRole.showVirtualFamily === "1") && {
                        title: props.translation.virtualFamily,
                        activeImg: IconMenuPeopleActive,
                        inactiveImg: IconMenuPeopleInactive,
                        to: "/virtualfamily",
                        active: props.pathname.includes("/virtualfamily")
                    }],
                    {
                        title: props.translation.map,
                        activeImg: IconMapActiveV1,
                        inactiveImg: IconMapInactiveV1,
                        to: 'https://map.olerex.ee/',
                        isExternal: true
                    }                    
                    
                ].filter(x => x)  /*remove undefined when no virtual family*/ :
                [
                    {
                        title: props.translation.overview,
                        activeImg: IconMenuOverviewActive,
                        inactiveImg: IconMenuOverviewInactive,
                        to: "/",
                        active: props.pathname === "/"
                    },
                    ...[(props.login.currentRole && props.login.currentRole.showWholeSale === "1") && {
                        title: props.translation.wholesale,
                        activeImg: IconMenuWholesaleActive,
                        inactiveImg: IconMenuWholesaleInactive,
                        to: '/wholesale',
                        active: props.pathname.includes('/wholesale')
                    }],
                    {
                        title: props.translation.vouchers,
                        activeImg: IconMenuVouchersActive,
                        inactiveImg: IconMenuVouchersInactive,
                        to: "/vouchers",
                        active: props.pathname.includes("/vouchers")
                    },
                    {
                        title: props.translation.transactions,
                        activeImg: IconMenuBillingActive,
                        inactiveImg: IconMenuBillingInactive,
                        to: "/transactions",
                        active: props.pathname.includes("/transactions")
                    },
                    ...[(props.login.currentRole && props.login.currentRole.isIdCredit === "1") && {
                        title: props.translation.idCredit,
                        activeImg: IconMenuCardActive,
                        inactiveImg: IconMenuCardInactive,
                        to: "/idcredit",
                        active: props.pathname.includes("/idcredit")
                    }],
                    ...[(props.login.currentRole && props.login.currentRole.showElectricity === "1") && {
                        title: props.translation.electricity,
                        activeImg: IconElectricityActive,
                        inactiveImg: IconElectricityInactive,
                        to: '/electricity',
                        active: props.pathname.includes('/electricity')
                    }],
                    {
                        title: props.translation.map,
                        activeImg: IconMapActiveV1,
                        inactiveImg: IconMapInactiveV1,
                        to: 'https://map.olerex.ee/',
                        isExternal: true
                    },                    
                ].filter(x => x) //remove undefined when no id credit
            } />
        <TransitionGroup component={null}>
            {props.isUserDropdownOpen && <CSSTransition key={"page-content-fade"} timeout={{ enter: 600, exit: 600 }} classNames={"fade"}>
                <div className="nav-user-dropdown-bar-fade" onClick={props.onUserDropdownClose}></div></CSSTransition>}
        </TransitionGroup>
        <NavUserControls
            isDrawerOpen={props.isDrawerOpen}
            onUserDropdownToggle={props.onUserDropdownToggle}
            isUserDropdownOpen={props.isUserDropdownOpen}
            onRoleChangeOpen={props.onRoleChangeOpen}
            onLanguageChangeOpen={props.onLanguageChangeOpen}
            onLogoutButtonClick={props.onLogoutButtonClick}
            login={props.login} />
    </div>
);
export default connect(state => ({ translation: state.translation.navbar, login: state.login, pathname: state.router.location.pathname }))(NavBar);
