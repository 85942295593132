import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Loader from '../../../components/Loader';
import ContentContainer from '../../../components/ContentContainer';
import { privateUpdateUserDataRequest } from '../../../../../Networking';
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import './UserDetails.scss';
import IconUserCircleYellow48px from '../assets/Icon_User-Circle_Yellow-48px.svg';
import IconMailGray16px from '../assets/Icon_Mail_Gray-16px.svg';
import IconCheckCircleFilledGreen13px from '../assets/Icon_Check-Circle-Filled_Green-13px.svg';
import IconCheckCircleFilledGray13px from '../assets/Icon_Cross-Circle-Filled_Gray-13px.svg';
import IconPhoneGray16px from '../assets/Icon_Phone_Gray-16px.svg';
import IconCheckGreen9px from '../assets/Icon_Check_Green-9px.svg';
import IconCrossGray9px from '../assets/Icon_Cross_Gray-9px.svg';
import IconCheckWhite16px from '../assets/Icon_Check_White-16px.svg';
import IconUsername from '../assets/Icon_Username.svg';

const CardField = React.memo(props =>
    <p className={`card-field ${props.type}`}>
        <img className="card-field_img-left" src={props.leftImg} alt={props.leftImgAlt} />
        <span className="card-field_text">
            <span className="desktop">{props.text}</span>
            <span className="mobile">{props.textMobile ? props.textMobile : props.text}</span>
        </span>
        <Tippy arrow={true}
            isEnabled={props.hover !== undefined}
            content={props.hover || ""}>
            <img className="card-field_img-right" src={props.rightImg} alt={props.rightImgAlt} />
        </Tippy>
    </p>
);
CardField.propTypes = {
    leftImg: PropTypes.string,
    leftImgAlt: PropTypes.string,
    text: PropTypes.string,
    textMobile: PropTypes.string,
    rightImg: PropTypes.string,
    rightImgAlt: PropTypes.string,
    hover: PropTypes.string
};

export const UserCard = connect(state => ({ translation: state.translation.overview.userDetails.userCard }))(React.memo(props =>
    <div className={`details-user-card ${props.language} ${props.username ? 'has-username' : ''}`}>
        <img className="card-user-image" src={IconUserCircleYellow48px} alt="user profile" />
        <h1 className="card-user-name">{props.firstName} {props.lastName}</h1>
        <p className="card-user-id">{props.personalCode}</p>
        <CardField {...{
            type: "email",
            leftImg: IconMailGray16px,
            leftImgAlt: "email",
            text: props.email,
            rightImg: props.emailConfirmed === "1" ? IconCheckCircleFilledGreen13px : IconCheckCircleFilledGray13px,
            rightImgAlt: "info hover",
            hover: props.emailConfirmed === "1" ? props.translation.emailVerified : props.translation.emailUnverified
        }} />
        <CardField {...{ type: "phone", leftImg: IconPhoneGray16px, leftImgAlt: "phone", text: props.phone }} />
        {props.username ? <CardField {...{ type: "username", leftImg: IconUsername, leftImgAlt: "username", text: props.username }} /> : null}
    </div>
));
UserCard.propTypes = {
    language: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    personalCode: PropTypes.string,
    email: PropTypes.string,
    emailConfirmed: PropTypes.string,
    phone: PropTypes.string
};

const SubscriptionsCard = connect(state => ({ translation: state.translation.overview.userDetails.subscriptionsCard }))(React.memo(props =>
    <div className={`details-subscriptions-card ${props.language}`}>
        <CardField {...{
            type: "newsletter", leftImg: props.allowNewsletterEmail === "1" ? IconCheckGreen9px : IconCrossGray9px, leftImgAlt: "subscribed",
            text: props.translation.emailNewsletter, textMobile: props.translation.emailNewsletterMobile
        }} />
        <CardField {...{
            type: "offers-email", leftImg: props.allowPersonalOfferEmail === "1" ? IconCheckGreen9px : IconCrossGray9px, leftImgAlt: "subscribed",
            text: props.translation.emailOffers, textMobile: props.translation.emailOffersMobile
        }} />
        <CardField {...{
            type: "offers-sms", leftImg: props.allowPersonalOfferSms === "1" ? IconCheckGreen9px : IconCrossGray9px, leftImgAlt: "not subscribed",
            text: props.translation.smsOffers, textMobile: props.translation.smsOffersMobile
        }} />
    </div>
));
SubscriptionsCard.propTypes = {
    language: PropTypes.string,
    allowNewsletterEmail: PropTypes.string,
    allowPersonalOfferEmail: PropTypes.string,
    allowPersonalOfferSms: PropTypes.string
};

export const TextEditField = React.memo(props =>
    <div className={`text-edit-field ${props.type} ${((props.required || props.validate) && (props.value[props.type].invalid || props.error) && 'invalid') || ''}`}>
        <label className={`text-edit-field_title ${((props.required || props.validate) && 'required') || ''}`} htmlFor={props.type}>{props.title}</label>
        <input className={`text-edit-field_input ${props.type}`} id={props.type} type={props.inputType || 'text'} size="1" value={props.value[props.type].value} onChange={props.onChange} disabled={props.disabled} required={props.required} />
        {(props.required || props.validate) && !props.hideErrorMessage && props.value[props.type].invalid && <span className="text-edit-field_error">{props.errorLabel}</span>}
    </div>
);
TextEditField.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    inputType: PropTypes.string,
    error: PropTypes.bool,
    errorLabel: PropTypes.string
};

export const EditProfileCard = connect(state => ({ translation: state.translation.overview.userDetails.editProfileCard }))(React.memo(props =>
    <div className={`details-edit-info-card ${props.language}`}>
        <TextEditField {...{ type: "first-name", title: props.translation.firstNameField, errorLabel: props.translation.requiredErrorLabel, value: props.editingFormData, onChange: props.onEditingValueChanged, required: true }} />
        <TextEditField {...{ type: "last-name", title: props.translation.lastNameField, errorLabel: props.translation.requiredErrorLabel, value: props.editingFormData, onChange: props.onEditingValueChanged, required: true }} />
        <TextEditField {...{ type: "id-number", title: props.translation.personalCodeField, value: props.editingFormData, disabled: true }} />
    </div>
));
EditProfileCard.propTypes = {
    language: PropTypes.string,
    editingFormData: PropTypes.any,
    onEditingValueChanged: PropTypes.func
};

const EditContactCard = connect(state => ({ translation: state.translation.overview.userDetails.editContactCard }))(React.memo(props =>
    <div className={`details-edit-contact-card ${props.language}`}>
        <TextEditField {...{ type: "email", title: props.translation.emailField, value: props.editingFormData, errorLabel: props.translation.requiredErrorLabel, onChange: props.onEditingValueChanged, required: true }} />
        <TextEditField {...{ type: "phone", title: props.translation.phoneField, value: props.editingFormData, errorLabel: props.translation.requiredErrorLabel, onChange: props.onEditingValueChanged, required: true }} />
    </div>
));
EditContactCard.propTypes = {
    language: PropTypes.string,
    editingFormData: PropTypes.any,
    onEditingValueChanged: PropTypes.func
};

const EditPasswordCard = connect(state => ({ translation: state.translation.overview.userDetails.editPasswordCard }))(React.memo(props =>
    <div className={`details-edit-password-card ${props.language}`}>
        <TextEditField {...{ type: "username", title: props.translation.usernameField, value: props.editingFormData, errorLabel: props.translation.requiredErrorLabel, onChange: props.onEditingValueChanged, disabled: true }} />
        <TextEditField {...{ type: "password1", inputType: 'password', title: props.translation.passwordField, value: props.editingFormData, errorLabel: props.translation.requiredErrorLabel, onChange: props.onEditingValueChanged, validate: true, error: props.error }} />
        <TextEditField {...{ type: "password2", inputType: 'password', title: props.translation.passwordRepeatField, value: props.editingFormData, errorLabel: props.translation.requiredErrorLabel, onChange: props.onEditingValueChanged, validate: true, hideErrorMessage: true, error: props.error }} />
        {props.error &&
            <div className="text-edit-field server-error-message">
                <span className="text-edit-field_error">{props.errorMessage}</span>
            </div>}
    </div>
));
EditPasswordCard.propTypes = {
    language: PropTypes.string,
    editingFormData: PropTypes.any,
    onEditingValueChanged: PropTypes.func
};

export const GreenCheckbox = React.memo(props =>
    <div className="green-checkbox">
        <input className="green-checkbox_input" id={props.type} type="checkbox" checked={props.checked[props.type].value} onChange={props.onChange} />
        <img className="green-checkbox_img" src={IconCheckWhite16px} alt="" />
    </div>
);
GreenCheckbox.propTypes = {
    type: PropTypes.string,
    checked: PropTypes.any,
    onChange: PropTypes.func
};

const CheckboxField = React.memo(props =>
    <div className={`checkbox-field ${props.type}`}>
        <GreenCheckbox {...props} />
        <label className="checkbox-field_title" htmlFor={props.type}>
            <span className="desktop">{props.title}</span>
            <span className="mobile">{props.titleMobile ? props.titleMobile : props.title}</span>
        </label>
    </div>
);
CheckboxField.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    titleMobile: PropTypes.string
};

const EditSubscriptionsAndActionsCard = connect(state => ({ translation: state.translation.overview.userDetails.editSubscriptionsAndActionsCard }))(React.memo(props =>
    <div className={`details-edit-other-card ${props.language} ${props.username ? 'has-username' : ''}`}>
        <CheckboxField {...{
            type: "email-newsletter", title: props.translation.emailNewsletter, titleMobile: props.translation.emailNewsletterMobile,
            checked: props.editingFormData, onChange: props.onEditingValueChanged
        }} />
        <CheckboxField {...{
            type: "email-offers", title: props.translation.emailOffers, titleMobile: props.translation.emailOffersMobile,
            checked: props.editingFormData, onChange: props.onEditingValueChanged
        }} />
        <CheckboxField {...{
            type: "sms-offers", title: props.translation.smsOffers, titleMobile: props.translation.smsOffersMobile,
            checked: props.editingFormData, onChange: props.onEditingValueChanged
        }} />
        <button className="details-edit-other-card_cancel-button" onClick={props.endEditing}>{props.translation.cancelButton}</button>
        <button className="details-edit-other-card_save-button" onClick={props.updateUserData}>{props.translation.saveButton}</button>
    </div>
));
EditSubscriptionsAndActionsCard.propTypes = {
    language: PropTypes.string,
    editingFormData: PropTypes.any,
    onEditingValueChanged: PropTypes.func,
    endEditing: PropTypes.func,
    updateUserData: PropTypes.func
};

class UserDetails extends PureComponent {
    constructor(props) {
        super(props);
        const userData = props.userData;
        this.state = {
            isEditing: false,
            isFirstEnter: true,
            editingFormData: {
                "first-name": {value: userData && userData.firstName ? userData.firstName : ""},
                "last-name": {value: userData && userData.lastName ? userData.lastName : ""},
                "id-number": {value: userData && userData.personalCode ? userData.personalCode : ""},
                "email": {value: userData && userData.email ? userData.email : ""},
                "phone": {value: userData && userData.phone ? userData.phone : ""},
                "email-newsletter": {value: userData && userData.allowNewsletterEmail === "1"},
                "email-offers": {value: userData && userData.allowPersonalOfferEmail === "1"},
                "sms-offers": {value: userData && userData.allowPersonalOfferSms === "1"},
                "username": {value: props.login.privateClientData.username},
                "password1": {value: ''},
                "password2": {value: ''}
            },
            error: false,
            errorMessage: null
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            const userData = this.props.userData;
            this.setState({
                editingFormData: {
                    "first-name": {value: userData && userData.firstName ? userData.firstName : ""},
                    "last-name": {value: userData && userData.lastName ? userData.lastName : ""},
                    "id-number": {value: userData && userData.personalCode ? userData.personalCode : ""},
                    "email": {value: userData && userData.email ? userData.email : ""},
                    "phone": {value: userData && userData.phone ? userData.phone : ""},
                    "email-newsletter": {value: userData && userData.allowNewsletterEmail === "1"},
                    "email-offers": {value: userData && userData.allowPersonalOfferEmail === "1"},
                    "sms-offers": {value: userData && userData.allowPersonalOfferSms === "1"},
                    "username": {value: this.props.login.privateClientData.username},
                    "password1": {value: ''},
                    "password2": {value: ''}                    
                }
            });
        }
    }

    updateUserData = () => {
        if (this.props.login && this.props.login.privateClientData && this.validateFields()) {
            const request = {
                "userId": this.props.login.privateClientData.userId,
                "token": this.props.login.token,
                "action": "updateUserData",
                "phone": this.state.editingFormData["phone"].value,
                "email": this.state.editingFormData["email"].value,
                "lastName": this.state.editingFormData["last-name"].value,
                "firstName": this.state.editingFormData["first-name"].value,
                "allowNewsletterEmail": this.state.editingFormData["email-newsletter"].value,
                "allowPersonalOfferEmail": this.state.editingFormData["email-offers"].value,
                "allowPersonalOfferSms": this.state.editingFormData["sms-offers"].value,
            };
            if (this.state.editingFormData.password1.value && this.props.login.privateClientData.username) {
                request.password = this.state.editingFormData.password1.value;
                request.password2 = this.state.editingFormData.password2.value;
                request.ignoreErrorModal = true;
            }
            this.props.updateUserDataRequest(
                request,
                data => {
                    this.setState({ isLoaderVisible: false }, () => this.endEditing(this.props.onUserDataUpdated));
                }, error => {
                    this.setState({ isLoaderVisible: false, error: true, errorMessage: error.records.result }); //TODO show error
                });
        }
    }

    validateFields = () => {
        let phoneInvalid = false;
        let emailInvalid = false;
        let firstNameInvalid = false;
        let lastNameInvalid = false;
        let passwordInvalid = false;
        let passwordInvalidType = null;
        if (!this.state.editingFormData.phone.value) {
          phoneInvalid = true;
        }
        if (!this.state.editingFormData.email.value ||
            !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.editingFormData.email.value)
        ) {
            emailInvalid = true;
        }
        if (!this.state.editingFormData['last-name'].value) {
          lastNameInvalid = true;
        }
        if (!this.state.editingFormData['first-name'].value) {
        firstNameInvalid = true;
        }
        if (this.state.editingFormData.password1.value && this.props.login.privateClientData.username) {
            if (!this.state.editingFormData.password2.value
            ) {
                passwordInvalid = true;
            }
        }
        this.setState({editingFormData: {
          ...this.state.editingFormData,
          phone: {
            ...this.state.editingFormData.phone,
            invalid: phoneInvalid
          },
          email: {
            ...this.state.editingFormData.email,
            invalid: emailInvalid
          },
          'last-name': {
            ...this.state.editingFormData['last-name'],
            invalid: lastNameInvalid
          },
          'first-name': {
            ...this.state.editingFormData['first-name'],
            invalid: firstNameInvalid
          },
          password1: {
            ...this.state.editingFormData.password1,
            invalid: passwordInvalid,
            invalidType: passwordInvalidType
          },
          password2: {
            ...this.state.editingFormData.password2,
            invalid: passwordInvalid
          }
        }});
        return !(phoneInvalid || firstNameInvalid || lastNameInvalid || emailInvalid || passwordInvalid);
    }

    onEditingToggle = () => {
        this.setState({ isEditing: !this.state.isEditing });
    };

    endEditing = (onEnded) => {
        this.setState({ isEditing: false, error: false, errorMessage: null }, () => typeof onEnded === "function" && onEnded());
    }

    onEditingValueChanged = (e) => {
        this.setState({
            editingFormData: {
                ...this.state.editingFormData,
                [e.target.id]: {value: e.target.type === "checkbox" ? e.target.checked : e.target.value}
            }
        });
    }

    render() {
        return (
            <ContentContainer className="user-details" {...{
                title: <h1 className="user-details_title">{this.props.translation.title}</h1>,
                action: <button className={`user-details_edit-button ${this.state.isEditing ? "editing" : ""}`} onClick={this.onEditingToggle}>{this.props.translation.editButton}</button>,
                content:
                    <div className="user-details-cards">
                        <TransitionGroup component={null}>
                            {this.props.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                <>
                                    <div className="details-user-card"></div>
                                    <Loader {...{ className: "user-details-user-card-loader" }} />
                                </>
                            </CSSTransition>}
                            {!this.props.isLoaderVisible && !this.state.isEditing &&
                                <CSSTransition key={"user-card-transition"} timeout={{ enter: 600, exit: 600 }}
                                    onEntered={() => this.setState({ isFirstEnter: false })}
                                    classNames={this.state.isFirstEnter ? "user-card-first" : "user-card"}>
                                    <UserCard {...{ ...this.props.userData, ...this.props.login.privateClientData, language: this.props.language }} />
                                </CSSTransition>}
                            {!this.props.isLoaderVisible && !this.state.isEditing &&
                                <CSSTransition key={"subscriptions-card-transition"} timeout={{ enter: 600, exit: 600 }}
                                    classNames={this.state.isFirstEnter ? "subscriptions-card-first" : "subscriptions-card"}>
                                    <SubscriptionsCard {...{ ...this.props.userData, language: this.props.language }} />
                                </CSSTransition>}
                            {this.state.isEditing && <CSSTransition key={"edit-profile-card-transition"} timeout={{ enter: 600, exit: 600 }} classNames={"edit-profile-card"}>
                                <EditProfileCard {...{
                                    editingFormData: this.state.editingFormData,
                                    onEditingValueChanged: this.onEditingValueChanged,
                                    language: this.props.language
                                }} />
                            </CSSTransition>}
                            {this.state.isEditing && <CSSTransition key={"edit-contact-card-transition"} timeout={{ enter: 600, exit: 600 }} classNames={"edit-contact-card"}>
                                <EditContactCard {...{
                                    editingFormData: this.state.editingFormData,
                                    onEditingValueChanged: this.onEditingValueChanged,
                                    language: this.props.language
                                }} />
                            </CSSTransition>}
                            {this.state.isEditing && this.props.login.privateClientData.username && <CSSTransition key={"edit-password-card-transition"} timeout={{ enter: 600, exit: 600 }} classNames={"edit-password-card"}>
                                <EditPasswordCard {...{
                                    editingFormData: this.state.editingFormData,
                                    onEditingValueChanged: this.onEditingValueChanged,
                                    language: this.props.language,
                                    error: this.state.error,
                                    errorMessage: this.state.errorMessage
                                }} />
                            </CSSTransition>}                            
                            {this.state.isEditing && <CSSTransition key={"edit-subscriptions-card-transition"} timeout={{ enter: 600, exit: 600 }} classNames={"edit-subscriptions-card"}>
                                <EditSubscriptionsAndActionsCard {...{
                                    editingFormData: this.state.editingFormData,
                                    onEditingValueChanged: this.onEditingValueChanged,
                                    endEditing: this.endEditing,
                                    updateUserData: this.updateUserData,
                                    language: this.props.language,
                                    username: this.props.login.privateClientData.username
                                }} />
                            </CSSTransition>}
                        </TransitionGroup>
                        <a className="user-details-privacy-policy" href={this.props.translation.privacyPolicyUrl} target="_blank" rel="noopener noreferrer">{this.props.translation.privacyPolicyLink}</a>
                    </div>
            }} />
        );
    }
}

UserDetails.propTypes = {
    userData: PropTypes.any,
    isLoaderVisible: PropTypes.bool
};

export default connect(
    state => ({ language: (state.login && state.login.privateClientData && state.login.privateClientData.language) || "et", translation: state.translation.overview.userDetails }),
    dispatch => ({ updateUserDataRequest: (body, success, error) => privateUpdateUserDataRequest(dispatch, body, success, error) }))(UserDetails);