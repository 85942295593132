import React from 'react';
import { connect } from 'react-redux';
import BlackCheckbox from '../dashboard/components/BlackCheckbox';
import { authRequest } from '../../Networking';
import { Environment } from '../../Utils';
import { loggedIn } from '../../state/Login';
import './Register.scss';
import olerexLogo from '../assets/Olerex_Logo.svg';
import clipartCrowd from '../dashboard/assets/Clipart_Crowd.png';
import iconChevronYellowRight from '../dashboard/assets/Icon_Chevron_Yellow-Right.svg';
import IconChevronBlackBack from "../dashboard/assets/Icon_Chevron_Black-Back.svg";
import IconPhoneBlack16px from './assets/Icon_Phone_Black-16px.svg';
import IconMailBlack16px from './assets/Icon_Mail_Black-16px.svg';

const TextField = React.memo(props =>
    <div className={`register_text-field ${props.className ? props.className : ""}`}>
        <p className="register_text-field-label">{props.label}</p>
        <p className="register_text-field-text">{props.text}</p>
    </div>
);

const EditTextField = React.memo(props =>
    <div className={`register_text-field ${props.className ? props.className : ""}`}>
        <label className="register_text-field-label" htmlFor={props.id}>{props.label}</label>
        <input className="register_text-field-text" id={props.id} value={props.value} onChange={props.onChange} size="1" disabled={props.disabled} />
    </div>
);

const CheckboxField = React.memo(props =>
    <div className={`register_checkbox-field ${props.className ? props.className : ""}`}>
        <BlackCheckbox {...{ type: props.id, large: false, checked: props.value || false, onChange: props.onChange }} />
        <span className="register_checkbox-text desktop">{props.text}</span>
        <span className="register_checkbox-text mobile">{props.mobileText}</span>
    </div>
);

class Register extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { email: "", phone: "", agreements: {}, registering: false, error: "" };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.agreements !== this.props.agreements) {
            this.setState({ agreements: {} });
        }
    }

    handleFieldChange = (e) => {
        this.setState({ [e.target.id]: e.target.type === "checkbox" ? e.target.checked : e.target.value });
    }

    handleAgreementFieldChange = (e) => {
        this.setState({ agreements: { ...this.state.agreements, [e.target.id]: e.target.type === "checkbox" ? e.target.checked : e.target.value } });
    }

    doRegister = () => {
        if (this.state.registering) {
            return;
        }
        this.setState({ registering: true }, () => {
            this.props.authRequest(
                {
                    "requestType": "validateNonce",
                    register: "1",
                    "nonce": this.props.nonce,
                    email: this.state.email,
                    phone: this.state.phone,
                    ...this.state.agreements,
                },
                async data => {
                    if (data.error) {
                        this.setState({ registering: false, error: data.error });
                    } else {
                        this.setState({ registering: false }, () => {
                            this.props.loggedIn(data);

                            if (this.props.environmentId === Environment.OlxApp) {
                                window.webViewBridge.send('login_success', {token: this.props.nonce, token_active_to: data.token_active_to});
                            } else if (data.redirectTo) {
                                window.location.href = data.redirectTo;
                            }
                        });
                    }
                }, async error => {
                    this.setState({ registering: false, error: "" });
                });
        })
    }

    render() {
        const registerDisabled = !(this.state.email && this.state.phone);
        return (
            <div className="register-modal">
                <img className="register-modal_left-img" src={clipartCrowd} alt="" />
                <img className="register-modal_right-img" src={clipartCrowd} alt="" />
                <div className="register">
                    <div className="logo-container register-logo">
                        <img className="header-logo" src={olerexLogo} alt="" />
                    </div>
                    <div className="register-card-top">
                        <h1 className="register-title">{this.props.translation.title}</h1>
                        <p className="register-card-top_text">{this.props.translation.topInfo}</p>
                    </div>
                    <div className="register-card-mid">
                        <EditTextField {...{
                            id: "firstName",
                            className: "first-name-field",
                            label: this.props.translation.firstNameField,
                            value: this.props.firstName,
                            onChange: this.handleFieldChange,
                            disabled: true
                        }} />
                        <EditTextField {...{
                            id: "lastName",
                            className: "last-name-field",
                            label: this.props.translation.lastNameField,
                            value: this.props.lastName,
                            onChange: this.handleFieldChange,
                            disabled: true
                        }} />
                        <EditTextField {...{
                            id: "personalCode",
                            className: "personalcode-field",
                            label: this.props.translation.personalCodeField,
                            value: this.props.personalCode,
                            onChange: this.handleFieldChange,
                            disabled: true
                        }} />
                        <EditTextField {...{
                            id: "email",
                            className: "email-field",
                            label: this.props.translation.emailField,
                            value: this.state.email,
                            onChange: this.handleFieldChange
                        }} />
                        <EditTextField {...{
                            id: "phone",
                            className: "phone-field",
                            label: this.props.translation.phoneField,
                            value: this.state.phone,
                            onChange: this.handleFieldChange
                        }} />
                        {this.state.error && <TextField {...{ className: "error", label: this.props.translation.errorTitle, text: this.state.error }} />}
                    </div>
                    <div className="register-card-bot">
                        {this.props.agreements && this.props.agreements.map((item, index) =>
                            <CheckboxField {...{
                                key: index,
                                id: item.key,
                                value: this.state.agreements[item.key],
                                onChange: this.handleAgreementFieldChange,
                                text: item.text,
                                mobileText: item.mobileText
                            }} />
                        )}
                    </div>
                    <div className="register-card-buttons">
                        <button className="register-modal_cancel-button" onClick={this.props.onClose}>
                            <img className="register-modal_cancel-button_img" src={IconChevronBlackBack} alt="" />
                            <span className="register-modal_cancel-button_text">{this.props.translation.backButton}</span>
                        </button>
                        <button className="register-modal_join-button" disabled={registerDisabled} onClick={this.doRegister}>
                            <span className="register-modal_join-button_text">{this.props.translation.joinButton}</span>
                            <img className="register-modal_join-button_img" src={iconChevronYellowRight} alt="" />
                        </button>
                    </div>
                    <div className="register-card-info">
                        <p className="login-bottom-text">{this.props.translation.bottomText}</p>
                        <p className="login-bottom-info phone"><img className="login-bottom-info-img" src={IconPhoneBlack16px} alt="support phone" /><span className="login-bottom-info-text">6 100 100</span></p>
                        <p className="login-bottom-info hours"><img className="login-bottom-info-img" src={IconMailBlack16px} alt="open hours" /><span className="login-bottom-info-text">{this.props.translation.bottomHours}</span></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ translation: state.translation.register }),
    dispatch => ({
        authRequest: (body, success, error) => authRequest(dispatch, body, success, error),
        loggedIn: (data) => dispatch(loggedIn(data))
    }))(Register);
