import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './MeteringPointsTabFooter.scss';
import IconModalCross16px from "../../../assets/Icon_Modal-Cross_16px.svg";
import IconInputYellow16px from "../../../assets/Icon_Input_Yellow-16px.svg";
import IconGraphYellow16px from "../../../assets/Icon_Graph_Yellow-16px.svg";
import IconCrossCircleYellow16px from "../../../assets/Icon_Cross-Circle_Yellow-16px.svg";

const ImageTextButton = React.memo(props =>
    <button className="selected-button" onClick={props.onClick} style={{ visibility: props.buttonHidden ? "hidden" : "" }}>
        <img className="selected-button_img" src={props.img} alt="" />
        <span className="selected-button_text" >{props.text}</span>
    </button>
);

const MeteringPointsTabFooter = React.memo(({translation, resetSelection, selectionLength, withContract, withoutContract, contractOnclick, ...props}) => {
    return (
        <div className="metering-points-tab-select-footer">
            <div className="metering-points-tab-select-footer-content">
                <p className="metering-points-tab-select-footer_text">{translation.meteringPointModal.pointsSelected(selectionLength)}</p>
                <button className="metering-points-tab-select-footer_close-button" onClick={resetSelection}>
                    <img className="metering-points-tab-select-footer_close-button_img" src={IconModalCross16px} alt="clear selection" />
                </button>
                <div className="cards-page-select-buttons">
                    <ImageTextButton {...{
                        text: translation.bringOver,
                        img: IconInputYellow16px,
                        buttonHidden: withContract,
                        onClick: contractOnclick(2)
                    }} />
                    <ImageTextButton {...{
                        text: translation.editContract,
                        img: IconGraphYellow16px,
                        buttonHidden: !withContract,
                        onClick: contractOnclick(1)
                    }} />
                    <ImageTextButton {...{
                        text: translation.endContract,
                        img: IconCrossCircleYellow16px,
                        buttonHidden: !withContract || withoutContract,
                        onClick: contractOnclick(3)
                    }} />
                </div>
            </div>
        </div>);
});
MeteringPointsTabFooter.propTypes = {
    onUncheckAllCards: PropTypes.func,
    selectedCards: PropTypes.array,
};

export default connect(state => ({ translation: state.translation.electricity }))(MeteringPointsTabFooter);
