import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalInputText, ModalInputTextLimit, ModalInputSwitch3 } from "../../manage/components/Modal";
import { CardsModalDropdown } from "./OrderCard";
import './Modals.scss';
import IconInputYellow16px from '../../../assets/Icon_Input_Yellow-16px.svg';
import IconParamsYellow16px from '../../../assets/Icon_Params_Yellow-16px.svg';

export const EditParams = connect(state => ({ translation: state.translation.cards.editParamsModal }))(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            refuelingParameters: Object.entries(this.props.refuelingParameters).map(x => ({ text: x[1], onClick: () => this.setState({ selectedRefuelingParameter: x[0] }) })),
            selectedRefuelingParameter: this.props.refuelingParameter || 0,
            isLoaderVisible: false, isDone: false, isError: false, result: null
        };
    }

    editRefuelingParam = (cardNumber, refuelingParameter) => {
        this.setState({ isLoaderVisible: true }, () => {
            this.props.updateCards(
                {
                    "requestType": "updateRefuelingParameter",
                    "cardNumber": cardNumber,
                    "refuelingParameter": refuelingParameter
                },
                data => this.setState({
                    result: data.records.result,
                    isDone: data.records.statusCode === 200,
                    isError: data.records.statusCode !== 200,
                    isLoaderVisible: false
                }),
                error => this.setState({ isLoaderVisible: false }))
        });
    }

    onFieldChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    render() {
        return (
            <Modal {...{
                title: this.props.translation.titleAdd,
                onCloseButtonClick: this.props.onModalClose,
                content:
                    <>
                        <CardsModalDropdown {...{
                            type: "refueling",
                            title: this.props.translation.paramField,
                            text: this.state.refuelingParameters[this.state.selectedRefuelingParameter].text,
                            items: this.state.refuelingParameters
                        }} />
                    </>,
                isSaveButton: true,
                actionButtonTitle: this.props.translation.editButton,
                actionButtonImg: IconParamsYellow16px,
                onActionButtonClick: () => this.editRefuelingParam(this.props.number, this.state.selectedRefuelingParameter),
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 112
            }} />
        )
    }
});

export const EditCardName = connect(state => ({ translation: state.translation.cards.editCardNameModal }), null)(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { name: props.name || "", isLoaderVisible: false, isDone: false, isError: false, result: null };
    }

    validate = (name) => {
        return name !== undefined && name !== null && name !== "";
    }

    editName = (cardNumber, cardNumbers, cardName) => {
        if (!this.validate(cardName)) {
            return;
        }
        this.setState({ isLoaderVisible: true }, () => {
            this.props.updateCards(
                {
                    "requestType": "editCardName",
                    "cardNumber": cardNumber,
                    "cardNumbers": cardNumbers,
                    "cardName": cardName
                },
                data => this.setState({
                    result: data.records.result,
                    isDone: data.records.statusCode === 200,
                    isError: data.records.statusCode !== 200,
                    isLoaderVisible: false
                }),
                error => this.setState({ isLoaderVisible: false }))
        });
    }

    onFieldChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    render() {
        return (
            <Modal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onModalClose,
                content:
                    <>
                        <ModalInputText {...{
                            text: this.props.translation.nameField,
                            id: "name",
                            value: this.state.name,
                            onChange: this.onFieldChange,
                            maxLength: 30,
                            required: true,
                            backgroundImage: "card"
                        }} />
                    </>,
                isSaveButton: true,
                actionButtonTitle: this.props.translation.saveButton,
                actionButtonImg: IconInputYellow16px,
                onActionButtonClick: () => this.editName(this.props.number, this.props.numbers, this.state.name),
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 112
            }} />
        )
    }
});

export const EditCardLimits = connect(state => ({ translation: state.translation.cards.editCardLimitsModal }), null)(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            limit: this.props.limit || 0,
            dayLimit: this.props.dayLimit || 0,
            fuel: (this.props.fuel && this.props.fuel + 1) || 2, //translate fuel [1, 2] to modalinputtext3 fuel [2, 3]
            product: this.props.product || 1,
            isLoaderVisible: false, isDone: false, isError: false, result: null
        };
    }

    validate = (limit) => {
        return limit !== undefined && limit !== null && limit !== "" && limit >= 0;
    }

    editLimit = (cardNumber, cardNumbers, cardLimit, dayLimit, allowedFuel, allowedProduct) => {
        if (!this.validate(cardLimit) || !this.validate(dayLimit)) {
            return;
        }
        this.setState({ isLoaderVisible: true }, () => {
            this.props.updateCards(
                {
                    "requestType": cardNumber ? "editCardLimit" : "editCardsLimit",
                    "cardNumber": cardNumber,
                    "cardNumbers": cardNumbers,
                    "limit": cardLimit,
                    "dayLimit": dayLimit,
                    "allowedFuel": allowedFuel,
                    "allowedProduct": allowedProduct
                },
                data => this.setState({
                    result: data.records.result,
                    isDone: data.records.statusCode === 200,
                    isError: data.records.statusCode !== 200,
                    isLoaderVisible: false
                }),
                error => this.setState({ isLoaderVisible: false }))
        });
    }

    onFieldChange = (e) => {
        try {
            if (e.target.value.length === 0 || /^(0|[1-9][0-9]{0,9})$/.test(e.target.value)) {
                const value = parseInt(e.target.value);
                this.setState({ [e.target.id]: isNaN(value) ? "" : value });
            }
        } catch (e) {
        }
    }

    onFuelTypeChange = (fuel) => {
        this.setState({ fuel: fuel, product: fuel === 2 ? 1 : this.state.product });
    }

    onProductTypeChange = (product) => {
        this.setState({ product: product });
    }

    render() {
        const inputSize = Math.max((this.state.limit && this.state.limit.length) || 0, 1);
        return (
            <Modal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onModalClose,
                content:
                    <>
                        <ModalInputTextLimit {...{
                            text: this.props.translation.limitField,
                            id: "limit",
                            value: this.state.limit,
                            onChange: this.onFieldChange,
                            maxLength: 9,
                            required: true,
                            size: inputSize,
                            clientTotalLimit: this.props.clientTotalLimit
                        }} />
                        <ModalInputTextLimit {...{
                            text: this.props.translation.dayLimitField,
                            id: "dayLimit",
                            value: this.state.dayLimit,
                            onChange: this.onFieldChange,
                            maxLength: 9,
                            required: true,
                            size: inputSize,
                            perPeriod: 'day',
                            clientTotalLimit: this.props.clientTotalLimit
                        }} />
                        <ModalInputSwitch3 {...{
                            className: "modals-card-limits-fuel-types-switch",
                            text: this.props.translation.fuelField,
                            item1: null,
                            item2: this.props.translation.fuel1,
                            item3: this.props.translation.fuel2,
                            active: this.state.fuel,
                            disabled: true,
                            item2Click: () => this.onFuelTypeChange(2),
                            item3Click: () => this.onFuelTypeChange(3),
                        }} />
                        <ModalInputSwitch3 {...{
                            text: this.props.translation.productField,
                            item1: this.props.translation.product1,
                            item2: this.props.translation.product2,
                            item3: this.props.translation.product3,
                            active: this.state.product,
                            disabled: true,
                            item1Click: () => this.onProductTypeChange(1),
                            item2Click: () => this.onProductTypeChange(2),
                            item3Click: () => this.onProductTypeChange(3),
                        }} />
                    </>,
                isSaveButton: true,
                actionButtonTitle: this.props.translation.saveButton,
                onActionButtonClick: () => this.editLimit(this.props.number, this.props.numbers, this.state.limit, this.state.dayLimit, this.state.fuel - 1, this.state.product), //translate modalinputswitch3 fuel [2, 3] to fuel [1, 2]
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
            }} />
        )
    }
});

export const BlockCard = connect(state => ({ translation: state.translation.cards.blockCardModal }), null)(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { reason: "", isLoaderVisible: false, isDone: false, isError: false, result: null };
    }

    blockCard = (cardNumber, cardNumbers, blockReason) => {
        this.setState({ isLoaderVisible: true }, () => {
            this.props.updateCards(
                {
                    "requestType": cardNumber ? "blockCard" : "blockCards",
                    "cardNumber": cardNumber,
                    "cardNumbers": cardNumbers,
                    "blockReason": blockReason,
                },
                data => this.setState({
                    result: data.records.result,
                    isDone: data.records.statusCode === 200,
                    isError: data.records.statusCode !== 200,
                    isLoaderVisible: false
                }),
                error => this.setState({ isLoaderVisible: false }))
        });
    }

    onFieldChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    render() {
        return (
            <Modal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onModalClose,
                content:
                    <>
                        {this.props.name && <p className="modals-block-card-info-text">{this.props.translation.contentBlockCard(this.props.name)}</p>}
                        {this.props.numbers && <p className="modals-block-card-info-text">{this.props.translation.contentBlockCards(this.props.numbers.length)}</p>}
                        <ModalInputText {...{
                            text: this.props.translation.reasonField,
                            id: "reason",
                            value: this.state.name,
                            onChange: this.onFieldChange
                        }} />
                    </>,
                isSaveButton: true,
                actionButtonTitle: this.props.translation.blockButton,
                onActionButtonClick: () => this.blockCard(this.props.number, this.props.numbers, this.state.reason),
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 156
            }} />
        )
    }
});

export const ExtendCard = connect(state => ({ translation: state.translation.cards.extendCardModal }), null)(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { isLoaderVisible: false, isDone: false, isError: false, result: null };
    }

    extendCard = (cardNumber, cardNumbers) => {
        this.setState({ isLoaderVisible: true }, () => {
            this.props.updateCards(
                {
                    "requestType": cardNumber ? "extendCard" : "extendCards",
                    "cardNumber": cardNumber,
                    "cardNumbers": cardNumbers
                },
                data => this.setState({
                    result: data.records.result,
                    isDone: data.records.statusCode === 200,
                    isError: data.records.statusCode !== 200,
                    isLoaderVisible: false
                }),
                error => this.setState({ isLoaderVisible: false }))
        });
    }

    render() {
        return (
            <Modal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onModalClose,
                content:
                    <>
                        {this.props.name && <p className="modals-extend-card-info-text">{this.props.translation.contentExtendCard(this.props.name)}</p>}
                        {this.props.numbers && <p className="modals-extend-card-info-text">{this.props.translation.contentExtendCards(this.props.numbers.length)}</p>}
                    </>,
                isSaveButton: true,
                actionButtonTitle: this.props.translation.extendButton,
                onActionButtonClick: () => this.extendCard(this.props.number, this.props.numbers),
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 56
            }} />
        )
    }
});

export const UnblockCard = connect(state => ({ translation: state.translation.cards.unblockCardModal }), null)(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { isLoaderVisible: false, isDone: false, isError: false, result: null };
    }

    unblockCard = (cardNumber, cardNumbers, blockReason) => {
        this.setState({ isLoaderVisible: true }, () => {
            this.props.updateCards(
                {
                    "requestType": cardNumber ? "unblockCard" : "unblockCards",
                    "cardNumber": cardNumber,
                    "cardNumbers": cardNumbers
                },
                data => this.setState({
                    result: data.records.result,
                    isDone: data.records.statusCode === 200,
                    isError: data.records.statusCode !== 200,
                    isLoaderVisible: false
                }),
                error => this.setState({ isLoaderVisible: false }))
        });
    }

    onFieldChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    render() {
        return (
            <Modal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onModalClose,
                content:
                    <>
                        {this.props.name && <p className="modals-block-card-info-text">{this.props.translation.contentUnblockCard(this.props.name)}</p>}
                        {this.props.numbers && <p className="modals-block-card-info-text">{this.props.translation.contentUnblockCards(this.props.numbers.length)}</p>}
                    </>,
                isSaveButton: true,
                actionButtonTitle: this.props.translation.blockButton,
                onActionButtonClick: () => this.unblockCard(this.props.number, this.props.numbers),
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 60
            }} />
        )
    }
});

export const ActivateCard = connect(state => ({
    translation: state.translation.cards.activateCardModal,
}), null)(class extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLoaderVisible: false,
            isDone: false,
            isError: false,
            result: null,
        };
    }

    activateCard = (cardNumber, cardNumbers) => {
        this.setState({ isLoaderVisible: true }, () => {
            this.props.updateCards({
                requestType: cardNumber ? 'activateCard' : 'activateCards',
                cardNumbers,
                cardNumber,
            }, (data) => {
                this.setState({
                    result: data.records.result,
                    isDone: data.records.statusCode === 200,
                    isError: data.records.statusCode !== 200,
                    isLoaderVisible: false,
                });
            }, (error) => {
                this.setState({ isLoaderVisible: false });
            });
        });
    }

    onFieldChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    render() {
        return (
            <Modal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onModalClose,
                content: <div />,
                isSaveButton: true,
                actionButtonTitle: this.props.translation.activateButton,
                onActionButtonClick: () => this.activateCard(this.props.number, this.props.numbers),
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
            }} />
        )
    }
});
