import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import { withScrollingHeader } from '../../components/withScrollingHeader';
import Header from './components/Header';
import Filters from './components/Filters';
import MobileFiltersButton from '../../components/MobileFiltersButton';
import CardGroups from './components/CardGroups';
import Loader from '../../components/Loader';
import NoContent from '../../components/NoContent';
import SelectedFooter from './components/SelectedFooter';
import { roleOrLanguageChanged } from '../../../../Utils';
import { url, businessCardsRequest } from '../../../../Networking';
import './Cards.scss';

class Cards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cards: null,
            selectedOrderWhat: null,
            orderWhatOptions: null,
            selectedOrderHow: null,
            orderHowOptions: null,
            groupedOption: null,
            grouped: 1,
            searchWord: null,
            clientTotalLimit: 0,
            cardsTotalLimit: 0,
            isLoaderVisible: false
        };
    }

    selectedOrderWhatChanged = (selectedOrderWhat) => {
        this.setState({ selectedOrderWhat: selectedOrderWhat }, () => this.downloadData());
    }

    selectedOrderHowChanged = (selectedOrderHow) => {
        this.setState({ selectedOrderHow: selectedOrderHow }, () => this.downloadData());
    }

    groupedChanged = (grouped) => {
        this.setState({ grouped: grouped }, () => this.downloadData());
    }

    parseCardsAndSetState = (data) => {
        const orderWhatOptions = Object.keys(data.records.orderWhatOptions).map(x => {
            return {
                onClick: () => this.selectedOrderWhatChanged(x),
                text: data.records.orderWhatOptions[x],
                value: x
            };
        });
        const orderHowOptions = Object.keys(data.records.orderHowOptions).map(x => {
            return {
                onClick: () => this.selectedOrderHowChanged(x),
                text: data.records.orderHowOptions[x],
                value: x
            }
        });
        let selectableCards = data.records.cards;
        if (selectableCards) {
            if (selectableCards.cardsUnGrouped) {
                selectableCards = {
                    ...selectableCards,
                    cards: selectableCards.cardsUnGrouped.map(x => ({ ...x, checked: false, expanded: false, usedLimitInt: parseInt(x.usedLimitInt) }))
                };
            } else {
                Object.entries(selectableCards).forEach(x => {
                    if (x[1].cards) {
                        selectableCards[x[0]] = {
                            ...x[1],
                            cards: x[1].cards.map(y => ({
                                ...y,
                                checked: false,
                                expanded: false,
                                usedLimitInt: parseInt(y.usedLimitInt),
                                isBlocked: ['blocked', 'blokeeritud', 'блокированный'].includes(x[0]) }))
                        };
                    } else {
                        selectableCards[x[0]] = x[1];
                    }
                });
            }
        }
        const groupedOption = {
            onClick: () => this.groupedChanged(this.state.grouped === 1 ? 0 : 1),
            text: this.props.translation.grouped
        }
        this.setState({
            status: data.status,
            cards: selectableCards,
            clientTotalLimit: data.records.clientTotalLimit,
            cardsTotalLimit: data.records.cards.usedLimitTotal,
            selectedOrderWhat: data.records.selectedOrderWhat,
            orderWhatOptions: orderWhatOptions,
            selectedOrderHow: data.records.selectedOrderHow,
            orderHowOptions: orderHowOptions,
            groupedOption: groupedOption,
            stations: data.records.stations,
            allowedFuels: data.records.allowedFuels,
            allowedProducts: data.records.allowedProducts,
            refuelingParameters: data.records.refuelingParameters,
            isLoaderVisible: false
        });
    }

    updateCards = (body, onResponse, onError) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        this.props.cardsRequest(
            {
                "accNr": accnr,
                "token": this.props.login.token,
                ...body
            },
            data => {
                if (onResponse) {
                    this.downloadData();
                    onResponse(data);
                }
            }, error => {
                if (onError) {
                    onError(error);
                }
            });
    }

    downloadData = () => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        this.setState({ isLoaderVisible: true }, () => {
            this.props.cardsRequest(
                {
                    "requestType": "cardsList",
                    "accNr": this.props.login.currentRole.accnr,
                    "token": this.props.login.token,
                    "orderWhat": this.state.selectedOrderWhat,
                    "orderHow": this.state.selectedOrderHow,
                    "grouped": this.state.grouped,
                    "searchWord": this.state.searchWord
                },
                data => {
                    this.parseCardsAndSetState(data);
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        });
    }

    componentDidMount() {
        this.downloadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (roleOrLanguageChanged(prevProps, this.props)) {
            this.downloadData();
        }
    }

    onCardCheckedChanged = (title, index, checked) => {
        const cards = { ...this.state.cards };
        if (cards) {
            if (cards.cardsUnGrouped) {
                cards.cardsUnGrouped = cards.cardsUnGrouped.map(x => x); //recreate for updating
                cards.cardsUnGrouped[index].checked = checked;
            } else {
                cards[title].cards[index].checked = checked;
            }
            this.setState({ cards: cards });
        }
    }

    onCardExpandedChanged = (title, index, expanded) => {
        const cards = { ...this.state.cards };
        if (cards) {
            if (cards.cardsUnGrouped) {
                cards.cardsUnGrouped = cards.cardsUnGrouped.map(x => x); //recreate for updating
                cards.cardsUnGrouped[index].expanded = expanded;
            } else {
                cards[title].cards[index].expanded = expanded;
            }
            this.setState({ cards: cards });
        }
    }

    onCardGroupCheckedChanged = (title, checked) => {
        const cards = { ...this.state.cards };
        if (cards) {
            cards[title].cards.forEach(x => x.checked = checked);
            this.setState({ cards: cards });
        }
    }

    onUncheckAllCards = () => {
        const cards = { ...this.state.cards };
        if (cards) {
            if (cards.cardsUnGrouped) {
                cards.cardsUnGrouped = cards.cardsUnGrouped.map(x => ({ ...x, checked: false })); //recreate for updating
            } else {
                Object.entries(cards).filter(x => x[0] !== "usedLimitTotal").forEach(x => {
                    cards[x[0]] = { ...x[1], cards: x[1].cards.map(x => ({ ...x, checked: false })) };
                });
            }
            this.setState({ cards: cards });
        }
    }

    onSearch = (text) => {
        this.setState({ searchWord: text }, () => this.downloadData());
    }

    currentFilterTitle = (filters, filterValue) => {
        if (filters) {
            const filter = filters.find(x => String(x.value) === String(filterValue));
            if (filter) {
                return filter.text;
            }
        }
        return "";
    }

    downloadExcel = () => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        window.open(encodeURI(`${url}&act=olerexweb.business.cards&requestType=cardsList&token=${this.props.login.token}&accNr=${accnr}&orderWhat=${this.state.selectedOrderWhat}&orderHow=${this.state.selectedOrderHow}&searchWord=${this.state.searchWord}&grouped=${this.state.grouped}&excel=1`));
    }

    render() {
        const selectedCards = this.state.cards ?
            (this.state.cards.cardsUnGrouped ? this.state.cards.cardsUnGrouped.filter(x => x.checked) :
                ([].concat(...Object.entries(this.state.cards).filter(x => x[0] !== "usedLimitTotal").map(x => x[1].cards))).filter(x => x.checked)) : [];
        const selectedCardsUsedTotal = selectedCards.map(x => x.usedLimitInt).reduce((x, y) => x + y, 0);
        const selectedOrderWhatTitle = this.currentFilterTitle(this.state.orderWhatOptions, this.state.selectedOrderWhat);
        const selectedOrderHowTitle = this.currentFilterTitle(this.state.orderHowOptions, this.state.selectedOrderHow);

        const headerClass = `${this.props.isHeaderFixed ? "fixed" : ""} ${this.props.isHeaderHiding ? "hiding" : ""} ${this.props.isHeaderAnimated ? "animated" : ""}`;
        return (
            <div className="cards-page">
                <div className="cards-page-header-placeholder"></div>
                <div className={`cards-page-header-static ${headerClass ? headerClass : ""}`}>
                    <div className="cards-page-header-background"></div>
                    <Header {...{
                        clientTotalLimit: this.state.clientTotalLimit,
                        cardsTotalLimit: this.state.cardsTotalLimit,
                        selectedCardsUsedTotal: selectedCardsUsedTotal,
                        onSearch: this.onSearch,
                        updateCards: this.updateCards,
                        onModalOpen: this.props.onModalOpen,
                        onModalClose: this.props.onModalClose,
                        stations: this.state.stations,
                        downloadExcel: this.downloadExcel
                    }} />
                    <div className="cards-page-filters-background"></div>
                    <Filters {...{
                        orderWhatOptions: this.state.orderWhatOptions,
                        selectedOrderWhat: this.state.selectedOrderWhat,
                        orderHowOptions: this.state.orderHowOptions,
                        selectedOrderHow: this.state.selectedOrderHow,
                        grouped: this.state.grouped,
                        groupedOption: this.state.groupedOption
                    }} />
                    <MobileFiltersButton {...{
                        className: "cards-page-header-mobile",
                        onClick: () => this.props.onMobileFiltersOpen([
                            {
                                text: selectedOrderWhatTitle,
                                items: this.state.orderWhatOptions
                            },
                            {
                                text: selectedOrderHowTitle,
                                items: this.state.orderHowOptions
                            },
                            {
                                text: this.state.grouped ? this.props.translation.grouped : this.props.translation.ungrouped,
                                items: [
                                    { text: this.props.translation.grouped, onClick: () => this.groupedChanged(1) },
                                    { text: this.props.translation.ungrouped, onClick: () => this.groupedChanged(0) }]
                            }
                        ])
                    }} />
                </div>
                <TransitionGroup component={null}>
                    {this.state.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <Loader className={"cards-page-loader"} />
                    </CSSTransition>}
                    <CSSTransition key={"content"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <div className="cards-page-body">
                            {this.state.cards && this.state.cards.length <= 0 ?
                                <NoContent {...{ text: this.props.translation.noCards }} /> :
                                <CardGroups {...{
                                    status: this.state.status,
                                    cards: this.state.cards,
                                    selectedCardsChanged: this.selectedCardsChanged,
                                    onCardCheckedChanged: this.onCardCheckedChanged,
                                    onCardExpandedChanged: this.onCardExpandedChanged,
                                    onCardGroupCheckedChanged: this.onCardGroupCheckedChanged,
                                    onModalOpen: this.props.onModalOpen,
                                    onModalClose: this.props.onModalClose,
                                    updateCards: this.updateCards,
                                    clientTotalLimit: this.state.clientTotalLimit,
                                    refuelingParameters: this.state.refuelingParameters,
                                    stations: this.state.stations
                                }} />}
                        </div>
                    </CSSTransition>
                    {selectedCards.length > 0 && <CSSTransition key={"footer"} timeout={{ enter: 250, exit: 250 }} classNames={"slide-bottom"}>
                        <SelectedFooter {...{
                            onUncheckAllCards: this.onUncheckAllCards,
                            selectedCards: selectedCards,
                            onModalOpen: this.props.onModalOpen,
                            onModalClose: this.props.onModalClose,
                            updateCards: this.updateCards,
                            clientTotalLimit: this.state.clientTotalLimit,
                            stations: this.state.stations
                        }} />
                    </CSSTransition>}
                </TransitionGroup>
            </div>
        );
    }
}

export default connect(
    state => ({ login: state.login, translation: state.translation.cards }),
    dispatch => ({ cardsRequest: (body, success, error) => businessCardsRequest(dispatch, body, success, error) }))(withScrollingHeader(Cards, 80, 72, 80, 52));
