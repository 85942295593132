import React, { Component } from 'react';
import Tippy from '@tippy.js/react';
import './DropdownFilter.scss';
import './CalendarFilter.scss';
import './DropdownFilterTippy.scss';
import IconChevronWhiteLeft from "../assets/Icon_Chevron_White-Left.svg";
import IconChevronWhiteRight from "../assets/Icon_Chevron_White-Right.svg";
import DatePicker from "react-datepicker";
import {connect} from "react-redux";
import {shortFormat} from "../helpers";

const shortFormatRange = (a, b) => `${shortFormat(a)} – ${shortFormat(b)}`;

class CalendarFilter extends Component {
    constructor(props) {
        super(props);
        const locale = (props.login && props.login.privateClientData && props.login.privateClientData.language) || 'et';
        this.state = {
            mounted: false,
            isVisible: false,
            locale: locale,
            startDate: null,
            endDate: null,
            selectsStart: props.dateRange.selectsStart || false,
            selectionStart: props.dateRange.selectionStart || true,
            selectedPeriodId: null,
            text: ''
        };
        this.scrollContainerRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { startDate, endDate, selectedPeriodId } = this.props.dateRange;
        const { items } = this.props;

        if (
            ((selectedPeriodId !== this.state.selectedPeriodId) && !this.state.mounted) ||
            ((prevProps.dateRange.selectedPeriodId !== this.props.dateRange.selectedPeriodId))
        ) {
            this.setState(prevState => {
                let text = '';

                if (selectedPeriodId && items) {
                    const period = items.find(it => it.value === selectedPeriodId);
                    if (period) {
                        text = period.text;
                    }
                }

                if (!text && startDate && endDate && (startDate.getTime() !== endDate.getTime())) {
                    text = shortFormatRange(startDate, endDate);
                }

                if (!text && startDate) {
                    text = shortFormat(startDate);
                }

                return {
                    mounted: true,
                    startDate: startDate || prevState.startDate || null,
                    endDate: endDate || prevState.endDate || null,
                    selectedPeriodId,
                    text,
                };
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (
            (this.state.selectionStart !== nextState.selectionStart) ||
            (this.state.selectedPeriodId !== nextState.selectedPeriodId) ||
            (this.state.selectsStart !== nextState.selectsStart) ||
            (this.state.startDate && (this.state.startDate.getTime() !== nextState.startDate.getTime())) ||
            (this.state.endDate && (this.state.endDate.getTime() !== nextState.endDate.getTime()))
        ) {
            return true;
        }

        if (!nextProps.items && !this.props.items) {
            return false;
        }

        if (
            nextState.isVisible !== this.state.isVisible ||
            nextProps.dateRange.selectedPeriodId !== this.props.dateRange.selectedPeriodId ||
            nextProps.text !== this.props.text
        ) {
            return true;
        }

        if (
            (!nextProps.items && this.props.items) ||
            (nextProps.items && !this.props.items) ||
            (nextProps.items !== this.props.items) ||
            (nextProps.items.some((value, index) => this.props.items.length > index && value.value !== this.props.items[index].value)) ||
            (nextState.mounted !== this.state.mounted)
        ) {
            return true;
        }

        return false;
    }

    get isScrollable() {
        return this.props.items && this.props.items.length > 10 ? true : false;
    }

    onWheelListener = (e) => {
        // close filters if not scrollable and scrolling or scrollable and scrolling outside
        if (this.isScrollable) {
            const current = this.scrollContainerRef.current;
            const scrollTop = current && current.scrollTop;
            const isScrollingDown = e.deltaY > 0;
            const isScrollingUp = e.deltaY < 0;
            const isAtTop = scrollTop === 0;
            const isAtBottom = scrollTop + current.clientHeight === current.scrollHeight;
            if ((isScrollingDown && isAtBottom) || (isScrollingUp && isAtTop)) {
                // e.preventDefault(); //stop the wheel event to prevent scroll chaining
            }
            const isTooltip = (e.target && e.target.classList && e.target.classList.value.includes("tippy")) || e.target.classList.value.includes("dropdown");
            if (!isTooltip) {
                this.onClose();
            }
        } else {
            this.onClose();
        }
    };

    onOpen = () => {
        this.setState({ isVisible: true }, () => {
            window.addEventListener('wheel', this.onWheelListener);
        });
    };

    onClose = () => {
        const { startDate, endDate, selectionStart } = this.state;

        if (!endDate) {
            this.setState({
                isVisible: false,
                endDate: startDate,
                selectionStart: !selectionStart,
                selectedPeriodId: null,
                text: startDate ? shortFormat(startDate) : '',
            }, () => {
                window.removeEventListener('wheel', this.onWheelListener);
                this.props.onChange(this.state);
            });
        } else {
            this.setState({ isVisible: false }, () => {
                window.removeEventListener('wheel', this.onWheelListener);
            });
        }
    };

    setFilterDate = (date) => {
        let {startDate, endDate, selectsStart, selectionStart} = this.state;
        if (!selectionStart && (date < startDate)) {
            endDate = startDate;
            startDate = date;
        } else if (selectionStart) {
            startDate = date;
            endDate = null;
        } else if (!selectionStart) {
            endDate = date;
        }
        const selectedPeriod = (startDate && endDate) ?
            (this.props.items.find(period => {
                return (period.from.getTime() === startDate.getTime()) && (period.to.getTime() === endDate.getTime())
            })) || null: null;
        const selectedPeriodId = selectedPeriod ? selectedPeriod.value: null;

        this.setState(prevState => ({
            ...prevState,
            startDate: startDate,
            endDate: endDate,
            selectsStart: selectsStart,
            selectionStart: !selectionStart,
            selectedPeriodId: selectedPeriodId,
            text: selectedPeriodId ? this.props.items.find(it => it.value === selectedPeriodId).text :
                (startDate && endDate && (startDate.getTime() !== endDate.getTime())) ? shortFormatRange(startDate, endDate) :
                    startDate ? shortFormat(startDate) : ''
        }), () => {
            this.props.onChange(this.state);
        });
    };

    handleMouseMove = (event) => {
        const target = event.target;
        if (target.classList.contains('react-datepicker__day')) {
            const monthParent = target.parentElement.parentElement;
            const yearMonth = monthParent.getAttribute('aria-label').split('-').slice(1);
            let year = parseInt(yearMonth[0]);
            let month = parseInt(yearMonth[1]) - 1;
            const day = parseInt(target.getAttribute('aria-label').split('-')[1]);
            if (target.classList.contains('react-datepicker__day--outside-month')) {
                if (day > 15){
                    month -= 1;
                    if (month < 0) {
                        month = 11;
                        year -= 1;
                    }
                } else {
                    month += 1;
                    if (month > 11) {
                        month = 0;
                        year +=1;
                    }
                }
            }
            const date = new Date(year, month, day);
            const {startDate} = this.state;
            if (startDate && (startDate <= date)) {
                this.setState({selectsStart: false})
            } else {
                this.setState({selectsStart: true})
            }
        }
    };

    render() {
        const {locale, startDate, endDate, selectsStart, selectionStart, mounted, text} = this.state;
        // const theme = this.props.distance === -72 ? "dropdown-filter dropdown-calendar-filter" : "dropdown-filter-light dropdown-calendar-filter-light";
        const theme = this.props.theme;
        if (!mounted) {
            return null;
        }
        return (
            <Tippy zIndex={20} placement={"bottom"} arrow={false} interactive={true} trigger={"manual"} animateFill={false}
                distance={this.props.distance}
                animation={"fade"}
                theme={theme}
                isVisible={this.state.isVisible}
                onHidden={() => this.onClose()}
                onHide={() => this.onClose()}
                offset={this.props.offset}
                content={
                    <div className="co-dropdown-filter-tippy-content co-dropdown-calendar-filter-tippy-content">
                        <div className="co-dropdown-filter-left">
                            <div className={`co-dropdown-filter_dropdown co-dropdown-calendar-filter_dropdown ${this.isScrollable ? "with-scroll" : ""}`} ref={this.scrollContainerRef}>
                                {this.props.items && this.props.items.map((item, index) => <div className="co-dropdown-filter_dropdown_option-container" key={index}>
                                        <button className={`co-dropdown-filter_dropdown_option-button ${text === item.text ? "active" : ""}`}
                                                onClick={() => {
                                                    this.onClose();
                                                    item.onClick();
                                                }} >
                                            {item.text}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="co-dropdown-filter-right">
                            <button className={`co-btn-dropdown tippy filter-calendar-tippy ${this.props.className ? this.props.className : ""}`} onClick={() => this.onClose()}>
                                {this.props.leftImg && <img className="co-btn-dropdown_img-left" src={this.props.leftImg} alt="" />}
                                <span className={`co-btn-dropdown_text date-filter-title tippy expanded ${this.props.className ? this.props.className : ""}`}>{text}</span>
                                {this.props.rightImg && <img className="co-btn-dropdown_img-right" src={this.props.rightImg} alt={this.state.isOpen ? "toggle filters close" : ""}/>}
                            </button>
                            <div className="dropdown-calendar" onMouseMove={this.handleMouseMove}>
                                <DatePicker
                                    {...{
                                        inline: true,
                                        onSelect: this.setFilterDate,
                                        locale: locale,
                                        selected: selectionStart ? endDate : startDate,
                                        selectsStart: (selectsStart && !selectionStart),
                                        selectsEnd: (!selectsStart && !selectionStart),
                                        startDate: (selectsStart && !selectionStart) ? endDate : startDate,
                                        endDate: (selectsStart && !selectionStart) ? startDate : endDate,
                                        renderCustomHeader: ({ date, decreaseMonth, increaseMonth }) => (
                                            <div className="§">
                                                <button className="date_picker-header-button" onClick={decreaseMonth}>
                                                    <img className="date_picker-header-button-img" src={IconChevronWhiteLeft} alt="prev month" />
                                                </button>
                                                <span className="date_picker-header-title">{`${this.props.months[date.getMonth()]} ${date.getFullYear()}`}</span>
                                                <button className="date_picker-header-button" onClick={increaseMonth}>
                                                    <img className="date_picker-header-button-img" src={IconChevronWhiteRight} alt="next month" />
                                                </button>
                                            </div>
                                        ),
                                    }} />
                            </div>
                        </div>
                    </div>
                } >
                <button className={`co-btn-dropdown ${this.props.className ? this.props.className : ""}`} onClick={ () => this.onOpen()} disabled={this.props.disabled}>
                    {this.props.leftImg && <img className="co-btn-dropdown_img-left" src={this.props.leftImg} alt="" />}
                    <span className={`co-btn-dropdown_text date-filter-title ${this.props.className ? this.props.className : ""}`}>{text}</span>
                    {this.props.rightImg && <img className="co-btn-dropdown_img-right" src={this.props.rightImg} alt={this.state.isOpen ? "" : "toggle filters open"} />}
                </button>
            </Tippy>
        );
    }
}


export default connect(
    state => ({
        login: state.login,
        translation: state.translation.electricity,
        months: state.translation.overview.fuelUsage.months,
        isMobileLayout: state.size.isMobileLayout
    })
)(CalendarFilter);
