import React from 'react';
import { connect } from 'react-redux';

import euroCircleImg from '../assets/Icon_Euro-Circle_32px.svg';
import timeCircleImg from '../assets/Icon_Time-Circle_32px.svg'
import graphCircleImg from '../assets/Icon_Graph-Circle_32px.svg';
import arrowsCircleImg from '../assets/Icon_Arrows-Circle_32px.svg';
import styles from './BillingStats.module.scss';

export const Item = React.memo(props => (
    <div className={styles.item}>
        <img className={styles.img} src={props.img} alt="" />
        <p className={styles.info}>{props.info}</p>
        <h1 className={styles.title}>{props.title}</h1>
    </div>
));

const BillingStats = React.memo(props => (
    <div className={`${styles.stats} ${props.className ? props.className : ''}`}>
        <Item {...{ img: euroCircleImg, info: props.translation.unpaidTotal, title: props.unpaidTotal }} />

        <div className={styles.separator}></div>
        <Item {...{ img: timeCircleImg, info: props.translation.overDeadline, title: props.overDeadlineTotal }} />

        <div className={styles.separator}></div>
        {props.balance !== undefined ?
            <Item {...{ img: graphCircleImg, info: props.translation.balance, title: props.balance }} /> :
            <Item {...{ img: graphCircleImg, info: props.translation.remainingLimit, title: props.remainingLimit }} />}

        <div className={styles.separator}></div>
        <Item {...{ img: arrowsCircleImg, info: props.translation.referenceNumber, title: props.referenceNumber }} />
    </div>
));

export default connect(state => ({ translation: state.translation.idCredit.headerStats }))(BillingStats);
