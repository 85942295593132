import React from 'react';
import { connect } from 'react-redux';
import { getHeaderState } from '../../../Utils';

export const withScrollingHeader = (WrappedComponent, beforeHeaderHeight, headerHeight, beforeHeaderHeightMobile, headerHeightMobile) =>
    connect(state => ({ isMobileLayout: state.size.isMobileLayout }), null)(
        //positioning in every component's own css
        class extends React.PureComponent {
            constructor(props) {
                super(props);
                this.state = { isHeaderFixed: false, isHeaderAnimated: false, isHeaderHiding: false };
            }

            scrollYHistory = {
                last: null,
                beforeLast: null
            };

            onScrollListener = (e) => {
                const scrollY = document.documentElement.scrollTop || document.body.scrollTop;
                const scrollY1 = this.scrollYHistory.last;
                const scrollY2 = this.scrollYHistory.beforeLast;

                const canHide = !this.props.isMobileLayout;
                const newHeaderState = getHeaderState(scrollY, scrollY1, scrollY2, this.props.isMobileLayout ? beforeHeaderHeightMobile : beforeHeaderHeight,
                    this.props.isMobileLayout ? headerHeightMobile : headerHeight, this.state.isHeaderFixed, this.state.isHeaderAnimated, canHide);
                this.setState({ ...newHeaderState });

                this.scrollYHistory.beforeLast = this.scrollYHistory.last;
                this.scrollYHistory.last = scrollY;
            }

            componentDidMount() {
                window.addEventListener('scroll', this.onScrollListener);
            }

            componentWillUnmount() {
                window.removeEventListener('scroll', this.onScrollListener);
            }

            render() {
                return (<WrappedComponent {...{ ...this.props, ...this.state }} />);
            }
        }
    );