import React, { useEffect } from 'react';

import Pakett01a from "../../../assets/Pakett_01a_SVG.svg";
import Pakett01b from "../../../assets/Pakett_01b_SVG.svg";
import Pakett02a from "../../../assets/Pakett_02a_SVG.svg";

export const PacketOptionsSection =
    ({
        selectedMeteringPoints,
        setSelectedPackage,
        recommended,
        electricityPackages,
        calculatedForMonth,
        translation,
        selectedStep,
        openSelectMeteringPointModal,
        isMobileLayout,
        isTabletLayout,
        scrollRef,
        behaviour,
        width,
        ...props
    }) => {
    const graph = {
        0: Pakett01a,
        1: Pakett01b,
        2: Pakett02a
    };

    const bgLayerCount = 2;

    electricityPackages.fixed1.invoicePrice.price = calculatedForMonth.fixed1;
    electricityPackages.fixed2.invoicePrice.price = calculatedForMonth.fixed2;
    electricityPackages.stock.invoicePrice.price = calculatedForMonth.stock;

    electricityPackages.fixed1.unitPrice.price = selectedStep.fixed1;
    electricityPackages.fixed2.unitPrice.day = selectedStep.fixed2.day;
    electricityPackages.fixed2.unitPrice.night = selectedStep.fixed2.night;

    electricityPackages.stock.unitPrice.price = selectedStep.stock;

    (recommended.indexOf(0) > -1) ? electricityPackages.fixed1.recommended = true : electricityPackages.fixed1.recommended = false;
    (recommended.indexOf(1) > -1) ? electricityPackages.fixed2.recommended = true : electricityPackages.fixed2.recommended = false;
    (recommended.indexOf(2) > -1) ? electricityPackages.stock.recommended = true : electricityPackages.stock.recommended = false;

    const {fixed1, fixed2, stock} = electricityPackages;
    fixed1.id = 1;
    fixed2.id = 2;
    stock.id = 3;
    const contracts = [fixed1, fixed2, stock];

    const mpLen = selectedMeteringPoints ? selectedMeteringPoints.filter(mp => mp.selected).length : 0;

    const getDeviation : number = (element: HTMLElement) => {
        const boxWidth = element.clientWidth;
        if (boxWidth <= width / 2) {
            return width / 4;
        } else {
            return (width - boxWidth) / 2;
        };
    };

    let scrollIndex = contracts.indexOf(contracts.find(ct => ct.recommended));

    const [transition, setTransition] = React.useState({
        indexes: {
            '1': fixed1.recommended,
            '2': fixed2.recommended,
            '3': stock.recommended
        },
        regions: {
            '1-1': fixed1.recommended && !fixed2.recommended,
            '1-2': fixed1.recommended && fixed2.recommended && !stock.recommended,
            '1-3': fixed1.recommended && fixed2.recommended && stock.recommended,
            '2-2': !fixed1.recommended && fixed2.recommended && !stock.recommended,
            '2-3': !fixed1.recommended && fixed2.recommended && stock.recommended,
            '3-3': !fixed1.recommended && !fixed2.recommended && stock.recommended
        },
        prevRegions: {
            '1-1': fixed1.recommended && !fixed2.recommended,
            '1-2': fixed1.recommended && fixed2.recommended && !stock.recommended,
            '1-3': fixed1.recommended && fixed2.recommended && stock.recommended,
            '2-2': !fixed1.recommended && fixed2.recommended && !stock.recommended,
            '2-3': !fixed1.recommended && fixed2.recommended && stock.recommended,
            '3-3': !fixed1.recommended && !fixed2.recommended && stock.recommended
        }
    });

    useEffect(() => {
        window.addEventListener('resize', scrollTo);
        updateIndexes();
        return function cleanup() {
            window.removeEventListener('resize', scrollTo);
        }
    });

    const updateIndexes = () => {
        scrollTo();
        if (
            (transition.indexes['1'] !== fixed1.recommended) ||
            (transition.indexes['2'] !== fixed2.recommended) ||
            (transition.indexes['3'] !== stock.recommended)
        ) {
            updateState();
        }
    };

    const updateState = () => {
        setTransition({
            indexes: {
                '1': fixed1.recommended,
                '2': fixed2.recommended,
                '3': stock.recommended
            },
            regions: {
                '1-1': fixed1.recommended && !fixed2.recommended,
                '1-2': fixed1.recommended && fixed2.recommended && !stock.recommended,
                '1-3': fixed1.recommended && fixed2.recommended && stock.recommended,
                '2-2': !fixed1.recommended && fixed2.recommended && !stock.recommended,
                '2-3': !fixed1.recommended && fixed2.recommended && stock.recommended,
                '3-3': !fixed1.recommended && !fixed2.recommended && stock.recommended
            },
            prevRegions: transition.regions
        });
    };

    const scrollTo = () => {
        if (isMobileLayout || (behaviour === 1 && isTabletLayout)) {
            const scrollArea: HTMLElement = scrollRef.current;
            const scrollToItem: HTMLElement = scrollArea.firstChild.children[scrollIndex  + bgLayerCount];
            const deviation = isMobileLayout ? 38 : getDeviation(scrollToItem);
            const scrollToLocation = (scrollToItem.offsetLeft - deviation) - scrollArea.scrollLeft;
            scrollArea.scrollBy({
                left: scrollToLocation,
                behavior: 'smooth'
            });
        }
    };

    const handleClick = (index) => (event) => {
        if (index > -1 && index < 3) {
            scrollIndex = index;
            updateIndexes();
        }
    };

    const bg1 = Object.keys(transition.regions).find(key => transition.regions[key]);
    const bg2 = Object.keys(transition.regions).find(key => transition.regions[key] && (key !== bg1));
    const bg1prev = Object.keys(transition.prevRegions).find(key => transition.prevRegions[key]);
    const bg2prev = Object.keys(transition.prevRegions).find(key => transition.prevRegions[key] && (key !== bg1prev));
    const fade1 = (bg1 === bg2prev);

    return (
        <div className="packet-options-container" ref={scrollRef}>
            <div className="packet-options ">
                <div className={`packet-options-bg-transition bg-1 loc-${bg1} ${fade1 ? 'bg-fadeout' : null}`} />
                <div className={`packet-options-bg-transition bg-2 loc-${bg2 || bg1} prev-loc-${(!bg2prev && !bg2) ? '' : 'changed'} prev-loc-${bg2prev || bg1prev} ${bg2 ? null : 'bg-fadeout'}`} />
                {contracts.map((packet, index) => (
                    <div key={index} className={`packet packet-${index} ${(packet.recommended && 'packet-recommended') || ''}`}>
                        <div className="packet-img">
                            <img src={graph[index]} alt=""></img>
                        </div>
                        {
                            (isMobileLayout || (behaviour === 1 && isTabletLayout)) ?
                                <div className="packet-title-mobile">
                                    <div onClick={handleClick(index - 1)} className={index > 0 ? "chevron-icon-container" : "chevron-icon-placeholder"}><div className="chevron-left-icon"/></div>
                                    <h3 className="packet-title">{ packet.name }</h3>
                                    <div onClick={handleClick(index + 1)} className={index < 2 ? "chevron-icon-container" : "chevron-icon-placeholder"}><div className="chevron-right-icon"/></div>
                                </div>
                            :
                            <h3 className="packet-title">{ packet.name }</h3>
                        }
                        <div className="packet-prices">
                            <div className="packet-price-container">
                            <span className="price-value">
                                {packet.invoicePrice.price}
                            </span>
                                <span className="price-unit">{translation.eurosMonth}</span>
                                <span className="description">{ packet.invoicePrice.text }</span>
                            </div>
                            <span className="divider">/</span>
                            <div className="packet-price-container">
                                {
                                    (packet.unitPrice.price) ? (
                                        <React.Fragment>
                                            <span className="price-value">{packet.unitPrice.price }</span>
                                            <span className="price-unit">{translation.centsKwh}</span>
                                            <span className="description">{ packet.unitPrice.text }</span>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <span className="price-value">{packet.unitPrice.night}</span>
                                            <span className="price-unit">{translation.cents}</span>
                                            <span className="divider">/</span>
                                            <span className="price-value">{packet.unitPrice.day}</span>
                                            <span className="price-unit">{translation.cents}</span>
                                            <span className="description">{packet.unitPrice.text}</span>
                                        </React.Fragment>
                                    )
                                }
                            </div>
                        </div>
                        <span className="chevron-bottom"></span>
                        <div className="price-options">
                            { packet.descRows.map((r, index) => <span key={'d_' + index}>{ r }</span>)}
                        </div>
                        <span className="chevron-bottom"></span>
                        {
                            (mpLen) ?
                                <button onClick={() => setSelectedPackage(packet)} className="btn btn-white">
                                    {translation.meteringPointModal.selectPackage}
                                </button>
                                :
                                <button onClick={openSelectMeteringPointModal} className="btn btn-white">
                                    {translation.meteringPointModal.selectMeteringPoint}
                                </button>
                        }
                    </div>
                ))}
            </div>
        </div>
    )
};
