export const TYPE_SHOW_ERROR_MODAL = "TYPE_SHOW_ERROR_MODAL";

export const errorModal = (isErrorModalVisible) => ({
    type: TYPE_SHOW_ERROR_MODAL,
    isErrorModalVisible: isErrorModalVisible
});

export const initialErrorState = { isErrorModalVisible: false };

export default function error(state = initialErrorState, action) {
    switch (action.type) {
        case TYPE_SHOW_ERROR_MODAL:
            return { ...state, isErrorModalVisible: action.isErrorModalVisible };
        default:
            return state;
    }
}