import React, { Component } from 'react';
import './Electricity.scss';
import {MeteringPointModal} from "./modal/MeteringPointModal/MeteringPointModal";
import {ContractPreviewModal} from "./modal/ConfirmationModal/ContractPreviewModal/ContractPreviewModal";
import {StepsSection} from "./components/StepsSection";
import {PacketCalculatorSection} from "./components/PacketCalculatorSection";
import {PacketOptionsSection} from "./components/PacketOptionsSection";

export default class SelectContractElectricity extends Component {
    constructor(props) {
        super(props);
        this.openSelectMeteringPointModal = this.openSelectMeteringPointModal.bind(this);
        this.setSelectedMeteringPoints = this.setSelectedMeteringPoints.bind(this);
        this.setSelectedPackage = this.setSelectedPackage.bind(this);
        const {predictedDistribution, predictedUsage, contractDuration} = props.data.priceParameters;
        let selectedMeteringPoints = [];
        if ((props.behaviour > 2) && (props.meteringPointCodes)) {
            selectedMeteringPoints = props.meteringPoints.map(meteringPoint => {
                meteringPoint.selected = props.meteringPointCodes.indexOf(meteringPoint.code) > -1;
                return meteringPoint;
            })
        } else {
            selectedMeteringPoints = props.meteringPoints.map(meteringPoint => {
                meteringPoint.selected = false;
                return meteringPoint;
            });
        }

        this.state = {
            dayValue: predictedDistribution.value,
            yearValue: predictedUsage.value,
            periodValue: Object.keys(contractDuration.steps).indexOf((contractDuration.value).toString(10)),
            selectedStep: {
                fixed1: null,
                fixed2: {
                    day: null,
                    night: null
                },
                stock: null
            },
            calculatedForMonth: {
                fixed1: null,
                fixed2: null,
                stock: null
            },
            selectedMeteringPoints: selectedMeteringPoints,
            selectedPackage: props.data.chosenPacket || null,
            recommended: [0]
        };
        this.scrollRef = React.createRef();
        this.scrollToSlidersRef = React.createRef();
        this.periods = props.periods;
    }

    componentDidMount() {
        this.calculateSums();
    }

    setSelectedPackage(selectedPackage) {
        this.setState({selectedPackage: selectedPackage}, () => this.openContractPreviewModal(selectedPackage)());
    }

    setSelectedMeteringPoints(selectedMeteringPoints) {
        this.setState({selectedMeteringPoints: selectedMeteringPoints});
        this.scrollToSliders();
    }

    openContractPreviewModal = (selectedPackage) => (event) => {
        const {yearValue, periodValue, dayValue, selectedMeteringPoints} = this.state;
        const {electricityRequest, onDarkModalClose, onDarkModalFastOpen, onSwitchDarkModal, refreshData, contractIds} = this.props;
        const onCloseButtonClick = onDarkModalClose;
        const passProps = {onSwitchDarkModal, onCloseButtonClick, electricityRequest, onDarkModalFastOpen, yearValue, periodValue, dayValue, selectedPackage, refreshData, contractIds, selectedMeteringPoints};
        this.props.onDarkModalFastOpen(
            <ContractPreviewModal
                {...passProps}
            />
        )
    };

    openSelectMeteringPointModal() {
        const {data, isMobileLayout} = this.props;
        const {selectedStep, calculatedForMonth, recommended, selectedMeteringPoints} = this.state;
        if (calculatedForMonth.fixed1 &&  calculatedForMonth.fixed2 && calculatedForMonth.stock) {
            data.electricityPackages.fixed1.invoicePrice.price = calculatedForMonth.fixed1;
            data.electricityPackages.fixed2.invoicePrice.price = calculatedForMonth.fixed2;
            data.electricityPackages.stock.invoicePrice.price = calculatedForMonth.stock;

            data.electricityPackages.fixed1.unitPrice.price = selectedStep.fixed1;
            data.electricityPackages.fixed2.unitPrice.day = selectedStep.fixed2.day;
            data.electricityPackages.fixed2.unitPrice.night = selectedStep.fixed2.night;
            data.electricityPackages.stock.unitPrice.price = selectedStep.stock;
            (recommended.indexOf(0) > -1) ? data.electricityPackages.fixed1.recommended = true : data.electricityPackages.fixed1.recommended = false;
            (recommended.indexOf(1) > -1) ? data.electricityPackages.fixed2.recommended = true : data.electricityPackages.fixed2.recommended = false;
            (recommended.indexOf(2) > -1) ? data.electricityPackages.stock.recommended = true : data.electricityPackages.stock.recommended = false;

            const newProps = {
                onCloseButtonClick: this.props.onModalClose,
                data: data,
                onModalOpen:this.props.onModalOpen,
                meteringPoints: selectedMeteringPoints,
                isMobileLayout: isMobileLayout,
                setSelectedMeteringPoints: this.setSelectedMeteringPoints,
                refreshData: this.props.refreshData,
            };

            this.props.onModalOpen(
                <MeteringPointModal
                    {...newProps}
                />
            );
        }
    }

    calculateSums() {
        const answer = this.props.calculateElectricity(this.state.yearValue, this.state.dayValue, this.state.periodValue);
        if (answer) {
            this.setState({selectedStep: answer.step, calculatedForMonth: answer.calculatedForMonth, recommended: answer.recommended});
        }
    }

    sliderChange = (slider) => (value) => {
        this.setState({ [slider]: value }, this.calculateSums);
    };

    scrollToSliders = () => {
        const scrollToItem = this.scrollToSlidersRef.current;
        const scrollArea = this.props.behaviour === 1 ? scrollToItem.parentElement.parentElement : scrollToItem.parentElement.parentElement.parentElement;
        const deviation = scrollArea.firstElementChild.clientHeight;
        const scrollDistance = (scrollToItem.getBoundingClientRect().top - deviation);
        window.scrollBy({
            top: scrollDistance,
            behavior: 'smooth'
        });
    };

    resetMeteringPoints = () => {
        const selectedMeteringPoints = this.state.selectedMeteringPoints.map(mp => {
            mp.selected = false;
            return mp;
        });
        this.setState({
            selectedMeteringPoints: selectedMeteringPoints
        })
    };

    render() {
        const {data, translation, getElectricityData, isMobileLayout, isTabletLayout, selectedPackage, behaviour, width} = this.props;
        const {selectedStep, calculatedForMonth, recommended, yearValue, dayValue, periodValue, selectedMeteringPoints} = this.state;
        const {priceParameters, electricityPackages, steps} = data;
        const {authentication, meteringPoint, electricityPackage} = steps;
        const {contractDuration, predictedUsage} = priceParameters;

        const {scrollToSliders, scrollToSlidersRef, setSelectedPackage, scrollRef, periods, sliderChange, openSelectMeteringPointModal, resetMeteringPoints} = this;
        return <>
            <div className={`electricity-page ${(behaviour > 1) ? 'electricity-page-inline' : 'electricity-page-full'} ${(selectedPackage === null || selectedPackage === undefined) ? '' : 'no-packet'}`}>
                <div className="electricity-header-shadow"></div>
                { behaviour === 1 &&
                    <div className="electricity-header">
                        <h1 className="electricity-header-title">{translation.title}</h1>
                    </div>
                }
                <StepsSection {...{getElectricityData, authentication, meteringPoint, electricityPackage, openSelectMeteringPointModal, isMobileLayout, selectedMeteringPoints, scrollToSliders, resetMeteringPoints, ...this.props}} />

                <PacketCalculatorSection {...{scrollToSlidersRef, yearValue, dayValue, periodValue, sliderChange, contractDuration, predictedUsage, translation, periods, ...this.props}}  />

                {selectedStep.fixed1 &&
                <PacketOptionsSection {...{selectedMeteringPoints, setSelectedPackage, recommended, electricityPackages, calculatedForMonth, translation, selectedStep, openSelectMeteringPointModal, isMobileLayout, isTabletLayout, behaviour, scrollRef, width, ...this.props}} />
                }
            </div>
        </>;
    }
}
