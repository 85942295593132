import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from './components/Header';
import Transactions from './components/Transactions';
import Invoices from './components/Invoices';
import './Invoicing.scss';

const Invoicing = React.memo(props =>
    <div className="invoicing-page">
        <Header {...{ pathname: props.pathname }} />
        <Route exact path="/invoicing" render={() => <Transactions {...{ onMobileFiltersOpen: props.onMobileFiltersOpen }} />} />
        <Route path="/invoicing/invoices" render={() => <Invoices {...{ onMobileFiltersOpen: props.onMobileFiltersOpen, onModalOpen: props.onModalOpen, onModalClose: props.onModalClose }} />} />
    </div>
);

export default connect(state => ({ pathname: state.router.location.pathname }), null)(Invoicing); //pass the route to the header to make it update on route changes
