import React from 'react';
import "./Loader.scss";

const Loader = React.memo(props =>
    <span className={`loader-outer ${props.className ? props.className : ""}`}>
        <span className={`loader`}>
            <span className="loader-inner"></span>
        </span>
    </span>
);

export default Loader;