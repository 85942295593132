import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ActivateCard, EditCardLimits, BlockCard, UnblockCard } from './Modals';
import OrderCard from './OrderCard';
import './SelectedFooter.scss';
import IconCrossCircleYellow16px from '../../../assets/Icon_Cross-Circle_Yellow-16px.svg';
import IconGraphYellow16px from '../../../assets/Icon_Graph_Yellow-16px.svg';
import IconInputYellow16px from '../../../assets/Icon_Input_Yellow-16px.svg';
import IconModalCross16px from '../../../assets/Icon_Modal-Cross_16px.svg';
import IconPlusYellow from '../../../assets/Icon_Plus_Yellow.svg';
import IconCheckOrange from '../../../assets/Icon_Check_Orange-16px.svg';

const ImageTextButton = React.memo(props =>
    <button className="selected-button" onClick={props.onClick} style={{ visibility: props.buttonHidden ? "hidden" : "" }}>
        <img className="selected-button_img" src={props.img} alt="" />
        <span className="selected-button_text" >{props.text}</span>
    </button>
);

const SelectedFooter = React.memo(props => {
    const blockedAndCanOpen = props.selectedCards ? props.selectedCards.filter(c => c.isBlocked && c.canOpen && !c.needsActivation) : [];
    const unblocked = props.selectedCards ? props.selectedCards.filter(c => !c.isBlocked && !c.needsActivation) : [];
    const needsActivation = props.selectedCards ? props.selectedCards.filter(c => c.needsActivation) : [];
    const selectedNumbers = props.selectedCards ? props.selectedCards.map(x => x.number) : null;
    const cards = props.selectedCards ? props.selectedCards.map(x => ({
        name: x.name,
        limit: x.totalLimitInt,
        dayLimit: x.dayLimitInt || 0,
        selectedFuelType: x.allowedFuel,
        selectedProductType: x.allowedProduct,
        number: x.number
    })) : [];

    return (
        <div className="cards-page-select-footer">
            <div className="cards-page-select-footer-content">
                <p className="cards-page-select-footer_text">{props.selectedCards.length} {props.selectedCards.length <= 1 ? props.translation.selectedCard : props.translation.selectedCards}</p>
                <button className="cards-page-select-footer_close-button" onClick={props.onUncheckAllCards}>
                    <img className="cards-page-select-footer_close-button_img" src={IconModalCross16px} alt="clear selection" />
                </button>
                <div className="cards-page-select-buttons">
                    <ImageTextButton {...{
                        text: props.translation.extendButton,
                        img: IconInputYellow16px,
                        onClick: () => props.onModalOpen(<OrderCard {...{
                            extending: true,
                            stations: props.stations,
                            onModalClose: props.onModalClose,
                            cards: cards
                        }} />)
                    }} />
                    <ImageTextButton {...{
                        text: props.translation.editLimitButton,
                        img: IconGraphYellow16px,
                        onClick: () => props.onModalOpen(<EditCardLimits {...{
                            numbers: selectedNumbers,
                            clientTotalLimit: props.clientTotalLimit,
                            updateCards: props.updateCards,
                            onModalClose: props.onModalClose
                        }} />)
                    }} />
                    { unblocked.length ? <ImageTextButton {...{
                        text: props.translation.blockButton,
                        img: IconCrossCircleYellow16px,
                        onClick: () => props.onModalOpen(<BlockCard {...{
                            numbers: unblocked.map(c => c.number),
                            updateCards: props.updateCards,
                            onModalClose: props.onModalClose
                        }} />)
                    }} /> : null}
                    { blockedAndCanOpen.length ? <ImageTextButton {...{
                        text: props.translation.unblockButton,
                        img: IconPlusYellow,
                        onClick: () => props.onModalOpen(<UnblockCard {...{
                            numbers: blockedAndCanOpen.map(c => c.number),
                            updateCards: props.updateCards,
                            onModalClose: props.onModalClose
                        }} />)
                    }} /> : null}
                    { needsActivation.length ? <ImageTextButton {...{
                        text: props.translation.activateButton,
                        img: IconCheckOrange,
                        onClick: () => props.onModalOpen(<ActivateCard {...{
                            numbers: needsActivation.map(c => c.number),
                            updateCards: props.updateCards,
                            onModalClose: props.onModalClose
                        }} />)
                    }} /> : null}
                </div>
            </div>
        </div>);
});
SelectedFooter.propTypes = {
    onUncheckAllCards: PropTypes.func,
    selectedCards: PropTypes.array,
    onModalOpen: PropTypes.func,
    onModalClose: PropTypes.func,
    updateCards: PropTypes.func,
    clientTotalLimit: PropTypes.any,
    stations: PropTypes.array
};

export default connect(state => ({ translation: state.translation.cards.footer }))(SelectedFooter);
