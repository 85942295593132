import React, { PureComponent } from 'react';
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import "./Pagination.scss";
import IconChevronRightPaginationGray16px from "../assets/Icon_Chevron-Right-Pagination_Gray-16px.svg";
import IconChevronRightPaginationLight16px from "../assets/Icon_Chevron-Right-Pagination_Light-16px.svg";
import IconEllipsis from "../assets/Icon_Ellipsis.svg";

class Pagination extends PureComponent {
    constructor(props) {
        super(props);
        this.pageTextInput = React.createRef();

        this.state = { pageTextInput: "", firstPage: 1, lastPage: this.props.pages ? Object.keys(this.props.pages).length - 1 : 1 };
    }

    componentDidUpdate(prevProps, prevState, snapshopt) {
        if (prevProps.pages !== this.props.pages && this.props.pages !== undefined) {
            this.setState({ lastPage: Object.keys(this.props.pages).length - 1 });
        }
    }

    onPageTextInputChanged = (e) => {
        if (e.target.value.length === 0 || (e.target.value !== "0" && /^\d+$/.test(e.target.value))) {
            this.setState({ pageTextInput: e.target.value });
        }
    }

    onPageTextInputSubmit = (e) => {
        e.preventDefault();
        if (this.state.pageTextInput) {
            this.props.handlePageSelected(Math.min(Math.max(parseInt(this.state.pageTextInput), this.state.firstPage), this.state.lastPage));
        }
    }

    get prevButtonActive() {
        return this.props.selectedPage > this.state.firstPage;
    }

    get nextButtonActive() {
        return this.props.selectedPage < this.state.lastPage;
    }

    onPrevButtonClick = () => {
        if (this.prevButtonActive) {
            this.props.handlePageSelected(this.props.selectedPage - 1);
        }
    }

    onNextButtonClick = () => {
        if (this.nextButtonActive) {
            this.props.handlePageSelected(this.props.selectedPage + 1);
        }
    }

    onPageButtonClick = (page) => {
        if (this.props.selectedPage !== page) {
            this.props.handlePageSelected(page);
        }
    }

    render() {
        const pages = this.props.pages;
        const selectedPage = this.props.selectedPage;
        const pagesKeys = Object.keys(pages);
        const pagesLen = pagesKeys.length;
        const fromSelectedPage = selectedPage < pagesLen - 5;
        const pageNumbers = pagesLen <= 6 ? pagesKeys :
            [
                this.props.pages[fromSelectedPage ? selectedPage : pagesLen - 5],
                this.props.pages[fromSelectedPage ? selectedPage + 1 : pagesLen - 4],
                this.props.pages[fromSelectedPage ? selectedPage + 2 : pagesLen - 3],
                this.props.pages[fromSelectedPage ? selectedPage + 3 : pagesLen - 2],
                "...",
                this.props.pages[pagesLen - 1]
            ];
        return (
            <div className={`co-pagination ${this.props.className ? this.props.className : ""}`}>
                <button className="co-pagination_button left" onClick={this.onPrevButtonClick}>
                    <img src={this.prevButtonActive ? IconChevronRightPaginationGray16px : IconChevronRightPaginationLight16px}
                        alt={this.prevButtonActive ? "previous page" : ""} />
                </button>
                {pageNumbers && pageNumbers.length > 0 && pageNumbers.map((item, index) => item === "..." ?
                    <div className="co-pagination_ellipsis" key={index} onClick={() => this.pageTextInput.current.focus()}>
                        <img className="co-pagination_ellipsis-img" src={IconEllipsis} alt="select page" />
                        <Tippy content={"Sisestage lehe number"} trigger={"click"}>
                            <form className="co-pagination_ellipsis-form" onSubmit={this.onPageTextInputSubmit}>
                                <input className="co-pagination_ellipsis-input" type="text" size={2} ref={this.pageTextInput}
                                    value={this.state.pageTextInput} onChange={this.onPageTextInputChanged}></input>
                            </form>
                        </Tippy>
                    </div> :
                    <button className={`co-pagination_button text ${String(selectedPage) === String(item) ? "active" : ""}`} key={index}
                        onClick={() => this.onPageButtonClick(item)}>{item}</button>)}
                <button className="co-pagination_button" onClick={this.onNextButtonClick}>
                    <img src={this.nextButtonActive ? IconChevronRightPaginationGray16px : IconChevronRightPaginationLight16px}
                        alt={this.nextButtonActive ? "next page" : ""} />
                </button>
            </div>
        );
    }
};

export default Pagination;