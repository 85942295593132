import React, { PureComponent } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ContentContainer from './ContentContainer';
import "./ExpandableContent.scss";
import IconChevronDownBlack16px from '../assets/Icon_Chevron-Down_Black-16px.svg';

export default class ExpandableContent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { isExpanded: true };
    }

    onExpandedToggle = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    onTitleExpandToggle = (e) => {
        const element = e.target.tagName.toLowerCase();
        if (element !== "input" && element !== "button") {
            this.setState({ isExpanded: !this.state.isExpanded });
        }
    }

    render() {
        return (
            <ContentContainer {...{
                className: `${this.props.className} ${this.state.isExpanded ? "" : "collapsed"}`,
                title:
                    <div className="co-expandable-content_title" onClick={this.onTitleExpandToggle}>
                        {this.props.title}
                        <button className={`co-expandable-content_expand-button ${this.props.buttonClassName ? this.props.buttonClassName : ""} ${this.state.isExpanded ? "" : "collapsed"}`} onClick={this.onExpandedToggle}>
                            <img className="co-expandable-content_expand-button_img" src={IconChevronDownBlack16px} alt={this.state.isExpanded ? "collapse" : "expand"} />
                        </button>
                    </div>,
                content: <TransitionGroup component={null}>
                    {this.state.isExpanded &&
                        <CSSTransition key={"content-container-content"} timeout={{ enter: 600, exit: 600 }} classNames={this.props.height ? "height" : "fade"}>
                            <div className={`co-expandable-content_content`} style={{ height: this.props.height ? `${this.props.height}px` : "auto" }}>
                                <div className="co-expandable-content_content-clipping">
                                    {this.props.content}
                                </div>
                            </div>
                        </CSSTransition>}
                </TransitionGroup>
            }} />
        );
    }
}