import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { BackgroundTable, TableDataCell, TableHeaderCell, withBackgroundTableHighlighting } from '../../../components/Table';
import BlackCheckbox from '../../../components/BlackCheckbox';
import { businessVirtualFamilyRequest } from '../../../../../Networking';
import Loader from '../../../components/Loader';
import NoContent from '../../../components/NoContent';
import Pagination from '../../../components/Pagination';
import { Modal, ModalText } from "../../manage/components/Modal";
import './Members.scss';
import IconCrossCircleYellow16px from '../../../assets/Icon_Cross-Circle_Yellow-16px.svg';
import IconModalCross16px from '../../../assets/Icon_Modal-Cross_16px.svg';

export const DeactiveMembers = connect(state => ({ translation: state.translation.virtualFamily.members.deactiveModal, login: state.login }))(class extends React.PureComponent {
    state = { isLoaderVisible: false, isDone: false, isError: false, result: null };

    deactivateMember = (personalCodes, familyId) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        this.setState({ isLoaderVisible: true }, () => {
            this.props.virtualFamilyRequest({
                "requestType": "deactivateMember",
                "accNr": accnr,
                "token": this.props.login.token,
                "familyId": familyId,
                "memberPersonalCode": personalCodes
            }, data => {
                this.setState({
                    result: data.records.result,
                    isDone: data.records.statusCode === 200,
                    isError: data.records.statusCode !== 200,
                    isLoaderVisible: false
                });
                if (data.records.statusCode === 200) {
                    this.props.onModalClose(true);
                }
            }, error => this.setState({ isLoaderVisible: false }));
        })
    }

    render() {
        return (
            <Modal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onModalClose,
                content: <ModalText {...{ text: this.props.translation.text }} />,
                isSaveButton: true,
                actionButtonTitle: this.props.translation.deactivateButton,
                onActionButtonClick: () => this.deactivateMember(this.props.personalCodes, this.props.familyId),
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 64
            }} />
        )
    }
});
DeactiveMembers.propTypes = {
    personalCodes: PropTypes.array,
    familyId: PropTypes.any,
    virtualFamilyRequest: PropTypes.func
};

const MembersSelectionBottomBar = connect(state => ({ translation: state.translation.virtualFamily.members.bottomBar }))(React.memo(props =>
    <div className="virtual_family-members-selection_footer">
        <div className="virtual_family-members-selection_footer-buttons">
            <button className="virtual_family-members-selection_footer-close_button" onClick={props.onCloseButtonClick}>
                <span className="virtual_family-members-selection_footer-close_button-text">
                    {props.count > 1 ? props.translation.membersSelected(props.count) : props.translation.memberSelected(props.count)}
                </span>
                <img className="virtual_family-members-selection_footer-close_button-img" src={IconModalCross16px} alt={props.translation.closeButton} />
            </button>
            <button className="virtual_family-members-selection_footer-button"
                onClick={() => props.onModalOpen(<DeactiveMembers {...{
                    onModalClose: props.onModalClose,
                    personalCodes: props.personalCodes,
                    familyId: props.familyId,
                    virtualFamilyRequest: props.virtualFamilyRequest
                }} />)}>
                <img className="virtual_family-members-selection_footer-button-img" src={IconCrossCircleYellow16px} alt="" />
                <span className="virtual_family-members-selection_footer-button-text">{props.translation.deactivateMember}</span>
            </button>
        </div>
    </div>
));
MembersSelectionBottomBar.propTypes = {
    count: PropTypes.any,
    personalCodes: PropTypes.array,
    onCloseButtonClick: PropTypes.func,
    familyId: PropTypes.any,
    virtualFamilyRequest: PropTypes.func
};

const MembersTableDataRow = React.memo(props => {
    const left = parseInt(props.statusInt) === 0;
    return (<tr className={`table-body-row members ${left ? "left" : ""}`} onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut}>
        <TableDataCell {...{
            className: "right members",
            content: <BlackCheckbox {...{ className: "members_table-data_row-checkbox", checked: props.checked, onChange: (e) => props.onCheckedChanged(e.target.checked) }} />
        }} />
        <TableDataCell {...{ className: "left small-table-first-col members", content: <span className="table-text-14px-bold">{props.name}</span> }} />
        <TableDataCell {...{
            className: "left members",
            content: <span className="table-text-14px-regular">{props.personalCode}</span>
        }} />
        <TableDataCell {...{
            className: "left members",
            content: <span className="table-text-14px-regular">{props.email}</span>
        }} />
        <TableDataCell {...{
            className: "left members",
            content: <span className="table-text-14px-regular">{props.status}</span>
        }} />
    </tr>);
});
MembersTableDataRow.propTypes = {
    checked: PropTypes.bool,
    onCheckedChanged: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    name: PropTypes.string,
    personalCode: PropTypes.string,
    email: PropTypes.string,
    status: PropTypes.string,
    statusInt: PropTypes.any
};

const MembersTableHeaderRow = connect(state => ({ translation: state.translation.virtualFamily.members.table }), null)(React.memo(props =>
    <tr className="table-header-row members">
        <TableHeaderCell {...{ className: "small left members members_table-header_row-checkbox", text: "" }} />
        <TableHeaderCell {...{
            className: "left small-table-first-col members members_table-header_row-name",
            text: props.translation.nameColumn,
            orderWhat: "name",
            selectedOrderWhat: props.selectedOrderWhat,
            selectedOrderHow: props.selectedOrderHow,
            selectedOrderSearch: props.filters.name,
            onTableColumnFilterSelected: (selectedOrderHow, selectedOrderWhat, selectedOrderSearch) => props.onTableColumnFilterSelected(selectedOrderHow, selectedOrderWhat, "name", selectedOrderSearch),
            selectedSearchOverride: true
        }} />
        <TableHeaderCell {...{
            className: "left members members_table-header_row-personalCode",
            text: props.translation.personalCodeColumn,
            orderWhat: "personalCode",
            selectedOrderWhat: props.selectedOrderWhat,
            selectedOrderHow: props.selectedOrderHow,
            selectedOrderSearch: props.filters.personalCode,
            onTableColumnFilterSelected: (selectedOrderHow, selectedOrderWhat, selectedOrderSearch) => props.onTableColumnFilterSelected(selectedOrderHow, selectedOrderWhat, "personalCode", selectedOrderSearch),
            selectedSearchOverride: true
        }} />
        <TableHeaderCell {...{
            className: "left members members_table-header_row-email",
            text: props.translation.emailColumn,
            orderWhat: "email",
            selectedOrderWhat: props.selectedOrderWhat,
            selectedOrderHow: props.selectedOrderHow,
            selectedOrderSearch: props.filters.email,
            onTableColumnFilterSelected: (selectedOrderHow, selectedOrderWhat, selectedOrderSearch) => props.onTableColumnFilterSelected(selectedOrderHow, selectedOrderWhat, "email", selectedOrderSearch),
            selectedSearchOverride: true
        }} />
        <TableHeaderCell {...{
            className: "left members members_table-header_row-status",
            text: props.translation.statusColumn,
            orderWhat: "status",
            selectedOrderWhat: props.selectedOrderWhat,
            selectedOrderHow: props.selectedOrderHow,
            selectedOrderSearch: props.filters.status,
            onTableColumnFilterSelected: (selectedOrderHow, selectedOrderWhat, selectedOrderSearch) => props.onTableColumnFilterSelected(selectedOrderHow, selectedOrderWhat, "status", selectedOrderSearch),
            selectedSearchOverride: true
        }} />
    </tr>
));
MembersTableHeaderRow.propTypes = {
    selectedOrderWhat: PropTypes.any,
    selectedOrderHow: PropTypes.any,
    filters: PropTypes.any,
    onTableColumnFilterSelected: PropTypes.func
};

//table-wrapper scrollable table
export const MembersTable = React.memo(props =>
    <div className="table-wrapper virtual_family-members-table">
        <table className="table">
            <thead>
                <MembersTableHeaderRow {...{
                    selectedOrderHow: props.selectedOrderHow,
                    selectedOrderWhat: props.selectedOrderWhat,
                    filters: props.filters,
                    onTableColumnFilterSelected: props.onTableColumnFilterSelected
                }} />
            </thead>
            <tbody>
                {props.members && props.members.map((item, index) => <MembersTableDataRow {...{
                    ...item,
                    key: index,
                    onMouseOver: (e) => props.onMouseOverElement(index),
                    onMouseOut: (e) => props.onMouseOutElement()
                }} />)}
            </tbody>
        </table>
    </div>
);
MembersTable.propTypes = {
    members: PropTypes.array,
    filters: PropTypes.any,
    onMouseOverElement: PropTypes.func,
    onMouseOutElement: PropTypes.func,
    selectedOrderHow: PropTypes.any,
    selectedOrderWhat: PropTypes.any,
    onTableColumnFilterSelected: PropTypes.func
};

const Item = React.memo(props =>
    <div className="family_info_header-item">
        <img className="family_info_header-item-icon" src={props.icon} alt="" />
        <span className="family_info_header-item-text">{props.title}</span>
        <span className="family_info_header-item-value">{props.value}</span>
    </div>
);
Item.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.any
};

export const FamilyInfoHeader = React.memo(props =>
    <div className="family_info_header">
        {props.items && props.items.map((item, index) => <Item {...{ ...item, key: index }} />)}
    </div>
);
FamilyInfoHeader.propTypes = {
    items: PropTypes.array
};

class Members extends React.PureComponent {
    render() {
        const isHeaderVsibile = this.props.infoBoxes && this.props.infoBoxes.length > 0;
        const hasMembers = this.props.members && this.props.members.length > 0;
        const hasActiveSearchFilters = this.props.filtersCopy.name !== "" || this.props.filtersCopy.personalCode !== "" || this.props.filtersCopy.email !== "" || this.props.filtersCopy.status !== "";
        const isTableVisible = hasMembers || hasActiveSearchFilters;
        const isNoContentVisible = !isTableVisible && !this.props.isLoaderVisible;
        const selectedMembers = hasMembers && this.props.members.filter(x => x.checked);
        const selectedMembersPersonalCodes = selectedMembers && selectedMembers.map(x => x.personalCode);
        const selectedMemberCount = selectedMembers && selectedMembers.length;
        const isSelectionBottomBarVisible = selectedMemberCount > 0;
        return (
            <div className="virtual_family-members">
                <TransitionGroup component={null}>
                    {this.props.isLoaderVisible &&
                        <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <Loader className={"virtual_family-members-loader"} />
                        </CSSTransition>}
                    {isHeaderVsibile &&
                        <CSSTransition key={"header-bg-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <div className="virtual_family-members-info_header-background"></div>
                        </CSSTransition>}
                    {isHeaderVsibile &&
                        <CSSTransition key={"header-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <FamilyInfoHeader {...{ items: this.props.infoBoxes }} />
                        </CSSTransition>}
                    {isTableVisible &&
                        <CSSTransition key={"table-bg-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <BackgroundTable {...{
                                className: "virtual_family-members-background_table",
                                items: this.props.members,
                                hovered: this.props.hoveredIndex,
                                stickyHeaderClass: "hidden"
                            }} />
                        </CSSTransition>}
                    {isTableVisible &&
                        <CSSTransition key={"table-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <MembersTable {...{
                                members: this.props.members,
                                filters: this.props.filters,
                                selectedOrderWhat: this.props.selectedOrderWhat,
                                selectedOrderHow: this.props.selectedOrderHow,
                                onMouseOverElement: this.props.onMouseOverTableRow,
                                onMouseOutElement: this.props.onMouseOutTableRow,
                                onTableColumnFilterSelected: this.props.onTableColumnFilterSelected,
                            }} />
                        </CSSTransition>}
                    {isTableVisible &&
                        <CSSTransition key={"pagination"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <Pagination {...{
                                className: "virtual_family-members-pagination",
                                selectedPage: this.props.selectedPage,
                                pages: this.props.pages,
                                handlePageSelected: this.props.onPageSelected
                            }} />
                        </CSSTransition>}
                    {isNoContentVisible &&
                        <CSSTransition key={"no_content-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <NoContent {...{ className: "virtual_family-members-no_content", text: this.props.translation.noContent }} />
                        </CSSTransition>}
                    {isSelectionBottomBarVisible &&
                        <CSSTransition key={"selection_bottom_bar-slide"} timeout={{ enter: 250, exit: 250 }} classNames={"slide-bottom"}>
                            <MembersSelectionBottomBar {...{
                                count: selectedMemberCount,
                                personalCodes: selectedMembersPersonalCodes,
                                onCloseButtonClick: this.props.onMembersUnchecked,
                                onModalOpen: this.props.onModalOpen,
                                onModalClose: this.props.onModalClose,
                                familyId: this.props.familyId,
                                virtualFamilyRequest: this.props.virtualFamilyRequest
                            }} />
                        </CSSTransition>}
                </TransitionGroup>
            </div>
        );
    }
}
Members.propTypes = {
    familyId: PropTypes.any,
    infoBoxes: PropTypes.any,
    members: PropTypes.array,
    filters: PropTypes.any,
    filtersCopy: PropTypes.any,
    selectedOrderWhat: PropTypes.string,
    selectedOrderHow: PropTypes.string,
    onMembersUnchecked: PropTypes.func,
    onTableColumnFilterSelected: PropTypes.func,
    onPageSelected: PropTypes.func,
    selectedPage: PropTypes.any,
    pages: PropTypes.any,
    omModalOpen: PropTypes.func,
    onModalClose: PropTypes.func,
    isLoaderVisible: PropTypes.bool,
};

export default connect(
    state => ({ login: state.login, translation: state.translation.virtualFamily.members }),
    dispatch => ({ virtualFamilyRequest: (body, success, error) => businessVirtualFamilyRequest(dispatch, body, success, error) }))(withBackgroundTableHighlighting(Members));