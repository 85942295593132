import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { businessVirtualFamilyRequest } from '../../../../../Networking';
import { Modal, ModalInputText } from "../../manage/components/Modal";

export const FamilyModal = connect(state => ({ translation: state.translation.virtualFamily.families.familyModal, login: state.login }),
    dispatch => ({ virtualFamilyRequest: (body, success, error) => businessVirtualFamilyRequest(dispatch, body, success, error) })
)(class extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { isLoaderVisible: false, isDone: false, isError: false, result: null, familyName: props.familyName || "" };
    }

    onFamilyNameChanged = (e) => {
        this.setState({ familyName: e.target.value });
    }

    newFamily = (familyName) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        this.setState({ isLoaderVisible: true }, () => {
            this.props.virtualFamilyRequest({
                "requestType": "newFamily",
                "accNr": accnr,
                "token": this.props.login.token,
                "familyName": familyName
            }, data => this.setState({
                result: data.records.result,
                isDone: data.records.statusCode === 200,
                isError: data.records.statusCode !== 200,
                isLoaderVisible: false
            }, () => this.props.onFamilyModalRequestSuccess()),
                error => this.setState({ isLoaderVisible: false }));
        })
    }

    editFamily = (familyName, familyId) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        this.setState({ isLoaderVisible: true }, () => {
            this.props.virtualFamilyRequest({
                "requestType": "editFamily",
                "accNr": accnr,
                "token": this.props.login.token,
                "familyId": familyId,
                "familyName": familyName
            }, data => this.setState({
                result: data.records.result,
                isDone: data.records.statusCode === 200,
                isError: data.records.statusCode !== 200,
                isLoaderVisible: false
            }, () => this.props.onFamilyModalRequestSuccess()),
                error => this.setState({ isLoaderVisible: false }));
        })
    }

    render() {
        return (
            <Modal {...{
                title: this.props.editing ? this.props.translation.titleEdit : this.props.translation.titleNew,
                onCloseButtonClick: this.props.onModalClose,
                content: <ModalInputText {...{
                    text: this.props.translation.text,
                    value: this.state.familyName,
                    maxLength: 250,
                    onChange: this.onFamilyNameChanged
                }} />,
                isSaveButton: true,
                actionButtonTitle: this.props.editing ? this.props.translation.saveButton : this.props.translation.createButton,
                onActionButtonClick: () => this.props.editing ? this.editFamily(this.state.familyName, this.props.familyId) : this.newFamily(this.state.familyName),
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 64
            }} />
        )
    }
});
FamilyModal.propTypes = {
    editing: PropTypes.any,
    familyId: PropTypes.any,
    familyName: PropTypes.string,
    onFamilyModalRequestSuccess: PropTypes.func,
    onModalClose: PropTypes.func
};