import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withCloseOnEsc } from '../components/withCloseOnEsc';
import "./MobileFilters.scss";
import IconInvoiceDarker20px from '../assets/Icon_Invoice_Darker_20px.svg';
import IconChevronRightGray16px from '../assets/Icon_Chevron-Right_Gray-16px.svg';
import IconModalCrossBlack16px from '../assets/Icon_Modal-Cross_Black_16px.svg'

const TitleItem = React.memo(props =>
    <button className="mobile-filter-item" onClick={props.onClick}>
        <img className="mobile-filter-item_left-img" src={IconInvoiceDarker20px} alt="" />
        <span className="mobile-filter-item_text">{props.text}</span>
        <img className="mobile-filter-item_right-img" src={IconChevronRightGray16px} alt="expand filters" />
    </button>
);

const ListItem = React.memo(props =>
    <button className={`mobile-filter-list-item ${props.selected ? "selected" : ""}`}
        onClick={() => {
            props.onCloseButtonClick();
            props.onClick();
        }}>
        <span className="mobile-filter-list-item_text">{props.text}</span>
        {/* <img className="mobile-filter-list-item_right-img" src={IconChevronRightGray16px} alt="" /> */}
    </button>
);

class MobileFilterItem extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { isExpanded: false };
    }

    onExpandedToggle = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    render() {
        return (
            <div className="mobile-filter">
                <TitleItem {...{ leftImgVisible: true, text: this.props.text, onClick: this.onExpandedToggle }} />
                <div className="mobile-filter-list">
                    {this.state.isExpanded && this.props.items && this.props.items.map((item, index) => <ListItem {...{ ...item, key: index, selected: item.text === this.props.text, onCloseButtonClick: this.props.onCloseButtonClick }} />)}
                </div>
            </div>
        );
    }
}

const MobileFilters = React.memo(props =>
    <div className="mobile-filters">
        <h1 className="mobile-filters-title">{props.translation.title}</h1>
        <button className="mobile-filters-close-button" onClick={props.onCloseButtonClick}>
            <img className="mobile-filters-close-button-img" src={IconModalCrossBlack16px} alt="close filters modal" />
        </button>
        <div className="mobile-filters-items">
            {props.filters && props.filters.map((item, index) => <MobileFilterItem {...{ ...item, key: index, onCloseButtonClick: props.onCloseButtonClick }} />)}
        </div>
    </div>
);

export default withCloseOnEsc(connect(state => ({ translation: state.translation.filters }))(MobileFilters));