import React from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { connect } from 'react-redux';
import './TabLink.scss';
import IconChevronDownBlack16px from '../../assets/Icon_Chevron-Down_Black-16px.svg';

const TabLink = React.memo(props =>
    <NavLink
        activeClassName={"active"}
        className={`co-tab-link ${props.className ? props.className : ""}`}
        exact={props.exact}
        to={props.to}
        location={{ pathname: props.pathname }}
        smooth={true}
        scroll={(e) => window.scrollTo({ left: 0, top: e.offsetTop - 176, behavior: 'smooth' })}>
        <span className={`co-tab-link_text`}>{props.text}</span>
        <img className={"co-tab-link_img-mobile"} src={IconChevronDownBlack16px} alt={""} />
    </NavLink>
);

export default connect(state => ({ pathname: state.router.location.pathname + state.router.location.hash }), null)(TabLink)