import React from 'react';
import ContractItem from "../components/ContractItem";

export const ContractsTab = (props) => (
    <div className="electricity-body">
        <div className="electricity-body-content">
            {props.data.contracts.map((contract, index) => (
                <ContractItem {...{
                    ...contract,
                    key: index,
                    editContract: props.editContract,
                    endContractHandler: props.endContractHandler,
                    isMobileLayout: props.isMobileLayout
                }} />
            ))}
        </div>
    </div>
);
