import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Loader from '../../../components/Loader';
import ContentContainer from '../../../components/ContentContainer';
import './GameProgress.scss';
import Tippy from '@tippy.js/react';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format'

const SingleTicket = React.memo(props =>
    <div className="game-progress-item">
      <div className="ticket-header">
        <p>{props.ticketName}</p>
        <p>{ props.stampsText}</p>
      </div>
      <div className="ticket-stamps-list">
        {props.stamps.map((stamp, index) => <SingleStamp key={stamp.cheque || index} {...stamp} texts={props.texts} />)}
      </div>
      { !props.last && <hr /> }
    </div>
);

const SingleStamp = React.memo(props =>
  <Tippy
    isEnabled={!!props.cheque}
    content={
      <div className="stamp-tooltip">
        <p>{props.texts.textCheque}: {props.cheque}</p>
        <p>{props.texts.textChequeTime}: {props.chequeTime && format(parseISO(props.chequeTime), 'dd.MM.yyyy')}</p>
        <p>{props.texts.textBruto}: {props.bruto} EUR</p>
        <p>{props.texts.textStation}: {props.station}</p>
      </div>
    }
    arrow={true}
  >
    <div className="single-stamp">
        <img className="single-stamp-img" src={props.stampURL} alt="" />
    </div>
  </Tippy>
);

const GameProgress = React.memo(props => {
    if (!props.gameInfo) {
      return null;
    }
    return (<ContentContainer className="user-details" {...{
        title:
            <div className="user-details_title">{props.gameInfo.sectionName}</div>,
        content:
            <div className="game-progress-container">
                <TransitionGroup component={null}>
                    {props.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <div className="person-overview-cards_loader_container"><Loader {...{ className: "person-overview-cards_loader" }} /></div>
                    </CSSTransition>}
                    {!props.isLoaderVisible && <CSSTransition key={"content-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <div className="game-progress-scrollable">
                            <div className="game-progress-header-text">{ props.gameInfo.gameHeader }</div>
                            <div className="game-progress-instructions-text" dangerouslySetInnerHTML={{ __html: props.gameInfo.gameInstructions }}></div>
                            {props.gameInfo.tickets && props.gameInfo.tickets.map((item, index) =>
                                <SingleTicket
                                  key={item.ticketName}
                                  last={index === props.gameInfo.tickets.length - 1}
                                  {...item }
                                  texts={{
                                    textBruto: props.gameInfo.text_bruto,
                                    textCheque: props.gameInfo.text_cheque,
                                    textChequeTime: props.gameInfo.text_chequeTime,
                                    textStation: props.gameInfo.text_station
                                  }}
                                />)}
                        </div>
                    </CSSTransition>}
                </TransitionGroup>
            </div>
    }} />)
}

);

export default GameProgress;