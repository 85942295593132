import React from 'react';
import { connect } from 'react-redux';
import { privateUpdateUserDataRequest } from '../../../../Networking';
import SelectModal from '../../components/SelectModal';

class LanguageChange extends React.PureComponent {
    updateUserLanguage = (language) => {
        this.setState({ isLoaderVisible: true }, async () => {
            this.props.updateUserDataRequest(
                {
                    "userId": this.props.login.privateClientData.userId,
                    "token": this.props.login.token,
                    "action": "updateUserLanguage",
                    "language": language
                },
                data => {
                    if (data.records.statusCode === 200) {
                        window.webViewBridge.send('get_language');
                    }
                    this.setState({ isLoaderVisible: false });
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        });
    }

    render() {
        return (
            <SelectModal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onCloseButtonClick,
                items: [
                    {
                        text: this.props.translation.language["et"],
                        img: "https://olerex.ee/olxweb/images/EE.png",
                        onClick: () => {
                            this.updateUserLanguage("et");
                            this.props.onCloseButtonClick();
                        }
                    },
                    {
                        text: this.props.translation.language["en"],
                        img: "https://olerex.ee/olxweb/images/EN.png",
                        onClick: () => {
                            this.updateUserLanguage("en");
                            this.props.onCloseButtonClick();
                        }
                    },
                    {
                        text: this.props.translation.language["ru"],
                        img: "https://olerex.ee/olxweb/images/RU.png",
                        onClick: () => {
                            this.updateUserLanguage("ru");
                            this.props.onCloseButtonClick();
                        }
                    }
                ]
            }} />
        );
    }
}

export default connect(
    state => ({
        login: state.login,
        translation: state.translation.languageChange
    }),
    dispatch => ({ updateUserDataRequest: (body, success, error) => privateUpdateUserDataRequest(dispatch, body, success, error) }))(LanguageChange);