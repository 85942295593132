import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import OutlinedTextButton from '../../components/OutlinedTextButton';
import ContentContainer from '../../../components/ContentContainer';
import Loader from '../../../components/Loader';
import { Modal, ModalInputText, ModalText } from "./Modal";
import './Contacts.scss';
import IconContact38px from '../../../assets/Icon_Contact-38px.svg';
import IconRoleEraisik48px from '../../../assets/Icon_Role-Eraisik_48px.svg';
import IconPencil from '../../../assets/Icon_Pencil.svg';
import IconDelete from '../../../assets/Icon_Delete.svg';

const AddContact = connect(state => ({ translation: state.translation.manage.contacts.addContactModal }))(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            name: "", email: "", phone: "", isLoaderVisible: false,
            isDone: false, isError: false, result: null
        };
    }

    addContact = (name, email, phone) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        if (this.state.isLoaderVisible) {
            return;
        }
        this.setState({ isLoaderVisible: true }, async () => {
            // await new Promise(x => setTimeout(x, 500000));
            this.props.manageRequest(
                {
                    "action": "addContact",
                    "accNr": accnr,
                    "token": this.props.login.token,
                    "contactName": name,
                    "contactEmail": email,
                    "contactPhone": phone
                },
                data => {
                    const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                    if (!_accnr || _accnr !== accnr) {
                        return; //role changed
                    }
                    this.setState({
                        result: data.records.result,
                        isDone: data.records.statusCode === 200,
                        isError: data.records.statusCode !== 200,
                        isLoaderVisible: false
                    });
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        })
    }

    onFieldChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    render() {
        return (
            <Modal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onModalClose,
                content:
                    <>
                        <ModalInputText {...{
                            text: this.props.translation.nameField,
                            id: "name",
                            value: this.state.name,
                            onChange: this.onFieldChange
                        }} />
                        <ModalInputText {...{
                            text: this.props.translation.emailField,
                            id: "email",
                            value: this.state.email,
                            onChange: this.onFieldChange
                        }} />
                        <ModalInputText {...{
                            text: this.props.translation.phoneField,
                            id: "phone",
                            value: this.state.phone,
                            onChange: this.onFieldChange
                        }} />
                    </>,
                isSaveButton: true,
                onActionButtonClick: () => this.addContact(this.state.name, this.state.email, this.state.phone),
                actionButtonTitle: this.props.translation.saveButton,
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 256
            }} />
        )
    }
});

const EditContact = connect(state => ({ translation: state.translation.manage.contacts.editContactModal }))(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id, name: props.name, email: props.email, phone: props.phone,
            isLoaderVisible: false, isDone: false, isError: false, result: null
        };
    }

    editContact = (id, name, email, phone) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        if (this.state.isLoaderVisible) {
            return;
        }
        this.setState({ isLoaderVisible: true }, async () => {
            // await new Promise(x => setTimeout(x, 500000));
            this.props.manageRequest(
                {
                    "action": "editContact",
                    "accNr": accnr,
                    "token": this.props.login.token,
                    "contactId": id,
                    "contactName": name,
                    "contactEmail": email,
                    "contactPhone": phone
                },
                data => {
                    const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                    if (!_accnr || _accnr !== accnr) {
                        return; //role changed
                    }
                    this.setState({
                        result: data.records.result,
                        isDone: data.records.statusCode === 200,
                        isError: data.records.statusCode !== 200,
                        isLoaderVisible: false
                    });
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        })
    }

    onFieldChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    render() {
        return (
            <Modal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onModalClose,
                content:
                    <>
                        <ModalInputText {...{
                            text: this.props.translation.nameField,
                            id: "name",
                            value: this.state.name,
                            onChange: this.onFieldChange
                        }} />
                        <ModalInputText {...{
                            text: this.props.translation.emailField,
                            id: "email",
                            value: this.state.email,
                            onChange: this.onFieldChange
                        }} />
                        <ModalInputText {...{
                            text: this.props.translation.phoneField,
                            id: "phone",
                            value: this.state.phone,
                            onChange: this.onFieldChange
                        }} />
                    </>,
                isSaveButton: true,
                onActionButtonClick: () => this.editContact(this.state.id, this.state.name, this.state.email, this.state.phone),
                actionButtonTitle: this.props.translation.saveButton,
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 256
            }} />
        )
    }
});

const DeleteContact = connect(state => ({ translation: state.translation.manage.contacts.deleteContactModal }))(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { id: props.id, isLoaderVisible: false, isDone: false, isError: false, result: null };
    }

    deleteContact = (id) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        if (this.state.isLoaderVisible) {
            return;
        }
        this.setState({ isLoaderVisible: true }, async () => {
            // await new Promise(x => setTimeout(x, 500000));
            this.props.manageRequest(
                {
                    "action": "deleteContact",
                    "accNr": accnr,
                    "token": this.props.login.token,
                    "contactId": id
                },
                data => {
                    const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                    if (!_accnr || _accnr !== accnr) {
                        return; //role changed
                    }
                    this.setState({
                        result: data.records.result,
                        isDone: data.records.statusCode === 200,
                        isError: data.records.statusCode !== 200,
                        isLoaderVisible: false
                    });
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        })
    }

    render() {
        return (
            <Modal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onModalClose,
                content: <ModalText {...{ text: this.props.translation.content(this.state.name) }} />,
                isSaveButton: false,
                onActionButtonClick: () => this.deleteContact(this.state.id),
                actionButtonTitle: this.props.translation.deleteButton,
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 112,
            }} />
        )
    }
});

const Item = React.memo(props =>
    <div className="manage-contacts_collection-item-wrap">
        <div className="manage-contacts_collection-item">
            <button className="manage-contacts_collection-item-button edit" onClick={props.onEditContactButtonClick}>
                <img className="manage-contacts_collection-item-button_img" src={IconPencil} alt="edit user" /></button>
            <button className="manage-contacts_collection-item-button delete" onClick={props.onDeleteContactButtonClick}>
                <img className="manage-contacts_collection-item-button_img" src={IconDelete} alt="delete user" /></button>
            <img className="manage-contacts_collection-item_img" src={IconRoleEraisik48px} alt="" />
            <p className="manage-contacts_collection-item_text-name">{props.name}</p>
            <p className="manage-contacts_collection-item_text-contact">{props.email}<br />{props.phone}</p>
        </div>
    </div>
);

class Contacts extends PureComponent {
    render() {
        return (
            <div className="manage-contacts" id="contacts">
                <ContentContainer {...{
                    className: "manage-contacts-container",
                    title:
                        <p className="manage-address_label">
                            <img className="manage-address_label-img" src={IconContact38px} alt="" />
                            <span className="manage-address_label-text">{this.props.translation.title}</span>
                        </p>,
                    action: <OutlinedTextButton {...{
                        className: "manage-contacts_button-add",
                        text: this.props.translation.addButton,
                        onClick: () => this.props.onModalOpen(<AddContact {...this.props} />)
                    }} />,
                    content:
                        <div className="manage-contacts-content">
                            <TransitionGroup component={null}>
                                {this.props.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                    <Loader {...{ className: "manage-contacts-loader" }} />
                                </CSSTransition>}
                                {this.props.contacts && <CSSTransition key={"content-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                    <div className="manage-contacts_collection">
                                        <TransitionGroup component={null}>
                                            {this.props.contacts && this.props.contacts.map((contact, index) => <CSSTransition key={index} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                                <Item {...{
                                                    ...contact,
                                                    onEditContactButtonClick: () => this.props.onModalOpen(<EditContact {...{ ...this.props, ...contact }} />),
                                                    onDeleteContactButtonClick: () => this.props.onModalOpen(<DeleteContact {...{ ...this.props, ...contact }} />),
                                                }} />
                                            </CSSTransition>)}
                                        </TransitionGroup>
                                    </div>
                                </CSSTransition>}
                            </TransitionGroup>
                        </div>
                }} />
            </div>
        );
    }
}

export default connect(state => ({ translation: state.translation.manage.contacts }), null)(Contacts);