import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';

import BillingStats from '../../components/BillingStats';
import { withScrollingHeader } from '../../components/withScrollingHeader';
import TransactionsFilters from '../transactions/components/TransactionsFilters';
import IdCreditContent from './components/IdCreditContent';
import MobileFiltersButton from '../../components/MobileFiltersButton';
import Loader from '../../components/Loader';
import NoContent from '../../components/NoContent';
import PageHeaderTabs from '../../components/PageHeaderTabs';
import { buildClassList, roleOrLanguageChanged } from '../../../../Utils';
import { url, privateInvoicesRequest, businessBanklinksRequest } from '../../../../Networking';
import BankLinks from '../../modal/banklinks/BankLinks';
import {dateToString} from "../../helpers";
import styles from './IdCredit.module.scss';
import IconInvoiceDarker20px from './assets/Icon_Invoice_Darker_20px.svg';
import IconListDarker20px from './assets/Icon_List_Darker_20px.svg';
import IconTimeDarker20px from './assets/Icon_Time_Darker_20px.svg';
import {FilterTypeEnum} from '../transactions/helpers/FilterTypeEnum';

class IdCredit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            periods: null,
            selectedPeriodId: null,
            invoiceTypes: null,
            selectedInvoiceTypeId: null,
            invoiceStatuses: null,
            selectedInvoiceStatusId: null,
            joined: null,
            isLoaderVisible: false,
            dateRange: {
                startDate: null,
                endDate: null,
                isVisible: false,
                selectsStart: false,
                selectionStart: true,
                selectedPeriodId: "1",
                text: ''
            }
        };
    }

    handlePeriodSelected = (selectedPeriodId) => {
        this.setState({ selectedPeriodId: selectedPeriodId }, () => this.downloadData());
    };

    handleInvoiceTypeSelected = (selectedInvoiceTypeId) => {
        this.setState({ selectedInvoiceTypeId: selectedInvoiceTypeId }, () => this.downloadData());
    };

    handleInvoiceStatusSelected = (selectedInvoiceStatusId) => {
        this.setState({ selectedInvoiceStatusId: selectedInvoiceStatusId }, () => this.downloadData());
    };

    downloadPDf = (invoiceNumber, isLink, pdfUrl) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        if (isLink && pdfUrl) {
            window.open(pdfUrl);
        } else {
            window.open(`${url}&act=olerexweb.business.invoices&requestType=invoicePdf&token=${this.props.login.token}&accNr=${accnr}&invoiceNo=${invoiceNumber}&pdf=1`);
        }
    };

    downloadData = () => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return;
        }
        const request = {
            "requestType": "getAll",
            "accNr": this.props.login.currentRole.accnr,
            "token": this.props.login.token,
            "invoiceTypeId": this.state.selectedInvoiceTypeId,
            "invoiceStatusId": this.state.selectedInvoiceStatusId
        };
        if (this.state.selectedPeriodId || !this.state.dateRange.endDate) {
            request.periodId = this.state.selectedPeriodId;
        } else {
            request.fromPeriod = dateToString(this.state.dateRange.startDate);
            request.toPeriod = dateToString(this.state.dateRange.endDate);
        }

        this.setState({ isLoaderVisible: true }, async () => {
            // await new Promise(x => setTimeout(x, 50000));
            this.props.invoicesRequest(request,
                data => {
                    const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                    if (_accnr !== accnr) {
                        return;
                    }
                    const response = data.records && data.records.response;
                    const invoices = response.invoices && response.invoices[0] !== "Arved puuduvad." ? response.invoices : {};
                    const incomings = response.incomings && response.incomings[0] !== "Laekumised puuduvad." ? response.incomings : {};
                    const joinedKeys = Array.from(new Set(Object.keys(invoices).concat(...Object.keys(incomings)))); //filter duplicates with set
                    let joined = joinedKeys.map(x => ({ text: x, invoices: invoices[x], incomings: incomings[x] }));
                    joined = joined.map(x => ({ ...x, invoices: { ...x.invoices, rows: x.invoices ? x.invoices.rows.map(y => ({ ...y, onDownloadButtonClick: () => this.downloadPDf(y.number, y.isLink, y.pdfUrl) })) : null } }));
                    this.setState(prevState => ({
                        joined: joined,
                        periods: Object.entries(data.records.response.periods).map(x => { return {
                            text: x[1],
                            value: x[0],
                            from: new Date(data.records.response.periodsFromTo[x[0]].from + 'T00:00:00'),
                            to: new Date(data.records.response.periodsFromTo[x[0]].to + 'T00:00:00'),
                            onClick: () => this.handlePeriodSelected(x[0])
                        } }),
                        selectedPeriodId: String(data.records.response.selectedPeriodId),
                        invoiceTypes: Object.entries(data.records.response.invoiceTypes).map(x => { return { text: x[1], value: x[0], onClick: () => this.handleInvoiceTypeSelected(x[0]) } }),
                        selectedInvoiceTypeId: String(data.records.response.selectedInvoiceTypeId),
                        invoiceStatuses: Object.entries(data.records.response.invoiceStatuses).map(x => { return { text: x[1], value: x[0], onClick: () => this.handleInvoiceStatusSelected(x[0]) } }),
                        selectedInvoiceStatusId: String(data.records.response.selectedInvoiceStatusId),
                        unpaidTotal: data.records.response.unpaidTotal,
                        overDeadlineTotal: data.records.response.overDeadlineTotal,
                        remainingLimit: data.records.response.remainingLimit,
                        referenceNumber: data.records.response.referenceNumber,
                        isLoaderVisible: false,
                        dateRange: {
                            startDate: String(data.records.response.selectedPeriodId) ? new Date(data.records.response.periodsFromTo[String(data.records.response.selectedPeriodId)].from + 'T00:00:00') : null,
                            endDate: String(data.records.response.selectedPeriodId) ? new Date(data.records.response.periodsFromTo[String(data.records.response.selectedPeriodId)].to + 'T00:00:00') : null,
                            isVisible: prevState.dateRange.isVisible,
                            selectsStart: false,
                            selectionStart: true,
                            selectedPeriodId: String(data.records.response.selectedPeriodId) || null,
                        }
                    }));
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        });
    };

    openBankLink = (invoiceId) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in id-credit private role
        }
        this.props.banklinksRequest(
            {
                "accNr": this.props.login.currentRole.accnr,
                "token": this.props.login.token,
                "invoiceId": invoiceId,
            },
            data => {
                const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                if (!_accnr || _accnr !== accnr) {
                    return; //role changed
                }
                this.props.onModalOpen(<BankLinks {...{ items: data.records.response.banklink, onCloseButtonClick: this.props.onModalClose }} />);
            },
            error => {
            }
        )
    };

    componentDidMount() {
        this.downloadData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.isMobileLayout && this.props.isMobileLayout) {
            if (this.state.lastScrollEvent) { //recalculate scroll hiding header
                this.onScrollListener(this.state.lastScrollEvent);
            }
        }
        if (roleOrLanguageChanged(prevProps, this.props)) {
            this.downloadData();
        }
    };

    currentFilterTitle = (filters, filterValue) => {
        if (filters) {
            const filter = filters.find(x => String(x.value) === String(filterValue));
            if (filter) {
                return filter.text;
            }
        }
        return "";
    };

    get invoiceTypeFilterTitle() {
        return this.currentFilterTitle(this.state.invoiceTypes, this.state.selectedInvoiceTypeId);
    }

    get invoiceStatusFilterTitle() {
        return this.currentFilterTitle(this.state.invoiceStatuses, this.state.selectedInvoiceStatusId);
    }

    get periodFilterTitle() {
        return this.currentFilterTitle(this.state.periods, this.state.selectedPeriodId);
    }

    handleDateChange = (dateRange) => {
        this.setState({
            ...this.state,
            dateRange: dateRange,
            selectedPeriodId: dateRange.selectedPeriodId
        }, () => {
            if (dateRange.selectedPeriodId || dateRange.endDate) {
                this.downloadData()
            }
        });
    };

    render() {
        const headerClass = buildClassList({
            [styles.fixed]: this.props.isHeaderFixed,
            [styles.hiding]: this.props.isHeaderHiding,
            [styles.animated]: this.props.isHeaderAnimated,
        });

        return (
            <div className={styles.idCredit}>
                <div className={styles.headerPlaceholder}></div>
                <div className={`${styles.headerStatic} ${headerClass}`}>
                    <div className={styles.background}></div>
                    <div className={styles.header}>
                        <h1 className={styles.title}>{this.props.translation.title}</h1>
                        <div className={styles.filters}>
                            <TransactionsFilters {...{
                                items: [
                                    {
                                        leftImg: IconInvoiceDarker20px,
                                        text: this.invoiceTypeFilterTitle,
                                        items: this.state.invoiceTypes,
                                        type: FilterTypeEnum.dropdown
                                    },
                                    {
                                        leftImg: IconListDarker20px,
                                        text: this.invoiceStatusFilterTitle,
                                        items: this.state.invoiceStatuses,
                                        type: FilterTypeEnum.dropdown
                                    },
                                    {
                                        leftImg: IconTimeDarker20px,
                                        text: this.periodFilterTitle,
                                        items: this.state.periods,
                                        type: FilterTypeEnum.calendar,
                                        onChange: this.handleDateChange,
                                        dateRange: this.state.dateRange
                                    },
                                ],
                                alignToStart: true
                            }} />
                        </div>
                        <PageHeaderTabs {...{
                            className: styles.tabs,
                            pathname: this.props.pathname,
                            links: [
                                { text: this.props.translation.incomingsTab, to: '/idcredit/incomings', exact: true },
                                { text: this.props.translation.invoicesTab, to: '/idcredit', exact: true },
                            ],
                        }} />
                    </div>
                    <MobileFiltersButton {...{
                        className: styles.mobileFilters,
                        onClick: () => this.props.onMobileFiltersOpen([{
                            text: this.invoiceTypeFilterTitle,
                            items: this.state.invoiceTypes
                        }, {
                            text: this.invoiceStatusFilterTitle,
                            items: this.state.invoiceStatuses
                        }, {
                            text: this.periodFilterTitle,
                            items: this.state.periods
                        }])
                    }} />
                    <div className={styles.stats}>
                        <TransitionGroup component={null}>
                            {(this.state.unpaidTotal || this.state.overDeadlineTotal || this.state.remainingLimit || this.state.referenceNumber) &&
                                <CSSTransition key={'stats'} timeout={{ enter: 250, exit: 250 }} classNames={'fast-fade'}>
                                    <BillingStats {...{
                                        unpaidTotal: this.state.unpaidTotal,
                                        overDeadlineTotal: this.state.overDeadlineTotal,
                                        remainingLimit: this.state.remainingLimit,
                                        referenceNumber: this.state.referenceNumber
                                    }} />
                                </CSSTransition>}
                        </TransitionGroup>
                    </div>
                </div>
                <div className={styles.mobileStats}>
                    <div className={styles.inner}>
                        <TransitionGroup component={null}>
                            {(this.state.unpaidTotal || this.state.overDeadlineTotal || this.state.remainingLimit || this.state.referenceNumber) && (
                                <CSSTransition key={'stats'} timeout={{ enter: 250, exit: 250 }} classNames={'fast-fade'}>
                                    <BillingStats {...{
                                        unpaidTotal: this.state.unpaidTotal,
                                        overDeadlineTotal: this.state.overDeadlineTotal,
                                        remainingLimit: this.state.remainingLimit,
                                        referenceNumber: this.state.referenceNumber
                                    }} />
                                </CSSTransition>
                            )}
                        </TransitionGroup>
                    </div>
                </div>
                <TransitionGroup component={null}>
                    {this.state.isLoaderVisible && <CSSTransition key={'loader-fade'} timeout={{ enter: 250, exit: 250 }} classNames={'fast-fade'}>
                        <Loader className={styles.loader} />
                    </CSSTransition>}
                    {this.state.joined !== null &&
                        <CSSTransition key={'content'} timeout={{ enter: 250, exit: 250 }} classNames={'fast-fade'}>
                            <div className={styles.body}>
                                {this.state.joined.length <= 0 ?
                                    <NoContent {...{ text: this.props.translation.noContent, className: styles.noContent }} /> :
                                    this.state.joined.map((item, index) => <IdCreditContent {...{
                                        key: index,
                                        text: item.text,
                                        invoices: item.invoices,
                                        incomings: item.incomings,
                                        payButtonClick: this.openBankLink
                                    }} />)}
                            </div>
                        </CSSTransition>}
                </TransitionGroup>
            </div>
        );
    }
}

export default connect(
    state => ({
        login: state.login,
        isMobileLayout: state.size.isMobileLayout,
        translation: state.translation.idCredit,
        pathname: state.router.location.pathname,
    }),
    dispatch => ({
        invoicesRequest: (body, success, error) => privateInvoicesRequest(dispatch, body, success, error),
        banklinksRequest: (body, success, error) => businessBanklinksRequest(dispatch, body, success, error)
    }))(
        withScrollingHeader(IdCredit, 0, 235, 80, 52));
