import React from 'react';
import "./NoContent.scss";
import IllustrationBlankstate from '../assets/Illustration_Blankstate.svg';
import LinesLeft from '../assets/Lines - Left.svg';
import LinesRight from '../assets/Lines - Right.svg';

const NoContent = React.memo(props =>
    <div className={`co-no-content ${props.className ? props.className : ""}`}>
        <p className="co-no-content_text">
            <img className="co-no-content_text_img left" src={LinesLeft} alt="" />
            <span className="co-no-content_text-middle">{props.text}</span>
            <img className="co-no-content_text_img right" src={LinesRight} alt="" />
        </p>
        <img className="co-no-content_bottom-img" src={IllustrationBlankstate} alt="" />
    </div>
);

export default NoContent;