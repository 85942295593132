import React, { PureComponent } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import { withScrollingHeader } from '../../../components/withScrollingHeader';
import TransactionsContent from '../../../person/transactions/components/TransactionsContent';
import TransactionsTable from '../../../person/transactions/components/TransactionsTable';
import { BackgroundTable, withBackgroundTableHighlighting } from '../../../components/Table';
import TransactionsFilters from '../../../person/transactions/components/TransactionsFilters';
import TransactionsSwitches from '../../../person/transactions/components/TransactionsSwitches';
import ImageButton24px from '../../components/ImageButton24px';
import MobileFiltersButton from '../../../components/MobileFiltersButton';
import ToolbarSeparator from '../../../components/ToolbarSeparator';
import Loader from '../../../components/Loader';
import NoContent from '../../../components/NoContent';
import Pagination from '../../../components/Pagination';
import { roleOrLanguageChanged } from '../../../../../Utils';
import { url, businessTransactionsRequest } from '../../../../../Networking';
import './Transactions.scss';
import IconDownload from '../../../assets/Icon_Download.svg';
import IconCartDarker20px from '../../../assets/Icon_Cart_Darker_20px.svg';
import IconFuelDarker20px from '../../../assets/Icon_Fuel_Darker_20px.svg';
import IconTimeDarker20px from '../../../assets/Icon_Time_Darker_20px.svg';
import {FilterTypeEnum} from "../../../person/transactions/helpers/FilterTypeEnum";
import {dateToString} from "../../../helpers";

class Transactions extends PureComponent {
    constructor(props) {
        super(props);
        this.displayTypeEnumeration = {
            list: 0,
            table: 1
        };
        this.state = {
            selectedDisplayType: this.displayTypeEnumeration.table,
            isLoaderVisible: false,
            detailedPurchaseList: {
                isDownloadingData: false,
                periods: null,
                productTypes: null,
                pages: null,
                itemsPerPageOptions: null,
                stations: null,
                selectedPeriodId: null,
                selectedStationId: null,
                selectedProductTypeId: null,
                selectedPage: null,
                selectedItemsPerPage: 20,
                invoices: null,
                selectedOrderHow: null,
                selectedOrderWhat: null,
                selectedOrderSearch: null
            },
            purchaseList: {
                isDownloadingData: false,
                periods: null,
                productTypes: null,
                pages: null,
                itemsPerPageOptions: null,
                stations: null,
                selectedPeriodId: null,
                selectedStationId: null,
                selectedProductTypeId: null,
                selectedPage: null,
                selectedItemsPerPage: 20,
                invoices: null
            },
            dateRange: {
                startDate: null,
                endDate: null,
                isVisible: false,
                selectsStart: false,
                selectionStart: true,
                selectedPeriodId: "1",
                text: ''
            }
        }
    }

    handlePurchaseListPeriodSelected = (selectedPeriodId) => {
        this.setState({
            purchaseList: {
                ...this.state.purchaseList,
                selectedPeriodId: selectedPeriodId,
                selectedPage: 1
            },
            dateRange: {
                ...this.state.dateRange,
                selectedPeriodId: selectedPeriodId
            }
        }, () => this.downloadData());
    };

    handleDetailedPurchaseListPeriodSelected = (selectedPeriodId) => {
        this.setState({
            detailedPurchaseList: {
                ...this.state.detailedPurchaseList,
                selectedPeriodId: selectedPeriodId,
                selectedPage: 1
            },
            dateRange: {
                ...this.state.dateRange,
                selectedPeriodId: selectedPeriodId
            }
        }, () => this.downloadData());    };

    handlePurchaseListProductTypeSelected = (selectedProductTypeId) => {
        this.setState({ purchaseList: { ...this.state.purchaseList, selectedProductTypeId: selectedProductTypeId, selectedPage: 1 } }, () => this.downloadData());
    };

    handleDetailedPurchaseListProductTypeSelected = (selectedProductTypeId) => {
        this.setState({ detailedPurchaseList: { ...this.state.detailedPurchaseList, selectedProductTypeId: selectedProductTypeId, selectedPage: 1 } }, () => this.downloadData());
    };

    handlePurchaseListStationSelected = (selectedStationId) => {
        this.setState({ purchaseList: { ...this.state.purchaseList, selectedStationId: selectedStationId, selectedPage: 1 } }, () => this.downloadData());
    };

    handleDetailedPurchaseListStationSelected = (selectedStationId) => {
        this.setState({ detailedPurchaseList: { ...this.state.detailedPurchaseList, selectedStationId: selectedStationId, selectedPage: 1 } }, () => this.downloadData());
    };

    onTableColumnFilterSelected = (selectedOrderHow, selectedOrderWhat, selectedOrderSearch) => {
        this.setState({ detailedPurchaseList: { ...this.state.detailedPurchaseList, selectedOrderHow, selectedOrderWhat, selectedOrderSearch } },
            async () => {
                await new Promise(resolve => setTimeout(resolve, 900));
                if (this.state.detailedPurchaseList.selectedOrderHow === selectedOrderHow && this.state.detailedPurchaseList.selectedOrderWhat === selectedOrderWhat && this.state.detailedPurchaseList.selectedOrderSearch === selectedOrderSearch) {
                    this.downloadData();
                }
            }
        );
    };

    displayTypeSelected = (displayType) => {
        this.setState({ selectedDisplayType: displayType }, () => this.downloadData());
    };

    get isListDisplayTypeSelected() {
        return this.state.selectedDisplayType === this.displayTypeEnumeration.list;
    }

    get isTableDisplayTypeSelected() {
        return this.state.selectedDisplayType === this.displayTypeEnumeration.table;
    }

    downloadExcel = () => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        const params = this.isListDisplayTypeSelected ? this.state.purchaseList : this.state.detailedPurchaseList;
        let periodQuery;
        if (params.selectedPeriodId || !this.state.dateRange.endDate) {
            periodQuery = '&periodId=' + params.selectedPeriodId;
        } else {
            periodQuery = '&fromPeriod=' + dateToString(this.state.dateRange.startDate) + '&toPeriod=' + dateToString(this.state.dateRange.endDate);
        }
        const type = "getDetailedPurchaseList";
        window.open(`${url}&act=olerexweb.business.transactions&requestType=${type}&token=${this.props.login.token}&accNr=${accnr}${periodQuery}&stationId=${params.selectedStationId}&page=${params.selectedPage}&itemsPerPage=${params.selectedItemsPerPage}&orderHow=${params.selectedOrderHow}&orderWhat=${params.selectedOrderWhat}&orderSearch=${params.selectedOrderSearch}&excel=1`);
    };

    downloadPdf = (invoiceNumber, isLink, pdfUrl) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        if (isLink && pdfUrl) {
            window.open(pdfUrl);
        } else {
            window.open(`${url}&act=olerexweb.business.transactions&requestType=transactionPdf&token=${this.props.login.token}&accNr=${accnr}&invoiceNo=${invoiceNumber}&pdf=1`);
        }        
    };

    downloadData = (ignoreState) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        if (this.isListDisplayTypeSelected) {
            if (this.state.purchaseList.isDownloadingData) {
                return;
            }

            const request = {
                "requestType": "getPurchaseList",
                "token": this.props.login.token,
                "accNr": accnr,
                "stationId": this.state.purchaseList.selectedStationId,
                "productTypeId": this.state.purchaseList.selectedProductTypeId,
                "page": this.state.purchaseList.selectedPage,
                "itemsPerPage": this.state.purchaseList.selectedItemsPerPage
            };
            if (this.state.purchaseList.selectedPeriodId || !this.state.dateRange.endDate) {
                request.periodId = this.state.purchaseList.selectedPeriodId;
            } else {
                request.fromPeriod = dateToString(this.state.dateRange.startDate);
                request.toPeriod = dateToString(this.state.dateRange.endDate);
            }
            this.setState({ purchaseList: { ...this.state.purchaseList, isDownloadingData: true } }, () => {
                this.props.transactionsRequest(request,
                    data => {
                        const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                        if (!_accnr || _accnr !== accnr) {
                            return; //role changed
                        }
                        let invoices = data.records.invoices && typeof data.records.invoices[0] !== "string" ?
                            [].concat(...Object.keys(data.records.invoices).map(x =>
                                Object.keys(data.records.invoices[x]).map(y => {
                                    return { title: `${y} ${x}`, items: data.records.invoices[x][y] }
                                }))) : []; //flatten with concat
                        invoices = invoices ? invoices.map(x => ({ ...x, items: x.items ? x.items.map(y => ({ ...y, onDownloadButtonClick: () => this.downloadPdf(y.invoiceId, y.isLink, y.pdfUrl) })) : null })) : null;
                        this.setState(prevState => ({
                            purchaseList: {
                                ...this.state.purchaseList,
                                isDownloadingData: false,
                                periods: Object.entries(data.records.periods).map(x =>
                                    ({
                                        text: x[1],
                                        value: x[0],
                                        from: new Date(data.records.periodsFromTo[x[0]].from + 'T00:00:00'),
                                        to: new Date(data.records.periodsFromTo[x[0]].to + 'T00:00:00'),
                                        onClick: () => this.handlePurchaseListPeriodSelected(x[0])
                                    })),
                                productTypes: Object.entries(data.records.productTypes).map(x =>
                                    ({ text: x[1], value: x[0], onClick: () => this.handlePurchaseListProductTypeSelected(x[0]) })),
                                pages: data.records.pages,
                                itemsPerPageOptions: data.records.itemsPerPageOptions,
                                stations: data.records.stations.map(x =>
                                    ({ text: x.value, value: String(x.key), onClick: () => this.handlePurchaseListStationSelected(x.key) })),
                                selectedPeriodId: this.state.purchaseList.selectedPeriodId || (ignoreState && String(data.records.selectedPeriodId)),
                                selectedStationId: String(data.records.selectedStationId),
                                selectedProductTypeId: String(data.records.selectedProductTypeId),
                                selectedPage: data.records.selectedPage,
                                selectedItemsPerPage: data.records.selectedItemsPerPage,
                                selectedOrderHow: data.records.selectedOrderHow,
                                selectedOrderWhat: data.records.selectedOrderWhat,
                                invoices: invoices
                            },
                            dateRange: {
                                startDate: this.state.dateRange.startDate || (String(data.records.selectedPeriodId) ? new Date(data.records.periodsFromTo[String(data.records.selectedPeriodId)].from + 'T00:00:00') : null),
                                endDate: this.state.dateRange.endDate || (String(data.records.selectedPeriodId) ? new Date(data.records.periodsFromTo[String(data.records.selectedPeriodId)].to + 'T00:00:00') : null),
                                isVisible: prevState.dateRange.isVisible,
                                selectsStart: false,
                                selectionStart: true,
                                selectedPeriodId: this.state.purchaseList.selectedPeriodId || (ignoreState && String(data.records.selectedPeriodId)) || null,
                            }
                        }));
                    }, error => {
                        const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                        if (!_accnr || _accnr !== accnr) {
                            return; //role changed
                        }
                        this.setState({
                            purchaseList: {
                                ...this.state.purchaseList,
                                isDownloadingData: false
                            }
                        });
                    });
            });
        } else {
            if (this.state.detailedPurchaseList.isDownloadingData) {
                return; //already downloading
            }
            const request = {
                "requestType": "getDetailedPurchaseList",
                "token": this.props.login.token,
                "accNr": accnr,
                "stationId": this.state.detailedPurchaseList.selectedStationId,
                "productTypeId": this.state.detailedPurchaseList.selectedProductTypeId,
                "page": this.state.detailedPurchaseList.selectedPage,
                "itemsPerPage": this.state.detailedPurchaseList.selectedItemsPerPage,
                "orderHow": this.state.detailedPurchaseList.selectedOrderHow,
                "orderWhat": this.state.detailedPurchaseList.selectedOrderWhat,
                "orderSearch": this.state.detailedPurchaseList.selectedOrderSearch
            };
            if (this.state.detailedPurchaseList.selectedPeriodId || !this.state.dateRange.endDate) {
                request.periodId = this.state.detailedPurchaseList.selectedPeriodId;
            } else {
                request.fromPeriod = dateToString(this.state.dateRange.startDate);
                request.toPeriod = dateToString(this.state.dateRange.endDate);
            }
            this.setState({ detailedPurchaseList: { ...this.state.detailedPurchaseList, isDownloadingData: true } }, () => {
                this.props.transactionsRequest(request,
                    data => {
                        const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                        if (!_accnr || _accnr !== accnr) {
                            return; //role changed
                        }
                        this.setState(prevState => ({
                            detailedPurchaseList: {
                                ...this.state.detailedPurchaseList,
                                isDownloadingData: false,
                                periods: Object.entries(data.records.periods).map(x =>
                                    ({
                                        text: x[1],
                                        value: x[0],
                                        from: new Date(data.records.periodsFromTo[x[0]].from + 'T00:00:00'),
                                        to: new Date(data.records.periodsFromTo[x[0]].to + 'T00:00:00'),
                                        onClick: () => this.handleDetailedPurchaseListPeriodSelected(x[0])
                                    })),
                                productTypes: Object.entries(data.records.productTypes).map(x =>
                                    ({ text: x[1], value: x[0], onClick: () => this.handleDetailedPurchaseListProductTypeSelected(x[0]) })),
                                pages: data.records.pages,
                                itemsPerPageOptions: data.records.itemsPerPageOptions,
                                stations: data.records.stations.map(x =>
                                    ({ text: x.value, value: String(x.key), onClick: () => this.handleDetailedPurchaseListStationSelected(x.key) })),
                                selectedPeriodId: this.state.detailedPurchaseList.selectedPeriodId || (ignoreState && String(data.records.selectedPeriodId)),
                                selectedStationId: String(data.records.selectedStationId),
                                selectedProductTypeId: String(data.records.selectedProductTypeId),
                                selectedPage: data.records.selectedPage,
                                selectedItemsPerPage: data.records.selectedItemsPerPage,
                                selectedOrderHow: data.records.selectedOrderHow,
                                selectedOrderWhat: data.records.selectedOrderWhat,
                                selectedOrderSearch: data.records.selectedOrderSearch,
                                invoices: data.records.invoices && typeof data.records.invoices[0] !== "string" ? data.records.invoices : [],
                                selectedOrderSearchCopy: data.records.selectedOrderSearch
                            },
                            dateRange: {
                                startDate: this.state.dateRange.startDate || (String(data.records.selectedPeriodId) ? new Date(data.records.periodsFromTo[String(data.records.selectedPeriodId)].from + 'T00:00:00') : null),
                                endDate: this.state.dateRange.endDate || (String(data.records.selectedPeriodId) ? new Date(data.records.periodsFromTo[String(data.records.selectedPeriodId)].to + 'T00:00:00') : null),
                                isVisible: prevState.dateRange.isVisible,
                                selectsStart: false,
                                selectionStart: true,
                                selectedPeriodId: this.state.detailedPurchaseList.selectedPeriodId || (ignoreState && String(data.records.selectedPeriodId)) || null,
                            }
                        }));
                    });
            }, error => {
                const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                if (!_accnr || _accnr !== accnr) {
                    return; //role changed
                }
                this.setState({
                    detailedPurchaseList: {
                        ...this.state.detailedPurchaseList,
                        isDownloadingData: false
                    }
                });
            });
        }
    };


    componentDidMount() {
        if (this.props.isMobileLayout) {
            this.displayTypeSelected(this.displayTypeEnumeration.table);
        } else {
            this.downloadData(true);
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.isMobileLayout && this.props.isMobileLayout) {
            this.displayTypeSelected(this.displayTypeEnumeration.table);
        } else {
            if (roleOrLanguageChanged(prevProps, this.props)) {
                this.downloadData(true);
            }
        }
    };

    currentFilterTitle = (filters, filterValue) => {
        if (filters) {
            const filter = filters.find(x => String(x.value) === String(filterValue));
            if (filter) {
                return filter.text;
            }
        }
        return "";
    };

    handlePageSelected = (page) => {
        if (this.isListDisplayTypeSelected) {
            this.setState({ purchaseList: { ...this.state.purchaseList, selectedPage: page } }, () => this.downloadData());
        } else {
            this.setState({ detailedPurchaseList: { ...this.state.detailedPurchaseList, selectedPage: page } }, () => this.downloadData());
        }
    };

    onExpandListSmallTable = (title, index, isExpanded) => {
        const invoices = [...this.state.purchaseList.invoices].map(x => ({ ...x }));
        let current = invoices.find(x => x.title === title);
        if (current && current.items) {
            current.items = current.items.map(x => ({ ...x }));
            current.items[index].isExpanded = isExpanded;
        }
        this.setState({ purchaseList: { ...this.state.purchaseList, invoices: invoices } });
    };

    handleDateChange = (dateRange) => {
        if (this.isListDisplayTypeSelected) {
            this.setState({
                ...this.state,
                dateRange: dateRange,
                purchaseList: { ...this.state.purchaseList, selectedPage: 1, selectedPeriodId: dateRange.selectedPeriodId,}
            }, () => {
                if (dateRange.selectedPeriodId || dateRange.endDate) {
                    this.downloadData()
                }
            });
        } else {
            this.setState({
                ...this.state,
                dateRange: dateRange,
                detailedPurchaseList: { ...this.state.detailedPurchaseList, selectedPage: 1, selectedPeriodId: dateRange.selectedPeriodId,}
            }, () => {
                if (dateRange.selectedPeriodId || dateRange.endDate) {
                    this.downloadData()
                }
            });
        }
    };

    render() {
        const isLoaderVisible = ((this.isListDisplayTypeSelected && this.state.purchaseList.isDownloadingData) ||
            (this.isTableDisplayTypeSelected && this.state.detailedPurchaseList.isDownloadingData));
        const selectedDisplayTypeState = this.isListDisplayTypeSelected ? this.state.purchaseList : this.state.detailedPurchaseList;
        const selectedStationTitle = this.currentFilterTitle(selectedDisplayTypeState.stations, selectedDisplayTypeState.selectedStationId);
        const selectedPeriodTitle = this.currentFilterTitle(selectedDisplayTypeState.periods, selectedDisplayTypeState.selectedPeriodId);
        const selectedProductTypeTitle = this.currentFilterTitle(selectedDisplayTypeState.productTypes, selectedDisplayTypeState.selectedProductTypeId);
        const listItems = this.state.purchaseList.invoices;
        const tableItems = this.state.detailedPurchaseList.invoices;
        const isNoContentVisible = (
            (this.isListDisplayTypeSelected && listItems && listItems.length <= 0) ||
            (this.isTableDisplayTypeSelected && (tableItems && tableItems.length <= 0) && !this.state.detailedPurchaseList.selectedOrderSearchCopy));
        const isListVisible = this.isListDisplayTypeSelected && listItems && listItems.length > 0;
        const isTableVisible = this.isTableDisplayTypeSelected && ((tableItems && tableItems.length > 0) || this.state.detailedPurchaseList.selectedOrderSearchCopy);

        const headerClass = `${this.props.isHeaderFixed ? "fixed" : ""} ${this.props.isHeaderHiding ? "hiding" : ""} ${this.props.isHeaderAnimated ? "animated" : ""}`;
        const tableHeaderClass = `${this.props.isHeaderFixed ? "fixed" : "hidden"} ${this.props.isHeaderHiding ? "alt" : ""} ${this.props.isHeaderAnimated ? "animated" : ""}`;
        return (<div className="invoicing-transactions">
            <div className="invoicing-transactions-header-placeholder"></div>
            <div className={`invoicing-transactions-header-static ${headerClass ? headerClass : ""}`}>
                <div className="invoicing-transactions-header-background"></div>
                <div className="invoicing-transactions-header">
                    <div className="invoicing-transactions-header-items left">
                        <TransactionsFilters {...{
                            items: [
                                {
                                    leftImg: IconFuelDarker20px,
                                    text: selectedStationTitle,
                                    items: selectedDisplayTypeState.stations,
                                    type: FilterTypeEnum.dropdown
                                },
                                {
                                    leftImg: IconTimeDarker20px,
                                    text: selectedPeriodTitle,
                                    items: selectedDisplayTypeState.periods,
                                    type: FilterTypeEnum.calendar,
                                    onChange: this.handleDateChange,
                                    dateRange: this.state.dateRange
                                },
                                ...[this.isTableDisplayTypeSelected && {
                                    leftImg: IconCartDarker20px,
                                    text: selectedProductTypeTitle,
                                    items: selectedDisplayTypeState.productTypes,
                                    type: FilterTypeEnum.dropdown
                                }],
                            ].filter(x => x), //filter out undefineds
                            alignToStart: true
                        }} />
                    </div>
                    <div className="invoicing-transactions-header-items right">
                        <ImageButton24px {...{ src: IconDownload, alt: "download", onClick: this.downloadExcel, tooltip: this.props.generalTranslation.downloadFile }} />
                        <ToolbarSeparator {...{ className: "invoicing-transactions-header_separator-right" }} />
                        <TransactionsSwitches {...{
                            onListClick: () => this.displayTypeSelected(this.displayTypeEnumeration.list),
                            isListDisplayTypeSelected: this.isListDisplayTypeSelected,
                            onTableClick: () => this.displayTypeSelected(this.displayTypeEnumeration.table),
                            isTableDisplayTypeSelected: this.isTableDisplayTypeSelected
                        }} />
                    </div>
                    <MobileFiltersButton {...{
                        className: "invoicing-transactions-header-mobile",
                        onClick: () => this.props.onMobileFiltersOpen([{
                            text: selectedStationTitle,
                            items: selectedDisplayTypeState.stations
                        }, {
                            text: selectedPeriodTitle,
                            items: selectedDisplayTypeState.periods
                        }, {
                            text: selectedProductTypeTitle,
                            items: selectedDisplayTypeState.productTypes
                        }])
                    }} />
                </div>
            </div>

            <TransitionGroup component={null}>
                {isTableVisible &&
                    <CSSTransition key={"table-background"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <div className="invoicing-transactions-body-background">
                            <BackgroundTable {...{
                                items: this.state.detailedPurchaseList.invoices,
                                hovered: this.props.hoveredIndex,
                                stickyHeaderClass: tableHeaderClass
                            }} />
                        </div>
                    </CSSTransition>}
                {isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                    <Loader className={"invoicing-transactions-loader"} />
                </CSSTransition>}

                <CSSTransition key={"body-content"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                    <div className="invoicing-transactions-body">
                        <TransitionGroup component={null}>
                            {isNoContentVisible &&
                                <CSSTransition key={"no-content"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                    <NoContent {...{ text: this.props.translation.noTransactions }} />
                                </CSSTransition>}
                            {isListVisible &&
                                <CSSTransition key={"list-content"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                    <>{listItems.map((item, index) => <TransactionsContent {...{
                                        ...item,
                                        key: index,
                                        onExpandListSmallTable: this.onExpandListSmallTable,
                                        downloadTooltip: this.props.generalTranslation.downloadFile
                                    }} />)}</>
                                </CSSTransition>}
                            {isTableVisible &&
                                <CSSTransition key={"table-content"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                    <TransactionsTable {...{
                                        items: tableItems,
                                        selectedOrderHow: selectedDisplayTypeState.selectedOrderHow,
                                        selectedOrderWhat: selectedDisplayTypeState.selectedOrderWhat,
                                        selectedOrderSearch: selectedDisplayTypeState.selectedOrderSearch,
                                        onTableColumnFilterSelected: this.onTableColumnFilterSelected,
                                        onMouseOverElement: this.props.onMouseOverTableRow,
                                        onMouseOutElement: this.props.onMouseOutTableRow,
                                        stickyHeaderClass: tableHeaderClass
                                    }} />
                                </CSSTransition>}
                            {(isListVisible || isTableVisible) &&
                                <CSSTransition key={"pagination"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                    <Pagination {...{
                                        className: "invoicing-transactions-pagination",
                                        selectedPage: selectedDisplayTypeState.selectedPage,
                                        pages: selectedDisplayTypeState.pages,
                                        handlePageSelected: this.handlePageSelected
                                    }} />
                                </CSSTransition>}
                        </TransitionGroup>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </div>);
    };
}

export default connect(
    state => ({
        login: state.login,
        isMobileLayout: state.size.isMobileLayout,
        translation: state.translation.invoicing.transactions,
        generalTranslation: state.translation.general
    }),
    dispatch => ({ transactionsRequest: (body, success, error) => businessTransactionsRequest(dispatch, body, success, error) }))(withBackgroundTableHighlighting(withScrollingHeader(Transactions, 80, 72, 80, 52)));
