import React from 'react';
import PropTypes from 'prop-types';
import './BlackCheckbox.scss';
import IconCheckYellow16px from '../assets/Icon_Check_Yellow-16px.svg';

const BlackCheckbox = React.memo(props => (
    <div className={`black-checkbox ${props.checked ? "checked" : ""} ${props.large ? "large" : ""} ${props.className ? props.className : ""}`}>
        <input className="black-checkbox_input" id={props.type} type="checkbox" checked={props.checked} onChange={props.onChange} />
        <img className="black-checkbox_img" src={IconCheckYellow16px} alt="" />
    </div>
));

BlackCheckbox.propTypes = {
    className: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.object
}

export default BlackCheckbox;