export const roleChanged = (prevProps, props) => {
    const prevRole = prevProps && prevProps.login && prevProps.login.currentRole;
    const role = props && props.login && props.login.currentRole;
    if (!prevRole && role && (role.accnr || role.userId)) {
        return true; // no role to role
    } else if (prevRole && !role) {
        return true; // role to no role
    } else if (prevRole && role && (prevRole.accnr !== role.accnr || prevRole.userId !== role.userId)) {
        return true; // role to a different role
    }
    return false; // no role change
}

export const languageChanged = (prevProps, props) => {
    const prevPrivateClientData = prevProps && prevProps.login && prevProps.login.privateClientData;
    const privateClientData = props && props.login && props.login.privateClientData;
    if (prevPrivateClientData && privateClientData && prevPrivateClientData.language !== privateClientData.language) {
        return true; // language changed
    }
    return false; // no language change
}

export const roleOrLanguageChanged = (prevProps, props) => {
    return roleChanged(prevProps, props) || languageChanged(prevProps, props);
}

export const multiLog = (title, ...items) => {
    console.group(title);
    items && items.forEach(x => console.log(x));
    console.groupEnd();
}


export const startedScrollingUp = (scrollY, scrollY1, scrollY2) => {
    return scrollY1 ? (scrollY < scrollY1 && (scrollY2 === null || scrollY1 >= scrollY2)) : false;
}

export const startedScrollingDown = (scrollY, scrollY1, scrollY2) => {
    return scrollY1 ? (scrollY > scrollY1 && (scrollY2 === null || scrollY1 <= scrollY2)) : false;
}

export const getHeaderState = (scrollY, scrollY1, scrollY2, headerOffsetY, headerHeight, isHeaderFixed, isHeaderAnimated, canHide) => {
    const navbarHeight = 80;
    const correctedY = scrollY + navbarHeight;
    const belowFiltersBottomEdge = correctedY >= navbarHeight + headerOffsetY + headerHeight;
    const belowFiltersTopEdge = correctedY > navbarHeight + headerOffsetY;
    const scrollingUp = scrollY < scrollY1 && scrollY1 < scrollY2;
    const scrollingDown = scrollY > scrollY1 && scrollY1 > scrollY2;
    return {
        isHeaderFixed: canHide ? ((isHeaderFixed && scrollingUp && belowFiltersTopEdge) || belowFiltersBottomEdge) : belowFiltersTopEdge,
        isHeaderHiding: canHide ? scrollingDown : false,
        isHeaderAnimated: canHide ? (isHeaderFixed && (startedScrollingDown || startedScrollingUp || isHeaderAnimated)) : false
    };
}

export const buildClassList = obj => Object.entries(obj)
    .filter(e => e[1])
    .map(e => e[0])
    .join(' ');


export const Environment = Object.freeze({
    OlxApp: 7,
});
