import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './TopAd.scss';
import crossImg from '../../../assets/Icon_Modal-Cross_16px.svg';

const TopAd = React.memo(props =>
    <div className="top_loyalty_ad">
        {props.iconUrl && <img className="top_loyalty_ad-icon" src={props.iconUrl} alt="" />}
        <span className="top_loyalty_ad-title">{props.title}</span>
        <button className="top_loyalty_ad-close_button" onClick={props.onTopAdClose}><img className="top_loyalty_ad-close_button-img" src={crossImg} alt="close" /></button>
        <span className="top_loyalty_ad-text">{props.body}</span>
        {props.buttonText && <div className="top_loyalty_ad-open_button-container"><a href={props.buttonUrl} className="top_loyalty_ad-open_button">{props.buttonText}</a></div>}
    </div>
);
TopAd.propTypes = {
    title: PropTypes.string,
    onTopAdClose: PropTypes.func,
    body: PropTypes.string,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    iconUrl: PropTypes.string
};

export default connect(state => ({ translation: state.translation.overview.topAd }))(TopAd);
