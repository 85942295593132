import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './Search.scss';
import IconSearch from '../../assets/Icon_Search.svg';
import IconModalCrossBlack16px from '../../assets/Icon_Modal-Cross_Black_16px.svg';

export const Search = React.memo(props => {
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef();
    return (<div className={`search ${props.className ? props.className : ""} ${(props.isOpen || isOpen) ? "open" : ""}`}>
        <button className="search-button" onClick={() => {
            props.setIsOpen ? props.setIsOpen(true) : setIsOpen(true)
            inputRef.current.focus();
        }}>
            <img className="search-button_img" src={IconSearch} alt={(props.isOpen || isOpen) ? "close" : "open"} />
        </button>
        <form className="search-input-form" onSubmit={props.onSearchFormSubmit}>
            <input className="search-input-field"
                type="text"
                size="1"
                placeholder={props.inputPlaceholder}
                onChange={props.onInputValueChange}
                value={props.inputValue}
                ref={inputRef} />
        </form>
        <button className="search-close-button" onClick={() => props.setIsOpen ? props.setIsOpen(false) : setIsOpen(false)}>
            <img className="search-close-button_img" src={IconModalCrossBlack16px} alt="close" />
        </button>
    </div>);
});

Search.propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    onSearchFormSubmit: PropTypes.func,
    inputPlaceholder: PropTypes.string,
    onInputValueChange: PropTypes.func,
    inputValue: PropTypes.string,
};
