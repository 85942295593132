import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import PageHeader from '../components/PageHeader';
import Calculator from './components/Calculator';
import OrderHistory from './components/OrderHistory';

import styles from './Wholesale.module.scss';

const Wholesale = React.memo(props =>
    <div className={styles.page}>
        <PageHeader {...{
            className: styles.header,
            title: props.translation.title,
            pathname: props.pathname,
            links: [
                { text: props.translation.calculatorTab, to: '/wholesale', exact: true },
                { text: props.translation.orderHistoryTab, to: '/wholesale/orders' },
            ],
        }} />
        <Route exact path="/wholesale" render={() => <Calculator {...props} />} />
        <Route path="/wholesale/orders" render={() => <OrderHistory {...props} />} />
    </div>
);

export default connect(
    state => ({
        translation: state.translation.wholesale.header,
        login: state.login,
        pathname: state.router.location.pathname,
    }),
    null)(Wholesale);
