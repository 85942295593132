import React from 'react';
import { Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './NavItems.scss';

class NavItem extends React.PureComponent {
    render() {
        if (this.props.isExternal) {
            return (
                <a className={`nav-item ${this.props.className ? this.props.className : ""}`} href={this.props.to} rel="noopener noreferrer" target="_blank">
                    <div className={`nav-item-img-container ${this.props.active ? "active" : ""}`}>
                        <img className="nav-item-img active" src={this.props.activeImg} alt="" />
                        <img className="nav-item-img" src={this.props.inactiveImg} alt="" />
                    </div>
                    <p className={`nav-item-title ${this.props.active ? "" : "inactive"}`}>{this.props.title}</p>                    
                </a>
            )
        }
        return (
            <Link className={`nav-item ${this.props.className ? this.props.className : ""}`} to={this.props.to}>
                <div className={`nav-item-img-container ${this.props.active ? "active" : ""}`}>
                    <img className="nav-item-img active" src={this.props.activeImg} alt="" />
                    <img className="nav-item-img" src={this.props.inactiveImg} alt="" />
                </div>
                <p className={`nav-item-title ${this.props.active ? "" : "inactive"}`}>{this.props.title}</p>
            </Link>
        );
    }
}

const NavItems = React.memo(props =>
    <nav className={`nav-items ${props.isDrawerOpen ? "drawer-open" : ""} ${props.isBusiness ? "business" : "private"}`}>
        <TransitionGroup component={null}>
            {props.items && props.items.map((item, index) => <CSSTransition key={index} timeout={{ enter: 600, exit: 600 }} classNames={"item"}>
                <NavItem {...{
                    ...item,
                    key: index,
                    className: `${props.isBusiness ? "business" : "private"} ${item.isMobileOnly ? "mobile-only" : ""} ${item.isDesktopOnly ? "desktop-only" : ""}`
                }} />
            </CSSTransition>)}
        </TransitionGroup>
    </nav>
);

export default NavItems;