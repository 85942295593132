import React, { Component } from 'react';
import {connect} from "react-redux";
import {ConfirmationModal} from "../ConfirmationModal";

export const EndContractModal = connect(
    state => ({
        translation: state.translation.electricity,
        login: state.login
    }),
    undefined
)(
    class ContractPreviewModal extends Component {
        onConfirmationClick = () => {
            this.props.onConfirmationClick(this.props.contractIds, this.props.onCloseButtonClick);
        };

        render() {
            const modalTitle = this.props.translation.endContractTitle;
            const checkboxTitle = this.props.translation.endContractCheckbox(this.props.contractIds.length);
            const {onCloseButtonClick} = this.props;
            const onConfirmationClick = this.onConfirmationClick;
            const confirmationText = this.props.translation.confirm;
            return (
                <ConfirmationModal {...{modalTitle, checkboxTitle, onConfirmationClick, onCloseButtonClick, confirmationText}} />
            )
        }
    }
);
