import React from 'react';
import { connect } from 'react-redux';
import { OlerexPlusCollection } from './OlerexPlus';
import './OlerexPlusModal.scss';
import modalCloseImg from '../assets/Icon_Modal-Close_32px.svg';

const OlerexPlusModal = React.memo(props =>
    <div className="olerex-plus-modal">
        <div className="olerex-plus-modal-content">
            <h1 className="olerex-plus-modal-title">{props.translation.title}</h1>
            {props.text && <p className="olerex-plus-modal-text">{props.text}</p>}

            <button className="olerex-plus-modal-close-button" onClick={props.onShowAllClose}>
                <img src={modalCloseImg} alt="" />
            </button>

            <OlerexPlusCollection {...{ items: props.loyaltyProgress }} />
        </div>
    </div>
);

export default connect(state => ({ translation: state.translation.overview.olerexPlus.modal }))(OlerexPlusModal);
