import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import Dashboard from './views/dashboard/Dashboard';
import { roleChanged } from './Utils';
import { windowSizeChanged } from './state/Size';
import { authRequest, inviteRequest } from './Networking';
import { loggedIn } from './state/Login';
import Loader from './views/dashboard/components/Loader';
import './App.scss';
import Register from './views/register/Register';

function getLoginUrl(pathname) {
    let url = 'https://login.olerex.ee';
    if (pathname === '/en' || pathname === '/ru') {
        url += pathname;
    }
    return url;
}

export class App extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { data: "", nonce: null, environmentId: null, register: null, inRequest: false, redirectUrl: "", redirectData: "" };
        this.form = React.createRef();
        this.inviteForm = React.createRef();
    }

    getEncryption = (params = {}) => {
        if (this.state.inRequest) {
            return;
        }
        this.setState({ inRequest: true }, () => {
            const request = {
                "requestType": "getEncryption"
            };
            if (params.invite) {
                request.inviteHash = params.invite;
            }
            this.props.authRequest(request,
                async data => {
                    if (data) {
                        this.setState({ data: data.data, loginUrl: getLoginUrl(this.props.pathname) }, async () => {
                            if (this.form.current) {
                                this.form.current.submit(); //redirect to login.olerex.ee, keep inrequest: true
                            }
                        });
                    } else {
                        await new Promise(x => setTimeout(x, 3000)); //wait before retrying
                        this.setState({ inRequest: false }, () => this.getEncryption(params));
                    }
                }, async error => {
                    await new Promise(x => setTimeout(x, 3000)); //wait before retrying
                    this.setState({ inRequest: false }, () => this.getEncryption(params));
                });
        });
    }

    getLogin = (params) => {
        if (this.state.inRequest) {
            return;
        }
        this.setState({ inRequest: true }, () => {
            this.props.authRequest(
                {
                    "requestType": "validateNonce",
                    "nonce": params.nonce
                },
                async data => {
                    if (data && !data.error) {
                        if (data.register === 1) {
                            this.setState({ inRequest: false, register: data, environmentId: data.environmentId }, () => {
                                this.props.navigateToRegister();
                            });
                        } else {
                            this.setState({ inRequest: false }, () => {
                                this.props.loggedIn(data);
                                if (data.redirectTo) {
                                    window.location.href = data.redirectTo;
                                }
                            });
                        }
                    } else {
                        await new Promise(x => setTimeout(x, 3000)); //wait before retrying
                        this.setState({ inRequest: false }, () => this.getEncryption(params));
                    }
                }, async error => {
                    await new Promise(x => setTimeout(x, 3000)); //wait before retrying
                    this.setState({ inRequest: false }, () => this.getEncryption(params));
                });
        });
    }

    isLoggedIn = (props) => {
        return props.login && props.login.token;
    }

    resizeEvent = () => {
        this.props.windowSizeChanged(window.innerWidth, window.innerHeight);
    }

    getParams = (search) => {
        let params = {};
        if (search) {
            const match = search.match(/(\?|&)[a-zA-Z0-9]+(=)[a-zA-Z0-9]+/g);
            if (match) {
                match.map(x => x.substring(1, x.length).split('=')).forEach(x => {
                    params[x[0]] = x[1];
                });
            }
        }
        return params;
    }

    doLogin = () => {
        const params = this.getParams(this.props.search);
        if (params.nonce) {
            this.setState({ nonce: params.nonce }, () => {
                this.getLogin(params);
            })
        } else {
            this.getEncryption(params);
        }
    }

    componentDidMount() {
        //TODO refactor
        //initial login handling
        if (!this.isLoggedIn(this.props)) {
            if (this.props.pathname === "/register") { //on register path, but state has no register data on load, redirect to root
                this.props.navigateToDashboard();
            } else {
                this.doLogin();
            }
        } else {
            if (this.props.pathname === "/register") { //on register path but logged in
                this.props.navigateToDashboard();
            }
        }

        window.addEventListener('resize', this.resizeEvent);
        this.resizeEvent();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //TODO refactor
        //role change, logout login handling
        if (roleChanged(prevProps, this.props)) {
            if (this.isLoggedIn(this.props)) {
                if (prevProps.login.currentRole) { //user changing role
                    this.props.navigateToDashboard();
                } else {
                    const params = this.getParams(this.props.search);
                    if (params.invite || params.nonce) {
                        this.props.replaceUrl(this.props.pathname); //clear invite and nonce from url
                    }
                    if (params.invite) {
                        const userId = this.props.login.privateClientData && this.props.login.privateClientData.userId;
                        if (userId) {
                            this.props.inviteRequest(
                                {
                                    "userId": userId,
                                    "invite": params.invite,
                                    "token": this.props.login.token
                                },
                                async data => {
                                    if (data) {
                                        this.setState({ redirectUrl: data.records.redirectUrl, redirectData: data.records.redirectData }, () => {
                                            if (this.inviteForm.current) {
                                                this.inviteForm.current.submit();
                                            }
                                        });
                                    }
                                }, async error => {
                                });
                        }
                    } else {
                        this.props.navigateToDashboard();
                    }
                }
            } else {
                this.doLogin();
            }
        } else if (this.props.pathname === "/" && !this.isLoggedIn(this.props) && this.state.register === null && this.state.nonce === null) {
            //path changed from register to root and user not logged in or user navigated back from register, perform login
            this.doLogin();
        } else if (this.isLoggedIn(this.props)) {
            const lang = this.props.login.privateClientData && this.props.login.privateClientData.language;
            if (lang && document.documentElement.getAttribute('lang') !== lang) {
                document.documentElement.setAttribute('lang', lang);
            }
            const params = this.getParams(this.props.search);
            if (params.invite || params.nonce) {
                this.props.replaceUrl(this.props.pathname); //clear invite and nonce from url
            }
            if (params.invite) {
                const userId = this.props.login.privateClientData && this.props.login.privateClientData.userId;
                if (userId) {
                    this.props.inviteRequest(
                        {
                            "userId": userId,
                            "invite": params.invite,
                            "token": this.props.login.token
                        },
                        async data => {
                            if (data) {
                                this.setState({ redirectUrl: data.records.redirectUrl, redirectData: data.records.redirectData }, () => {
                                    if (this.inviteForm.current) {
                                        this.inviteForm.current.submit();
                                    }
                                });
                            }
                        }, async error => {
                        });
                }
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeEvent);
    }

    render() {
        return (
            <div className="App">
                <Switch>
                    <Route path="/register" render={() => <Register {...{ ...this.state.register, nonce: this.state.nonce, environmentId: this.state.environmentId, onClose: this.props.navigateToDashboard }} />} />
                    {this.isLoggedIn(this.props) && <Route path="/" component={Dashboard} />}
                    {!this.isLoggedIn(this.props) && <Route path="/" render={() => <div className="login-redirecting">
                        <form className="login-redirecting-hidden-form" ref={this.form} method="POST" action={this.state.loginUrl}>
                            <input name="data" type="text" value={this.state.data} onChange={(e) => this.setState({ data: e.target.value })} />
                        </form>
                        <Loader className="login-redirecting-loader" />
                    </div>} />}
                </Switch>
                <form className="login-redirecting-hidden-invite-form" ref={this.inviteForm} method="POST" action={this.state.redirectUrl || "https://family.olerex.ee"}>
                    <input name="data" type="text" value={this.state.redirectData} onChange={(e) => this.setState({ data: e.target.value })} />
                </form>
            </div>
        );
    }
}

export default connect(
    state => ({ pathname: state.router.location.pathname, login: state.login, search: state.router.location.search }),
    dispatch => ({
        navigateToDashboard: () => dispatch(replace('/')),
        navigateToRegister: () => dispatch(replace('/register')),
        replaceUrl: (url) => dispatch(replace(url)),
        windowSizeChanged: (width, height) => dispatch(windowSizeChanged(width, height)),
        authRequest: (body, success, error) => authRequest(dispatch, body, success, error),
        loggedIn: (data) => dispatch(loggedIn(data)),
        inviteRequest: (body, success, error) => inviteRequest(dispatch, body, success, error)
    }))(App);
