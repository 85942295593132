import React from 'react';
import ExpandableContent from "../../../components/ExpandableContent";
import MeteringPoint from "../components/MeteringPoint";
import './MeteringPointsTab.scss';
import MeteringPointsTabFooter from "../components/MeteringPointsTabFooter";
import {CSSTransition, TransitionGroup} from "react-transition-group";


export const MeteringPointsTab = ({mpProps, calculateHeight, translation}) => {
    const groups = {
        withContract: mpProps.meteringPoints.filter(mp => mp.contractId !== null),
        withoutContract: mpProps.meteringPoints.filter(mp => mp.contractId === null)
    };

    const [state, setState] = React.useState({
        groups: {
            withContract: false,
            withoutContract: false
        },
        footerData: {
            showFooter: false,
            selectionLength: 0
        }
    });

    const resetSelection = () => {
        const groups = (Object.keys(state.groups).map( key => state.groups[key] = false));
        setState(prevState => ({
            ...prevState,
            footerData: {showFooter: false, selectionLength: 0},
            groups: groups
        }))
    };

    const headerChecked = (input) => (event) => {
        const newGroups = state.groups;
        const areTrue = (Object.keys(state.groups).filter(key => (key !== input))).filter(key => state.groups[key]).concat(!state.groups[input] ? input : []);
        // const selectionLength = areTrue.map(key => groups[key].length).reduce((a, b) => a + b, 0);
        const selectionLength = groups[input].length;
        Object.keys(newGroups).forEach( key => { (key === input) ? newGroups[key] = !newGroups[key] : newGroups[key] = false});
        setState(prevState => ({
            ...prevState,
            groups: newGroups,
            footerData: { showFooter: (areTrue.length > 0), selectionLength: selectionLength }
        }));
        event.stopPropagation();
    };

    const {showFooter, selectionLength} = state.footerData;
    const {withContract, withoutContract} = state.groups;

    const contractOnclick = (type: number) =>() => {
        const chosenMeteringPoints = Object.keys(state.groups).filter(key => state.groups[key]).map(key => groups[key]).reduce((a, b) => (a.concat(b)), []);
        const meteringPointCodes = chosenMeteringPoints.map(mp => mp.code);
        const contractIds = chosenMeteringPoints.map(mp => parseInt(mp.contractId, 10)).reduce(((a, b) => (a.indexOf(b) === -1 ? a.concat(b) : a)), []);
        switch(type) {
            case 1:
                mpProps.editContract(contractIds, meteringPointCodes, 4);
                break;
            case 2:
                mpProps.editContract([], meteringPointCodes, 3);
                break;
            case 3:
                mpProps.endContractHandler(contractIds, meteringPointCodes);
                break;
            default:
                return;
        }
    };

    return (
            <div className="electricity-body electricity-body-metering-points">
                <div className="electricity-body-content">
                    <ExpandableContent
                        className="electricity-metering-points"
                        {...{
                            title: <div className="electricity-metering-points-header">
                                <div className={`electricity-metering-points-checkbox ${state.groups.withContract ? 'checked' : ''}`} onClick={headerChecked('withContract')}>
                                    <div className="checkmark-circle"/>
                                    <div className="checkmark-img"/>
                                </div>
                                <div className="electricity-metering-points-title">{mpProps.translation.boundByContract}</div>
                                <div className="electricity-metering-points-count"><span>{groups.withContract.length}</span></div>
                            </div>,
                            content:
                                <React.Fragment>
                                    {groups.withContract.map((meteringPoint, index) => (
                                        <MeteringPoint {...{
                                            ...mpProps,
                                            meteringPoint: meteringPoint,
                                            headerChecked: state.groups.withContract,
                                            key: index
                                        }} />
                                    ))}
                                </React.Fragment>,
                            height: calculateHeight(groups.withContract.length, mpProps.isMobileLayout)
                        }}
                    />

                    <ExpandableContent
                        className="electricity-metering-points"
                        {...{
                            title: <div className="electricity-metering-points-header">
                                <div className={`electricity-metering-points-checkbox ${state.groups.withoutContract ? 'checked' : ''}`} onClick={headerChecked('withoutContract')}>
                                    <div className="checkmark-circle"/>
                                    <div className="checkmark-img"/>
                                </div>
                                <div className="electricity-metering-points-title">{mpProps.translation.contractElsewhere}</div>
                                <div className="electricity-metering-points-count"><span>{groups.withoutContract.length}</span></div>
                            </div>,
                            content:
                                <React.Fragment>
                                    {groups.withoutContract.map((meteringPoint, index) => (
                                        <MeteringPoint {...{
                                            ...mpProps,
                                            meteringPoint: meteringPoint,
                                            headerChecked: state.groups.withoutContract,
                                            key: index
                                        }} />
                                    ))}
                                </React.Fragment>,
                            height: calculateHeight(groups.withoutContract.length, mpProps.isMobileLayout)
                        }}
                    />
                    <TransitionGroup component={null}>
                        {
                            (showFooter && !mpProps.isMobileLayout) &&
                            <CSSTransition key={"footer"} timeout={{ enter: 250, exit: 250 }} classNames={"slide-bottom"}>
                                <MeteringPointsTabFooter {...{selectionLength, resetSelection, withContract, withoutContract, contractOnclick}} />
                            </CSSTransition>
                        }
                    </TransitionGroup>

                </div>
            </div>
        )
};
