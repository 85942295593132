import React from 'react';
import './Welcome.scss';
import bgStarYellow from '../assets/BG_Star_Yellow-48px.svg';
import { Link } from 'react-router-dom';

const Welcome = React.memo(props => {
    return (<div className="welcome">
        <h1 className="welcome-title">{props.welcomeText}</h1>
        <div className="welcome-notifications">
            { props.welcomeTextNotifications.map((n, i) => <div key={n.text + i} style={{ display: 'flex', marginBottom: '12px', position: 'relative' }}>
                {n.showStar ? <img className="welcome-img-shield" src={bgStarYellow} alt="" /> : null}
                {n.showStar ? <div className="welcome-img in-small" style={{ backgroundImage: `url(${n.thumbnail || ""})` }}></div> : <img className="welcome-img" src={n.thumbnail} alt="" />}
                {n.href ? <Link className="welcome-text" to={n.href}>{n.text}</Link> : <p className="welcome-text">{n.text}</p>}
            </div>)}
        </div>
    </div>)
});

export default Welcome;