import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { businessVirtualFamilyRequest } from '../../../../../Networking';
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Loader from '../../../components/Loader';
import NoContent from '../../../components/NoContent';
import { FamilyModal } from './FamilyModal';
import './VirtualFamilies.scss';
import IconChevronRight from '../../../assets/Icon_Chevron-Right.svg';
import IconFiltersDots from '../../../assets/Icon_Filters_Dots.svg'

const VirtualFamilyProgressBar = React.memo(props =>
    <Tippy arrow={true}
        isEnabled={props.progressTitle !== null}
        content={props.progressTitle || ""}>
        <div className="virtual_family_progress_bar">
            <span className="virtual_family_progress_bar-first" style={{ width: `calc(${props.progressInt}% - 1px)` }}></span>
            <span className="virtual_family_progress_bar-second" style={{ width: `calc(${100 - props.progressInt}% - 1px)` }}></span>
        </div >
    </Tippy>
);
VirtualFamilyProgressBar.propTypes = {
    progressInt: PropTypes.any,
    progressTitle: PropTypes.any
};

const VirtualFamilyInfoField = React.memo(props =>
    <div className="virtual_family_info_field">
        <span className="virtual_family_info_field-title">{props.text}</span>
        <img className="virtual_family_info_field-img" src={props.icon} alt="" />
        <span className="virtual_family_info_field-text">{props.value}</span>
    </div>
);
VirtualFamilyInfoField.propTypes = {
    text: PropTypes.any,
    icon: PropTypes.any,
    value: PropTypes.any
};

const VirtualFamilyCell = connect(state => ({ translation: state.translation.virtualFamily.families }))(React.memo(props => {
    const link = props.familyId ? `/virtualfamily/${props.familyId}` : `/virtualfamily`;
    return (<div className="virtual_family_cell">
        <NavLink className="virtual_family_cell-title_button"
            to={link}>
            <div className="virtual_family_cell-title_bar">
                <span className="virtual_family_cell-title_bar-text">{props.familyName}</span>
                <img className="virtual_family_cell-title_bar-img" src={IconChevronRight} alt="" />
            </div>
        </NavLink>
        <div className="virtual_family_cell-info">
            <div className="virtual_family_cell-info-collection">
                {props.familyParams && props.familyParams.map((item, index) =>
                    <VirtualFamilyInfoField {...{ ...item, key: index }} />)}
            </div>
            <button className="virtual_family_cell-info-button" onClick={() => props.onModalOpen(<FamilyModal {...{
                onModalClose: props.onModalClose,
                editing: true,
                familyId: props.familyId,
                familyName: props.familyName,
                onFamilyModalRequestSuccess: props.familyNameUpdated
            }} />)}><img src={IconFiltersDots} alt="" /></button>
        </div>
        <VirtualFamilyProgressBar {...{ progressInt: props.progressInt, progressTitle: props.progressTitle }} />
    </div>);
}));
VirtualFamilyCell.propTypes = {
    familyId: PropTypes.any,
    familyName: PropTypes.any,
    familyParams: PropTypes.array,
    editFamilyName: PropTypes.func,
    onModalOpen: PropTypes.func,
    onModalClose: PropTypes.func,
    virtualFamilyRequest: PropTypes.func,
    familyNameUpdated: PropTypes.func
};

export const VirtualFamilies = connect(state => ({ translation: state.translation.virtualFamily.families }),
    dispatch => ({ virtualFamilyRequest: (body, success, error) => businessVirtualFamilyRequest(dispatch, body, success, error) }))(React.memo(props =>
        <div className="virtual_family-families">
            <TransitionGroup component={null}>
                {props.isLoaderVisible &&
                    <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <Loader className={"virtual_family-families-loader"} />
                    </CSSTransition>}
                {props.isNoContentVisible &&
                    <CSSTransition key={"no-content"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <NoContent {...{
                            className: "virtual_family-families-no_content",
                            text: props.translation.noContent
                        }} />
                    </CSSTransition>}
                {props.families && <CSSTransition key={"families-collection"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                    <div className="virtual_family-families-collection">
                        {props.families && props.families.map((item, index) =>
                            <VirtualFamilyCell {...{
                                ...item,
                                editFamilyName: props.editFamilyName,
                                virtualFamilyRequest: props.virtualFamilyRequest,
                                onModalOpen: props.onModalOpen,
                                onModalClose: props.onModalClose,
                                familyNameUpdated: props.familyNameUpdated,
                                key: index
                            }} />)}
                    </div>
                </CSSTransition>}
            </TransitionGroup>
        </div>
    ));
VirtualFamilies.propTypes = {
    families: PropTypes.array,
    isLoaderVisible: PropTypes.bool,
    isNoContentVisible: PropTypes.bool,
    editFamilyName: PropTypes.func,
    familyNameUpdated: PropTypes.func,
    onModalOpen: PropTypes.func,
    onModalClose: PropTypes.func,
};