import React from 'react';
import { connect } from 'react-redux';
import { BankLinksSelectModal } from '../../components/SelectModal';

const BankLinks = connect(state => ({ translation: state.translation.bankLinks }))(React.memo(props =>
    <BankLinksSelectModal {...{
        title: props.translation.title,
        onCloseButtonClick: props.onCloseButtonClick,
        items: props.items ? props.items.map(x => ({ img: x.logo_url, text: x.name, onClick: () => window.open(x.url) })) : []
    }} />
));

export default connect(state => ({ translation: state.translation.bankLinks }))(BankLinks);