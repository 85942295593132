import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { businessVirtualFamilyRequest } from '../../../../../Networking';
import { Modal, ModalInputText } from "../../manage/components/Modal";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './LinkModal.scss';
import { registerLocale } from "react-datepicker";
import et from 'date-fns/locale/et';
import en from 'date-fns/locale/en-GB';
import ru from 'date-fns/locale/ru';
import IconChevronWhiteLeft from '../../../assets/Icon_Chevron_White-Left.svg';
import IconChevronWhiteRight from '../../../assets/Icon_Chevron_White-Right.svg';
registerLocale('et', et);
registerLocale('en', en);
registerLocale('ru', ru);

class DatePickerInputCustomField extends React.PureComponent {
    //DatePicker ref warning with function component
    render = () => <input
        className={`new_link_item-text_field-date_picker_input`}
        type="text"
        size="1"
        id={this.props.id}
        value={this.props.value}
        onClick={this.props.onClick}
        onChange={this.props.onChange}>
    </input>;
}

const DatePickerInput = connect(state => ({
    login: state.login,
    translation: state.translation.virtualFamily.links.linkModal,
    months: state.translation.overview.fuelUsage.months
}))(React.memo(props => {
    let expiresIn = null;
    try {
        expiresIn = props.value ? Math.ceil((props.value - Date.now()) / (1000 * 60 * 60 * 24)) : null;
    } catch (e) {

    }
    const loginLocale = props.login && props.login.privateClientData && props.login.privateClientData.language;
    let locale = et;
    if (loginLocale === "et") {
        locale = et;
    } else if (loginLocale === "en") {
        locale = en;
    } else if (loginLocale === "ru") {
        locale = ru;
    }
    return (<div className={`new_link_item-text_field ${props.className ? props.className : ""}`}>
        <label className="new_link_item-text_field-label" htmlFor={props.id}>
            <span className="new_link_item-text_field-label-left">{props.translation.expiresAtText}</span>
            <span className="new_link_item-text_field-label-right">
                {expiresIn === null ? props.translation.expiresNever : (expiresIn === 1 ? props.translation.expiresInDay(expiresIn) : props.translation.expiresInDays(expiresIn))}
            </span>
        </label>
        <DatePicker {...{
            onChange: props.onChange,
            selected: props.value,
            minDate: Date.now(),
            locale: locale,
            customInput: <DatePickerInputCustomField {...{ id: props.id }} />,
            renderCustomHeader: ({ date, decreaseMonth, increaseMonth }) => (
                <div className="date_picker-header">
                    <button className="date_picker-header-button" onClick={decreaseMonth}>
                        <img className="date_picker-header-button-img" src={IconChevronWhiteLeft} alt="prev month" />
                    </button>
                    <span className="date_picker-header-title">{`${props.months[date.getMonth()]} ${date.getFullYear()}`}</span>
                    <button className="date_picker-header-button" onClick={increaseMonth}>
                        <img className="date_picker-header-button-img" src={IconChevronWhiteRight} alt="next month" />
                    </button>
                </div>
            ),
            // calendarContainer: 
        }} />
    </div>);
}));
DatePickerInput.propTypes = {
    className: PropTypes.func,
    id: PropTypes.string,
    text: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    maxLength: PropTypes.number,
    required: PropTypes.bool
};

export const LinkModal = connect(state => ({ translation: state.translation.virtualFamily.links.linkModal, login: state.login }),
    dispatch => ({ virtualFamilyRequest: (body, success, error) => businessVirtualFamilyRequest(dispatch, body, success, error) })
)(class extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { isLoaderVisible: false, isDone: false, isError: false, result: null, inviteDescription: props.inviteDescription || "", inviteExpiresAt: props.inviteExpiresAt || "" };
    }

    onInviteDescriptionChanged = (e) => {
        this.setState({ inviteDescription: e.target.value });
    }

    onInviteExpiresAtChanged = (date) => {
        this.setState({ inviteExpiresAt: date });
    }

    newLink = (inviteDescription, inviteExpiresAt) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        this.setState({ isLoaderVisible: true }, () => {
            this.props.virtualFamilyRequest({
                "requestType": "newLink",
                "accNr": accnr,
                "token": this.props.login.token,
                "familyId": this.props.familyId,
                inviteDescription,
                inviteExpiresAt
            }, data => this.setState({
                result: data.records.result,
                isDone: data.records.statusCode === 200,
                isError: data.records.statusCode !== 200,
                isLoaderVisible: false
            }, () => this.props.onFamilyModalRequestSuccess()),
                error => this.setState({ isLoaderVisible: false }));
        })
    }

    editLink = (inviteId, inviteDescription, inviteExpiresAt) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        this.setState({ isLoaderVisible: true }, () => {
            this.props.virtualFamilyRequest({
                "requestType": "editLink",
                "accNr": accnr,
                "token": this.props.login.token,
                inviteId,
                inviteDescription,
                inviteExpiresAt
            }, data => this.setState({
                result: data.records.result,
                isDone: data.records.statusCode === 200,
                isError: data.records.statusCode !== 200,
                isLoaderVisible: false
            }, () => this.props.onLinkModalRequestSuccess()),
                error => this.setState({ isLoaderVisible: false }));
        })
    }

    render() {
        const actionButtonActive = !!this.state.inviteExpiresAt && (this.state.inviteExpiresAt instanceof Date) && (this.state.inviteExpiresAt - Date.now() > 0);
        const inviteDescription = this.state.inviteDescription;
        const inviteExpiresAt = (this.state.inviteExpiresAt && this.state.inviteExpiresAt instanceof Date) ?
            `${this.state.inviteExpiresAt.getFullYear()}/${this.state.inviteExpiresAt.getMonth() + 1}/${this.state.inviteExpiresAt.getDate()}` : "";
        return (
            <Modal {...{
                title: this.props.editing ? this.props.translation.titleEdit : this.props.translation.titleNew,
                onCloseButtonClick: this.props.onModalClose,
                content:
                    <>
                        <ModalInputText {...{
                            text: this.props.translation.descriptionText,
                            value: this.state.inviteDescription,
                            maxLength: 250,
                            onChange: this.onInviteDescriptionChanged
                        }} />
                        <DatePickerInput {...{
                            onChange: this.onInviteExpiresAtChanged,
                            value: this.state.inviteExpiresAt
                        }} />
                    </>,
                isSaveButton: true,
                actionButtonTitle: this.props.editing ? this.props.translation.saveButton : this.props.translation.createButton,
                onActionButtonClick: () => this.props.editing ? this.editLink(this.props.inviteId, inviteDescription, inviteExpiresAt) : this.newLink(inviteDescription, inviteExpiresAt),
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 156,
                actionButtonActive
            }} />
        )
    }
});
LinkModal.propTypes = {
    editing: PropTypes.any,
    familyId: PropTypes.any,
    inviteId: PropTypes.any,
    inviteDescription: PropTypes.any,
    inviteExpiresAt: PropTypes.any,
    onLinkModalRequestSuccess: PropTypes.func,
    onModalClose: PropTypes.func
};