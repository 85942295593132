import React, { PureComponent } from 'react';
import ToolbarSeparator from "../../../components/ToolbarSeparator";
import IconDocument from "../../../assets/Icon_Document.svg";
import IconTime from "../../../assets/Icon_Time.svg";
import {withScrollingHeader} from "../../../components/withScrollingHeader";
import './ConsumptionTab.scss';
import {ConsumptionChart} from "../components/ConsumptionChart";
import ConsumptionFilters from "../components/ConsumptionFilters";
import MobileFiltersButton from "../../../components/MobileFiltersButton";
import {url} from "../../../../../Networking";


class ConsumptionTab extends PureComponent {
    componentDidMount() {
        this.getUsage({});
    }

    getUsage({...props}) {
        const userId =
            this.props.login &&
            this.props.login.currentRole &&
            this.props.login.currentRole.userId;
        if (!userId) {
            return;
        }
        this.setState({ isLoaderVisible: true }, async () => {
            this.props.electricityRequest(
                {
                    requestType: 'getUsage',
                    userId: userId,
                    token: this.props.login.token,
                    ...props
                },
                queryResult => {
                    const data : any = queryResult.records.result;
                    data.filters.isCurrentYear = true;
                    data.filters.selectedItemId = data.filters.selectedItemId.toString();
                    const newState = {
                        statistics: {
                            year: data.yearlyUsage,
                            month: data.avgPricePerMonth,
                            distribution: data.distribution
                        },
                        monthChart: data.months,
                        weekChart: data.weeks,
                        dayChart: data.days,
                        filters: data.filters,
                        toggle: true
                    };
                    this.setState(newState);
                },
                error => {
                    this.setState({ isLoaderVisible: false });
                }
            );
        });
    }

    handleDownloadClick = () => {
        const {filters} = this.state;
        const userId = this.props.login && this.props.login.currentRole && this.props.login.currentRole.userId;
        if (!userId) {
            return; //not in private role
        }
        const downloadLink = `${url}&act=olerexweb.private.electricity&requestType=getUsage&excel=1&token=${this.props.login.token}&userId=${userId}` +
            `${filters.selectedYear ? '&year=' + filters.selectedYear : ''}` +
            `${filters.selectedTypeId ? '&typeId=' + filters.selectedTypeId : ''}` +
            `${filters.selectedItemId ? '&itemId=' + filters.selectedTypeId : ''}`;
        window.open(downloadLink);
    };

    handleButtonClick = (item, value) => {
        const split = item.split(".");
        if (split.length === 1) {
            this.setState({[split[0]]: value});
        } else if (split.length === 2) {
            const parent = this.state[split[0]];
            parent[split[1]] = value;
            if (item === 'filters.selectedYear') {
                parent['isCurrentYear'] = new Date().getFullYear() === value
            } else if ( item === 'filters.selectedTypeId') {
                parent['selectedItemId'] = 0;
            }

            if (split[0] === 'filters') {
                const {filters} = this.state;
                this.getUsage({
                    year: filters.selectedYear,
                    typeId: filters.selectedTypeId,
                    itemId: filters.selectedItemId
                });
            } else {
                this.setState(prevState => ({
                    [split[0]]: parent,
                    toggle: !prevState.toggle
                }));
            }
        }
    };

    handleGraphClick = (item, value) => (event) =>  {
        // console.log('gC', item, value, event);
    };

    createProps(chart, chartName) {
        const unit = chart.unit;
        const {isCurrentYear, selectedYear} = this.state.filters;
        const props = {unit, selectedYear, isCurrentYear,...this.props};
        props.data = Object.keys(chart.usage).map(key => {
            const row = {};
            row.x = chart.usage[key].text;
            // row.y = parseFloat((chart.usage[key].value).toFixed(3));
            row.y = parseFloat((chart.usage[key].value).toFixed(0));
            row.tooltip = chart.usage[key].tooltip;
            return row;
        });
        props.columns = props.data.map((col) => (col.x));
        props.type = chartName;
        props.currentValue = (chartName === 'month') ? this.props.currentMonth : (chartName === 'week') ? this.props.currentWeekDay : this.props.currentDay;
        props.hideLabels = (chartName === 'day') ? true : false;
        props.chartName = chartName;
        props.onClick = this.handleGraphClick;
        return props;
    }

    render () {
        if (!this.state || !this.state.filters) {
            return null;
        }

        const {translation} = this.props;
        const {monthChart, weekChart, dayChart, filters, statistics} = this.state;
        const filterHeaderClass = `${this.props.isHeaderFixed ? "fixed" : "hidden"} ${this.props.isHeaderHiding ? "alt" : ""} ${this.props.isHeaderAnimated ? "animated" : ""}`;
        const monthProps = this.createProps(monthChart, 'month');
        const weekProps = this.createProps(weekChart, 'week');
        const dayProps = this.createProps(dayChart, 'day');

        this.filters = [
            {
                items: Object.keys(filters.items).map( key => {
                    return {text: filters.items[key], value: key, onClick: () => this.handleButtonClick('filters.selectedItemId', key)};
                }),
                leftImg: IconDocument,
                text: filters.items[filters.selectedItemId]
            },
            {
                items: (Object.keys(filters.years).sort((a, b) => {return parseInt(b, 10) - parseInt(a, 10)})).map( key => {
                    return {text: filters.years[key], value: key, onClick: () => this.handleButtonClick('filters.selectedYear', parseInt(key, 10))};
                }),
                leftImg: IconTime,
                text: (this.state.filters.selectedYear.toString(10))
            }
        ];

        this.mobileFilters = [{
            items: Object.keys(filters.types).map( key => {
                return {text: filters.types[key], value: key, onClick: () => this.handleButtonClick('filters.selectedTypeId', parseInt(key, 10))};
            }),
            leftImg: IconTime,
            text: filters.types[filters.selectedTypeId]
        }].concat(this.filters);

        return (
            <div className="electricity-consumption">
                <div className={`electricity-filter-row ${filterHeaderClass}`}>
                    <div className="electricity-filter-row-content">
                        <div className="electricity-filter-row-content-left">
                            <span className="electricity-filter-header">
                                <span className="electricity-filter-header-icon" />
                                <span>{translation.filter}</span>
                            </span>
                            <ToolbarSeparator className="electricity-filter-separator" />
                            {
                                Object.keys(filters.types).map( key => {
                                    return (
                                        <button key={key} onClick={()  => this.handleButtonClick('filters.selectedTypeId', parseInt(key, 10))} className={`electricity-filter-button ${parseInt(key, 10) === (filters.selectedTypeId) ? 'active' : ''}`}>
                                            <span title={filters.types[key]}>{filters.types[key]}</span>
                                        </button>
                                    )
                                })
                            }
                        </div>
                        <div className="electricity-filter-row-content-right">
                            <ConsumptionFilters {...{ items: this.filters }} />
                            <ToolbarSeparator className="electricity-filter-separator" />
                            <button onClick={() => this.handleDownloadClick()} className="electricity-filter-button">
                                <span className="electricity-filter-button-icon"/>
                            </button>
                        </div>
                        <MobileFiltersButton {...{
                            className: "electricity-filter-row-content-mobile",
                            onClick: () => this.props.onMobileFiltersOpen(this.mobileFilters)
                        }} />
                    </div>
                </div>
                <div className="electricity-sections-container">
                    <section className="electricity-odd-section">
                        <div className="consumption-chart-header"><h2>{monthChart.title}</h2></div>
                        <div className="electricity-body-scrollable">
                            <div className="electricity-body">
                                <div className="electricity-body-content">
                                    <div className="consumption-chart-body">
                                        <ConsumptionChart {...monthProps}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="electricity-even-section">
                        <div className="consumption-chart-header"><h2>{weekChart.title}</h2></div>
                        <div className="electricity-body-scrollable">
                            <div className="electricity-body">
                                <div className="electricity-body-content">
                                    <div className="consumption-chart-body">
                                        <ConsumptionChart {...weekProps}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="electricity-even-section">
                        <div className="consumption-chart-header"><h2>{dayChart.title}</h2></div>
                        <div className="electricity-body-scrollable">
                            <div className="electricity-body">
                                <div className="electricity-body-content">
                                    <div className="consumption-chart-body">
                                        <ConsumptionChart {...dayProps}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="electricity-footer-section">
                        <div className="electricity-body">
                            <div className="electricity-body-content">
                                <div className="electricity-usage-data">
                                    <div className="electricity-usage-container">
                                        <div className="electricity-usage-header">
                                            {statistics.year.title}
                                        </div>
                                        <div className="electricity-usage-box">
                                            <div className="electricity-usage-number">
                                                {statistics.year.value.toFixed(2)}
                                            </div>
                                            <div className="electricity-usage-unit">
                                                <div className="electricity-usage-unit-top">
                                                    {(statistics.year.text).split(' ')[0]}
                                                </div>
                                                <div className="electricity-usage-unit-bottom">
                                                    {(statistics.year.text).split(' ')[1]}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="electricity-usage-description">
                                            {statistics.year.description}
                                        </div>
                                    </div>

                                    <div className="electricity-usage-container">
                                        <div className="electricity-usage-header">
                                            {statistics.month.title}
                                        </div>
                                        <div className="electricity-usage-box">
                                            <div className="electricity-usage-number">
                                                {statistics.month.value.toFixed(2)}
                                            </div>
                                            <div className="electricity-usage-unit">
                                                <div className="electricity-usage-unit-top">
                                                    {(statistics.month.text).split(' ')[0]}
                                                </div>
                                                <div className="electricity-usage-unit-bottom">
                                                    {(statistics.month.text).split(' ')[1]}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="electricity-usage-description">
                                            {statistics.month.description}
                                        </div>
                                    </div>

                                    <div className="electricity-usage-container">
                                        <div className="electricity-usage-header">
                                            {statistics.distribution.title}
                                        </div>
                                        <div className="electricity-usage-box">
                                            <div className="electricity-usage-box-left">
                                                <div className="electricity-usage-unit">
                                                    <div className="electricity-usage-unit-top">
                                                        %
                                                    </div>
                                                    <div className="electricity-usage-unit-bottom">
                                                        {statistics.distribution.day.text}
                                                    </div>
                                                </div>
                                                <div className="electricity-usage-number">
                                                    {statistics.distribution.day.value.toFixed(0)}
                                                </div>
                                            </div>
                                            <div className="tilted-spacer"/>
                                            <div className="electricity-usage-box-right">
                                                <div className="electricity-usage-number">
                                                    {statistics.distribution.night.value.toFixed(0)}
                                                </div>
                                                <div className="electricity-usage-unit">
                                                    <div className="electricity-usage-unit-top">
                                                        %
                                                    </div>
                                                    <div className="electricity-usage-unit-bottom">
                                                        {statistics.distribution.night.text}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="electricity-usage-description">
                                            {statistics.distribution.description}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>


            </div>
        );
    }
}

export default withScrollingHeader(ConsumptionTab, 0, 96, 80, 52)
