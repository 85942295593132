import React from 'react';
import { connect } from 'react-redux';
import BlackCheckbox from '../../components/BlackCheckbox';
import './JoinVirtualFamily.scss';
import olerexLogo from '../../../assets/Olerex_Logo.svg';
import clipartCrowd from '../../assets/Clipart_Crowd.png';
import iconChevronYellowRight from '../../assets/Icon_Chevron_Yellow-Right.svg';
import IconChevronBlackBack from "../../assets/Icon_Chevron_Black-Back.svg";

const TextField = React.memo(props =>
    <div className={`join-virtual-family_text-field ${props.className ? props.className : ""}`}>
        <p className="join-virtual-family_text-field-label">{props.label}</p>
        <p className="join-virtual-family_text-field-text">{props.text}</p>
    </div>
);

const JoinVirtualFamily = React.memo(props =>
    <div className="join-virtual-family-modal">
        <img className="join-virtual-family-modal_left-img" src={clipartCrowd} alt="" />
        <img className="join-virtual-family-modal_right-img" src={clipartCrowd} alt="" />
        <div className="join-virtual-family">
            <div className="logo-container join-virtual-family-logo">
                <img className="header-logo" src={olerexLogo} alt="" />
            </div>
            <h1 className="join-virtual-family-title">Tere tulemast Ettevõte OÜ Virtuaalperre.</h1>
            <div className="join-virtual-family-card-top">
                <p className="join-virtual-family-card-top_text">{props.translation.topInfo}</p>
            </div>
            <div className="join-virtual-family-card-mid">
                <TextField {...{
                    className: "name-field",
                    label: props.translation.nameField,
                    text: "Kalev Sepp"
                }} />
                <TextField {...{
                    className: "personalcode-field",
                    label: props.translation.personalCodeField,
                    text: "31234567890"
                }} />
                <TextField {...{
                    className: "email-field",
                    label: props.translation.emailField,
                    text: "Kalev.sepp@transpordifirma.ee"
                }} />
            </div>
            <div className="join-virtual-family-card-bot">
                <BlackCheckbox {...{ large: true, checked: true, onChange: () => true }} />
                <span className="join-virtual-family_checkbox-text">{props.translation.checkboxInfo}</span>
            </div>
            <button className="join-virtual-family-modal_cancel-button" onClick={props.onClose}>
                <img className="join-virtual-family-modal_cancel-button_img" src={IconChevronBlackBack} alt="" />
                <span className="join-virtual-family-modal_cancel-button_text">{props.translation.backButton}</span>
            </button>
            <button className="join-virtual-family-modal_join-button">
                <span className="join-virtual-family-modal_join-button_text">{props.translation.joinButton}</span>
                <img className="join-virtual-family-modal_join-button_img" src={iconChevronYellowRight} alt="" />
            </button>
        </div>
    </div>
);

export default connect(state => ({ translation: state.translation.joinVirtualFamily }))(JoinVirtualFamily);