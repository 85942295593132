import React from 'react';
import PropTypes from 'prop-types';
import {hwcrypto} from './hwcrypto';
import {oneOf} from './oneOf';
import {signUrls} from "../../../Networking";
import {connect} from "react-redux";

const createRequestOptions = (type, data) => {
    const formData = new URLSearchParams();
    for ( const key in data ) {
        formData.append(key, data[key]);
    }
    return {
        method: type,
        body: formData
    };
};

export const withDigidoc = (WrappedComponent) => connect(
    state => (
        {
            login: state.login
        }),
    undefined
)(
    class extends React.PureComponent {
        constructor(props) {
            super(props);
            this.eidSign = this.eidSign.bind(this);
            this.eidSignError = this.eidSignError.bind(this);
            this.signSuccess = this.signSuccess.bind(this);
            this.startMIDSign = this.startMIDSign.bind(this);
            this.checkMIDSignStatus = this.checkMIDSignStatus.bind(this);
            this.startSign = this.startSign.bind(this);
            this.keyHandler = this.keyHandler.bind(this);

            this.signingEnumeration = {
                idCard: 0,
                mobiilId: 1,
                smartId: 2,
                smartId2: 3
            };

            this.state = {
                disabled: false,
                status: '',
                message: '',
                timerCode: null,
                inputs: true,
                smartIdRef: false,
                smartIdRef2: false,
                data: null,
                selectedSigningType: null,
                signingEnumeration: this.signingEnumeration,
                finishContractData: null,
                signStartTime: null
            };
        }

        _midTimeout = null;

        signingMethodSelected = (selectedSigningType, callback) => {
            this.setState({ selectedSigningType: selectedSigningType, showError: false, status: '', message: ''});
            callback();
        };

        eidSignContainer = (param) => {
            this.setState({data: param}, this.eidSign(param));
        };

        eidSign = (param) => {
            this.setState({disabled: true});
            hwcrypto.getCertificate({lang: 'et'}).then((certificate) => {
                if (param === null) {
                    param = this.state.data;
                }
                const data = {
                    data: param,
                    token: this.props.login.token,
                    action: 'createeidhash',
                    certificate: certificate.hex
                };

                const requestOptions = createRequestOptions('POST', data);

                fetch(signUrls.idCard, requestOptions)
                    .then (res => res.json())
                    .then( data => {
                        if (data.status !== null && data.status === 1) {
                            const signatureDigest = data.signedinfodigest,
                                signatureID = data.signatureid,
                                signatureHashType = data.hashtype,
                                additionalData = data.additionaldata;

                            hwcrypto.sign(certificate, {
                                hex: signatureDigest,
                                type: signatureHashType
                            }, {lang: 'et'}).then((signature) => {
                                if (param === null) {
                                    param = this.state.data;
                                }
                                const sendData = {
                                    data: param,
                                    action: 'eidsigncomplete',
                                    signature_id: signatureID,
                                    signature_value: signature.hex,
                                    additionaldata: additionalData
                                };
                                const requestOptions = createRequestOptions('POST', sendData);
                                fetch(signUrls.idCard, requestOptions)
                                    .then (res => res.json())
                                    .then (
                                        data => {
                                            if (data.status !== null && data.status === 1) {
                                                this.setState({status: data.content});
                                                this.signSuccess(data.data);
                                            } else {
                                                this.setState({disabled: false, status: 'Viga allkirjastamisel: ' + oneOf(data.error, 'Tundmatu viga')});
                                            }
                                        }
                                    ).catch(err => {
                                        const error = err.statusText || err.toString();
                                        this.setState({status: 'Viga allkirjastamisel: ' + error})
                                    })

                            }, (reason) => {
                                this.eidSignError(reason);
                            });

                        } else {
                            this.setState({
                                disabled: false,
                                status: 'Viga allkirjastamisel: ' + oneOf(data.error, 'Tundmatu viga')
                            });
                        }
                    })
                    .catch( err => {
                        const error = err.statusText || err.toString();
                        this.setState({
                            disabled: false,
                            status: 'Viga allkirjastamisel: ' + error
                        });
                    });
            }, (reason) => {
                this.eidSignError(reason);
            });
        };

        eidSignError = (reason) => {
            let longMessage = 'Viga ID-kaardiga allkirjastamisel: ';

            switch (reason.message) {
                case'no_backend':
                    longMessage += 'Cannot find ID-card browser extensions';
                    break;
                case hwcrypto.USER_CANCEL:
                    longMessage += 'Signing canceled by user';
                    break;
                case hwcrypto.INVALID_ARGUMENT:
                    longMessage += 'Invalid argument';
                    break;
                case hwcrypto.NO_CERTIFICATES_FOUND:
                    longMessage += ' Failed reading ID-card certificates make sure. ID-card reader or ID-card is inserted correctly';
                    break;
                case hwcrypto.NO_IMPLEMENTATION:
                    longMessage += ' Please install or update ID-card Utility or install missing browser extension. More information about on id.installer.ee';
                    break;
                case hwcrypto.TECHNICAL_ERROR:
                    longMessage += 'Unknown technical error occurred';
                    break;
                default:
                    longMessage += 'Unknown error occurred that we can not explain: ' + reason.message;
            }
            this.setState({
                disabled: false,
                status: longMessage
            });
        };

        signSuccess = (response) => {
            this.setState({
                finishContractData: response
            });
        };

        startMIDSignContainer = (param) => {
            this.setState({data: param}, this.startMIDSign(param));
        };

        startMIDSign = (param) => {
            if (param === null) {
                param = this.state.data;
            }
            this.setState({disabled: true});
            const requestOptions = createRequestOptions('POST', {data: param, action: 'startmidsign'});

            fetch(signUrls.mobileId, requestOptions)
                .then (res => res.json())
                .then ( data => {
                    if (data.status !== null && data.status === 1) {
                        this._midTimeout = setTimeout(() => {this.checkMIDSignStatus(param, data.additionaldata)}, 5000);
                        this.setState({
                            smartIdRef2: true,
                            timerCode: data.content,
                            selectedSigningType: this.signingEnumeration.smartId2,
                            signStartTime: Date.now()
                        });
                    } else if (data.status !== null && data.status === 3 && data.nouser === 1) {
                        clearTimeout(this._midTimeout);
                        this.setState({signStartTime: null, selectedSigningType: null, timerCode: null});
                    } else {
                        clearTimeout(this._midTimeout);
                        this.setState({signStartTime: null, selectedSigningType: null, timerCode: null});
                    }
                })
                .catch( err => {
                    const error = err.statusMessage || err;
                    clearTimeout(this._midTimeout);
                    this.setState({
                        disabled: false,
                        status: 'Viga allkirjastamisel: ' + error,
                        signStartTime: null,
                        selectedSigningType: null,
                        timerCode: null
                    });
                });
        };

        checkMIDSignStatus = (param, additionaldata) => {
            if (param === null) {
                param = this.state.data;
            }
            const requestOptions = createRequestOptions('POST', {data: param, action: 'checkmidsignstatus', additionaldata: additionaldata});

            fetch(signUrls.mobileId, requestOptions)
                .then ( res => res.json())
                .then( data => {
                    if (data.status !== null && data.status === 1) {
                        if (data.signstatus !== null && data.signstatus === 'pending') {
                            this._midTimeout = setTimeout(() => {this.checkMIDSignStatus(param, additionaldata)}, 3000);
                        } else if (data.signed !== null && data.signed > 0) {
                            clearTimeout(this._midTimeout);
                            this.setState({
                                message: 'success',
                                inputs: false,
                                smartIdRef: true
                            });
                            this.signSuccess(data.data);
                        } else {
                            clearTimeout(this._midTimeout);
                            this.setState({
                                message: data.content,
                                inputs: false,
                                smartIdRef: true,
                                signStartTime: null,
                                selectedSigningType: null,
                                timerCode: null
                            });
                        }

                    } else {
                        clearTimeout(this._midTimeout);
                        this.setState({
                            message: data.error,
                            inputs: false,
                            smartIdRef: true,
                            signStartTime: null,
                            selectedSigningType: null,
                            timerCode: null,
                            disabled: false
                        });
                    }
                })
                .catch( error => {
                    clearTimeout(this._midTimeout);
                    this.setState({disabled: false, signStartTime: null, selectedSigningType: null, timerCode: null});
                });
        };

        startSignContainer = (param) => {
            this.setState({data: param}, this.startSign('init', param, null));
        };

        startSign = (action, param, additionaldata) => {
            if (action === null) {
                action = 'init';
            }
            if (param === null) {
                param = this.state.data;
            }

            this.setState({disabled: true});

            const data = {
                data: param,
                action: action,
                additionaldata: additionaldata,
                lang: 'et'
            };
            if (additionaldata === null) {
                delete data.additionaldata;
            }

            const requestOptions = createRequestOptions('POST', data);

            fetch(signUrls.smartId, requestOptions)
                .then ( res => res.json())
                .then(data => {
                    if (data !== null && data.status === '1') {
                        if (data.checkverificationcode !== null && data.checkverificationcode > 0) {
                            setTimeout(() => {
                                this.startSign('status', param, data.additionaldata);
                            }, (parseInt(data.checkverificationcode) * 500));
                        }

                        if (data.checkstatus !== null && data.checkstatus > 0) {
                            setTimeout(() => {
                                this.startSign('pending', param, data.additionaldata);
                            }, (parseInt(data.checkstatus) * 1000));
                        }

                        if (data.content !== null && data.content !== '') {
                            this.setState({
                                inputs: false,
                                smartIdRef2: true,
                                selectedSigningType: this.signingEnumeration.smartId2,
                                timerCode: data.content,
                                signStartTime: Date.now()
                            });
                        }

                        if (data.signed !== null && data.signed > 0) {
                            this.signSuccess(data.data);
                            this.setState({
                                timerCode: 'success',
                                signStartTime: null
                            });
                        }

                    } else {
                        this.setState({
                            disabled: false,
                            message: data.error,
                            inputs: false,
                            smartIdRef: true,
                            signStartTime: null
                        });
                    }
                })
                .catch( err => {
                    const error = err.statusText || err.toString();
                    this.setState({
                        disabled: false,
                        message: error,
                        signStartTime: null
                    });
                });
        };

        keyHandler = (event) => {
            if (event.which === 13) {
                this.startSign();
            }
        };

        render() {
            const olerexSign = {
                idCard: {
                    eidSign: this.eidSignContainer,
                    eidSignError: this.eidSignError
                },
                mobileId: {
                    startMIDSign: this.startMIDSignContainer,
                    checkMIDSignStatus: this.checkMIDSignStatus
                },
                smartId: {
                    keypress: this.keyHandler,
                    startSign: this.startSignContainer
                },
                signSuccess: this.signSuccess,
                signingMethodSelected: this.signingMethodSelected
            };
            return (
                <React.Fragment>
                    <WrappedComponent {...{ ...this.props, ...this.state, ...olerexSign}} />
                </React.Fragment>
            );
        }
    }
);

withDigidoc.propTypes = {
    data: PropTypes.any,
    onCloseButtonClick: PropTypes.func.isRequired
};
