import React from 'react';
import "./ContentContainer.scss";

const ContentContainer = React.memo(props =>
    <div style={props.style} className={`co-content-container ${props.className ? props.className : ""}`} id={props.id}
         onClick={(e) => e.stopPropagation()}>
            <div className={`co-content-container_title`}>
                    {props.title}
            </div>
            <div className={`co-content-container_action`}>
                    {props.action}
            </div>
            <div className={`co-content-container_content`}>
                    {props.content}
            </div>
    </div>
);

export default ContentContainer;
