import React from 'react';

import styles from './Textarea.module.scss';

const Textarea = props => (
    <div className={styles.container}>
        <textarea {...props}></textarea>
        {props.maxLength && <div className={styles.hint}>{props.value.length}/{props.maxLength}</div>}
    </div>
);

export default Textarea;
