import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Header } from './components/Header';
import { VirtualFamilies } from './components/VirtualFamilies';
import { roleOrLanguageChanged } from '../../../../Utils';
import { url, businessVirtualFamilyRequest } from '../../../../Networking';
import Members from './components/Members';
import Links from './components/Links';
import './VirtualFamily.scss';

class VirtualFamily extends PureComponent {
    state = {
        footerAdvertisement: null,
        families: null,
        members: {
            items: null,
            infoBoxes: null,
            pages: null,
            selectedItemsPerPage: null,
            selectedPage: null,
            orderBy: null,
            orderHow: null,
            filters: {
                name: "",
                personalCode: "",
                email: "",
                status: "",
            },
            filtersCopy: {
                name: "",
                personalCode: "",
                email: "",
                status: "",
            },
            isLoaderVisible: false
        },
        links: {
            items: null,
            pages: null,
            selectedItemsPerPage: null,
            selectedPage: null,
            orderBy: null,
            orderHow: null,
            filters: {
                link: "",
                description: "",
                status: ""
            },
            filtersCopy: {
                link: "",
                description: "",
                status: "",
                //expires: ""
            },
            isLoaderVisible: false
        },
        isLoaderVisible: false
    };

    downloadData = () => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        this.setState({ isLoaderVisible: true }, async () => {
            // await new Promise(x => setTimeout(x, 5000));
            this.props.virtualFamilyRequest(
                {
                    "requestType": "familyList",
                    "accNr": accnr,
                    "token": this.props.login.token
                },
                data => {
                    const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                    if (!_accnr || _accnr !== accnr) {
                        return; //role changed
                    }
                    this.setState({
                        footerAdvertisement: data.records.footerAdvertisement,
                        families: data.records.families,
                        isLoaderVisible: false
                    });
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        });
    }

    onMemberCheckedChanged = (index, checked) => {
        this.setState({
            members: {
                ...this.state.members,
                items: [
                    ...this.state.members.items.slice(0, index),
                    { ...this.state.members.items[index], checked },
                    ...this.state.members.items.slice(index + 1)
                ]
            }
        });
    }

    onMembersUnchecked = () => {
        this.setState({
            members: {
                ...this.state.members,
                items: this.state.members.items.map(x => ({ ...x, checked: false }))
            }
        })
    }

    onMembersPageSelected = (page) => {
        this.setState({ members: { ...this.state.members, selectedPage: page } }, this.downloadVirtualFamilyMembers);
    }

    onMembersColumnFilterSelected = (orderHow, orderBy, filter, search) => {
        const searchChanged = this.state.members.filters[filter] !== search;
        this.setState({ members: { ...this.state.members, orderHow, orderBy, filters: { ...this.state.members.filters, [filter]: search } } }, async () => {
            if (searchChanged) {
                await new Promise(x => setTimeout(x, 1000));
            }
            if (this.state.members.filters[filter] === search && this.state.members.orderHow === orderHow && this.state.members.orderBy === orderBy) {
                this.downloadVirtualFamilyMembers();
            }
        });
    }
    onModalClose = (memberDisabled = false) => {
        if (memberDisabled) {
            this.downloadVirtualFamilyMembers();
        }
        this.props.onModalClose();
    }

    downloadVirtualFamilyMembers = () => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        const familyId = this.props.match.params.id;
        if (!familyId) {
            return; //no family id
        }
        this.setState({ members: { ...this.state.members, isLoaderVisible: true } }, async () => {
            // await new Promise(x => setTimeout(x, 5000));
            this.props.virtualFamilyRequest({
                "requestType": "familyView",
                "accNr": accnr,
                "token": this.props.login.token,
                "familyId": familyId,
                "page": this.state.members.selectedPage,
                "itemsPerPage": this.state.members.selectedItemsPerPage,
                "orderBy": this.state.members.orderBy,
                "orderHow": this.state.members.orderHow,
                "filters": this.state.members.filters,
            }, data => {
                const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                if (!_accnr || _accnr !== accnr) {
                    return; //role changed
                }
                const members = data.records.family.members.map((item, index) => ({
                    ...item,
                    checked: false,
                    onCheckedChanged: (checked) => this.onMemberCheckedChanged(index, checked)
                }));
                this.setState({
                    isLoaderVisible: false,
                    members: {
                        ...this.state.members,
                        items: members,
                        infoBoxes: data.records.infoBoxes,
                        pages: data.records.pages,
                        selectedPage: data.records.selectedPage,
                        selectedItemsPerPage: data.records.selectedItemsPerPage,
                        selectedOrderWhat: data.records.orderBy,
                        orderHow: data.records.orderHow,
                        filters: data.records.filters,
                        filtersCopy: data.records.filters,
                        isLoaderVisible: false
                    }
                });
            }, error => {
                this.setState({ members: { ...this.state.members, isLoaderVisible: false } });
            });
        });
    }

    onLinksPageSelected = (page) => {
        this.setState({ links: { ...this.state.links, selectedPage: page } }, () => this.downloadVirtualFamilyInvites());
    }

    onLinksColumnFilterSelected = (orderHow, orderBy, filter, search) => {
        const searchChanged = this.state.links.filters[filter] !== search;
        this.setState({ links: { ...this.state.links, orderHow, orderBy, filters: { ...this.state.links.filters, [filter]: search } } }, async () => {
            if (searchChanged) {
                await new Promise(x => setTimeout(x, 1000));
            }
            if (this.state.links.filters[filter] === search && this.state.links.orderHow === orderHow && this.state.links.orderBy === orderBy) {
                this.downloadVirtualFamilyInvites();
            }
        });
    }

    downloadVirtualFamilyInvites = () => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        const familyId = this.props.match.params.id;
        if (!familyId) {
            return; //no family id
        }
        this.setState({ links: { ...this.state.links, isLoaderVisible: true } }, async () => {
            // await new Promise(x => setTimeout(x, 5000));
            this.props.virtualFamilyRequest({
                "requestType": "familyLinks",
                "accNr": accnr,
                "token": this.props.login.token,
                "familyId": familyId,
                "page": this.state.links.selectedPage,
                "itemsPerPage": this.state.links.selectedItemsPerPage,
                "orderBy": this.state.links.orderBy,
                "orderHow": this.state.links.orderHow,
                "filters": this.state.links.filters
            }, data => {
                const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                if (!_accnr || _accnr !== accnr) {
                    return; //role changed
                }
                const invites = data.records.invites.map((item, index) => ({
                    ...item,
                    // checked: false,
                    // onCheckedChanged: (checked) => this.onMemberCheckedChanged(index, checked)
                }));
                this.setState({
                    links: {
                        ...this.state.links,
                        items: invites,
                        pages: data.records.pages,
                        selectedPage: data.records.selectedPage,
                        selectedItemsPerPage: data.records.selectedItemsPerPage,
                        orderBy: data.records.orderBy,
                        orderHow: data.records.orderHow,
                        filters: data.records.filters,
                        filtersCopy: data.records.filters,
                        isLoaderVisible: false
                    }
                });
            }, error => {
                this.setState({ links: { ...this.state.links, isLoaderVisible: false } });
            });
        });
    }

    componentDidMount() {
        this.downloadData();
        const familyId = this.props.match.params.id;
        if (familyId) {
            if (this.props.invitesInPathname) {
                this.downloadVirtualFamilyInvites();
            } else {
                this.downloadVirtualFamilyMembers();
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (roleOrLanguageChanged(prevProps, this.props)) {
            this.downloadData();
        }
        const familyId = this.props.match.params.id;
        const pathname = this.props.pathname;
        if (prevProps.match.params.id !== familyId ||
            prevProps.pathname !== pathname) {
            if (this.props.invitesInPathname) {
                this.downloadVirtualFamilyInvites();
            } else {
                this.downloadVirtualFamilyMembers();
            }
        }
    }

    downloadExcel = () => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        const familyId = this.props.match.params.id;
        const { orderBy, orderHow } = this.state.members;
        const { name, personalCode, email, status } = this.state.members.filters;
        window.open(encodeURI(`${url}&act=olerexweb.business.virtualfamily&requestType=familyView&token=${this.props.login.token}&accNr=${accnr}&familyId=${familyId}&orderBy=${orderBy}&orderHow=${orderHow}&name=${name}&personalCode=${personalCode}&email=${email}&status=${status}&excel=1`));
    }

    render() {
        const familyId = this.props.match.params.id;
        const selectedFamily = this.state.families && this.state.families.find(x => x.familyId === familyId);
        const familiesSelected = (selectedFamily === undefined || selectedFamily === null) && !familyId;
        const familiesNoContentVisible = familiesSelected && (!this.state.families || this.state.families.length === 0);
        const membersSelected = familyId && !this.props.invitesInPathname;
        const invitesSelected = familyId && this.props.invitesInPathname;
        const showDownloadExcelButton = membersSelected && Array.isArray(this.state.members.items) && this.state.members.items.length > 0;

        const footer = this.state.footerAdvertisement;
        const footerColor = (footer && footer.backgroundHex) || "#000000";
        const footerImg = footer && footer.image;
        const footerUrl = footer && footer.imageUrl;

        const familyAdVisible = familiesSelected;
        return (
            <div className="virtual-family-page">
                <div className="virtual-family-page-header-background"></div>
                <Header {...{
                    pathname: this.props.pathname,
                    selectedFamily,
                    familyId,
                    downloadExcel: this.downloadExcel,
                    onModalOpen: this.props.onModalOpen,
                    onModalClose: this.props.onModalClose,
                    onNewLinkAdded: this.downloadVirtualFamilyInvites,
                    onNewFamilyAdded: this.downloadData,
                    showDownloadExcelButton
                }} />
                <TransitionGroup component={null}>
                    <CSSTransition key={"body-content"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <div className="virtual-family-page-body">
                            <TransitionGroup component={null}>
                                {familiesSelected &&
                                    <CSSTransition key={"table-content"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                        <VirtualFamilies {...{
                                            families: this.state.families,
                                            isLoaderVisible: this.state.isLoaderVisible,
                                            isNoContentVisible: familiesNoContentVisible,
                                            familyNameUpdated: this.downloadData,
                                            onModalOpen: this.props.onModalOpen,
                                            onModalClose: this.props.onModalClose,
                                        }} />
                                    </CSSTransition>}
                                {membersSelected &&
                                    <CSSTransition key={"members"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                        <Members {...{
                                            familyId,
                                            members: this.state.members.items,
                                            infoBoxes: this.state.members.infoBoxes,
                                            onMembersUnchecked: this.onMembersUnchecked,
                                            onTableColumnFilterSelected: this.onMembersColumnFilterSelected,
                                            onPageSelected: this.onMembersPageSelected,
                                            selectedPage: this.state.members.selectedPage,
                                            pages: this.state.members.pages,
                                            filters: this.state.members.filters,
                                            filtersCopy: this.state.members.filtersCopy,
                                            selectedOrderWhat: this.state.members.orderBy,
                                            selectedOrderHow: this.state.members.orderHow,
                                            onModalOpen: this.props.onModalOpen,
                                            onModalClose: this.onModalClose,
                                            isLoaderVisible: this.state.members.isLoaderVisible,
                                        }} />
                                    </CSSTransition>}
                                {invitesSelected &&
                                    <CSSTransition key={"invites"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                        <Links {...{
                                            familyId,
                                            links: this.state.links.items,
                                            onTableColumnFilterSelected: this.onLinksColumnFilterSelected,
                                            onPageSelected: this.onLinksPageSelected,
                                            selectedPage: this.state.links.selectedPage,
                                            pages: this.state.links.pages,
                                            filters: this.state.links.filters,
                                            filtersCopy: this.state.links.filtersCopy,
                                            selectedOrderWhat: this.state.links.orderBy,
                                            selectedOrderHow: this.state.links.orderHow,
                                            onModalOpen: this.props.onModalOpen,
                                            onModalClose: this.props.onModalClose,
                                            isLoaderVisible: this.state.links.isLoaderVisible,
                                            inviteToggled: this.downloadVirtualFamilyInvites,
                                            onLinkUpdated: this.downloadVirtualFamilyInvites
                                        }} />
                                    </CSSTransition>}
                            </TransitionGroup>
                        </div>
                    </CSSTransition>
                    {familyAdVisible && <CSSTransition key={"ad-background"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <div className="virtual-family-page-ad-background" style={{ background: footerColor }}></div>
                    </CSSTransition>}
                    {familyAdVisible && <CSSTransition key={"ad-content"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <div className="virtual-family-page-ad">
                            <a href={footerUrl} target="_blank" rel="noopener noreferrer">
                                <img className="virtual-family-page-ad_img" src={footerImg} alt="advertisement" />
                            </a>
                        </div>
                    </CSSTransition>}
                </TransitionGroup>
            </div>
        );
    }
}
VirtualFamily.propTypes = {
    onModalOpen: PropTypes.func,
    onModalClose: PropTypes.func
};
export default connect(
    state => ({ login: state.login, translation: state.translation.virtualFamily, pathname: state.router.location.pathname, invitesInPathname: state.router.location.pathname.includes("/invites") }),
    dispatch => ({ virtualFamilyRequest: (body, success, error) => businessVirtualFamilyRequest(dispatch, body, success, error) }))(VirtualFamily);