import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ExpandableContent from '../../../components/ExpandableContent';
import { TransactionsTableSmall } from './TransactionsTable';
import "./TransactionsContent.scss";
import IconDownload from '../../../assets/Icon_Download.svg';
import IconChevronDownBlack16px from '../../../assets/Icon_Chevron-Down_Black-16px.svg';
import IconChevronUpYellow16px from '../assets/Icon_Chevron-Up_Yellow-16px.svg';
import Tippy from '@tippy.js/react';

class TransactionItem extends PureComponent {
    render() {
        const height = this.props.purchases.length ? 48 + (80 * this.props.purchases.length) + 2 : undefined; //pad + items + extra for border
        return (
            <>
                <div className={`transaction-collection-item ${this.props.isExpanded ? "expanded" : ""}`}>
                    <h1 className="transaction-collection-item_title">
                        <span className="transaction-collection-item_title-text">{this.props.invoiceId}</span>
                        {this.props.pdfUploaded && <button className="transaction-collection-item_download-button" onClick={this.props.onDownloadButtonClick}>
                        <Tippy arrow={true}
                          isEnabled={true}
                          content={this.props.downloadTooltip || ""}
                        >
                          <img className="transaction-collection-item_download-button_img" src={IconDownload} alt="download" />
                        </Tippy>    
                        </button>}
                    </h1>
                    <p className="transaction-collection-item_time">{this.props.saleDateTime}</p>
                    <p className="transaction-collection-item_text-station_name">{this.props.stationName}</p>
                    <p className="transaction-collection-item_text-refueling_parameter">{this.props.refuelingParameter}</p>
                    <p className="transaction-collection-item_price">{this.props.totalFullAmount}</p>
                    <div className="transaction-collection-item_divider"></div>
                    <button className={`transaction-collection-item_dropdown-button ${this.props.isExpanded ? "expanded" : ""}`} onClick={this.props.onExpand}>
                        <img className="transaction-collection-item_arrow"
                            src={this.props.isExpanded ? IconChevronUpYellow16px : IconChevronDownBlack16px}
                            alt={this.props.isExpanded ? "collapse" : "expand"} />
                    </button>
                </div>
                <TransitionGroup component={null}>
                    {this.props.isExpanded && <CSSTransition key={"transactions-table-small"} timeout={{ enter: 600, exit: 600 }} classNames={"height"}>
                        <div className="transactions-table-small-container" style={{ height: height ? `${height}px` : undefined }}>
                            <TransactionsTableSmall {...{ items: this.props.purchases }} />
                        </div>
                    </CSSTransition>}
                </TransitionGroup>
            </>
        );
    }
}


const TransactionsContent = React.memo(props => {
    const height = props.items ? props.items
        .map(x => {
            if (x.isExpanded) {
                const tableHeight = x.purchases ? 48 + (80 * x.purchases.length) + 2 : 0; //pad + items + extra for border
                return 103 + 8 + tableHeight;
            }
            return 103 + 8;
        })
        .reduce((x, y) => x + y, 0) : 0;
    return (
        <ExpandableContent className="transactions_expandable-collection" {...{
            title: <h1 className="transactions_expandable-collection_title">{props.title}</h1>,
            content: <div className="transactions_collection">
                {props.items && props.items.map((item, index) => <TransactionItem {...{
                    ...item,
                    key: index,
                    onExpand: () => props.onExpandListSmallTable(props.title, index, !item.isExpanded),
                    downloadTooltip: props.downloadTooltip
                }} />)}
            </div>,
            height: height
        }} />
    )
});

TransactionsContent.propTypes = {
    onExpandListSmallTable: PropTypes.func.isRequired
}

export default TransactionsContent;