import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ContentContainer from '../../../components/ContentContainer';
import OutlinedTextButton from '../../components/OutlinedTextButton';
import Loader from '../../../components/Loader';
import { TableDataCell, TableHeaderCell } from '../../../components/Table';
import { Modal, ModalInputText, ModalInputSwitch3, ModalText } from "./Modal";
import './Users.scss';
import IconUsers38px from '../../../assets/Icon_Users-38px.svg';
import IconPencil from '../../../assets/Icon_Pencil.svg';
import IconDelete from '../../../assets/Icon_Delete.svg';

const AddUser = connect(state => ({ translation: state.translation.manage.users.addUserModal }), null)(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            name: "", email: "", personalCode: "", userLevel: "1",
            isLoaderVisible: false, isDone: false, isError: false, result: null
        };
    }

    addUser = (name, email, personalCode, userLevel) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        if (this.state.isLoaderVisible) {
            return;
        }
        this.setState({ isLoaderVisible: true }, async () => {
            // await new Promise(x => setTimeout(x, 500000));
            this.props.manageRequest(
                {
                    "action": "addUser",
                    "accNr": accnr,
                    "token": this.props.login.token,
                    "clientUserName": name,
                    "clientUserEmail": email,
                    "clientUserLevelId": userLevel,
                    "clientUserPersonalCode": personalCode,
                },
                data => {
                    const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                    if (!_accnr || _accnr !== accnr) {
                        return; //role changed
                    }
                    this.setState({
                        result: data.records.result,
                        isDone: data.records.statusCode === 200,
                        isError: data.records.statusCode !== 200,
                        isLoaderVisible: false
                    });

                    if (typeof this.props.onDataChanged === 'function') {
                        setTimeout(this.props.onDataChanged, 0);
                    }
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        })
    }

    onFieldChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    render() {
        return (
            <Modal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onModalClose,
                content:
                    <>
                        <ModalInputText {...{
                            text: this.props.translation.nameField,
                            id: "name",
                            value: this.state.name,
                            onChange: this.onFieldChange
                        }} />
                        <ModalInputText {...{
                            text: this.props.translation.emailField,
                            id: "email",
                            value: this.state.email,
                            onChange: this.onFieldChange
                        }} />
                        <ModalInputSwitch3 {...{
                            text: this.props.translation.roleField,
                            item1: this.props.translation.role1,
                            item1Click: () => this.setState({ userLevel: 1 }),
                            item2: this.props.translation.role2,
                            item2Click: () => this.setState({ userLevel: 2 }),
                            item3: this.props.translation.role3,
                            item3Click: () => this.setState({ userLevel: 3 }),
                            active: this.state.userLevel
                        }} />
                        <ModalInputText {...{
                            text: this.props.translation.personalCodeField,
                            id: "personalCode",
                            value: this.state.personalCode,
                            onChange: this.onFieldChange
                        }} />
                    </>,
                isSaveButton: true,
                onActionButtonClick: () => this.addUser(this.state.name, this.state.email, this.state.personalCode, this.state.userLevel),
                actionButtonTitle: this.props.translation.saveButton,
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 336,
            }} />
        )
    }
})

const EditUser = connect(state => ({ translation: state.translation.manage.users.editUserModal }), null)(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id, name: props.name, email: props.email, phone: props.phone, personalCode: props.personalCode, userLevel: props.userLevel,
            isLoaderVisible: false, isDone: false, isError: false, result: null
        };
    }

    editUser = (id, name, email, personalCode, userLevel) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        if (this.state.isLoaderVisible) {
            return;
        }
        this.setState({ isLoaderVisible: true }, async () => {
            // await new Promise(x => setTimeout(x, 500000));
            this.props.manageRequest(
                {
                    "action": "editUser",
                    "accNr": accnr,
                    "token": this.props.login.token,
                    "clientUserId": id,
                    "clientUserName": name,
                    "clientUserEmail": email,
                    "clientUserLevelId": userLevel,
                    "clientUserPersonalCode": personalCode,
                },
                data => {
                    const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                    if (!_accnr || _accnr !== accnr) {
                        return; //role changed
                    }
                    this.setState({
                        result: data.records.result,
                        isDone: data.records.statusCode === 200,
                        isError: data.records.statusCode !== 200,
                        isLoaderVisible: false
                    });

                    if (typeof this.props.onDataChanged === 'function') {
                        setTimeout(this.props.onDataChanged, 0);
                    }
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        })
    }

    onFieldChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    render() {
        return (
            <Modal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onModalClose,
                content:
                    <>
                        <ModalInputText {...{
                            text: this.props.translation.nameField,
                            id: "name",
                            value: this.state.name,
                            onChange: this.onFieldChange
                        }} />
                        <ModalInputText {...{
                            text: this.props.translation.emailField,
                            id: "email",
                            value: this.state.email,
                            onChange: this.onFieldChange
                        }} />
                        <ModalInputSwitch3 {...{
                            text: this.props.translation.roleField,
                            item1: this.props.translation.role1,
                            item1Click: () => this.setState({ userLevel: 1 }),
                            item2: this.props.translation.role2,
                            item2Click: () => this.setState({ userLevel: 2 }),
                            item3: this.props.translation.role3,
                            item3Click: () => this.setState({ userLevel: 3 }),
                            active: this.state.userLevel
                        }} />
                        <ModalInputText {...{
                            text: this.props.translation.personalCodeField,
                            id: "personalCode",
                            value: this.state.personalCode,
                            onChange: this.onFieldChange
                        }} />
                    </>,
                isSaveButton: true,
                onActionButtonClick: () => this.editUser(this.state.id, this.state.name, this.state.email, this.state.personalCode, this.state.userLevel),
                actionButtonTitle: this.props.translation.saveButton,
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 336,
            }} />
        )
    }
});

const DeleteUsers = connect(state => ({ translation: state.translation.manage.users.deleteUsersModal }), null)(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { id: props.id, isLoaderVisible: false, isDone: false, isError: false, result: null };
    }

    deleteUser = (id) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        if (this.state.isLoaderVisible) {
            return;
        }
        this.setState({ isLoaderVisible: true }, async () => {
            // await new Promise(x => setTimeout(x, 500000));
            this.props.manageRequest(
                {
                    "action": "deleteUser",
                    "accNr": accnr,
                    "token": this.props.login.token,
                    "clientUserId": id
                },
                data => {
                    const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                    if (!_accnr || _accnr !== accnr) {
                        return; //role changed
                    }
                    this.setState({
                        result: data.records.result,
                        isDone: data.records.statusCode === 200,
                        isError: data.records.statusCode !== 200,
                        isLoaderVisible: false
                    });

                    if (typeof this.props.onDataChanged === 'function') {
                        setTimeout(this.props.onDataChanged, 0);
                    }
                },
                error => {
                    this.setState({ isLoaderVisible: false });
                });
        })
    }

    render() {
        return (
            <Modal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onModalClose,
                content: <ModalText {...{ text: this.props.translation.content(this.props.name) }} />,
                isSaveButton: false,
                onActionButtonClick: () => this.deleteUser(this.state.id),
                actionButtonTitle: this.props.translation.deleteButton,
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 56
            }} />
        )
    }
});

const UsersTableDataRow = React.memo(props =>
    <tr className="table-body-row users">
        <TableDataCell {...{ className: "left small-table-first-col users", content: <span className="table-text-14px-bold">{props.name}</span> }} />
        <TableDataCell {...{
            className: "left users",
            content: <span className="table-text-14px-regular">{props.personalCode}</span>
        }} />
        <TableDataCell {...{
            className: "left users",
            content: <span className="table-text-14px-regular">{props.email}</span>
        }} />
        <TableDataCell {...{
            className: "left users",
            content: <span className="table-text-14px-regular">{props.role}</span>

        }} />
        <TableDataCell {...{
            className: "right users users-table-data-row-actions",
            content:
                <>
                    <button className="users-table-data-row_button edit" onClick={props.onEditButtonClick}>
                        <img className="users-table-data-row_button_img" src={IconPencil} alt="edit user" />
                    </button>
                    <button className="users-table-data-row_button delete" onClick={props.onDeleteButtonClick}>
                        <img className="users-table-data-row_button_img" src={IconDelete} alt="delete user" />
                    </button>
                </>
        }} />
    </tr>
);

const UsersTableHeaderRow = connect(state => ({ translation: state.translation.manage.users.table }), null)(React.memo(props =>
    <tr className="table-header-row users">
        <TableHeaderCell {...{ className: "left small-table-first-col users", text: props.translation.nameColumn, orderWhat: "name", ...props }} />
        <TableHeaderCell {...{ className: "left users", text: props.translation.personalCodeColumn, orderWhat: "personalCode", ...props }} />
        <TableHeaderCell {...{ className: "left users", text: props.translation.emailColumn, orderWhat: "email", ...props }} />
        <TableHeaderCell {...{ className: "left users", text: props.translation.roleColumn, orderWhat: "role", ...props }} />
        <TableHeaderCell {...{ className: "small left users", text: "" }} />
    </tr>
));

//table-wrapper scrollable table
export const UsersTable = React.memo(props =>
    <div className="table-wrapper manage-users-table">
        <table className="table">
            <thead>
                <UsersTableHeaderRow {...{
                    selectedOrderHow: props.selectedOrderHow,
                    selectedOrderWhat: props.selectedOrderWhat,
                    onTableColumnFilterSelected: props.onTableColumnFilterSelected
                }} />
            </thead>
            <tbody>
                {props.users && props.users.map((item, index) => <UsersTableDataRow {...{
                    ...item,
                    key: index,
                    onEditButtonClick: () => props.onModalOpen(<EditUser {...{ ...props, ...item }} />),
                    onDeleteButtonClick: () => props.onModalOpen(<DeleteUsers {...{ ...props, ...item }} />)
                }} />)}
            </tbody>
        </table>
    </div>
);

const Users = connect(state => ({ translation: state.translation.manage.users }), null)(React.memo(props =>
    <div className="manage-users" id="users">
        <ContentContainer {...{
            title:
                <p className="manage-users_label">
                    <img className="manage-users_label-img" src={IconUsers38px} alt="" />
                    <span className="manage-users_label-text">{props.translation.title}</span>
                </p>,
            action: <OutlinedTextButton {...{
                className: "manage-users_button-add",
                text: props.translation.addButton,
                onClick: () => props.onModalOpen(<AddUser {...props} />)
            }} />,
            content:
                <div className="manage-users-content">
                    <TransitionGroup component={null}>
                        {props.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <Loader {...{ className: "manage-users-loader" }} />
                        </CSSTransition>}
                        {props.users && <CSSTransition key={"olerex-plus-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <UsersTable {...props} />
                        </CSSTransition>}
                    </TransitionGroup>
                </div>
        }} />
    </div>
));

export default Users;
