import React from 'react';
import { connect } from 'react-redux';
import { withCloseOnEsc } from './withCloseOnEsc';
import BlackCheckbox from '../components/BlackCheckbox';
import { banklinksCheckbox } from '../../../state/Login';
import './SelectModal.scss';
import IconChevronRightGray16px from '../assets/Chevron_Right.svg';
import IconModalCross16px from '../assets/Icon_Modal-Cross_Black_16px.svg';

const SelectModalItem = React.memo(props =>
    <div className={`select-modal-item ${props.disabled ? "disabled" : ""}`} onClick={props.disabled ? null : props.onClick}>
        <img className={`select-modal-item-img${props.text ? '' : ' notext'}`} src={props.img} alt="" />
{props.text && <p className="select-modal-item-text">
            <span className="select-modal-item-text-primary">{props.text}</span>
            {props.secondary && <><br></br><span className="select-modal-item-text-secondary">{props.secondary}</span></>}
        </p>}
        <img className="select-modal-item-chevron" src={IconChevronRightGray16px} alt="" />
    </div>
);

const SelectModalCollection = React.memo(props =>
    <div className="select-modal-collection">
        {props.items && props.items.map((item, index) => <SelectModalItem {...{ ...item, disabled: false, key: index }} />)}
    </div>
);

const SelectModal = React.memo(props =>
    <div className="select-modal" onClick={(e) => e.stopPropagation()}>
        <div className="select-modal-header-background"></div>
        <h1 className="select-modal-title">{props.title}</h1>
        <button className="select-modal-close-button" onClick={props.onCloseButtonClick}>
            <img className="select-modal-close-button_img" src={IconModalCross16px} alt="close" />
        </button>
        <SelectModalCollection {...{ items: props.items }} />
    </div>
);

const BankLinksSelectModalCollection = connect(
    state => ({
        translation: state.translation.bankLinks,
        banklinksCheckbox: state.login.banklinksCheckbox
    }),
    dispatch => ({ onCheck: (checked) => dispatch(banklinksCheckbox(checked)) }))(React.memo(props =>
        <div className="select-modal-collection">
            <div className="select-modal-collection-bank-links-checkbox">
                <BlackCheckbox {...{ checked: props.banklinksCheckbox, onChange: (e) => props.onCheck(e.target.checked) }} />
                <span className="select-modal-collection-bank-links-checkbox-text">{props.translation.checkboxText} <a className="select-modal-collection-bank-links-checkbox-text-url" href={props.translation.termsOfSaleUrl} target="_blank" rel="noopener noreferrer">{props.translation.termsOfSaleLink}</a>
                </span>
            </div>
            {props.items && props.items.map((item, index) => <SelectModalItem {...{ ...item, disabled: !props.banklinksCheckbox, key: index }} />)}
        </div>
    ));

export const BankLinksSelectModal = withCloseOnEsc(React.memo(props =>
    <div className="select-modal" onClick={(e) => e.stopPropagation()}>
        <div className="select-modal-header-background"></div>
        <h1 className="select-modal-title">{props.title}</h1>
        <button className="select-modal-close-button" onClick={props.onCloseButtonClick}>
            <img className="select-modal-close-button_img" src={IconModalCross16px} alt="close" />
        </button>
        <BankLinksSelectModalCollection {...{ items: props.items }} />
    </div>
));

export default withCloseOnEsc(SelectModal);