import React from 'react';
import './NavTitle.scss';
import olerexLogo from '../../assets/Olerex_Logo.svg';

const NavTitle = React.memo(props => {
    const isWhiteLogo = props.pathname === "/vouchers" ||
        props.pathname === "/transactions" ||
        props.pathname === "/idcredit" ||
        props.pathname === "/cards" ||
        props.pathname === "/invoicing" ||
        props.pathname === "/manage" ||
        props.pathname === "/virtualfamily" ||
        props.pathname === "/invoicing/invoices";
    return (
        <div className="nav-title">
            <div className={`logo-container ${isWhiteLogo ? "slide-left" : ""}`}>
                <img className="header-logo" src={olerexLogo} alt="" />
            </div>
            <div className="menu-button">
                <input className="checkbox" type="checkbox" checked={props.isDrawerOpen} onChange={(e) => props.onDrawerToggle(e.target.checked)} />
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
        </div>
    )
});

export default NavTitle;
