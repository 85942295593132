import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { BackgroundTable, TableDataCell, TableHeaderCell, withBackgroundTableHighlighting } from '../../../components/Table';
import { businessVirtualFamilyRequest } from '../../../../../Networking';
import Loader from '../../../components/Loader';
import NoContent from '../../../components/NoContent';
import Pagination from '../../../components/Pagination';
import { Modal, ModalText } from "../../manage/components/Modal";
import { LinkModal } from './LinkModal';
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import './Links.scss';
import IconPencil from '../../../assets/Icon_Pencil.svg';

export const ToggleLink = connect(state => ({ translation: state.translation.virtualFamily.links.toggleLinkModal, login: state.login }))(class extends React.PureComponent {
    state = { isLoaderVisible: false, isDone: false, isError: false, result: null };

    toggleLink = (inviteIsActive, inviteId) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        this.setState({ isLoaderVisible: true }, () => {
            this.props.virtualFamilyRequest({
                "requestType": "toggleLink",
                "accNr": accnr,
                "token": this.props.login.token,
                "inviteId": inviteId,
                "inviteIsActive": inviteIsActive
            }, data => this.setState({
                result: data.records.result,
                isDone: data.records.statusCode === 200,
                isError: data.records.statusCode !== 200,
                isLoaderVisible: false
            }, () => this.props.inviteToggled()), error => this.setState({ isLoaderVisible: false }));
        })
    }

    render() {
        const title = this.props.inviteIsActive ? this.props.translation.deactivateTitle : this.props.translation.activateTitle;
        const text = this.props.inviteIsActive ? this.props.translation.deactivateText : this.props.translation.activateText;
        const button = this.props.inviteIsActive ? this.props.translation.deactivateButton : this.props.translation.activateButton;
        return (
            <Modal {...{
                title: title,
                onCloseButtonClick: this.props.onModalClose,
                content: <ModalText {...{ text: text }} />,
                isSaveButton: true,
                actionButtonTitle: button,
                onActionButtonClick: () => this.toggleLink(!this.props.inviteIsActive, this.props.inviteId),
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 64
            }} />
        )
    }
});
ToggleLink.propTypes = {
    inviteId: PropTypes.string,
    virtualFamilyRequest: PropTypes.func,
    inviteIsActive: PropTypes.bool,
    inviteToggled: PropTypes.func
};

const LinksTableDataRow = connect(state => ({ translation: state.translation.virtualFamily.links.table }))(React.memo(props => {
    const expired = props.expired === "1";
    const isActive = props.isActive === "1";
    const othersClass = (expired || !isActive) ? "inactive" : "";
    const expiresClass = (!isActive ? "inactive" : (expired ? "expired" : ""));
    return (<tr className="table-body-row links" onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut}>
        <TableDataCell {...{
            className: "right links links-table-data-row-actions",
            content: <button onClick={() => props.onModalOpen(<ToggleLink {...{
                onModalClose: props.onModalClose,
                virtualFamilyRequest: props.virtualFamilyRequest,
                inviteId: props.inviteId,
                inviteIsActive: props.isActive === "1",
                inviteToggled: props.inviteToggled
            }} />)}><img src={props.icon} alt={expired ? props.translation.expiredButton : (isActive ? props.translation.pauseButton : props.translation.activateButton)} /></button>
        }} />
        <TableDataCell {...{
            className: "left small-table-first-col links",
            content:
                <Tippy
                    arrow={false}
                    trigger="click"
                    content={props.translation.copiedToClipboard}>
                    <textarea
                        className={`table-text-14px-bold links_table-data_row-textarea ${othersClass}`}
                        onClick={e => {
                            e.target.select();
                            document.execCommand("copy");
                        }}
                        readOnly>
                        {props.link}
                    </textarea>
                </Tippy>
        }} />
        <TableDataCell {...{
            className: "left links",
            content: <span className={`table-text-14px-regular ${expiresClass}`}>{props.expires}</span>
        }} />
        <TableDataCell {...{
            className: "left links",
            content: <span className={`table-text-14px-regular ${othersClass}`}>{props.description}</span>
        }} />
        <TableDataCell {...{
            className: "left links",
            content: <span className={`table-text-14px-regular ${othersClass}`}>{props.status}</span>
        }} />
        <TableDataCell {...{
            className: "left links links_table-actions_data_row",
            content:
                <>
                    <button className="links_table-actions_data_row-button edit"
                        onClick={() => props.onModalOpen(<LinkModal {...{
                            editing: true,
                            familyId: props.familyId,
                            inviteId: props.inviteId,
                            inviteDescription: props.description,
                            inviteExpiresAt: new Date(props.expiresTimeStamp / 0.001),
                            onLinkModalRequestSuccess: props.onLinkUpdated,
                            onModalClose: props.onModalClose
                        }} />)}>
                        <img className="links_table-actions_data_row-button-img" src={IconPencil} alt="edit link" />
                    </button>
                </>
        }} />
    </tr>)
}));
LinksTableDataRow.propTypes = {
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    link: PropTypes.string,
    expires: PropTypes.string,
    description: PropTypes.string,
    expired: PropTypes.string,
    isActive: PropTypes.string,
    icon: PropTypes.string,
    inviteId: PropTypes.string,
    onModalOpen: PropTypes.func,
    onModalClose: PropTypes.func,
    inviteToggled: PropTypes.func,
    familyId: PropTypes.any,
    onLinkUpdated: PropTypes.func
};

const LinksTableHeaderRow = connect(state => ({ translation: state.translation.virtualFamily.links.table }))(React.memo(props =>
    <tr className="table-header-row links">
        <TableHeaderCell {...{
            className: "small links members_table-header_row-toggle_button",
            text: ""
        }} />
        <TableHeaderCell {...{
            className: "left small-table-first-col links members_table-header_row-link",
            text: props.translation.linkColumn,
            orderWhat: "link",
            selectedOrderWhat: props.selectedOrderWhat,
            selectedOrderHow: props.selectedOrderHow,
            selectedOrderSearch: props.filters.link,
            onTableColumnFilterSelected: (selectedOrderHow, selectedOrderWhat, selectedOrderSearch) => props.onTableColumnFilterSelected(selectedOrderHow, selectedOrderWhat, "link", selectedOrderSearch),
            selectedSearchOverride: true
        }} />
        <TableHeaderCell {...{
            className: "left links members_table-header_row-expires",
            text: props.translation.expiryColumn,
            orderWhat: "expires",
            selectedOrderWhat: props.selectedOrderWhat,
            selectedOrderHow: props.selectedOrderHow,
            onTableColumnFilterSelected: (selectedOrderHow, selectedOrderWhat, selectedOrderSearch) => props.onTableColumnFilterSelected(selectedOrderHow, selectedOrderWhat, "expires", selectedOrderSearch),
            selectedSearchOverride: true,
            withSearch: false
        }} />
        <TableHeaderCell {...{
            className: "left links members_table-header_row-description",
            text: props.translation.descriptionColumn,
            orderWhat: "description",
            selectedOrderWhat: props.selectedOrderWhat,
            selectedOrderHow: props.selectedOrderHow,
            selectedOrderSearch: props.filters.description,
            onTableColumnFilterSelected: (selectedOrderHow, selectedOrderWhat, selectedOrderSearch) => props.onTableColumnFilterSelected(selectedOrderHow, selectedOrderWhat, "description", selectedOrderSearch),
            selectedSearchOverride: true
        }} />
        <TableHeaderCell {...{
            className: "left links members_table-header_row-status",
            text: props.translation.statusColumn,
            orderWhat: "status",
            selectedOrderWhat: props.selectedOrderWhat,
            selectedOrderHow: props.selectedOrderHow,
            selectedOrderSearch: props.filters.status,
            onTableColumnFilterSelected: (selectedOrderHow, selectedOrderWhat, selectedOrderSearch) => props.onTableColumnFilterSelected(selectedOrderHow, selectedOrderWhat, "status", selectedOrderSearch),
            selectedSearchOverride: true
        }} />
        <TableHeaderCell {...{
            className: "small left links members_table-header_row-action_buttons",
            text: ""
        }} />
    </tr>
));
LinksTableHeaderRow.propTypes = {
    selectedOrderWhat: PropTypes.any,
    selectedOrderHow: PropTypes.any,
    filters: PropTypes.any,
    onTableColumnFilterSelected: PropTypes.func
};

//table-wrapper scrollable table
export const LinksTable = React.memo(props =>
    <div className="table-wrapper virtual_family-links-table">
        <table className="table">
            <thead>
                <LinksTableHeaderRow {...{
                    selectedOrderHow: props.selectedOrderHow,
                    selectedOrderWhat: props.selectedOrderWhat,
                    filters: props.filters,
                    onTableColumnFilterSelected: props.onTableColumnFilterSelected
                }} />
            </thead>
            <tbody>
                {props.links && props.links.map((item, index) => <LinksTableDataRow {...{
                    ...item,
                    key: index,
                    onMouseOver: (e) => props.onMouseOverElement(index),
                    onMouseOut: (e) => props.onMouseOutElement(),
                    onModalOpen: props.onModalOpen,
                    onModalClose: props.onModalClose,
                    virtualFamilyRequest: props.virtualFamilyRequest,
                    inviteToggled: props.inviteToggled,
                    familyId: props.familyId,
                    onLinkUpdated: props.onLinkUpdated
                }} />)}
            </tbody>
        </table>
    </div>
);
LinksTable.propTypes = {
    links: PropTypes.array,
    filters: PropTypes.any,
    onMouseOverElement: PropTypes.func,
    onMouseOutElement: PropTypes.func,
    selectedOrderHow: PropTypes.any,
    selectedOrderWhat: PropTypes.any,
    onTableColumnFilterSelected: PropTypes.func,
    onModalOpen: PropTypes.func,
    onModalClose: PropTypes.func,
    virtualFamilyRequest: PropTypes.func,
    inviteToggled: PropTypes.func,
    familyId: PropTypes.any,
    onLinkUpdated: PropTypes.func
};

class Links extends React.PureComponent {
    render() {
        const hasLinks = this.props.links && this.props.links.length > 0;
        const hasActiveSearchFilters = this.props.filtersCopy.link !== "" || this.props.filtersCopy.description !== "" || this.props.filtersCopy.status !== "";
        const isTableVisible = hasLinks || hasActiveSearchFilters;
        const isNoContentVisible = !isTableVisible && !this.props.isLoaderVisible;
        return (
            <div className="virtual_family-links">
                <TransitionGroup component={null}>
                    {this.props.isLoaderVisible &&
                        <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <Loader className={"virtual_family-links-loader"} />
                        </CSSTransition>}
                    {isTableVisible &&
                        <CSSTransition key={"table-bg-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <BackgroundTable {...{
                                className: "virtual_family-links-background_table",
                                items: this.props.links,
                                hovered: this.props.hoveredIndex,
                                stickyHeaderClass: "hidden"
                            }} />
                        </CSSTransition>}
                    {isTableVisible &&
                        <CSSTransition key={"table-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <LinksTable {...{
                                links: this.props.links,
                                filters: this.props.filters,
                                onMouseOverElement: this.props.onMouseOverTableRow,
                                onMouseOutElement: this.props.onMouseOutTableRow,
                                onModalOpen: this.props.onModalOpen,
                                onModalClose: this.props.onModalClose,
                                onTableColumnFilterSelected: this.props.onTableColumnFilterSelected,
                                selectedOrderWhat: this.props.selectedOrderWhat,
                                selectedOrderHow: this.props.selectedOrderHow,
                                virtualFamilyRequest: this.props.virtualFamilyRequest,
                                inviteToggled: this.props.inviteToggled,
                                familyId: this.props.familyId,
                                onLinkUpdated: this.props.onLinkUpdated
                            }} />
                        </CSSTransition>}
                    {(isTableVisible && this.props.pages && this.props.selectedPage && this.props.handlePageSelected) &&
                        <CSSTransition key={"pagination"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <Pagination {...{
                                className: "virtual_family-links-pagination",
                                selectedPage: this.props.selectedPage,
                                pages: this.props.pages,
                                handlePageSelected: this.props.handlePageSelected
                            }} />
                        </CSSTransition>}
                    {isNoContentVisible &&
                        <CSSTransition key={"no_content-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <NoContent {...{ className: "virtual_family-links-no_content", text: this.props.translation.noContent }} />
                        </CSSTransition>}
                </TransitionGroup>
            </div>
        );
    }
}
Links.propTypes = {
    familyId: PropTypes.any,
    links: PropTypes.array,
    filters: PropTypes.any,
    onTableColumnFilterSelected: PropTypes.func,
    onPageSelected: PropTypes.func,
    selectedPage: PropTypes.any,
    pages: PropTypes.any,
    omModalOpen: PropTypes.func,
    onModalClose: PropTypes.func,
    isLoaderVisible: PropTypes.bool,
    inviteToggled: PropTypes.func,
    onLinkUpdated: PropTypes.func
};
export default connect(
    state => ({ login: state.login, translation: state.translation.virtualFamily.links }),
    dispatch => ({ virtualFamilyRequest: (body, success, error) => businessVirtualFamilyRequest(dispatch, body, success, error) }))(withBackgroundTableHighlighting(Links));