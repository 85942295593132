export const english = {
    errorModal: {
        title: "Something went wrong",
        text: "Please refresh the page or try again in a while.",
        backButton: "Home"
    },
    table: {
        asc: "Ascending order",
        desc: "Descending order",
        searchPlaceholder: "Search from column..."
    },
    register: {
        title: "Register",
        topInfo: "If you are not our customer yet, then please fill in your data and register for entering the self-service portal.",
        firstNameField: "Your first name *",
        lastNameField: "Your last name *",
        personalCodeField: "Your personal identification code *",
        emailField: "Your email address *",
        phoneField: "Your mobile phone number *",
        emailNewsletter: "I would like to receive Olerex newsletter by email",
        emailNewsletterMobile: "Olerex newsletter by email",
        emailOffers: "I would like personal promotional offers by email",
        emailOffersMobile: "Personal offers by email",
        smsOffers: "I would like personal promotional offers via SMS",
        smsOffersMobile: "Personal offers via SMS",
        backButton: "Back",
        joinButton: "Register",
        bottomText: "In case of login issues, please contact Olerex customer service or your personal client manager.",
        bottomHours: "Mon-Fri 8:00 - 18:00",
        errorTitle: "An error occurred while registering",
    },
    title: {
        "/": "My Account | Olerex",
        "/vouchers": "Vouchers | Olerex",
        "/transactions": "Transactions | Olerex",
        "/idcredit": "ID-Credit | Olerex",
        "/cards": "Cards | Olerex",
        "/invoicing": "Transactions | Olerex",
        "/invoicing/invoices": "Invoices & Receipts | Olerex",
        "/manage": "Administration | Olerex",
        "/virtualfamily": "Virtual Family | Olerex",
        "/electricity": "Elekter | Olerex",
        "/wholesale": "Wholesale | Olerex",
        "/wholesale/orders": "Order history | Olerex",
    },
    bankLinks: {
        title: "Select a payment method",
        checkboxText: "I agree to the",
        termsOfSaleLink: "Terms of sale",
        termsOfSaleUrl: "https://olerex.ee/olxweb/documents/Terms_of_Sale_Olerex_en.pdf"
    },
    login: {
        title: "SELF-SERVICE",
        titleInfo: "Please choose an authentication method to continue",
        passwordLogin: "Password",
        idCardInfo: "Insert your ID-card into the card reader and press the button to continue",
        phoneField: "Mobile phone number",
        personalCodeField: "Personal code",
        smartId2TimerText: "Security code",
        usernameField: "Username",
        passwordField: "Password",
        bottomText: "In case of login issues, please contact Olerex customer service or your personal client manager.",
        bottomHours: "Mon-Fri 8:00 - 18:00"
    },
    signing: {
        title: "SIGNING",
        titleInfo: "Please choose an authentication method to continue",
        idCardInfo: "Insert your ID-card into the card reader and press the button to continue",
        phoneField: "Mobile phone number",
        personalCodeField: "Personal code",
        smartId2TimerText: "Security code",
        bottomText: "In case of signing issues, please contact Olerex customer service or your personal client manager.",
        bottomHours: "Mon-Fri 8:00 - 18:00"
    },
    joinVirtualFamily: {
        topInfo: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt in ligula id faucibus. Duis malesuada sagittis dignissim.Sed dui turpis, euismod et sodales sit amet, feugiat eget est. Pellentesque finibus interdum nibh sed interdum. Integer ut massa a velit euismod hendrerit quis quis velit. Cras lectus odio, rhoncus ac elementum non, interdum vitae justo.",
        nameField: "Your first and last name",
        personalCodeField: "Your personal identification code",
        emailField: "Your email address",
        checkboxInfo: "I agree, et cras lectus odio, rhoncus ac elementum non, vitae justo. Duis malesuada sagittis dignissim.",
        backButton: "Back",
        joinButton: "Confirm and join the virtual family"
    },
    filters: {
        title: "FILTERS"
    },
    navbar: {
        overview: "MY ACCOUNT",
        wholesale: "WHOLESALE",
        cards: "CARDS",
        invoicing: "INVOICING",
        invoicingMobile: "TRANSACTIONS",
        invoicesMobile: "INVOICING",
        manage: "ADMINISTRATION",
        virtualFamily: "VIRTUAL FAMILY",
        vouchers: "VOUCHERS",
        transactions: "TRANSACTIONS",
        idCredit: "ID-CREDIT",
        electricity: "ELECTRICITY",
        map: "GAS STATIONS",
        userControls: {
            language: {
                et: "Eesti keel",
                en: "English",
                ru: "Русский"
            },
            settings: "Account settings",
            roleChange: "Role change",
            logout: "Log out"
        }
    },
    roleChange: {
        title: "Role change",
        privateClient: "Private customer",
        businessClient: "Business client"
    },
    languageChange: {
        title: "Change language",
        language: {
            et: "Eesti keel",
            en: "English",
            ru: "Русский"
        },
    },
    overview: {
        personCards: {
            title: "My discount cards",
            editButton: "Edit",
            deleteCard: {
                title: "Delete discount card",
                content: (card) => `Are you sure you want to delete the discount card ${card}?`,
                deleteButton: "Delete"
            }
        },
        creditCards: {
            title: "My payment cards",
            editButton: "Edit",
            blockUnblockCard: {
                blockButton: "Block",
                unblockButton: "Open",
            },
            changePin: {
                title: "Change PIN code",
                enterPin: 'Enter your PIN',
                enterPinAgain: 'Re-enter your PIN',
                pinNotEqual: 'PIN does not match',
                pinTooShort: 'PIN must be 4 digits',
                confirmButton: "Confirm",
            }
        },
        olerexPlus: {
            title: "Olerex Pluss",
            showAllButton: "Show all",
            item: {
                nextFree: "Next free"
            },
            modal: {
                title: "Your Olerex Pluss",
                text: "Every 6th of your favourite hot drink, pastry, hot dish and car wash is for free!  The sixth free product will appear in your loyalty programme within about one hour. Olerex has the right to change the products included in the loyalty programme without prior notice. Products removed from the loyalty programme are replaced with new products, and the progress of the removed product will be transferred to the progress of an analogous product."
            }
        },
        topAd: {
            readMoreButton: "Read more"
        },
        userDetails: {
            title: "My details",
            editButton: "Edit",
            userCard: {
                emailVerified: "Email verified",
                emailUnverified: "Email unverified"
            },
            subscriptionsCard: {
                emailNewsletter: "I would like to receive Olerex newsletter by email",
                emailNewsletterMobile: "Olerex newsletter by email",
                emailOffers: "I would like personal promotional offers by email",
                emailOffersMobile: "Personal offers by email",
                smsOffers: "I would like personal promotional offers via SMS",
                smsOffersMobile: "Personal offers via SMS",
            },
            editProfileCard: {
                firstNameField: "First name",
                lastNameField: "Last name",
                personalCodeField: "Personal identification code",
                requiredErrorLabel: 'Required field'
            },
            editContactCard: {
                emailField: "Email",
                phoneField: "Phone number",
                requiredErrorLabel: 'Required field'
            },
            editPasswordCard: {
                usernameField: "Username",
                passwordField: "Password",
                passwordRepeatField: "Repeat password",
                requiredErrorLabel: 'Passwords don\'t match'
            },
            editSubscriptionsAndActionsCard: {
                emailNewsletter: "I would like to receive Olerex newsletter by email",
                emailNewsletterMobile: "Olerex newsletter by email",
                emailOffers: "I would like personal promotional offers by email",
                emailOffersMobile: "Personal offers by email",
                smsOffers: "I would like personal promotional offers via SMS",
                smsOffersMobile: "Personal offers via SMS",
                cancelButton: "Cancel",
                saveButton: "Save changes"
            },
            privacyPolicyLink: "Principles of processing personal data",
            privacyPolicyUrl: "https://olerex.ee/olxweb/documents/Principles_of_processing_personal_data.pdf"
        },
        fuelUsage: {
            title: "My fuel consumption",
            months: ["JANUARY", "FEBRUARY", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUGUST", "SEPTEMBER", "OCTOBER", "NOVEMBER", "DECEMBER"],
        },
        fuelPrices: {
            title: "Today's fuel prices",
            askPriceModal: {
                cancelButton: "Cancel"
            }
        },
        cards: {
            title: "Cards",
            showAllButton: "Show all"
        },
        bills: {
            title: "Latest invoices",
            showAllButton: "Show all"
        }
    },
    transactions: {
        title: "Transactions",
        table: {
            cardColumn: "Card",
            productColumn: "Product",
            stationColumn: "Station",
            timeColumn: "Time",
            quantityColumn: "Quantity",
            sumColumn: "Sum (VAT excl.)",
            sumVatColumn: "Sum (VAT incl.)"
        },
        noContent: "No transactions found"
    },
    vouchers: {
        title: "Vouchers",
        item: {
            validTo: (to) => `Valid until ${to}`
        },
        email: {
            title: "Email this voucher",
            emailField: "Recipient's email",
            sendButton: "Send"
        },
        noContent: "No vouchers found"
    },
    idCredit: {
        title: "Credit card",
        invoicesTab: "Invoices",
        incomingsTab: "Incomings",
        headerStats: {
            unpaidTotal: "Amount due",
            overDeadline: "Overdue",
            balance: "Balance",
            remainingLimit: "Balance",
            referenceNumber: "Reference no."
        },
        content: {
            invoices: "Invoices",
            incomings: "Incomings",
            total: (total) => `Total ${total}`,
            receivedItem: {
                type: "Incoming",
                sum: "Amount received",
                sumMobile: "SUM",
                date: "Date received"
            },
            billItem: {
                total: "Amount due",
                totalMobile: "Sum",
                date: "Invoice date",
                dateMobile: "Date",
                paymentDate: "Payment date",
                paymentDateMobile: "Deadline",
                paid: "Paid",
                payButton: "Pay the invoice"
            }
        },
        noContent: "No invoices found"
    },
    electricity: {
        title: 'Elekter',
        createContract: 'Sõlmi leping',
        eurosMonth: '€ / kuu',
        centsKwh: 'senti / kWh',
        cents: 's',
        duringDay: 'päeval',
        atNight: 'öösel',
        period: (period) => {
            if (period.type === 'month') {
                return period.value > 1 ? 'kuud' : 'kuu'
            }
            return period.value > 1 ? 'aastat' : 'aasta'
        },
        meteringPointModal: {
            selectMeteringPoint: 'Vali mõõtepunkt',
            selectPackage: 'Vali pakett',
            search: 'Otsi...',
            meteringPointCode: 'Mõõtepunkti kood',
            selectAll: 'Vali kõik',
            next: 'Edasi',
            pointsSelected: (count) => count + (count === 1 ? ' mõõtepunkt' : ' mõõtepunkti') + ' valitud'
        },
        package: "Package",
        packagePrice: 'Paketi hind',
        contractBeginning: 'Lepingu algus',
        contractEnd: 'Lepingu lõpp',
        meteringPoint: "mõõtepunkt",
        meteringPoints: "mõõtepunkti",
        consumptionAddress: "Tarbimiskoha aadress",
        relatedContract: 'Seotud leping',
        meteringPointCode: 'Mõõtepunkti kood',
        tabs: {
            contractsTab: 'Lepingud',
            meteringPointsTab: 'Mõõtepunktid',
            consumptionTab: 'Tarbimus'
        },
        filter: 'Filtreeri',
        boundByContract: 'Lepinguga seotud',
        contractElsewhere: 'Leping mujal',
        newContract: 'Uus leping',
        bringOver: 'Too üle',
        editContract: 'Muuda lepingut',
        endContract: 'Lõpeta leping',
        iAgree: "Nõustun",
        withContract: "Lepinguga",
        toSignPage: "Allkirjastama",
        contractPreviewTitle: "Lepingu eelvaade",
        endContractTitle: "Lepingu lõpetamine",
        endContractCheckbox: (contracts) => 'Saada ' + contracts + (contracts === 1 ? ' leping' : ' lepingut') + ' kliendihaldurile lõpetamiseks',
        confirm: "Kinnita"
    },
    cards: {
        noCards: "No cards were found",
        grouped: "Grouped",
        ungrouped: "Ungrouped",
        orderBy: "Sort by",
        header: {
            title: "Cards",
            selectedCards: "Selected cards:",
            totalUsed: "Used credit line:",
            businessLimit: "Company's limit:",
            newCardButton: "New card",
            searchPlaceholder: "SEARCH...",
            errorModalTitle: "Error!"
        },
        cardItem: {
            users: n => `${n} users`,
            expiry: "Valid until",
            limit: "Card limit",
            unused: "Unused",
            used: "Used",
            limitDropdown: "Limit",
            allowedToBuy: "Enabled purchases",
            param: "Tankimise parameeter",
            editNameButton: "Edit name",
            editLimitButton: "Edit limits",
            extendButton: "Replace expiring",
            blockButton: "Block",
            unblockButton: "Open card",
            addParamButton: "Add parameter",
            editParamButton: "Edit parameter",
            activateButton: "Activate",
        },
        footer: {
            selectedCard: "card selected",
            selectedCards: "cards selected",
            extendButton: "Replace expiring",
            editLimitButton: "Edit limits",
            blockButton: "Block",
            unblockButton: "Open",
            activateButton: "Activate",
        },
        cardUsersModal: {
            title: 'Card users',
            close: 'Close',
            remove: 'Remove',
            empty: 'No users associated with the selected card',
            confirmRemove: name => `Are you sure you wish to remove user ${name} from this card?`,
            blockAddingApp: 'Block new users from associating with the selected card',
        },
        editCardNameModal: {
            title: "Edit card name",
            nameField: "Card name",
            saveButton: "Save the name"
        },
        editCardLimitsModal: {
            title: "Edit card limits",
            limitField: "Card limit",
            dayLimitField: "Day limit",
            fuelField: "Allowed fuels",
            fuel1: "Only diesel",
            fuel2: "All fuels",
            productField: "Allowed goods",
            product1: "Goods prohibited",
            product2: "Only vehicle goods",
            product3: "All goods",
            saveButton: "Save"
        },
        activateCardModal: {
            title: 'Activate card',
            activateButton: 'Activate card',
        },
        blockCardModal: {
            title: "Block card",
            contentBlockCard: (name) => `Are you sure you want to block the card: ${name}?`,
            contentBlockCards: (count) => `Are you sure you want to block the (${count}) selected cards?`,
            reasonField: "Blocking reason",
            blockButton: "Block card"
        },
        unblockCardModal: {
            title: "Open card",
            contentUnblockCard: (name) => `Are you sure you want to open the card: ${name}?`,
            contentUnblockCards: (count) => `Are you sure you want to open the (${count}) selected cards?`,
            blockButton: "Open card"
        },
        extendCardModal: {
            title: "Replace expiring card",
            contentExtendCard: (name) => `Are you sure you want to replace the expiring card: ${name}?`,
            contentExtendCards: (count) => `Are you sure you want to replace the (${count}) selected expiring cards?`,
            extendButton: "Replace expiring card"
        },
        orderCard: {
            orderCards: "Order a new card",
            cardsOrdered: "Cards ordered",
            nameField: "Card name",
            limitField: "Card limit",
            fuelField: "Allowed fuels",
            fuel1: "Only diesel",
            fuel2: "All fuels",
            productField: "Allowed goods",
            product1: "Goods prohibited",
            product2: "Only vehicle goods",
            product3: "All goods",
            maxLength: (length, maxLength) => `${length} / ${maxLength} characters`,
            perMonthLimit: "€ per month",
            perDayLimit: "€ per day",
            page1: {
                topText: "We recommend giving unique names to the ordered cards. For example, you could name the card using an employee's name or the registration number of a vehicle that this card will be used by.",
                fuel1: "Only diesel",
                fuel2: "All fuels",
                productField: "Allowed goods",
                product1: "Goods prohibited",
                product2: "Only vehicle goods",
                product3: "All goods",
                addCardButton: "Add a card",
                nextButton: "Next"
            },
            page2: {
                topText: "Please specify the details of the person who will pick the ordered card(s) up from the station.",
                pickupField: "Card receiver",
                pickupPersonalCode: "Receiver's personal ID code",
                pickupEmail: "Receiver's email",
                pickupLocation: "Card pickup station",
                locationInfo: "You can also pick the location by clicking on the desired station on the map.",
                backButton: "Back",
                orderButton: "Order the card"
            },
            page3: {
                closeButton: "Close"
            }
        },
        editParamsModal: {
            titleAdd: "Add refueling parameter",
            titleEdit: "Edit refueling parameter",
            paramField: "Refueling parameter",
            editButton: "Save parameter",
        }
    },
    invoicing: {
        title: "Settlement",
        invoicingTab: "Transactions",
        invoicesTab: "Invoices & Receipts",
        incomingsTabMobile: "Receipts",
        invoicesTabMobile: "Invoices",
        searchPlaceholder: "SEARCH...",
        invoices: {
            noInvoices: "No invoices.",
            noIncomings: "No receipts.",
            noContent: "No invoices found.",
            termsOfSaleLink: "Terms of sale",
            termsOfSaleUrl: "https://olerex.ee/olxweb/documents/Terms_of_Sale_Olerex_en.pdf"
        },
        transactions: {
            noTransactions: "No transactions found."
        }
    },
    manage: {
        header: {
            title: "Administration",
            addressTab: "Address",
            contactsTab: "Contact persons",
            usersTab: "Users",
            managerTab: "Customer manager",
            servicesTab: "Services"
        },
        modal: {
            backButton: "Back",
            closeButton: "Close",
            maxLength: (length, maxLength) => `${length} / ${maxLength} characters`,
            perMonthLimit: "€ per month",
            perDayLimit: "€ per day",
            companyLimit: (limit) => `Company's credit line: ${limit} €`
        },
        address: {
            title: "Address",
            editButton: "Change",
            editAddressModal: {
                title: "Change the address",
                addressField: "Address",
                saveButton: "Save the address"
            }
        },
        contacts: {
            title: "Contact persons",
            addButton: "Add",
            addContactModal: {
                title: "Add a contact",
                nameField: "Name",
                emailField: "Email",
                phoneField: "Phone number",
                saveButton: "Save the contact"
            },
            editContactModal: {
                title: "Edit a contact",
                nameField: "Name",
                emailField: "Email",
                phoneField: "Phone number",
                saveButton: "Save the contact"
            },
            deleteContactModal: {
                title: "Delete a contact",
                content: (name) => `Are you sure you want to delete ${name} from your contact persons? A deleted contact cannot be restored.`,
                deleteButton: "Delete the contact"
            }
        },
        users: {
            title: "Users",
            addButton: "Add",
            table: {
                nameColumn: "Name",
                personalCodeColumn: "Personal ID code",
                emailColumn: "Email",
                roleColumn: "Role"
            },
            addUserModal: {
                title: "Add a user",
                nameField: "Name",
                emailField: "Email",
                roleField: "Role",
                role1: "Regular user",
                role2: "Administrator",
                role3: "Management board / CEO",
                personalCodeField: "Personal identification code",
                saveButton: "Add the user"
            },
            editUserModal: {
                title: "Edit a user",
                nameField: "Name",
                emailField: "Email",
                roleField: "Role",
                role1: "Regular user",
                role2: "Administrator",
                role3: "Management board / CEO",
                personalCodeField: "Personal identification code",
                saveButton: "Save the user"
            },
            deleteUsersModal: {
                title: "Delete a user",
                content: (name) => `Are you sure you want to delete ${name} from users? A deleted user cannot be restored.`,
                deleteButton: "Delete the user"
            }
        },
        manager: {
            title: "My customer manager",
            sendEmail: "Email",
            call: "Call",
            hours: "8:00 - 17:00",
            weekdays: "Workdays"
        },
        services: {
            title: "Additional services",
            activated: "Activated",
            notActivated: "Not activated",
            activate: "Activate",
            deactivate: "Deactivate",
            editActivationModal: {
                activateButton: "Activate",
                deactivateButton: "Deactivate"
            }
        }
    },
    wholesale: {
        header: {
            title: 'Wholesale',
            calculatorTab: 'Price calculator',
            orderHistoryTab: 'Order history'
        },
        manager: {
            title: 'My customer manager',
            sendEmail: 'Email',
            call: 'Call',
            hours: '8:00–17:00',
            weekdays: 'Workdays'
        },
        editAddressModal: {
            title: 'Delivery location',
            address: 'Address',
            closeButton: 'Back',
            saveButton: 'Save the aadress',
        },
        orderHistory: {
            title: 'Order history',
            table: {
                fuelColumn: 'Fuel',
                addressColumn: 'Address',
                commentColumn: 'Comment',
                timeColumn: 'Time',
                statusColumn: 'Status',
                quantityColumn: 'Quantity',
                sumColumn: 'Sum (VAT excl.)',
                sumVatColumn: 'Sum (VAT incl.)'
            },
            noContent: 'No orders found'
        },
        calculator: {
            fuelSection: 'Fuel type',
            vehicleSection: 'Fuel truck type',
            timeSection: 'Delivery time window',
            quantitySection: 'Quantity in liters',
            periodSection: 'Delivery period',
            addressSection: 'Delivery location',
            commentSection: 'Comment',
            smallVehicleInfo: 'The fuel truck (gross weight 20t) can reach more places but it can only deliver smaller quantities.',
            bigVehicleInfo: 'The fuel truck (gross weight 36t) can reach more places and it can deliver larger quantities.',
            mtf: 'M-F',
            mts: 'M-S',
            withinDays: 'within % days',
            change: 'Change',
            typeHere: 'Type here',
            balance: 'Balance',
            priceForYouIs: 'According to the selected calculator parameters, your price is',
            includingVatAndTransport: 'price includes VAT and delivery',
            sendOrder: 'Send order',
            priceInfo: 'The price is based on the selected fuel type, fuel truck type, fuel quantity, delivery period, and delivery location.',
            personalOfferInfo: 'The selected calculator parameters will be forwarded to an Olerex customer manager who will then contact you',
            wantPersonalOffer: 'Request a personal offer',
            contactViaPhone: 'Contact me via phone',
            contactViaEmail: 'Contact me via e-mail',
            orderSubmitted: 'Order submitted',
            personalOfferSubmitted: 'Request for personal offer submitted',
            error: 'Error',
            success: 'Submitted',
            missingAddress: 'Please set the delivery location',
            missingContactPreference: 'Please choose how you wish to be contacted',
        },
    },
    virtualFamily: {
        title: "Virtual family",
        newVirtualFamilyButton: "New virtual family",
        membersTab: "Members",
        invitesTab: "Invites",
        header: {
            newLinkButton: "New link",
            sendInvitationsButton: "Send invitations",
            addMembersButton: "Add members",
            top1: "Joined the family",
            top2: "Active this month",
            top3: "Earned discount"
        },
        downloadExcel: "Download as excel",
        families: {
            noContent: "No virtual families",
            familyModal: {
                titleNew: "Create a new virtual family",
                titleEdit: "Edit family name",
                text: "Family name",
                saveButton: "Save",
                createButton: "Create"
            }
        },
        members: {
            table: {
                nameColumn: "Name",
                personalCodeColumn: "Personal identification code",
                emailColumn: "Email",
                statusColumn: "Membership"
            },
            noContent: "The virtual family does not have any members.",
            bottomBar: {
                memberSelected: (count) => `${count} member selected`,
                membersSelected: (count) => `${count} members selected`,
                closeButton: "Close",
                deactivateMember: "Deactivate selected",
            },
            deactiveModal: {
                title: "Deactivate member",
                text: "Deactivation will remove the selected member(s) from the virtual family.",
                deactivateButton: "Deactivate"
            }
        },
        links: {
            table: {
                linkColumn: "Invite link",
                expiryColumn: "Valid until",
                descriptionColumn: "Description",
                statusColumn: "Status",
                expiredButton: "Expired",
                pauseButton: "Pause",
                activateButton: "Activate",
                copyButton: "Copy",
                editButton: "Edit",
                copiedToClipboard: "Copied to clipboard"
            },
            noContent: "The virtual family does not have any links.",
            toggleLinkModal: {
                deactivateTitle: "Deactivate link",
                deactivateText: "The link will be deactivated.",
                deactivateButton: "Deactivate",
                activateTitle: "Activate link",
                activateTet: "The link will be activated.",
                activateButton: "Activate"
            },
            linkModal: {
                titleNew: "Create a new invite link",
                titleEdit: "Invite link edit",
                descriptionText: "Link description",
                expiresAtText: "Expires in",
                expiresInDay: (days) => `${days} day`,
                expiresInDays: (days) => `${days} days`,
                saveButton: "Save",
                createButton: "Create"
            }
        }
    },
    general: {
      downloadFile: 'Download file'
    }
};
