import React, { Component } from 'react';
import Tippy from '@tippy.js/react';
import './DropdownFilter.scss';
import './DropdownFilterTippy.scss';

export default class DropdownFilter extends Component {
    constructor(props) {
        super(props);
        this.state = { isVisible: false };
        this.scrollContainerRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextProps.items && !this.props.items) {
            return false;
        }
        if (nextState.isVisible !== this.state.isVisible ||
            nextProps.text !== this.props.text) {
            return true;
        }
        if ((!nextProps.items && this.props.items) ||
            (nextProps.items && !this.props.items) ||
            (nextProps.items !== this.props.items) ||
            (nextProps.items.some((value, index) => this.props.items.length > index && value.value !== this.props.items[index].value))) {
            return true;
        }
        return false;
    }

    get isScrollable() {
        return this.props.items && this.props.items.length > 10 ? true : false;
    }

    onWheelListener = (e) => {
        // close filters if not scrollable and scrolling or scrollable and scrolling outside
        if (this.isScrollable) {
            const current = this.scrollContainerRef.current;
            const scrollTop = current && current.scrollTop;
            const isScrollingDown = e.deltaY > 0;
            const isScrollingUp = e.deltaY < 0;
            const isAtTop = scrollTop === 0;
            const isAtBottom = scrollTop + current.clientHeight === current.scrollHeight;
            if ((isScrollingDown && isAtBottom) || (isScrollingUp && isAtTop)) {
                e.preventDefault(); //stop the wheel event to prevent scroll chaining
            }
            const isTooltip = (e.target && e.target.classList && e.target.classList.value.includes("tippy")) || e.target.classList.value.includes("dropdown");
            if (!isTooltip) {
                this.onClose();
            }
        } else {
            this.onClose();
        }
    }

    onOpen = () => {
        this.setState({ isVisible: true }, () => {
            window.addEventListener('wheel', this.onWheelListener);
        });
    }

    onClose = () => {
        this.setState({ isVisible: false }, () => {
            window.removeEventListener('wheel', this.onWheelListener);
        });
    }

    render() {
        const theme = this.props.distance === -72 ? "dropdown-filter" : "dropdown-filter-light";
        return (
            <Tippy zIndex={20} placement={"bottom"} arrow={false} interactive={true} trigger={"manual"} animateFill={false}
                distance={this.props.distance} animation={"fade"} theme={theme} isVisible={this.state.isVisible} onHidden={this.onClose} onHide={this.onClose}
                content={
                    <div className="co-dropdown-filter-tippy-content">
                        <button className={`co-btn-dropdown tippy ${this.props.className ? this.props.className : ""}`} onClick={this.onClose}>
                            {this.props.leftImg && <img className="co-btn-dropdown_img-left" src={this.props.leftImg} alt="" />}
                            <span className={`co-btn-dropdown_text tippy expanded ${this.props.className ? this.props.className : ""}`}>{this.props.text}</span>
                            {this.props.rightImg && <img className="co-btn-dropdown_img-right" src={this.props.rightImg} alt={this.state.isOpen ? "toggle filters close" : ""}/>}
                        </button>
                        <div className={`co-dropdown-filter_dropdown ${this.isScrollable ? "with-scroll" : ""}`} ref={this.scrollContainerRef}>
                            {this.props.items && this.props.items.map((item, index) => <div className="co-dropdown-filter_dropdown_option-container" key={index}>
                                <button className={`co-dropdown-filter_dropdown_option-button ${this.props.text === item.text ? "active" : ""}`}
                                    onClick={() => {
                                        this.onClose();
                                        item.onClick();
                                    }} >
                                    {item.text}
                                </button>
                            </div>
                            )}
                        </div>
                    </div>
                } >
                <button className={`co-btn-dropdown ${this.props.className ? this.props.className : ""}`} onClick={this.onOpen} disabled={this.props.disabled}>
                    {this.props.leftImg && <img className="co-btn-dropdown_img-left" src={this.props.leftImg} alt="" />}
                    <span className={`co-btn-dropdown_text ${this.props.className ? this.props.className : ""}`}>{this.props.text}</span>
                    {this.props.rightImg && <img className="co-btn-dropdown_img-right" src={this.props.rightImg} alt={this.state.isOpen ? "" : "toggle filters open"} />}
                </button>
            </Tippy>
        );
    }
}
