import React from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Loader from '../../../components/Loader';
import { VictoryPie } from "victory";
import ContentContainer from '../../../components/ContentContainer';
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import './OlerexPlus.scss';
import IconHelpGray20px from '../assets/Icon_Help_Gray-20px.svg';
import BGStarWhite100px from '../assets/BG_Star_White-100px.svg';

const OlerexPlusItem = connect(state => ({ translation: state.translation.overview.olerexPlus.item }))(React.memo(props =>
    <div
        className={`olerex-plus-cell ${props.current === props.total ? "highlighted" : ""}`}
    >
        <img className="olerex-plus-cell_img-shield" src={BGStarWhite100px} alt="" />
        <div className="olerex-plus-cell_img" style={{ backgroundImage: `url(${props.thumbnail || ""})` }}>
            {props.percent && <span className="olerex-plus-cell_percent">{props.percent}</span>}
        </div>
        {
            props.current !== props.total && <div className="olerex-plus-cell_counter">
                <VictoryPie
                    innerRadius={128}
                    data={[1, 1, 1, 1, 1]}
                    padAngle={8}
                    labels={() => ""}
                    style={{
                        data: {
                            fill: d => d._x < props.current ? "#FFCD00" : "none"
                        }
                    }}
                />
            </div>
        }
        <h1 className={`olerex-plus-cell_title font-weight-${(props.name.bold && 'bold') || 'normal'}`}>{props.name.text}</h1>
        <p className="olerex-plus-cell_completion">
            {props.bottom && props.bottom.text ?
                <span className={`font-weight-${(props.bottom.bold && 'bold') || 'normal'}`}>{props.bottom.text}</span> :
                <>
                    {props.current === props.total && <span>{props.translation.nextFree}</span>}
                    {props.current !== props.total && <span>
                        <span className="olerex-plus-cell_completion-alt_text">{props.current}</span>
                        <span className="olerex-plus-cell_completion-alt_separator">/</span>
                        <span className="olerex-plus-cell_completion-alt_text">{props.total}</span>
                    </span>}
                </>}
        </p>
    </div>
));

export const OlerexPlusCollection = React.memo(props =>
    <div className="olerex-plus-collection">
        <TransitionGroup component={null}>
            {props.items && props.items.map((item, index) => <CSSTransition key={index} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"} appear>
                <OlerexPlusItem {...{ ...item, key: index, total: 5 }} />
            </CSSTransition>)}
        </TransitionGroup>
    </div>
);

const OlerexPlus = React.memo(props => {
    const showAllVisible = props.loyaltyProgress && props.loyaltyProgress.length > 6;
    const loyaltyProgress = (props.loyaltyProgress && props.loyaltyProgress.slice(0, 6)) || null;
    const loyaltyTitle = (props.loyaltyInfo && props.loyaltyInfo.loyaltyTitle) || null;
    const loyaltyText = (props.loyaltyInfo && props.loyaltyInfo.loyaltyText) || null;

    return (<ContentContainer className={props.className || 'olerex-plus'} {...{
        title:
            <div className="olerex-plus_title">
                <span className="olerex-plus_title-text">{loyaltyTitle}</span>
                {loyaltyText && (
                    <Tippy arrow={true}
                        isEnabled={loyaltyText !== null}
                        content={loyaltyText || ""}
                    >
                        <img className="olerex-plus_help" src={IconHelpGray20px} alt="info hover" />
                    </Tippy>
                )}
            </div>,
        action: showAllVisible ? <button className="olerex-plus_show-all-button" onClick={props.onShowAllToggle}>{props.translation.showAllButton}</button> : "",
        content:
            <div className="olerex-plus-content">
                <div className="olerex-plus-content-scroll">
                    <TransitionGroup component={null}>
                        {props.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <div className="olerex-plus-loader-container"><Loader {...{ className: "olerex-plus-loader" }} /></div>
                        </CSSTransition>}
                        {!props.isLoaderVisible && <CSSTransition key={"olerex-plus-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <OlerexPlusCollection {...{ items: loyaltyProgress }} />
                        </CSSTransition>}
                    </TransitionGroup>
                </div>
            </div>
    }} />)
});

export default connect(state => ({ translation: state.translation.overview.olerexPlus }))(OlerexPlus);
