import {withCloseOnEsc} from "../../../../components/withCloseOnEsc";
import {connect} from "react-redux";
import ContentContainer from "../../../../components/ContentContainer";
import React, { Component } from 'react';
import IconModalCross24px from "../../../../assets/Icon_Modal-Close_24px.svg";
import ButtonIDKaartActive from "../../../../../login/assets/Button_ID-Kaart_Active.svg";
import ButtonIDKaartInactive from "../../../../../login/assets/Button_ID-Kaart_Inactive.svg";
import ButtonMobiilIDActive from "../../../../../login/assets/Button_Mobiil-ID_Active.svg";
import ButtonMobiilIDInactive from "../../../../../login/assets/Button_Mobiil-ID_Inactive.svg";
import ButtonSmartIDActive from "../../../../../login/assets/Button_Smart-ID_Active.svg";
import ButtonSmartIDInactive from "../../../../../login/assets/Button_Smart-ID_Inactive.svg";
import IconChevronRightGray16px from "../../../../assets/Icon_Chevron-Right_Gray-16px.svg";
import {TextEditField} from "../../../overview/components/UserDetails";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import { VictoryPie } from "victory";
import './SignContractModal.scss';
import {toCamelCase, withDigidoc} from "../../../../helpers";

export const SignContractModal = withCloseOnEsc(withDigidoc(connect(
    state => (
        {
            translation: state.translation.signing
        }),
    undefined
)(
    class ContractModal extends Component {
        constructor(props) {
            super(props);
            this.signingEnumeration = this.props.signingEnumeration;

            this.state = {
                data: this.getData(0),
                idCode:  {
                    invalid: true,
                    value: ''
                },
                personalCode: {
                    invalid: this.props.login.privateClientData.personalCode ? false : true,
                    value: this.props.login.privateClientData.personalCode || ''
                },
                phoneNumber: {
                    invalid: this.props.login.privateClientData.phone ? false : true,
                    value: this.props.login.privateClientData.phone || ''
                },
                offer: this.props.offer || {},
                showError: false
            };
        }

        componentDidMount(): void {
            this.intervalID = setInterval(
                () => this.updateTimer(),
                1000
            );
        }

        componentWillUnmount() {
            clearInterval(this.intervalID);
        }

        componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
            if ((this.props.finishContractData !== null) && (this.props.finishContractData !== prevProps.finishContractData)) {
                this.finishContract();
            }
        }

        updateTimer() {
            const {signStartTime} = this.props;
            if (signStartTime) {
                const fromStart = Date.now() - signStartTime;
                const percent = Math.min(((fromStart) / 600), 100); // technically 700 (70 seconds)
                this.setState({data: this.getData(percent)});
            }
        }

        signingMethodSelected = (selectedSigningType) => {
            this.props.signingMethodSelected(selectedSigningType,
                () => {
                    if (selectedSigningType !== 0) {
                        this.getSignData(selectedSigningType);
                    }
                }
            );
        };

        getSignData(selectedSigningType) {
            const userId =
                this.props.login &&
                this.props.login.currentRole &&
                this.props.login.currentRole.userId;
            if (!userId) {
                return;
            }
            const requestData = {
                requestType: 'getSignData',
                userId: userId,
                token: this.props.login.token,
                fileId: this.state.offer.fileId
                // field: this.state.offer.signData
            };
            if (selectedSigningType !== 0) {
                requestData.phone = this.state.phoneNumber.value;
                requestData.personalCode = this.state.personalCode.value;
                if (requestData.phone.length > 1 && requestData.personalCode.lenght > 1) {
                    this.setState({ isLoaderVisible: true }, async () => {
                        this.props.electricityRequest(
                            requestData,
                            data => {
                                const newOffer = this.state.offer;
                                newOffer.signData =  data.records.result;
                                this.setState({
                                    offer: newOffer
                                });
                            },
                            error => {
                                this.setState({ isLoaderVisible: false });
                            }
                        );
                    });
                }
            }
        }

        finishContract() {
            const userId =
                this.props.login &&
                this.props.login.currentRole &&
                this.props.login.currentRole.userId;
            if (!userId) {
                return;
            }
            const requestData = {
                requestType: 'finishContract',
                userId: userId,
                token: this.props.login.token,
                data: this.props.finishContractData
            };
            this.setState({ isLoaderVisible: true }, async () => {
                this.props.electricityRequest(
                    requestData,
                    data => {
                        this.props.onCloseButtonClick();
                        this.props.refreshData();
                    },
                    error => {
                        this.setState({ isLoaderVisible: false });
                    }
                );
            });
        }

        getData(percent) {
            return [{ x: 1, y: percent }, { x: 2, y: 100 - percent }];
        }

        onFieldChange = (e) => {
            const prev = this.state[toCamelCase(e.target.id)];
            prev.value = e.target.value;
            prev.invalid = (e.target.value) ? false: true;
            this.setState({ [toCamelCase(e.target.id)]: prev }, () => {
                this.getSignData(this.props.selectedSigningType);
            });
        };

        showErrorBox = (event) => {
            this.setState({showError: true});
        };

        render() {
            const {onCloseButtonClick, idCard, mobileId, smartId, status, timerCode, disabled} = this.props;
            const {phoneNumber, personalCode, showError} = this.state;

            const idCardSigningActive = this.props.selectedSigningType === this.signingEnumeration.idCard;
            const mobiilIdSigningActive = this.props.selectedSigningType === this.signingEnumeration.mobiilId;
            const smartIdSigningActive = this.props.selectedSigningType === this.signingEnumeration.smartId;
            const smartIdSigning2Active = this.props.selectedSigningType === this.signingEnumeration.smartId2;
            const signingCardBotExtra = [
                idCardSigningActive && "idcard",
                mobiilIdSigningActive && "mobiilid",
                smartIdSigningActive && "smartid",
                smartIdSigning2Active && "smartid2"
            ].filter(x => x)[0];

            const translation = this.props.translation;
            const {signData} = this.state.offer;
            return (
                <ContentContainer {...{
                    className: `sign-contract-modal`,
                    action:
                        <button className="sign-contract-modal_close-button" onClick={onCloseButtonClick}>
                            <img className="sign-contract-modal_close-button_img" src={IconModalCross24px} alt="close" />
                        </button>,
                    content:
                        <>
                            <div
                                className="sign-contract-modal-content-container"
                            >
                                <div className="sign-contract-modal-content">
                                   <div className="sign-contract-modal-top">
                                       <h1>{translation.title}</h1>
                                       <div>{translation.titleInfo}</div>
                                   </div>
                                   <div className="sign-contract-modal-middle">
                                       <button disabled={disabled} className={`signing-type-select id-card  ${(idCardSigningActive) ? "active" : ""}`} onClick={() => {this.signingMethodSelected(this.signingEnumeration.idCard)}}>
                                           <img className="signing-type-select_img" src={idCardSigningActive ? ButtonIDKaartActive : ButtonIDKaartInactive} alt={idCardSigningActive ? "" : "id card signing"} />
                                       </button>
                                       <button disabled={disabled} className={`signing-type-select mobiil-id  ${(mobiilIdSigningActive) ? "active" : ""}`} onClick={() => {this.signingMethodSelected(this.signingEnumeration.mobiilId)}}>
                                           <img className="signing-type-select_img" src={mobiilIdSigningActive ? ButtonMobiilIDActive : ButtonMobiilIDInactive} alt={mobiilIdSigningActive ? "" : "mobiil id signing"} />
                                       </button>
                                       <button disabled={disabled} className={`signing-type-select smart-id  ${(smartIdSigningActive) ? "active" : ""}`} onClick={() => {this.signingMethodSelected(this.signingEnumeration.smartId)}}>
                                           <img className="signing-type-select_img" src={smartIdSigningActive ? ButtonSmartIDActive : ButtonSmartIDInactive} alt={smartIdSigningActive || smartIdSigning2Active ? "" : "smartid signing"} />
                                       </button>
                                   </div>
                                   {this.props.selectedSigningType !== null && <div className={`sign-contract-modal-bottom ${signingCardBotExtra} ${(showError && (status)) ? 'show-error': ''}`}>
                                       <TransitionGroup component={null}>
                                           {idCardSigningActive &&
                                           <CSSTransition key={"signing-id-card"} timeout={{ enter: 300, exit: 300 }} classNames={"modal-bottom"}>
                                               <div className="signing-bot-container">
                                                   <div className="signing-card-bot_id-card signing-card-bot">
                                                       <p className="signing-card-bot_id-card_title">{translation.idCardInfo}</p>
                                                       <button disabled={disabled} onClick={() => {this.showErrorBox(); idCard.eidSign(signData)}} className="signing-card-bot_id-card_signing-button">
                                                           <img src={IconChevronRightGray16px} alt="signing" />
                                                       </button>
                                                   </div>
                                                   {showError && (status) &&
                                                        <div className={'sign-contract-modal-error'}>{status}</div>
                                                   }
                                               </div>
                                           </CSSTransition>}
                                           {mobiilIdSigningActive &&
                                           <CSSTransition key={"signing-mobiil-id"} timeout={{ enter: 300, exit: 300 }} classNames={"modal-bottom"}>
                                               <div className="signing-bot-container">
                                                   <div className="signing-card-bot_mobiil-id signing-card-bot">
                                                       <TextEditField disabled={disabled} {...{ type: "phone-number", title: translation.phoneField, value: {"phone-number": phoneNumber}, onChange: this.onFieldChange }} />
                                                       <button disabled={disabled} onClick={() => {this.showErrorBox(); mobileId.startMIDSign(signData)}} className="signing-card-bot_mobiil-id_signing-button">
                                                           <img src={IconChevronRightGray16px} alt="signing" />
                                                       </button>
                                                   </div>
                                               </div>
                                           </CSSTransition>}
                                           {smartIdSigningActive &&
                                           <CSSTransition key={"signing-card-smart-id"} timeout={{ enter: 300, exit: 300 }} classNames={"modal-bottom"}>
                                               <div className="signing-bot-container">
                                                   <div className="signing-card-bot_smart-id">
                                                       <TextEditField disabled={true} {...{ type: "personal-code", title: translation.personalCodeField, value: {"personal-code": personalCode}, onChange: this.onFieldChange }} />
                                                       <TextEditField disabled={disabled} {...{ type: "phone-number", title: translation.phoneField, value: {"phone-number": phoneNumber}, onChange: this.onFieldChange }} />
                                                       <button disabled={disabled} onClick={() => {this.showErrorBox(); smartId.startSign(signData)}} className="signing-card-bot_mobiil-id_signing-button">
                                                           <img src={IconChevronRightGray16px} alt="signing" />
                                                       </button>
                                                   </div>
                                                   {showError && (status) &&
                                                        <div className={'sign-contract-modal-error'}>{status}</div>
                                                   }
                                               </div>

                                           </CSSTransition>}
                                           {smartIdSigning2Active &&
                                           <CSSTransition key={"signing-card-smart-id-2"} timeout={{ enter: 300, exit: 300 }} classNames={"modal-bottom"}>
                                               <div className="signing-bot-container">
                                                   <div className="signing-card-bot_smart-id-2 signing-card-bot">
                                                       <svg className='signing-card-bot_smart-id-2_timer' viewBox="0 0 400 400">
                                                           <VictoryPie
                                                               standalone={false}
                                                               animate={{ duration: 1000 }}
                                                               width={400} height={400}
                                                               data={this.state.data}
                                                               radius={200}
                                                               innerRadius={194}
                                                               cornerRadius={0}
                                                               labels={() => null}
                                                               style={{
                                                                   data: { fill: (datum) => {
                                                                           const color = datum.y < 75 ? "rgb(255, 205, 0)" : "rgb(245,95,95)";
                                                                           return datum.x === 1 ? color : "rgb(234,234,234)";
                                                                       }
                                                                   }
                                                               }}
                                                           />
                                                       </svg>
                                                       <div className="signing-card-bot_smart-id-2_timer_label">
                                                           <p className="signing-card-bot_smart-id-2_timer_title">{translation.smartId2TimerText}</p>
                                                           <p className="signing-card-bot_smart-id-2_timer_code">{timerCode}</p>
                                                       </div>
                                                   </div>
                                                   {showError && (status) &&
                                                        <div className={'sign-contract-modal-error'}>{status}</div>
                                                   }
                                               </div>

                                           </CSSTransition>}
                                       </TransitionGroup>
                                   </div>}
                               </div>
                                <div className='clickable-modal-background' onClick={onCloseButtonClick} />
                            </div>
                        </>
                }} />
            )
        }
    }
)));
