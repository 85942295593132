import React, { PureComponent } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import VouchersContent from "./components/VouchersContent";
import { Modal, ModalInputText } from '../../business/manage/components/Modal';
import { roleOrLanguageChanged } from "../../../../Utils";
import { url, privateVouchersRequest } from '../../../../Networking';
import Loader from '../../components/Loader';
import NoContent from '../../components/NoContent';
import "./Vouchers.scss";

const EmailVoucher = connect(state => ({ translation: state.translation.vouchers.email }))(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { email: props.email, isLoaderVisible: false, isDone: false, isError: false, result: null };
    }

    onEmailVoucher = (email) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        if (this.state.isLoaderVisible) {
            return;
        }
        this.setState({ isLoaderVisible: true }, () => {
            this.props.vouchersRequest(
                {
                    "requestType": "emailVoucher",
                    "userId": this.props.login.privateClientData.userId,
                    "token": this.props.login.token,
                    "voucherNr": this.props.voucherNr,
                    "email": email
                },
                data => {
                    const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                    if (!_accnr || _accnr !== accnr) {
                        return; //role changed
                    }
                    this.setState({
                        result: data.records.result,
                        isDone: data.records.statusCode === 200,
                        isError: data.records.statusCode !== 200,
                        isLoaderVisible: false
                    });
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        });
    }

    onEmailChange = (e) => {
        this.setState({ email: e.target.value });
    }

    render() {
        return (
            <Modal {...{
                title: this.props.translation.title,
                onCloseButtonClick: this.props.onModalClose,
                content:
                    <ModalInputText {...{
                        text: this.props.translation.emailField,
                        id: "email",
                        value: this.state.email,
                        onChange: this.onEmailChange
                    }} />,
                isSaveButton: true,
                onActionButtonClick: () => this.onEmailVoucher(this.state.email),
                actionButtonTitle: this.props.translation.sendButton,
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: 112
            }} />
        )
    }
});

class Vouchers extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isGiftCardsCollectionVisible: true,
            isVouchersCollectionVisible: true,
            vouchers: null
        };
    }

    openVoucherPdf = (voucherNr) => {
        const userId = this.props.login && this.props.login.currentRole && this.props.login.currentRole.userId;
        if (!userId) {
            return; //not in private role
        }
        window.open(`${url}&act=olerexweb.private.vouchers&requestType=printVoucher&token=${this.props.login.token}&userId=${userId}&voucherNr=${voucherNr}&pdf=1`);
    }

    downloadData = () => {
        if (this.props.login && this.props.login.privateClientData) {
            this.setState({ isLoaderVisible: true }, () => {
                this.props.vouchersRequest(
                    {
                        "requestType": "getVoucher",
                        "userId": this.props.login.privateClientData.userId,
                        "token": this.props.login.token
                    },
                    data => {
                        const vouchers = data.records.voucherType ? data.records.voucherType.map(x => ({
                            ...x, vouchers: x.vouchers ? x.vouchers.map(y => ({
                                ...y,
                                onEmailButtonClick: () => this.props.onModalOpen(<EmailVoucher {...{
                                    onModalClose: this.props.onModalClose,
                                    vouchersRequest: this.props.vouchersRequest,
                                    login: this.props.login,
                                    voucherNr: y.voucherNr,
                                    email: this.props.login.privateClientData.email
                                }} />),
                                onPrintButtonClick: () => this.openVoucherPdf(y.voucherNr)
                            })) : null
                        })) : null;
                        this.setState({ isLoaderVisible: false, vouchers: vouchers });
                    }, error => {
                        this.setState({ isLoaderVisible: false });
                    });
            });
        }
    }

    componentDidMount() {
        this.downloadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (roleOrLanguageChanged(prevProps, this.props)) {
            this.downloadData();
        }
    }

    render() {
        const isNoContentVisible = this.state.vouchers === null;
        return (
            <div className="vouchers">
                <div className="vouchers-header-shadow"></div>
                <div className="vouchers-header">
                    <h1 className="vouchers-header-title">{this.props.translation.title}</h1>
                </div>
                <TransitionGroup component={null}>
                    {this.state.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <Loader className={"vouchers-loader"} />
                    </CSSTransition>}

                    {!this.state.isLoaderVisible && <CSSTransition key={"body-content"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <div className="vouchers-body">
                            <TransitionGroup component={null}>
                                {isNoContentVisible &&
                                    <CSSTransition key={"no-content"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                        <NoContent {...{ text: this.props.translation.noContent }} />
                                    </CSSTransition>}
                                {!isNoContentVisible &&
                                    <CSSTransition key={"content"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                        <div className="vouchers-body-content">
                                            {this.state.vouchers && this.state.vouchers.map((voucher, index) => <VouchersContent {...{
                                                ...voucher,
                                                key: index,
                                                isMobileLayout: this.props.isMobileLayout
                                            }} />)}
                                        </div>
                                    </CSSTransition>}
                            </TransitionGroup>
                        </div>
                    </CSSTransition>}
                </TransitionGroup>
            </div>
        );
    }
}

export default connect(
    state => ({
        login: state.login,
        isMobileLayout: state.size.isMobileLayout,
        translation: state.translation.vouchers
    }),
    dispatch => ({ vouchersRequest: (body, success, error) => privateVouchersRequest(dispatch, body, success, error) }))(Vouchers);