import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import IconModalCross16px from '../../../assets/Icon_Modal-Cross_16px.svg';
import ContentContainer from '../../../components/ContentContainer';
import Loader from '../../../components/Loader';
import styles from './CardUsersModal.module.scss';

// Delay is added because the loader will look ridiculous if the request
// completes too quickly.
const requestDelay = 400;

const CheckboxInput = ({ label, ...props }) => (
    <label className={styles.checkbox}>
        <input type="checkbox" {...props} />
        <span className={styles.box}></span>
        <span className={styles.label}>{label}</span>
    </label>
);

class CardUsersModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [...props.users],
            isBlockedAddingApp: props.isBlockedAddingApp,
            loading: false,
        };
    }

    onChangeIsBlockedAddingApp(e) {
        const isBlockedAddingApp = e.target.checked;

        this.setState({ loading: true }, async () => {
            await new Promise(resolve => setTimeout(resolve, requestDelay));

            const req = {
                requestType: 'updateIsBlockedAddingApp',
                cardNumber: this.props.cardNumber,
                isBlockedAddingApp,
            };

            this.props.updateCards(req, (res) => {
                if (res.records.statusCode === 200) {
                    this.setState({ isBlockedAddingApp });
                }

                this.setState({ loading: false });
            });
        });
    }

    onRemove(user) {
        if (!window.confirm(this.props.translation.confirmRemove(`${user.firstName} ${user.lastName}`))) {
            return;
        }

        const { personalCode } = user;

        this.setState({ loading: true }, async () => {
            await new Promise(resolve => setTimeout(resolve, requestDelay));

            const req = {
                requestType: 'removeCardUser',
                cardNumber: this.props.cardNumber,
                personalCode,
            };

            this.props.updateCards(req, (res) => {
                this.setState((prevState) => {
                    let users = prevState.users;

                    if (res.records.statusCode === 200) {
                        users = prevState.users.filter(u => u.personalCode !== personalCode);
                    }

                    return { users, loading: false };
                });
            });
        });
    }

    render() {
        return <ContentContainer {...{
            className: styles.modal,
            title: <h1>{this.props.translation.title}</h1>,
            action:
                <button className={styles.close} onClick={this.props.onModalClose}>
                    <img src={IconModalCross16px} alt={this.props.translation.close} />
                </button>,
            content:
                <div className={styles.content}>
                    <TransitionGroup component={null}>
                        {this.state.loading && (
                            <CSSTransition key="loader-fade" timeout={{ enter: 250, exit: 250 }} classNames="fast-fade">
                                <Loader className={styles.loader} />
                            </CSSTransition>
                        )}

                        {!this.state.loading && (
                            <CSSTransition key="loader-fade" timeout={{ enter: 250, exit: 250 }} classNames="fast-fade">
                                <div>
                                    <div className={styles.userRows}>
                                        {this.state.users.map(user => (
                                            <div className={styles.userRow} key={user.personalCode}>
                                                <div className={styles.name}>{user.firstName} {user.lastName}</div>
                                                <div className={styles.code}>{user.personalCode}</div>

                                                <button
                                                    className={styles.remove}
                                                    onClick={() => this.onRemove(user)}
                                                >{this.props.translation.remove}</button>
                                            </div>
                                        ))}

                                        {!this.state.users.length && (
                                            <div className={styles.empty}>{this.props.translation.empty}</div>
                                        )}
                                    </div>

                                    <div className={styles.options}>
                                        <CheckboxInput
                                            label={this.props.translation.blockAddingApp}
                                            checked={this.state.isBlockedAddingApp}
                                            onChange={e => this.onChangeIsBlockedAddingApp(e)}/>
                                    </div>
                                </div>
                            </CSSTransition>
                        )}
                    </TransitionGroup>
                </div>
        }} />;
    }
}

export default connect(state => ({
    translation: state.translation.cards.cardUsersModal,
}))(CardUsersModal);
