import { attachEventsHook } from '@olerex/web-events-hook';
import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { connectRouter, routerMiddleware, ConnectedRouter } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import login, { initialLoginState } from './state/Login';
import size, { initialWindowSizeState } from './state/Size';
import translation, { initialTranslationState } from './state/Translation';
import error, { initialErrorState } from './state/Error';
import { Switch, Route } from 'react-router-dom';

const history = createBrowserHistory();
const store = createStore(
    combineReducers({ router: connectRouter(history), ...{ login, size, translation, error /* other state reducers */ } }),
    { login: { ...initialLoginState, ...JSON.parse(localStorage.getItem("login")) }, size: initialWindowSizeState, translation: initialTranslationState, error: initialErrorState }, //load initial state
    applyMiddleware(thunkMiddleware, routerMiddleware(history))
);

if (process.env.NODE_ENV === 'production') {
    // Start listening on console events and uncaught errors
    const hook = attachEventsHook({
        url: 'https://api.olerex.ee/v2/olerex.web.event?apiKey=jIEDvOaIvLzU7Utxi9-lql5dmCJPfnEG5M5bg_QAe0KOSoWrP0gidQ',
    });

    store.subscribe(() => {
        const state = store.getState();
        const accNr = parseInt(state.login?.currentRole?.accnr, 10);
        const userId = parseInt(state.login?.currentRole?.userId, 10);

        hook.setLabel('ss.role.accNr', accNr || undefined);
        hook.setLabel('ss.role.userId', userId || undefined);
    });
}

//save login to local storage
store.subscribe(() => {
    const state = store.getState();
    if (state.login) {
        localStorage.setItem("login", JSON.stringify(state.login));
    } else {
        localStorage.removeItem("login");
    }
});

//update the document title
store.subscribe(() => {
    const state = store.getState();
    const pathname = state.router && state.router.location && state.router.location.pathname;
    const translation = state.translation && state.translation.title;
    const title = (translation && pathname && translation[pathname]) || "Olerex";
    document.title = title;
});

//App itself in a route or the back button will not with inner routes
ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/" component={App} />
            </Switch>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
