import React from 'react';
import { connect } from 'react-redux';
import './Filters.scss';
import IconSort from '../../../assets/Icon_Sort.svg';
import IconArrowActiveDown from '../../../assets/Icon_Arrow_Active-Down.svg';
import IconArrowActiveUp from '../../../assets/Icon_Arrow_Active-Up.svg';
import IconListActiveAlt from '../../../assets/Icon_List_Active-Alt.svg';

const FiltersButtonSmall = React.memo(props =>
    <button className={`filters-button-small ${props.active ? "active" : ""}`} onClick={props.onClick}>
        <img className="filters-button-small-img" src={props.img} alt="" />
        <span className={`filters-button-small-text ${props.active ? "active" : ""}`}>{props.text}</span>
    </button>
);

const Filters = React.memo(props =>
    <div className="filters">
        <div className="filters-title">
            <img className="filters-title_img" src={IconSort} alt="" />
            <span className="filters-title_text">{props.translation.orderBy}</span>
        </div>
        <div className="filters-separator"></div>
        {props.orderWhatOptions && props.orderWhatOptions.map((item, index) =>
            <button className={`filters-order-by-button ${props.selectedOrderWhat === item.value ? "active" : ""}`} onClick={item.onClick} key={index}>{item.text}</button>)}
        <div className="filters-separator"></div>
        {props.orderHowOptions && props.orderHowOptions.map((item, index) => {
            const active = props.selectedOrderHow === item.value;
            const img = item.value === "ASC" ? IconArrowActiveUp : IconArrowActiveDown;
            return <FiltersButtonSmall {...{
                ...item,
                active: active,
                img: img,
                key: index
            }} />
        }
        )}
        <div className="filters-separator"></div>
        <FiltersButtonSmall {...{
            ...props.groupedOption,
            active: props.grouped === 1,
            img: IconListActiveAlt
        }} />
    </div>

);

export default connect(state => ({ translation: state.translation.cards }))(Filters);