export const russian = {
    errorModal: {
        title: "Что-то пошло не так",
        text: "Oбновите страницу или попробуйте позже.",
        backButton: "На главную страничку"
    },
    table: {
        asc: "По возрастанию",
        desc: "По убыванию",
        searchPlaceholder: "Поиск в колонке..."
    },
    register: {
        title: "Зарегистрироваться",
        topInfo: "Для входа в самообслуживание, пожалуйста, заполните анкету и зарегистрируйтесь нашим клиентом.",
        firstNameField: "Ваше имя *",
        lastNameField: "Ваше фамилия *",
        personalCodeField: "Ваш личный код *",
        emailField: "Электронная почта *",
        phoneField: "Номер телефона *",
        emailNewsletter: "Хочу получать новостную рассылку Olerex по электронной почте",
        emailNewsletterMobile: "Новостная рассылка Olerex по емейлу",
        emailOffers: "Хочу получать персональные специальные предложения по электронной почте",
        emailOffersMobile: "Персональные предложения по емейлу",
        smsOffers: "Хочу получать персональные специальные предложения по СМС",
        smsOffersMobile: "Персональные предложения по СМС",
        backButton: "Назад",
        joinButton: "Зарегистрироваться",
        bottomText: "В случае проблем со входом в систему, просим связаться с отделом обслуживания клиентов Olerex или Вашим персональным менеджером по работе с клиентами.",
        bottomHours: "Пн-Пт 8:00 - 18:00",
        errorTitle: "Oшибка при регистрации",
    },
    title: {
        "/": "Мой аккаунт | Olerex",
        "/vouchers": "Ваучеры | Olerex",
        "/transactions": "Операции | Olerex",
        "/idcredit": "ID-кредит | Olerex",
        "/cards": "Карты | Olerex",
        "/invoicing": "Расчёт | Olerex",
        "/invoicing/invoices": "Счета и поступления | Olerex",
        "/manage": "Управление | Olerex",
        "/virtualfamily": "Виртуальная семья | Olerex",
        "/electricity": "Elekter | Olerex",
        "/wholesale": "Wholesale | Olerex",
        "/wholesale/orders": "Order history | Olerex",
    },
    bankLinks: {
        title: "Bыберите способ оплаты",
        checkboxText: "Я согласен с",
        termsOfSaleLink: "Условиями продажи",
        termsOfSaleUrl: "https://olerex.ee/olxweb/documents/Terms_of_Sale_Olerex_ru.pdf"
    },
    login: {
        title: "САМООБСЛУЖИВАНИЕ",
        titleInfo: "Чтобы продолжить, выберите способ идентификации",
        passwordLogin: "Пароль",
        idCardInfo: "Введите ID-карту в считыватель и нажмите на кнопку, чтобы продолжить",
        phoneField: "Номер мобильного телефона",
        personalCodeField: "Личный код",
        smartId2TimerText: "Код безопасности",
        usernameField: "Имя пользователя",
        passwordField: "Пароль",
        bottomText: "В случае проблем со входом в систему, просим связаться с отделом обслуживания клиентов Olerex или Вашим персональным менеджером по работе с клиентами.",
        bottomHours: "Пн-Пт 8:00 - 18:00"
    },
    signing: {
        title: "ПОДПИСАНИЕ",
        titleInfo: "Чтобы продолжить, выберите способ идентификации",
        idCardInfo: "Введите ID-карту в считыватель и нажмите на кнопку, чтобы продолжить",
        phoneField: "Номер мобильного телефона",
        personalCodeField: "Личный код",
        smartId2TimerText: "Код безопасности",
        bottomText: "В случае проблем со подписью, просим связаться с отделом обслуживания клиентов Olerex или Вашим персональным менеджером по работе с клиентами.",
        bottomHours: "Пн-Пт 8:00 - 18:00"
    },
    joinVirtualFamily: {
        topInfo: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam tincidunt in ligula id faucibus. Duis malesuada sagittis dignissim.Sed dui turpis, euismod et sodales sit amet, feugiat eget est. Pellentesque finibus interdum nibh sed interdum. Integer ut massa a velit euismod hendrerit quis quis velit. Cras lectus odio, rhoncus ac elementum non, interdum vitae justo.",
        nameField: "Ваше имя и фамилия",
        personalCodeField: "Ваш личный код",
        emailField: "Электронная почта",
        checkboxInfo: "Nõustun, et cras lectus odio, rhoncus ac elementum non, vitae justo. Duis malesuada sagittis dignissim.",
        backButton: "Назад",
        joinButton: "Подтвердите данные и присоединитесь к виртуальной семье"
    },
    filters: {
        title: "ФИЛЬТРЫ"
    },
    navbar: {
        overview: "МОЙ АККАУНТ",
        wholesale: "HULGIMÜÜK",
        cards: "КАРТЫ",
        invoicing: "РАСЧЁТ",
        invoicingMobile: "РАСЧЁТ",
        invoicesMobile: "СЧЕТА",
        manage: "УПРАВЛЕНИЕ",
        virtualFamily: "ВИРТУАЛЬНАЯ СЕМЬЯ",
        vouchers: "ВАУЧЕРЫ",
        transactions: "ОПЕРАЦИИ",
        idCredit: "ID-КРЕДИТ",
        electricity: "ЭЛЕКТРИЧЕСТВО",
        map: "ЗАПРАВКИ",
        userControls: {
            language: {
                et: "Eesti keel",
                en: "English",
                ru: "Русский"
            },
            settings: "Настройки учетной записи",
            roleChange: "Смена роли",
            logout: "Выйти"
        }
    },
    roleChange: {
        title: "Смена роли",
        privateClient: "Частный клиент",
        businessClient: "Бизнес-клиент"
    },
    languageChange: {
        title: "Смена языка",
        language: {
            et: "Eesti keel",
            en: "English",
            ru: "Русский"
        },
    },
    overview: {
        personCards: {
            title: "Мои скидочные карты",
            editButton: "Изменить",
            deleteCard: {
                title: "Удалить скидочную карту",
                content: (card) => `Вы уверены, что хотите удалить скидочную карту ${card}?`,
                deleteButton: "Удалить"
            }
        },
        creditCards: {
            title: "Мои кредитные карты",
            editButton: "Изменить",
            blockUnblockCard: {
                blockButton: "Заблокировать",
                unblockButton: "Oткрыто"
            },
            changePin: {
                title: "Измените свой PIN-код",
                enterPin: 'Введите свой PIN-код',
                enterPinAgain: 'Введите ваш PIN-код еще раз',
                pinNotEqual: 'ПИН не совпадает',
                pinTooShort: 'ПИН-код должен быть 4 цифры',
                confirmButton: "Сохранить",
            }
        },
        olerexPlus: {
            title: "Olerex Pluss",
            showAllButton: "Смотреть все",
            item: {
                nextFree: "Следующий бесплатно"
            },
            modal: {
                title: "Ваш Olerex Pluss",
                text: "Каждый 6-й Ваш любимый горячий напиток, булочка, горячее блюдо и автомойка бесплатно! Шестой бесплатный продукт появится в Вашей программе лояльности в течение ~ одного часа. Olerex имеет право изменять продукты, входящие в программу лояльности. Продукты, которые вышли из программы лояльности, будут заменены на новые, а прогресс удаленных продуктов будет отражен в прогрессе аналогичного продукта."
            }
        },
        topAd: {
            readMoreButton: "Прочитать подробнее"
        },
        userDetails: {
            title: "Мои данные",
            editButton: "Изменить",
            userCard: {
                emailVerified: "Email verified",
                emailUnverified: "Email unverified"
            },
            subscriptionsCard: {
                emailNewsletter: "Хочу получать новостную рассылку Olerex по электронной почте",
                emailNewsletterMobile: "Новостная рассылка Olerex по емейлу",
                emailOffers: "Хочу получать персональные специальные предложения по электронной почте",
                emailOffersMobile: "Персональные предложения по емейлу",
                smsOffers: "Хочу получать персональные специальные предложения по СМС",
                smsOffersMobile: "Персональные предложения по СМС",
            },
            editProfileCard: {
                firstNameField: "Имя",
                lastNameField: "Фамилия",
                personalCodeField: "Личный код",
                requiredErrorLabel: 'Обязательное'
            },
            editContactCard: {
                emailField: "Электронная почта",
                phoneField: "Номер телефона",
                requiredErrorLabel: 'Обязательное'
            },
            editPasswordCard: {
                usernameField: "Имя пользователя",
                passwordField: "Пароль",
                passwordRepeatField: "Повторите пароль",
                requiredErrorLabel: 'Пароли не соответствуют'
            },
            editSubscriptionsAndActionsCard: {
                emailNewsletter: "Хочу получать новостную рассылку Olerex по электронной почте",
                emailNewsletterMobile: "Новостная рассылка Olerex по емейлу",
                emailOffers: "Хочу получать персональные специальные предложения по электронной почте",
                emailOffersMobile: "Персональные предложения по емейлу",
                smsOffers: "Хочу получать персональные специальные предложения по СМС",
                smsOffersMobile: "Персональные предложения по СМС",
                cancelButton: "Отменить",
                saveButton: "Сохранить изменения"
            },
            privacyPolicyLink: "Принципы обработки личных данных",
            privacyPolicyUrl: "https://olerex.ee/ru/principy-obrabotki-lichnyh-dannyh"
        },
        fuelUsage: {
            title: "Мой расход топлива",
            months: ["ЯНВАРЬ", "ФЕВРАЛЬ", "МАРТ", "АПРЕЛЬ", "МАЙ", "ИЮНЬ", "ИЮЛЬ", "АВГУСТ", "СЕНТЯБРЬ", "ОКТЯБРЬ", "НОЯБРЬ", "ДЕКАБРЬ"],
        },
        fuelPrices: {
            title: "Сегодняшние цены на топливо",
            askPriceModal: {
                cancelButton: "Отменить"
            }
        },
        cards: {
            title: "Карты",
            showAllButton: "Смотреть все"
        },
        bills: {
            title: "Недавние счета",
            showAllButton: "Смотреть все"
        }
    },
    transactions: {
        title: "Операции",
        table: {
            cardColumn: "Карта",
            productColumn: "Товар",
            stationColumn: "Заправка",
            timeColumn: "Время",
            quantityColumn: "Количество",
            sumColumn: "Сумма без НСО",
            sumVatColumn: "Сумма с НСО"
        },
        noContent: "Операций не найдено"
    },
    vouchers: {
        title: "Ваучеры",
        item: {
            validTo: (to) => `Действителен до ${to}`
        },
        email: {
            title: "Отправьте по электронной почте этот ваучер",
            emailField: "Адрес электронной почты получателя",
            sendButton: "Отправить"
        },
        noContent: "Ваучеры не найдены"
    },
    idCredit: {
        title: "Кредитная карта",
        invoicesTab: "Счета",
        incomingsTab: "Поступления",
        headerStats: {
            unpaidTotal: "Сумма к оплате",
            overDeadline: "Просрочено",
            balance: "Сальдо",
            remainingLimit: "Balance",
            referenceNumber: "Номер ссылки"
        },
        content: {
            invoices: "Счета",
            incomings: "Поступления",
            total: (total) => `Всего ${total}`,
            receivedItem: {
                type: "Поступление",
                sum: "Поступившая сумма",
                sumMobile: "СУММА",
                date: "Дата поступления"
            },
            billItem: {
                total: "Сумма к оплате",
                totalMobile: "Сумма",
                date: "Дата счета",
                dateMobile: "Дата",
                paymentDate: "Дата оплаты",
                paymentDateMobile: "День оплаты",
                paid: "Оплачено из счета",
                payButton: "Оплатить"
            }
        },
        noContent: "Счета не найдены"
    },
    electricity: {
        title: 'Elekter',
        createContract: 'Sõlmi leping',
        eurosMonth: '€ / kuu',
        centsKwh: 'senti / kWh',
        cents: 's',
        duringDay: 'päeval',
        atNight: 'öösel',
        period: (period) => {
            if (period.type === 'month') {
                return period.value > 1 ? 'kuud' : 'kuu'
            }
            return period.value > 1 ? 'aastat' : 'aasta'
        },
        meteringPointModal: {
            selectMeteringPoint: 'Vali mõõtepunkt',
            selectPackage: 'Vali pakett',
            search: 'Otsi...',
            meteringPointCode: 'Mõõtepunkti kood',
            selectAll: 'Vali kõik',
            next: 'Edasi',
            pointsSelected: (count) => count + (count === 1 ? ' mõõtepunkt' : ' mõõtepunkti') + ' valitud'
        },
        package: "Pakett",
        packagePrice: 'Paketi hind',
        contractBeginning: 'Lepingu algus',
        contractEnd: 'Lepingu lõpp',
        meteringPoint: "mõõtepunkt",
        meteringPoints: "mõõtepunkti",
        consumptionAddress: "Tarbimiskoha aadress",
        relatedContract: 'Seotud leping',
        meteringPointCode: 'Mõõtepunkti kood',
        tabs: {
            contractsTab: 'Lepingud',
            meteringPointsTab: 'Mõõtepunktid',
            consumptionTab: 'Tarbimus'
        },
        filter: 'Filtreeri',
        boundByContract: 'Lepinguga seotud',
        contractElsewhere: 'Leping mujal',
        newContract: 'Uus leping',
        bringOver: 'Too üle',
        editContract: 'Muuda lepingut',
        endContract: 'Lõpeta leping',
        iAgree: "Nõustun",
        withContract: "Lepinguga",
        toSignPage: "Allkirjastama",
        contractPreviewTitle: "Lepingu eelvaade",
        endContractTitle: "Lepingu lõpetamine",
        endContractCheckbox: (contracts) => 'Saada ' + contracts + (contracts === 1 ? ' leping' : ' lepingut') + ' kliendihaldurile lõpetamiseks',
        confirm: "Kinnita"
    },
    cards: {
        noCards: "Карточки не найдены.",
        grouped: "По группам",
        ungrouped: "Несгруппирован",
        orderBy: "Сортировать",
        header: {
            title: "Карты",
            selectedCards: "Выбранные карты:",
            totalUsed: "Всего использовано:",
            businessLimit: "Лимит компании:",
            newCardButton: "Новая карта",
            searchPlaceholder: "ИСКАТЬ...",
            errorModalTitle: "Ошибка!"
        },
        cardItem: {
            users: n => `${n} kasutajat`,
            expiry: "Действителен до",
            limit: "Лимит карты",
            unused: "Не использовано",
            used: "Использован",
            limitDropdown: "Лимит",
            allowedToBuy: "Разрешено купить",
            param: "Параметр заправки",
            editNameButton: "Изменить имя",
            editLimitButton: "Изменить лимиты",
            extendButton: "заменить истекающий",
            blockButton: "Заблокировать",
            unblockButton: "Oткрыто",
            addParamButton: "Добавить параметр",
            editParamButton: "Изменить параметр",
            activateButton: "Aktiveeri",
        },
        footer: {
            selectedCard: "карточка выбрана",
            selectedCards: "карточек выбрано",
            extendButton: "заменить истекающий",
            editLimitButton: "Изменить лимиты",
            blockButton: "Заблокировать",
            unblockButton: "Oткрыто",
            activateButton: "Aktiveeri",
        },
        cardUsersModal: {
            title: 'Kaardi kasutajad',
            close: 'Sulge',
            remove: 'Eemalda',
            empty: 'Valitud kaardiga seotud kasutajad puuduvad',
            confirmRemove: name => `Kas oled kindel, et soovid kasutaja ${name} kaardilt eemaldada?`,
            blockAddingApp: 'Keela uute kasutajate sidumine valitud kaardiga',
        },
        editCardNameModal: {
            title: "Изменить имя на карточке",
            nameField: "Название карты",
            saveButton: "Изменить имя"
        },
        editCardLimitsModal: {
            title: "Изменить лимиты карточки",
            limitField: "Лимит карты",
            dayLimitField: "Day limit",
            fuelField: "Разрешенные виды топлива",
            fuel1: "Только дизель",
            fuel2: "Все виды топлива",
            productField: "Разрешенные товары",
            product1: "Товары запрещены",
            product2: "Только автомобильные товары",
            product3: "Все товары",
            saveButton: "Сохранить"
        },
        activateCardModal: {
            title: 'Aktiveeri kaart',
            activateButton: 'Aktiveeri kaart',
        },
        blockCardModal: {
            title: "Заблокировать карточку",
            contentBlockCard: (name) => `Вы уверены, что хотите заблокировать карточку с именем ${name}?`,
            contentBlockCards: (count) => `Вы уверены, что хотите заменить выбранные истекающие карточки (${count})?`,
            reasonField: "Причина блокировки",
            blockButton: "Заблокировать карточку"
        },
        unblockCardModal: {
            title: "Oткрыть карточку",
            contentUnblockCard: (name) => `Вы уверены, что хотите открыть карточку с именем ${name}?`,
            contentUnblockCards: (count) => `Вы уверены, что хотите открыть выбранные истекающие карточки (${count})?`,
            blockButton: "Oткрыть карточку"
        },
        extendCardModal: {
            title: "Продлить карточку",
            contentExtendCard: (name) => `Вы уверены, что хотите продлить карточку с именем ${name}?`,
            contentExtendCards: (count) => `Вы уверены, что хотите заменить выбранные истекающие карточки (${count})?`,
            extendButton: "Продлить карточку"
        },
        orderCard: {
            orderCards: "Заказать новую карту",
            cardsOrdered: "Карточки заказаны",
            nameField: "Название карты",
            limitField: "Лимит карты",
            fuelField: "Разрешенные виды топлива",
            fuel1: "Только дизель",
            fuel2: "Все виды топлива",
            productField: "Разрешенные товары",
            product1: "Товары запрещены",
            product2: "Только автомобильные товары",
            product3: "Все товары",
            maxLength: (length, maxLength) => `${length} / ${maxLength} символов`,
            perMonthLimit: "€ в месяц",
            perDayLimit: "€ в день",
            page1: {
                topText: "В поле названия карты можете написать имя работника, номер машины и т.д.",
                fuel1: "Только дизель",
                fuel2: "Все виды топлива",
                productField: "Разрешенные товары",
                product1: "Товары запрещены",
                product2: "Только автомобильные товары",
                product3: "Все товары",
                addCardButton: "Добавить карту",
                nextButton: "Далее"
            },
            page2: {
                topText: "Добавьте имя и фамилию в поле „получателя карты“.",
                pickupField: "Получатель карточки",
                pickupPersonalCode: "Личный код получателя",
                pickupEmail: "Электронная почта получателя",
                pickupLocation: "Место получения карточки",
                locationInfo: "Вы также можете выбрать место на карте, щелкнув по заправочной станции.",
                backButton: "Назад",
                orderButton: "Заказать карточку"
            },
            page3: {
                closeButton: "Закрыть"
            }
        },
        editParamsModal: {
            titleAdd: "добавить параметр заправки",
            titleEdit: "изменить параметр заправки",
            paramField: "параметр заправки",
            editButton: "сохранить параметр",
        }
    },
    invoicing: {
        title: "Расчёт",
        invoicingTab: "Операции",
        invoicesTab: "Счета и поступления",
        incomingsTabMobile: "Поступления",
        invoicesTabMobile: "Счета",
        searchPlaceholder: "ИСКАТЬ...",
        invoices: {
            noInvoices: "Счетов к оплате нет.",
            noIncomings: "Поступления отсутствуют.",
            noContent: "Счета не найдены",
            termsOfSaleLink: "Условия продажи",
            termsOfSaleUrl: "https://olerex.ee/olxweb/documents/Terms_of_Sale_Olerex_ru.pdf"
        },
        transactions: {
            noTransactions: "Операций не найдено"
        }
    },
    manage: {
        header: {
            title: "Управление",
            addressTab: "Адрес",
            contactsTab: "Контактные лица",
            usersTab: "Пользователи",
            managerTab: "Управляющий",
            servicesTab: "Сервисы"
        },
        modal: {
            backButton: "Назад",
            closeButton: "Закрыть",
            maxLength: (length, maxLength) => `${length} / ${maxLength} символов`,
            perMonthLimit: "€ в месяц",
            perDayLimit: "€ päevas",
            companyLimit: (limit) => `Лимит компании: ${limit} €`
        },
        address: {
            title: "Адрес",
            editButton: "Изменить",
            editAddressModal: {
                title: "Изменить адрес",
                addressField: "Адрес",
                saveButton: "Сохранить адрес"
            }
        },
        contacts: {
            title: "Контактные лица",
            addButton: "Еще",
            addContactModal: {
                title: "Добавить контактное лицо",
                nameField: "Имя",
                emailField: "Электронная почта",
                phoneField: "Номер телефона",
                saveButton: "Добавить контактное лицо"
            },
            editContactModal: {
                title: "Изменить контактное лицо",
                nameField: "Имя",
                emailField: "Электронная почта",
                phoneField: "Номер телефона",
                saveButton: "Сохранить контактное лицо"
            },
            deleteContactModal: {
                title: "Удалить контактное лицо",
                content: (name) => `Вы уверены, что хотите удалить контактное лицо с именем ${name}? Удаленное контактное лицо невозможно восстановить.`,
                deleteButton: "Удалить контактное лицо"
            }
        },
        users: {
            title: "Пользователи",
            addButton: "Еще",
            table: {
                nameColumn: "Имя",
                personalCodeColumn: "Личный код",
                emailColumn: "Электронная почта",
                roleColumn: "Роль"
            },
            addUserModal: {
                title: "Добавить пользователя",
                nameField: "Имя",
                emailField: "Электронная почта",
                roleField: "Роль пользователя",
                role1: "Обычный пользователь",
                role2: "Администратор",
                role3: "Правление / Управляющий директор",
                personalCodeField: "Личный код",
                saveButton: "Добавить пользователя"
            },
            editUserModal: {
                title: "Изменить пользователя",
                nameField: "Имя",
                emailField: "Электронная почта",
                roleField: "Роль пользователя",
                role1: "Обычный пользователь",
                role2: "Администратор",
                role3: "Правление / Управляющий директор",
                personalCodeField: "Личный код",
                saveButton: "Сохранить пользователя"
            },
            deleteUsersModal: {
                title: "Удалить пользователя",
                content: (name) => `Вы уверены, что хотите удалить пользователя с именем ${name}? Удаленного пользователя невозможно восстановить.`,
                deleteButton: "Удалить пользователя"
            }
        },
        manager: {
            title: "Мой управляющий по продаже",
            sendEmail: "Отправь е-mail",
            call: "Позвони",
            hours: "8:00 - 17:00",
            weekdays: "В рабочее время"
        },
        services: {
            title: "Дополнительные услуги",
            activated: "Активирован",
            notActivated: "Не активирован",
            activate: "Активировать",
            deactivate: "Деактивировать",
            editActivationModal: {
                activateButton: "Активировать",
                deactivateButton: "Деактивировать"
            }
        }
    },
    wholesale: {
        header: {
            title: 'Wholesale',
            calculatorTab: 'Price calculator',
            orderHistoryTab: 'Order history'
        },
        manager: {
            title: "Мой управляющий по продаже",
            sendEmail: "Отправь е-mail",
            call: "Позвони",
            hours: '8:00–17:00',
            weekdays: "В рабочее время"
        },
        editAddressModal: {
            title: 'Tarne asukoht',
            address: 'Address',
            closeButton: 'Back',
            saveButton: 'Save the aadress',
        },
        orderHistory: {
            title: 'Tellimuste ajalugu',
            table: {
                fuelColumn: 'Kütus',
                addressColumn: "Адрес",
                commentColumn: 'Kommentaar',
                timeColumn: "Время",
                statusColumn: 'Staatus',
                quantityColumn: "Количество",
                sumColumn: "Сумма без НСО",
                sumVatColumn: "Сумма с НСО"
            },
            noContent: 'Tellimusi ei leitud'
        },
        calculator: {
            fuelSection: 'Vali kütuse liik',
            vehicleSection: 'Kütuseveoki tüüp',
            timeSection: 'Kohaletoimetamise ajavahemik',
            quantitySection: 'Kogus liitrites',
            periodSection: 'Tarneperiood',
            addressSection: 'Tarne asukoht',
            commentSection: 'Kommentaar',
            smallVehicleInfo: 'Kütuseveoki (täismass 20t) pääseb rohkematesse kohtadesse aga saab väiksemaid koguseid viia.',
            bigVehicleInfo: 'Kütuseveoki (täismass 36t) pääseb rohkematesse kohtadesse ja saab suuremaid koguseid viia.',
            mtf: 'E-R',
            mts: 'E-P',
            withinDays: '% päeva jooksul',
            change: 'Muuda',
            typeHere: 'Kirjuta siia',
            balance: 'Vaba jääk',
            priceForYouIs: 'Hinnakalkulaatori parameetrite järgi on hind sulle',
            includingVatAndTransport: 'hind koos käibemaksu ja transpordiga',
            sendOrder: 'Esita tellimus',
            priceInfo: 'Hinna arvutamisel võetakse arvesse kütuse liiki, kütuseveoki tüüpi, liitreid, tarneperioodi ja asukohta ja kohaletoimetamise aega.',
            personalOfferInfo: 'Personaalse pakkumise soovil edastatakse teie hinnakalkulaatori päring Olerex kliendihaldurile, kes teiega ühendust võtab.',
            wantPersonalOffer: 'Soovin personaalset pakkumist',
            contactViaPhone: 'Võta ühendust tel teel',
            contactViaEmail: 'Võta ühendust e-maili teel',
            orderSubmitted: 'Tellimus esitatud',
            personalOfferSubmitted: 'Pakkumise soov edastatud',
            error: 'Viga',
            success: 'Esitatud',
            missingAddress: 'Paluh määra tarne asukoht',
            missingContactPreference: 'Palun määra kuidas soovid, et teiega ühendust võetakse',
        },
    },
    virtualFamily: {
        title: "Виртуальная семья",
        newVirtualFamilyButton: "New virtual family",
        membersTab: "Members",
        invitesTab: "Invites",
        header: {
            newLinkButton: "Новая ссылка",
            sendInvitationsButton: "Отправить приглашения",
            addMembersButton: "Добавить участников",
            top1: "К семье присоединились",
            top2: "Активные в этом месяце",
            top3: "Заработанная скидка"
        },
        downloadExcel: "Download as excel",
        families: {
            noContent: "No virtual families",
            familyModal: {
                titleNew: "Create a new virtual family",
                titleEdit: "Edit family name",
                text: "Family name",
                saveButton: "Save",
                createButton: "Create"
            }
        },
        members: {
            table: {
                nameColumn: "Имя",
                personalCodeColumn: "Личный код",
                emailColumn: "Электронная почта",
                statusColumn: "Членство"
            },
            noContent: "В виртуальной семье нету членов",
            bottomBar: {
                closeButton: "Close",
                memberSelected: (count) => `${count} member selected`,
                membersSelected: (count) => `${count} members selected`,
                deactivateMember: "Deactivate selected",
            },
            deactiveModal: {
                title: "Deactivate member",
                text: "Deactivation will remove the selected member(s) from the virtual family.",
                deactivateButton: "Deactivate"
            }
        },
        links: {
            table: {
                linkColumn: "Invite link",
                expiryColumn: "Valid until",
                descriptionColumn: "Description",
                statusColumn: "Status",
                expiredButton: "Expired",
                pauseButton: "Pause",
                activateButton: "Activate",
                copyButton: "Copy",
                editButton: "Edit"
            },
            noContent: "The virtual family does not have any links.",
            toggleLinkModal: {
                deactivateTitle: "Deactivate link",
                deactivateText: "The link will be deactivated.",
                deactivateButton: "Deactivate",
                activateTitle: "Activate link",
                activateTet: "The link will be activated.",
                activateButton: "Activate",
                copiedToClipboard: "Copied to clipboard"
            },
            linkModal: {
                titleNew: "Create a new invite link",
                titleEdit: "Invite link edit",
                descriptionText: "Link description",
                expiresAtText: "Expires in",
                expiresInDay: (days) => `${days} day`,
                expiresInDays: (days) => `${days} days`,
                saveButton: "Save",
                createButton: "Create"
            }
        }
    },
    general: {
      downloadFile: 'Скачать файл'
    }
};
