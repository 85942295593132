import React, { Component } from 'react';
import {withCloseOnEsc} from "../../../../components/withCloseOnEsc";
import {connect} from "react-redux";
import IconModalCross16px from "../../../../assets/Icon_Modal-Cross_16px.svg";
import ContentContainer from "../../../../components/ContentContainer";
import './MeteringPointModal.scss';

export const MeteringPointModal = withCloseOnEsc(connect(
    state => ({ translation: state.translation.electricity.meteringPointModal}),
    undefined
)(
    class MeteringPointModal extends Component {
        state = {
            searchValue: '',
            meteringPoints: this.props.meteringPoints
        };

        constructor(props) {
            super(props);
            this.onSelectPoint = this.onSelectPoint.bind(this);
        }

        setSelectedMeteringPoints = () => {
            this.props.setSelectedMeteringPoints(this.state.meteringPoints);
            this.props.onCloseButtonClick();
        };

        filterMeteringPoints() {
            const searchValue = this.state.searchValue.toLocaleLowerCase();
            return this.state.meteringPoints
                .filter(p =>
                    p.title.toLocaleLowerCase().indexOf(searchValue) !== -1 ||
                    p.info.toLocaleLowerCase().indexOf(searchValue) !== -1 ||
                    p.code.toLocaleLowerCase().indexOf(searchValue) !== -1
                );
        }

        onSelectPoint(point) {
            this.setState({
                meteringPoints: this.state.meteringPoints
                    .map(p => p.code === point.code ? {...p, selected: !p.selected} : p)
            });
        }

        toggleAll(selected) {
            this.setState({ meteringPoints: this.state.meteringPoints.map(p => ({ ...p, selected: selected })) });
        }

        getActivePoints() {
            return this.filterMeteringPoints().filter(p => p.selected);
        }

        render() {
            const activePointCount = this.getActivePoints().length;
            const footer = activePointCount ?
                <div className="select-metering-point-modal-footer">
                    <div className="metering-points-selected">
                        {this.props.translation.pointsSelected(activePointCount)}
                        {activePointCount > 0 ? <img src={IconModalCross16px} alt="close" onClick={() => this.toggleAll(false)} /> : ''}
                    </div>
                    <button onClick={() => this.setSelectedMeteringPoints()} className="btn">{this.props.translation.next}</button>
                </div> : null;
            return (
                <ContentContainer {...{
                    className: `select-metering-point-modal`,
                    title: <h1 className="select-metering-point-modal_title">{this.props.translation.selectMeteringPoint}</h1>,
                    action:
                        <button className="select-metering-point-modal_close-button" onClick={this.props.onCloseButtonClick}>
                            <img className="select-metering-point-modal_close-button_img" src={IconModalCross16px} alt="close" />
                        </button>,
                    content:
                        <>
                            <div className="select-metering-point-modal-content">
                                <div className="select-metering-point-modal-content_content">
                                    <div className="select-metering-point-modal-content_search-row">
                                        <span className="select-metering-point-modal-content_search-box">
                                            <input
                                                type="text"
                                                className={`search-box${this.state.searchValue ? ' has-value' : ''}`}
                                                placeholder={this.props.translation.search}
                                                onChange={(e) => this.setState({ searchValue: e.target.value })}
                                                value={this.state.searchValue}
                                            />
                                            <div className="cancel-search" onClick={() => this.setState({ searchValue: '' })}>
                                                <img className="select-metering-point-modal_close-button_img" src={IconModalCross16px} alt="close" />
                                            </div>
                                        </span>
                                        <button className="btn btn-white" onClick={() => this.toggleAll(true)}>{this.props.translation.selectAll}</button>
                                    </div>
                                    <div className="select-metering-point-modal-content_metering_points">
                                        {this.filterMeteringPoints().map((p, i) =>
                                            <div
                                                key={`mp${i}`}
                                                className={`metering-point-row ${p.selected ? 'selected' : ''}`}
                                                onClick={() => this.onSelectPoint(p)}
                                            >
                                                <div className="metering-point-details">
                                                    <div className="metering-point-title">{p.title}</div>
                                                    <div className="metering-point-info">{p.info}</div>
                                                </div>
                                                <div className="metering-point-code">
                                            <span className="label">
                                                {this.props.translation.meteringPointCode}
                                            </span>
                                                    <span className="code">
                                                {p.code}
                                            </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {footer}
                        </>
                }} />
            )
        }
    }
));
