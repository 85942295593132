import React from 'react';
import { connect } from 'react-redux';
import TabLink from '../../components/TabLink';
import './Header.scss';

const Header = React.memo(props => (
    <div className="manage-header">
        <h1 className="manage-header_title">{props.translation.title}</h1>
        <div className="manage-header_tabs">
            <TabLink {...{ text: props.translation.addressTab, to: "/manage#address" }} />
            <TabLink {...{ text: props.translation.contactsTab, to: "/manage#contacts" }} />
            <TabLink {...{ text: props.translation.usersTab, to: "/manage#users" }} />
            <TabLink {...{ text: props.translation.managerTab, to: "/manage#manager" }} />
            <TabLink {...{ text: props.translation.servicesTab, to: "/manage#services" }} />
        </div>
    </div>
));

export default connect(state => ({ translation: state.translation.manage.header }), null)(Header);