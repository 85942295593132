import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import DropdownFilter from '../../../components/DropdownFilter';
import ToolbarSeparator from '../../../components/ToolbarSeparator';
import "./ConsumptionFilters.scss";
import IconChevronDownGray6px from '../../../assets/Icon_Chevron-Down_Gray-6px.svg';

const ConsumptionFilters = React.memo(props => (
    <div className={`transaction-filter-items ${props.alignToStart ? "align-to-start" : ""}`}>
        <TransitionGroup component={null}>
            {props.items && props.items.map((item, index) =>
                <CSSTransition key={index} timeout={{ enter: 600, exit: 600 }} classNames={props.alignToStart ? "align-to-start-filter" : "filter"}>
                    <>
                        <DropdownFilter {...{
                            ...item,
                            rightImg: ((item.items && item.items.length > 0) ? IconChevronDownGray6px : ''),
                            distance: -72
                        }} />
                        {index < props.items.length - 1 && <ToolbarSeparator />}
                    </>
                </CSSTransition>)}
        </TransitionGroup>
    </div>
));

export default ConsumptionFilters;
