import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ContentContainer from '../../../components/ContentContainer';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Loader from '../../../components/Loader';
import './FuelPrices.scss';
import Tippy from '@tippy.js/react';
import IconDownload from '../../../assets/Icon_Download.svg';
import AskPriceModal from './AskPriceModal';

const Item = React.memo(props => {
    return (
        <div className="fuel-price-item">
            <div className="fuel-price-item_card">
                <img className="fuel-price-item_img" src={props.iconUrl} alt="" />
                <p className="fuel-price-item_title">{props.text}</p>
                <p className="fuel-price-item_price" title={props.neto}>
                    {!props.askPrice ? props.bruto : <button type="button" onClick={() => props.onAskPrice({
                        askPriceText: props.bruto,
                        country: props.text
                    })}>{props.bruto}</button>}
                </p>
            </div>
            <div className="fuel-price-item_locations">
                {props.items && props.items.map((item, index) =>
                    <Tippy arrow={true} key={index}
                        isEnabled={!!item.neto}
                        content={(item && item.neto + '') || ""}
                        placement="top-end"
                    >
                        <p className="fuel-price-item_location" key={index}>
                            <span className="fuel-price-item_location_title">{item.text}</span>
                            <span className="fuel-price-item_location_price">
                                {item.bruto}
                            </span>
                        </p>
                    </Tippy>
                    )}
            </div>
        </div>
    );
}

);

const Collection = React.memo(props =>
    <div className="fuel-price-items">
        {props.items && props.items.map((item, index) => <Item {...{ ...item, ...{ key: index } }} onAskPrice={props.onAskPrice} />)}
    </div>
);

class FuelPrices extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { selectedFuelType: 0 };
    }

    handleSelectedFuelTypeChanged = (selectedFuelType) => {
        this.setState({ selectedFuelType: selectedFuelType });
    }

    render() {
        const todaysPrices = this.props.todaysPrices && Object.entries(this.props.todaysPrices).map(x =>
            ({
                text: x[0],
                items: Object.entries(x[1]).map(y =>
                    ({
                        text: y[0],
                        bruto: y[1]["lowestPrice"],
                        neto: y[1]["lowestPriceNeto"],
                        iconUrl: y[1]["iconUrl"],
                        askPrice: y[1].askPrice,
                        items: (y[1]["chainPrices"] && Object.entries(y[1]["chainPrices"]).map(z =>
                            ({
                                text: z[0],
                                bruto: z[1].bruto,
                                neto: z[1].neto
                            }))) || []
                    }))
            }));
        const selectedFuel = todaysPrices && todaysPrices.length > this.state.selectedFuelType ? todaysPrices[this.state.selectedFuelType] : null;
        return (
            <ContentContainer className="fuel-prices" {...{
                title: <div className="fuel-prices-header">
                    <h1 className="fuel-prices_title">{this.props.translation.title}
                        <button className="bill-item_download-button vertical-align-text-top" onClick={this.props.downloadTodaysPrices}>
                            <Tippy arrow={true}
                            isEnabled={true}
                            content={this.props.generalTranslation.downloadFile || ""}
                            >
                            <img className="bill-item_download-button_img co-btn-image-24px_img" src={IconDownload} alt="download" />
                            </Tippy>
                        </button>
                    </h1>
                    <div className="fuel-prices-buttons">
                        <TransitionGroup component={null}>
                            {todaysPrices && todaysPrices.map((value, index) => <CSSTransition key={index} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                <button className={`fuel-prices-button ${this.state.selectedFuelType === index ? "active" : ""}`}
                                    onClick={() => this.handleSelectedFuelTypeChanged(index)}>
                                    <span className="fuel-prices-button_text">{value.text}</span>
                                </button>
                            </CSSTransition>)}
                        </TransitionGroup>
                    </div>
                </div>,
                content:
                    <div className="fuel-prices-content">
                        <TransitionGroup component={null}>
                            {this.props.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                <Loader {...{ className: "fuel-prices-loader" }} />
                            </CSSTransition>}
                            {!this.props.isLoaderVisible && <CSSTransition key={"content-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                <Collection
                                    {...selectedFuel}
                                    onAskPrice={(item) => this.props.onModalOpen(<AskPriceModal {...this.props} {...item} fuel={selectedFuel && selectedFuel.text} />)}
                                />
                            </CSSTransition>}
                        </TransitionGroup>
                    </div>
            }} />
        );
    }
}

export default connect(state => ({ translation: state.translation.overview.fuelPrices, generalTranslation: state.translation.general }))(FuelPrices);