import React from 'react';
import { VictoryPie } from "victory";
import Tippy from '@tippy.js/react'
import './Dials.scss';

const Dial = React.memo(props =>
    <div className={`dial ${props.outlined ? "outlined" : ""} ${props.onClick ? "clickable" : ""} ${props.className ? props.className : ""}`} onClick={props.onClick}>
        <p className={`dial-text-top font-weight-${(props.before && props.before.bold && 'bold') || 'normal'}`}>{(props.before && props.before.text) || props.header}</p>
        <h1 className="dial-text-middle">
            {props.value || props.data}
        </h1>
        <p className={`dial-text-bottom font-weight-${(props.after && props.after.bold && 'bold') || 'normal'}`}>{(props.after && props.after.text) || props.footer}</p>
        { props.valueFloating &&
            <Tippy arrow={true}
                isEnabled={!!props.valueFloating.title}
                content={props.valueFloating.title || ''}
                placement="top-middle"
            >
                <span className="dial-text-floating" style={{ cursor: props.valueFloating.title ? 'pointer' : 'default' }}>{ props.valueFloating.value }</span>
            </Tippy>
        }
    </div>
);

const Dials = React.memo(props => {
    const infoBubble2Fill = (props.infoBubble2 && props.infoBubble2.fill) || 0;
    return (<div className="dials">
        <Dial {...{ ...props.infoBubble1, className: "first" }} />
        <div className="dial-large second">
            <Dial {...{ ...props.infoBubble2, outlined: true }} />
            {props.infoBubble2 && <div className="dial-large-completion">
                <VictoryPie
                    padding={18}
                    innerRadius={170}
                    data={[infoBubble2Fill, 360 - infoBubble2Fill]}
                    padAngle={0}
                    labels={() => ""}
                    style={{
                        data: {
                            fill: d => d._x === 0 ? "#FFCD00" : "#1E1E1E"
                        }
                    }}
                />
            </div>}
            {props.infoBubble2 && <>
                <div className="dial-large-completion-bg-mobile"></div>
                <div className="dial-large-completion-mobile" style={{ width: `calc(${Math.min(100, Math.max(0, infoBubble2Fill / 360 * 100))}% - 16px)` }}></div>
            </>}
        </div>
        <Dial {...{ ...props.infoBubble3, onClick: props.onRightDialClick, className: "third" }} />
    </div>)
}
);

export default Dials;
