import React, { Component } from 'react';
import { connect } from 'react-redux';

import { TableDataCell, TableHeaderCell } from '../../components/Table';
import { fmtPrice } from '../utils';
import styles from './OrderHistoryTable.module.scss';

const paddingTop = 16;

const fmtDate = s => s.replace(/^(\d+)-(\d+)-(\d+)$/, (_, y, m, d) => `${d}.${m}.${y}`);
const fmtTime = s => s.replace(/^(\d+:\d+):\d+$/, (_, time) => time);

export const fmtQuantity = (() => {
    const format = new Intl.NumberFormat('en');
    return price => format.format(price).replace(/,/g, ' ');
})();

const nl2br = text => text.split('\n').map((item, key) => (
    <span key={key}>
        {item}
        <br />
    </span>
));

const TableHeaderRow = connect(state => ({
    translation: state.translation.wholesale.orderHistory.table,
}))(React.memo(props =>
    <tr className={`${styles.headerRow} ${props.className ? props.className : ''}`} style={props.style}>
        <TableHeaderCell {...{
            ...props,
            className: `${styles.left} ${styles.fuel}`,
            text: props.translation.fuelColumn,
            orderWhat: 'fuel',
        }} />
        <TableHeaderCell {...{ ...props,
            className: `${styles.left} ${styles.address}`,
            text: props.translation.addressColumn,
            orderWhat: 'address',
        }} />
        <TableHeaderCell {...{ ...props,
            className: `${styles.left} ${styles.comment}`,
            text: props.translation.commentColumn,
            orderWhat: 'comment',
        }} />
        <TableHeaderCell {...{ ...props,
            className: `${styles.left} ${styles.status}`,
            text: props.translation.statusColumn,
            orderWhat: 'status',
        }} />
        <TableHeaderCell {...{ ...props,
            className: `${styles.left} ${styles.time}`,
            text: props.translation.timeColumn,
            orderWhat: 'time',
        }} />
        <TableHeaderCell {...{ ...props,
            className: `${styles.right} ${styles.quantity}`,
            text: props.translation.quantityColumn,
            orderWhat: 'quantity',
        }} />
        <TableHeaderCell {...{ ...props,
            className: `${styles.right} ${styles.sum}`,
            text: props.translation.sumColumn,
            orderWhat: 'sum',
        }} />
        <TableHeaderCell {...{ ...props,
            className: `${styles.right} ${styles.sumvat}`,
            text: props.translation.sumVatColumn,
            orderWhat: 'sumVat',
        }} />
    </tr>
));

const TableDataRow = React.memo(props =>
    <tr className={styles.bodyRow} onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut}>
        <TableDataCell {...{
            className: `${styles.left} ${styles.fuel}`,
            content: <span className="table-text-14px-bold">{props.fuelName}</span>
        }} />
        <TableDataCell {...{
            className: `${styles.left} ${styles.address}`,
            content: <span className="table-text-14px-regular">{props.address}</span>
        }} />
        <TableDataCell {...{
            className: `${styles.left} ${styles.comment}`,
            content: <span className="table-text-14px-regular">{nl2br(props.comment)}</span>
        }} />
        <TableDataCell {...{
            className: `${styles.left} ${styles.status}`,
            content: <span className="table-text-14px-regular">{props.status}</span>
        }} />
        <TableDataCell {...{
            className: `${styles.left} ${styles.time}`,
            content: <><span className="table-text-14px-regular">{`${fmtDate(props.date)}`}</span><br />
                <span className="table-text-12px-subtitle">{fmtTime(props.time)}</span></>
        }} />
        <TableDataCell {...{
            className: `${styles.right} ${styles.quantity}`,
            content: <><span className="table-text-14px-regular">{fmtQuantity(props.quantity)}</span><br />
                <span className="table-text-12px-subtitle">{props.price} / L</span></>
        }} />
        <TableDataCell {...{
            className: `${styles.right} ${styles.sum}`,
            content: <><span className="table-text-14px-regular">{fmtPrice(props.amount)}&euro;</span><br />
                <span className="table-text-12px-subtitle">+ {fmtPrice(props.vat)} km</span></>
        }} />
        <TableDataCell {...{
            className: `${styles.right} ${styles.sumvat}`,
            content: <span className="table-text-24px-bold">{fmtPrice(props.fullAmount)}&euro;</span>
        }} />
    </tr>
);

export default class OrderHistoryTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scrollX: paddingTop,
        };
    }

    onScroll = (e) => {
        this.setState({
            scrollX: -e.target.scrollLeft + paddingTop,
        });
    }
    render() {
        return (
            <div className={styles.wrapper} onScroll={this.onScroll}>
                <div className={styles.marginWrapper}>
                    <table className={styles.table}>
                        <thead>
                            <TableHeaderRow {...{
                                style: this.props.isMobileLayout ? { left: `${this.state.scrollX}px` } : {},
                                className: `${this.props.stickyHeaderClass} ${this.props.headerRowClass}`,
                                selectedOrderHow: this.props.selectedOrderHow,
                                selectedOrderWhat: this.props.selectedOrderWhat,
                                selectedOrderSearch: this.props.selectedOrderSearch,
                                onTableColumnFilterSelected: this.props.onTableColumnFilterSelected
                            }} />
                            <TableHeaderRow {...{
                                className: this.props.headerRowClass,
                                selectedOrderHow: this.props.selectedOrderHow,
                                selectedOrderWhat: this.props.selectedOrderWhat,
                                selectedOrderSearch: this.props.selectedOrderSearch,
                                onTableColumnFilterSelected: this.props.onTableColumnFilterSelected
                            }} />
                        </thead>
                        <tbody>
                            {this.props.items && this.props.items.map((item, index) => <TableDataRow {...{
                                ...item,
                                key: index,
                                onMouseOver: (e) => this.props.onMouseOverElement(index),
                                onMouseOut: (e) => this.props.onMouseOutElement()
                            }} />)}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
