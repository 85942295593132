import GoogleMapReact  from 'google-map-react';
import React, { Component } from 'react';
import { geocodeByAddress } from 'react-places-autocomplete';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { businessWholeSaleRequest } from '../../../../Networking';
import { roleOrLanguageChanged } from '../../../../Utils';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import Textarea from '../../components/Textarea';
import MessageModal from '../../modal/message/MessageModal';
import AddressModal from './AddressModal';
import Slider from './Slider';

import { mapStyle } from '../../../assets/MapStyle';
import MapMarker from '../../assets/Map_Marker.svg';
import { fmtPrice, parseGeocodeResult } from '../utils';
import styles from './Calculator.module.scss';

function buildFuelValues(min, max, step = 500) {
    const values = [];
    let value;

    for (value = min; value <= max; value += step) {
        values.push(value);
    }

    if (value !== max) {
        values.push(max);
    }

    return values;
}

function getFuelQty(values, qty) {
    if (values.includes(qty)) {
        return qty;
    }

    const min = Math.min(...values);
    const max = Math.max(...values);

    return (qty < min) ? min : max;
}

function getDeliveryType(weekday, time) {
    if (weekday === 'mf') {
        return time === '8-17' ? 'b1' : 'b2';
    }
    return time === '8-17' ? 'b3' : 'b4';
}

const makeDays = (min, max) => Array.from({ length: max - min + 1 }, (_, i) => i + min);
const daysValues = makeDays(2, 30);

const formatAddress = addr => [
    addr.street,
    addr.city,
    addr.county,
    addr.state,
    'Estonia',
].filter(Boolean).join(', ');

const Marker = React.memo(props =>
    <div className="address-marker"><img src={MapMarker} alt="" /></div>
);

const RadioInput = ({ label, ...props }) => (
    <label className={styles.radio}>
        <input type="radio" {...props} />
        <span>{label}</span>
    </label>
);

const VehicleRadioInput = ({ label, kind, ...props }) => (
    <label className={styles.vehicleRadio}>
        <input type="radio" {...props} />
        <span>
            <span className={`${styles.icon} ${styles[kind]}`}></span>
            <span className={styles.text}>{label}</span>
        </span>
    </label>
);

const CheckboxInput = ({ label, ...props }) => (
    <label className={styles.checkbox}>
        <input type="checkbox" {...props} />
        <span className={styles.box}></span>
        <span className={styles.label}>{label}</span>
    </label>
);

const ManagerBox = connect(state => ({
    translation: state.translation.wholesale.manager,
}))(({ translation, manager }) => (
    <div className={styles.manager}>
        <div className={styles.detailsPane}>
            <h2>{translation.title}</h2>

            <div className={styles.person}>
                {manager.imageUrl && <img src={manager.imageUrl} alt="" />}
                <div className={styles.details}>
                    <div className={styles.name}>{manager.name}</div>
                    <div className={styles.contacts}>
                        {manager.email && <div>{manager.email}</div>}
                        {manager.telefon && <div>{manager.telefon}</div>}
                    </div>
                </div>
            </div>
        </div>

        <div className={styles.contactsPane}>
            <div className={styles.item}>
                <div className={`${styles.icon} ${styles.email}`} />
                <div className={styles.action}>{translation.sendEmail}</div>
                <div className={styles.subtext}>{manager.email}</div>
            </div>
            <div className={styles.item}>
                <div className={`${styles.icon} ${styles.phone}`} />
                <div className={styles.action}>{translation.call}</div>
                <div className={styles.subtext}>{manager.telefon}</div>
            </div>
            <div className={styles.item}>
                <div className={`${styles.icon} ${styles.hours}`} />
                <div className={styles.action}>{translation.hours}</div>
                <div className={styles.subtext}>{translation.weekdays}</div>
            </div>
        </div>
    </div>
));

class Calculator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoadingInit: true,
            isLoadingPrice: false,
            manager: null,
            balance: null,
            fuelTypes: [],
            currentFuelType: null,
            currentFuelQty: null,
            currentVehicleType: '1',
            currentWeekday: 'mf',
            currentTime: '8-17',
            currentDays: daysValues[0],
            currentAddress: null,
            currentLatLng: {
                lat: 58.3552502943993,
                lng: 26.722270133821746,
            },
            currentComment: '',
            currentContactViaPhone: true,
            currentContactViaEmail: false,
            price: null,
            priceQty: null,
        };

        this.priceTimer = null;

        this.onFuelTypeChange = this.onFuelTypeChange.bind(this);
        this.onPriceInputChange = this.onPriceInputChange.bind(this);
        this.onCommentChange = this.onCommentChange.bind(this);
        this.onContactViaChange = this.onContactViaChange.bind(this);
        this.onCalculate = this.onCalculate.bind(this);
        this.onOrder = this.onOrder.bind(this);
        this.onPersonalOffer = this.onPersonalOffer.bind(this);
        this.onEditAddress = this.onEditAddress.bind(this);
    }

    componentDidMount() {
        this.initialize();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (roleOrLanguageChanged(prevProps, this.props)) {
            this.initialize();
        }
    }

    initialize() {
        this.setState({ isLoadingInit: true }, async () => {
            let records;

            try {
                records = await this.request({ requestType: 'status' });
            } catch (err) {
                console.error(err);
                return;
            }

            if (!records.wholesale_allowed) {
                window.location = '/';
                return;
            }

            const fuelTypes = [];

            for (const [id, fuel] of Object.entries(records.fuel_types)) {
                const values = fuel.values || buildFuelValues(fuel.min, fuel.max);
                if (values.length > 0) {
                    fuelTypes.push({ id, values, name: fuel.name });
                }
            }

            let currentAddress = null;
            let currentLatLng = null;

            if (records.client_address) {
                const results = await geocodeByAddress(formatAddress(records.client_address));

                if (results.length > 0) {
                    const parsed = await parseGeocodeResult(results[0]);
                    currentAddress = parsed.address;
                    currentLatLng = parsed.latLng;
                }
            }

            this.setState({
                isLoadingInit: false,
                balance: records.limit,
                manager: records.manager,
                fuelTypes,
                currentFuelType: fuelTypes[0],
                currentFuelQty: fuelTypes[0].values[0],
                currentAddress,
                currentLatLng,
            }, () => {
                this.onCalculate();
            });
        });
    }

    request(body) {
        return new Promise((resolve, reject) => {
            this.props.businessWholeSaleRequest({
                accNr: this.props.login.currentRole.accnr,
                userId: this.props.login.currentRole.userId,
                token: this.props.login.token,
                ...body,
            }, ({ status, records }) => {
                if (status.error) {
                    reject(new Error(`Error ${status.error}: ${status.message}`));
                } else if (records.statusCode !== 200) {
                    reject(new Error(`Error ${records.statusCode}: ${records.result}`));
                } else {
                    resolve(records);
                }
            }, reject);
        });
    }

    onFuelTypeChange(event) {
        const fuelTypeId = event.target.value;

        this.setState((prevState) => {
            const fuelType = prevState.fuelTypes.find(f => f.id === fuelTypeId);

            return {
                currentFuelType: fuelType,
                currentFuelQty: getFuelQty(fuelType.values, prevState.currentFuelQty),
            };
        }, () => {
            this.onPriceParamsChanged();
        });
    }

    onPriceValueChange(name, value) {
        this.setState({ [name]: value }, () => {
            this.onPriceParamsChanged();
        });
    }

    onPriceInputChange(event) {
        this.setState({ [event.target.name]: event.target.value }, () => {
            this.onPriceParamsChanged();
        });
    }

    onCommentChange(event) {
        this.setState({ currentComment: event.target.value });
    }

    onContactViaChange(event) {
        this.setState({ [event.target.name]: event.target.checked });
    }

    onPriceParamsChanged() {
        if (this.state.price === null) {
            return;
        }

        this.setState({ isLoadingPrice: true }, () => {
            if (this.priceTimer !== null) {
                clearTimeout(this.priceTimer);
            }
            this.priceTimer = setTimeout(this.onCalculate, 900);
        });
    }

    onCalculate() {
        this.setState({ isLoadingPrice: true }, async () => {
            const req = this.getRequestData();
            const result = await this.request({
                requestType: 'calculate',
                values: req,
            });

            this.setState({
                isLoadingPrice: false,
                price: parseFloat(result.price),
                priceQty: req.quantity,
            });
        });
    }

    onOrder(event) {
        const req = this.getRequestData();

        this.setState({ isLoadingPrice: true }, async () => {
            await this.request({
                requestType: 'addOrder',
                values: req,
            });

            this.onShowMessage(this.props.translation.orderSubmitted, 'success', () => {
                window.location.reload();
            });

            this.setState({ isLoadingPrice: false });
        });
    }

    onPersonalOffer(event) {
        const req = {
            ...this.getRequestData(),
            responseType: [],
        };

        if (this.state.currentContactViaPhone) {
            req.responseType.push('contactViaPhone');
        }

        if (this.state.currentContactViaEmail) {
            req.responseType.push('contactViaEmail');
        }

        if (!req.latitude || !req.longitude || !req.address) {
            this.onShowMessage(this.props.translation.missingAddress);
            return;
        }

        if (!req.responseType.length) {
            this.onShowMessage(this.props.translation.missingContactPreference);
            return;
        }

        this.setState({ isLoadingPrice: true }, async () => {
            await this.request({
                requestType: 'sendOrder',
                values: req,
            });

            this.onShowMessage(this.props.translation.personalOfferSubmitted, 'success', () => {
                window.location.reload();
            });

            this.setState({ isLoadingPrice: false });
        });
    }

    onEditAddress() {
        this.props.onModalOpen(<AddressModal
            onModalClose={this.props.onModalClose}
            onSave={info => {
                this.setState({
                    currentAddress: info.address,
                    currentLatLng: info.latLng,
                }, () => {
                    this.onPriceParamsChanged();
                });
            }} />);
    }

    onShowMessage(message, kind = 'error', callback) {
        this.props.onModalOpen(<MessageModal
            title={this.props.translation[kind]}
            text={message}
            kind={kind}
            onModalClose={() => {
                if (typeof callback === 'function') {
                    setTimeout(callback, 0);
                }
                this.props.onModalClose();
            }} />);
    }

    getRequestData() {
        return {
            fuel: this.state.currentFuelType?.id,
            truck: this.state.currentVehicleType,
            quantity: this.state.currentFuelQty,
            days: this.state.currentDays,
            delivery: getDeliveryType(this.state.currentWeekday, this.state.currentTime),
            latitude: this.state.currentLatLng?.lat,
            longitude: this.state.currentLatLng?.lng,
            address: this.state.currentAddress?.formatted,
            price: this.state.price,
            comment: this.state.currentComment,
        };
    }

    get displayPrice() {
        if (!this.state.price) {
            return '0';
        }
        return (this.state.priceQty * this.state.price).toFixed(2);
    }

    get isReadyToOrder() {
        return !this.state.isLoadingPrice && this.state.price && this.state.currentLatLng;
    }

    render() {
        return (
            <div className={styles.page}>
                <TransitionGroup component={null}>
                    {this.state.isLoadingInit ?
                        <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <Loader  />
                        </CSSTransition> :
                        <CSSTransition key={"olerex-plus-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <form>
                                <div className={styles.calculatorContainer}>
                                    <div className={styles.calculatorContent}>
                                        <div className={styles.controlsSide}>
                                            <div className={styles.section}>
                                                <h2>{this.props.translation.fuelSection}</h2>
                                                <div className={`${styles.radioRow} ${styles.fuelTypes}`}>
                                                    <div className={styles.group}>
                                                        {this.state.fuelTypes.map(fuel =>
                                                            <RadioInput
                                                                label={fuel.name}
                                                                key={fuel.id}
                                                                value={fuel.id}
                                                                name="fuelType"
                                                                onChange={this.onFuelTypeChange}
                                                                checked={this.state.currentFuelType?.id === fuel.id} />)}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={styles.section}>
                                                <h2>{this.props.translation.vehicleSection}</h2>

                                                <div className={styles.vehicleRadioRow}>
                                                    <VehicleRadioInput
                                                        name="currentVehicleType"
                                                        kind="small"
                                                        value="0"
                                                        label={this.props.translation.smallVehicleInfo}
                                                        checked={this.state.currentVehicleType === '0'}
                                                        onChange={this.onPriceInputChange} />

                                                    <VehicleRadioInput
                                                        name="currentVehicleType"
                                                        kind="big"
                                                        value="1"
                                                        label={this.props.translation.bigVehicleInfo}
                                                        checked={this.state.currentVehicleType === '1'}
                                                        onChange={this.onPriceInputChange} />
                                                </div>
                                            </div>

                                            <div className={styles.section}>
                                                <h2>{this.props.translation.timeSection}</h2>

                                                <div className={styles.radioRow}>
                                                    <div className={styles.group}>
                                                        <RadioInput
                                                            label={this.props.translation.mtf}
                                                            name="currentWeekday"
                                                            value="mf"
                                                            checked={this.state.currentWeekday === 'mf'}
                                                            onChange={this.onPriceInputChange} />

                                                        <RadioInput
                                                            label={this.props.translation.mts}
                                                            name="currentWeekday"
                                                            value="ms"
                                                            checked={this.state.currentWeekday === 'ms'}
                                                            onChange={this.onPriceInputChange} />
                                                    </div>

                                                    <div className={styles.group}>
                                                        <RadioInput
                                                            label="8–17:00"
                                                            value="8-17"
                                                            name="currentTime"
                                                            checked={this.state.currentTime === '8-17'}
                                                            onChange={this.onPriceInputChange} />

                                                        <RadioInput
                                                            label="8–22:00"
                                                            value="8-22"
                                                            name="currentTime"
                                                            checked={this.state.currentTime === '8-22'}
                                                            onChange={this.onPriceInputChange} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={styles.section}>
                                                <h2>{this.props.translation.quantitySection}</h2>
                                                {this.state.currentFuelType && <Slider
                                                    name="fuelQty"
                                                    values={this.state.currentFuelType.values}
                                                    value={this.state.currentFuelQty}
                                                    onChange={v => this.onPriceValueChange('currentFuelQty', v)}
                                                    unit="L"
                                                    kind="fuel" />}

                                                <h2>{this.props.translation.periodSection}</h2>
                                                <Slider
                                                    name="days"
                                                    values={daysValues}
                                                    value={this.state.currentDays}
                                                    onChange={v => this.onPriceValueChange('currentDays', v)}
                                                    unit={this.props.translation.withinDays}
                                                    kind="days" />
                                            </div>

                                            <div className={`${styles.section} ${styles.addressSection}`}>
                                                <div className={styles.address}>
                                                    <div className={styles.titleBar}>
                                                        <h2>{this.props.translation.addressSection}</h2>
                                                        <button className={styles.edit} type="button" onClick={this.onEditAddress}>{this.props.translation.change}</button>
                                                    </div>

                                                    {this.state.currentAddress && (
                                                        <>
                                                            <div className={styles.addr}>
                                                                {this.state.currentAddress.street}<br />
                                                                {this.state.currentAddress.city}
                                                            </div>
                                                            <div className={styles.subaddr}>
                                                                {this.state.currentAddress.county}<br />
                                                                {this.state.currentAddress.country}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>

                                                {this.state.currentLatLng && <div className={styles.map}>
                                                    <GoogleMapReact
                                                        bootstrapURLKeys={{
                                                            key: 'AIzaSyAmZsw-uujy2JfcBeiFezmxLUJFUzwhRpg'
                                                        }}
                                                        center={[this.state.currentLatLng.lat, this.state.currentLatLng.lng]}
                                                        defaultZoom={14}
                                                        options={maps => ({ styles: mapStyle })}
                                                    >
                                                        <Marker latLng={this.state.currentLatLng} />
                                                    </GoogleMapReact>
                                                </div>}
                                            </div>

                                            <div className={styles.section}>
                                                <h2>{this.props.translation.commentSection}</h2>
                                                <Textarea
                                                    value={this.state.currentComment}
                                                    onChange={this.onCommentChange}
                                                    placeholder={this.props.translation.typeHere}
                                                    maxLength={255} />
                                            </div>
                                        </div>
                                        <div className={styles.resultsSide}>
                                            <div className={styles.balancePane}>
                                                <h2>{this.props.translation.balance}</h2>
                                                <span className={styles.value}>{fmtPrice(this.state.balance)} &euro;</span>
                                            </div>

                                            <div className={styles.pricePane}>
                                                <h2>{this.props.translation.priceForYouIs}</h2>

                                                <div className={styles.price}>
                                                    <div className={styles.value}>{fmtPrice(this.displayPrice)} &euro;</div>
                                                    <div className={styles.note}>{this.props.translation.includingVatAndTransport}</div>
                                                </div>

                                                <Button type="button" onClick={this.onOrder} disabled={!this.isReadyToOrder}>
                                                    {this.props.translation.sendOrder}
                                                </Button>

                                                <div className={styles.explain}>
                                                    {this.props.translation.priceInfo}
                                                </div>
                                            </div>

                                            {this.state.price &&
                                                <div className={styles.offerPane}>
                                                    <h2>{this.props.translation.personalOfferInfo}</h2>
                                                    <Button kind="secondary" type="button" onClick={this.onPersonalOffer}>{this.props.translation.wantPersonalOffer}</Button>

                                                    <CheckboxInput
                                                        name="currentContactViaPhone"
                                                        label={this.props.translation.contactViaPhone}
                                                        checked={this.state.currentContactViaPhone}
                                                        onChange={this.onContactViaChange} />

                                                    <CheckboxInput
                                                        name="currentContactViaEmail"
                                                        label={this.props.translation.contactViaEmail}
                                                        checked={this.state.currentContactViaEmail}
                                                        onChange={this.onContactViaChange} />
                                                </div>}
                                        </div>
                                    </div>
                                </div>

                                {this.state.manager && <ManagerBox manager={this.state.manager} />}
                            </form>
                        </CSSTransition>
                    }
                </TransitionGroup>
            </div>
        );
    }
}

export default connect(
    state => ({
        login: state.login,
        pathname: state.router.location.pathname,
        translation: state.translation.wholesale.calculator,
    }),
    dispatch => ({
        businessWholeSaleRequest: (body, success, error) => businessWholeSaleRequest(dispatch, body, success, error),
    }))(Calculator);
