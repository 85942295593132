import React, { PureComponent } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import TransactionsContent from './components/TransactionsContent';
import TransactionsFilters from './components/TransactionsFilters';
import TransactionsSwitches from './components/TransactionsSwitches';
import ToolbarSeparator from '../../components/ToolbarSeparator';
import TransactionsTable from './components/TransactionsTable';
import { BackgroundTable, withBackgroundTableHighlighting } from '../../components/Table';
import MobileFiltersButton from '../../components/MobileFiltersButton';
import Loader from '../../components/Loader';
import NoContent from '../../components/NoContent';
import Pagination from '../../components/Pagination';
import { roleOrLanguageChanged } from '../../../../Utils';
import { url, privateTransactionsRequest } from '../../../../Networking';
import { withScrollingHeader } from '../../components/withScrollingHeader';
import "./Transactions.scss";
import IconCartDarker20px from '../../assets/Icon_Cart_Darker_20px.svg';
import IconFuelDarker20px from '../../assets/Icon_Fuel_Darker_20px.svg';
import IconTimeDarker20px from '../../assets/Icon_Time_Darker_20px.svg';
import IconCardDarker16px from '../../assets/Icon_Card_Darker_16px.svg';
import {FilterTypeEnum} from './helpers/FilterTypeEnum';
import {dateToString} from "../../helpers";

class Transactions extends PureComponent {
    constructor(props) {
        super(props);
        this.displayTypeEnumeration = {
            list: 0,
            table: 1
        };

        this.state = {
            selectedDisplayType: this.displayTypeEnumeration.list,
            headerOffsetFromTop: 0,
            isHeaderAbsolute: false,
            isHeaderFixed: false,
            accessoryOffsetFromTop: 96, //by desktop header height
            isAccessoryAbsolute: false,
            isAccessoryFixed: false,
            detailedPurchaseList: {
                isDownloadingData: false,
                periods: null,
                productTypes: null,
                pages: null,
                itemsPerPageOptions: null,
                stations: null,
                selectedPeriodId: null,
                selectedStationId: null,
                selectedProductTypeId: null,
                selectedPage: null,
                selectedItemsPerPage: 20,
                selectedOrderHow: null,
                selectedOrderWhat: null,
                invoices: null,
            },
            purchaseList: {
                isDownloadingData: false,
                periods: null,
                productTypes: null,
                pages: null,
                itemsPerPageOptions: null,
                stations: null,
                selectedPeriodId: null,
                selectedStationId: null,
                selectedProductTypeId: null,
                selectedPage: null,
                selectedItemsPerPage: 20,
                selectedOrderHow: null,
                selectedOrderWhat: null,
                invoices: null
            },
            dateRange: {
                startDate: null,
                endDate: null,
                isVisible: false,
                selectsStart: false,
                selectionStart: true,
                selectedPeriodId: "1",
                text: ''
            }
        }
    };

    handlePurchaseListPeriodSelected = (selectedPeriodId) => {
        this.setPurchaseListState({ selectedPeriodId, selectedPage: 1 }, this.downloadData);
    };

    handleDetailedPurchaseListPeriodSelected = (selectedPeriodId) => {
        this.setDetailedPurchaseListState({ selectedPeriodId, selectedPage: 1 }, this.downloadData);
    };

    handlePurchaseListCardTypeSelected = (selectedCardTypeId) => {
        this.setPurchaseListState({ selectedCardTypeId, selectedPage: 1 }, this.downloadData);
    };

    handleDetailedPurchaseListCardTypeSelected = (selectedCardTypeId) => {
        this.setDetailedPurchaseListState({ selectedCardTypeId, selectedPage: 1 }, this.downloadData);
    };

    handlePurchaseListProductTypeSelected = (selectedProductTypeId) => {
        this.setPurchaseListState({ selectedProductTypeId, selectedPage: 1 }, this.downloadData);
    };

    handleDetailedPurchaseListProductTypeSelected = (selectedProductTypeId) => {
        this.setDetailedPurchaseListState({ selectedProductTypeId, selectedPage: 1 }, this.downloadData);
    };

    handlePurchaseListStationSelected = (selectedStationId) => {
        this.setPurchaseListState({ selectedStationId, selectedPage: 1 }, this.downloadData);
    };

    handleDetailedPurchaseListStationSelected = (selectedStationId) => {
        this.setDetailedPurchaseListState({ selectedStationId, selectedPage: 1 }, this.downloadData);
    };

    handleDetailedPurchaseOrderSelected = (selectedOrderHow, selectedOrderWhat, selectedOrderSearch) => {
        this.setDetailedPurchaseListState({
            selectedOrderHow,
            selectedOrderWhat,
            selectedOrderSearch,
        }, async () => {
            await new Promise(resolve => setTimeout(resolve, 900));

            if (
                this.state.detailedPurchaseList.selectedOrderHow === selectedOrderHow &&
                this.state.detailedPurchaseList.selectedOrderWhat === selectedOrderWhat &&
                this.state.detailedPurchaseList.selectedOrderSearch === selectedOrderSearch
            ) {
                await this.downloadData();
            }
        });
    };

    displayTypeSelected = (selectedDisplayType) => {
        this.setState({ selectedDisplayType }, () => this.downloadData());
    };

    get isListDisplayTypeSelected() {
        return this.state.selectedDisplayType === this.displayTypeEnumeration.list;
    }

    get isTableDisplayTypeSelected() {
        return this.state.selectedDisplayType === this.displayTypeEnumeration.table;
    }

    downloadPdf = (invoiceNumber, isLink, pdfUrl) => {
        const userId = this.props.login && this.props.login.currentRole && this.props.login.currentRole.userId;
        if (!userId) {
            return; //not in private role
        }
        if (isLink && pdfUrl) {
            window.open(pdfUrl);
        } else {
            window.open(`${url}&act=olerexweb.private.transactions&requestType=transactionPdf&token=${this.props.login.token}&userId=${userId}&invoiceNo=${invoiceNumber}&pdf=1`);
        }
    };

    downloadData = () => {
        const userId = this.props.login && this.props.login.currentRole && this.props.login.currentRole.userId;
        if (!userId) {
            return; //not in private role
        }
        if (this.isListDisplayTypeSelected) {
            if (this.state.purchaseList.isDownloadingData) {
                return;
            }

            const request = {
                "requestType": "getPurchaseList",
                "token": this.props.login.token,
                "userId": userId,
                "isIdCredit": this.props.login.currentRole.isIdCredit,
                "stationId": this.state.purchaseList.selectedStationId,
                "page": this.state.purchaseList.selectedPage,
                "itemsPerPage": this.state.purchaseList.selectedItemsPerPage,
                "cardTypeId": this.state.purchaseList.selectedCardTypeId,
                "orderHow": this.state.purchaseList.selectedOrderHow,
                "orderWhat": this.state.purchaseList.selectedOrderWhat
            };
            if (this.state.purchaseList.selectedPeriodId || !this.state.dateRange.endDate) {
                request.periodId = this.state.purchaseList.selectedPeriodId;
            } else {
                request.fromPeriod = dateToString(this.state.dateRange.startDate);
                request.toPeriod = dateToString(this.state.dateRange.endDate);
            }

            this.setPurchaseListState({ isDownloadingData: true }, () => {
                this.props.transactionsRequest(request,
                    data => {
                        const _userId = this.props.login && this.props.login.currentRole && this.props.login.currentRole.userId;
                        if (!_userId || _userId !== userId) {
                            return; //role changed
                        }
                        let invoices = data.records.invoices && typeof data.records.invoices[0] !== "string" ?
                            [].concat(...Object.keys(data.records.invoices).map(x =>
                                Object.keys(data.records.invoices[x]).map(y => {
                                    return { title: `${y} ${x}`, items: data.records.invoices[x][y] }
                                }))) : []; //flatten with concat
                        invoices = invoices ? invoices.map(x => ({ ...x, items: x.items ? x.items.map(y => ({ ...y, onDownloadButtonClick: () => this.downloadPdf(y.invoiceId, y.isLink, y.pdfUrl) })) : null })) : null;
                        this.setState(prevState => ({
                            purchaseList: {
                                ...this.state.purchaseList,
                                isDownloadingData: false,
                                periods: Object.entries(data.records.periods).map(x =>
                                    ({
                                        text: x[1],
                                        value: x[0],
                                        from: new Date(data.records.periodsFromTo[x[0]].from + 'T00:00:00'),
                                        to: new Date(data.records.periodsFromTo[x[0]].to + 'T00:00:00'),
                                        onClick: () => this.handlePurchaseListPeriodSelected(x[0])
                                    })),
                                cardTypes: Object.entries(data.records.cardTypes).map(x =>
                                    ({ text: x[1], value: x[0], onClick: () => this.handlePurchaseListCardTypeSelected(x[0]) })),
                                productTypes: Object.entries(data.records.productTypes).map(x =>
                                    ({ text: x[1], value: x[0], onClick: () => this.handlePurchaseListProductTypeSelected(x[0]) })),
                                pages: data.records.pages,
                                itemsPerPageOptions: data.records.itemsPerPageOptions,
                                stations: data.records.stations.map(x =>
                                    ({ text: x.value, value: String(x.key), onClick: () => this.handlePurchaseListStationSelected(x.key) })),
                                selectedPeriodId: String(data.records.selectedPeriodId),
                                selectedStationId: String(data.records.selectedStationId),
                                selectedProductTypeId: String(data.records.selectedProductTypeId),
                                selectedPage: data.records.selectedPage,
                                selectedItemsPerPage: data.records.selectedItemsPerPage,
                                selectedCardTypeId: data.records.selectedCardTypeId,
                                selectedOrderHow: data.records.selectedOrderHow,
                                selectedOrderWhat: data.records.selectedOrderWhat,
                                invoices: invoices
                            },
                            dateRange: {
                                startDate: prevState.dateRange.startDate || (data.records.selectedPeriodId ? new Date(data.records.periodsFromTo[String(data.records.selectedPeriodId)].from + 'T00:00:00') : null),
                                endDate: prevState.dateRange.endDate || (data.records.selectedPeriodId ? new Date(data.records.periodsFromTo[String(data.records.selectedPeriodId)].to + 'T00:00:00') : null),
                                isVisible: prevState.dateRange.isVisible,
                                selectsStart: false,
                                selectionStart: true,
                                selectedPeriodId: String(data.records.selectedPeriodId) || null,
                            }
                        }));
                    },
                    error => {
                        const _userId = this.props.login && this.props.login.currentRole && this.props.login.currentRole.userId;
                        if (!_userId || _userId !== userId) {
                            return; //role changed
                        }
                        this.setPurchaseListState({ isDownloadingData: false });
                    });
            });
        } else {
            if (this.state.detailedPurchaseList.isDownloadingData) {
                return; //already downloading
            }

            const request = {
                "requestType": "getDetailedPurchaseList",
                "token": this.props.login.token,
                "userId": userId,
                "isIdCredit": this.props.login.currentRole.isIdCredit,
                "stationId": this.state.detailedPurchaseList.selectedStationId,
                "productTypeId": this.state.detailedPurchaseList.selectedProductTypeId,
                "page": this.state.detailedPurchaseList.selectedPage,
                "itemsPerPage": this.state.detailedPurchaseList.selectedItemsPerPage,
                "cardTypeId": this.state.detailedPurchaseList.selectedCardTypeId,
                "orderHow": this.state.detailedPurchaseList.selectedOrderHow,
                "orderWhat": this.state.detailedPurchaseList.selectedOrderWhat,
                "orderSearch": this.state.detailedPurchaseList.selectedOrderSearch,
            };
            if (this.state.detailedPurchaseList.selectedPeriodId || !this.state.dateRange.endDate) {
                request.periodId = this.state.detailedPurchaseList.selectedPeriodId;
            } else {
                request.fromPeriod = dateToString(this.state.dateRange.startDate);
                request.toPeriod = dateToString(this.state.dateRange.endDate);
            }

            this.setDetailedPurchaseListState({ isDownloadingData: true }, () => {
                this.props.transactionsRequest(request,
                    data => {
                        const _userId = this.props.login && this.props.login.currentRole && this.props.login.currentRole.userId;
                        if (!_userId || _userId !== userId) {
                            return; //role changed
                        }
                        this.setState(prevState => ({
                            detailedPurchaseList: {
                                ...this.state.detailedPurchaseList,
                                isDownloadingData: false,
                                periods: Object.entries(data.records.periods).map(x =>
                                    ({
                                        text: x[1],
                                        value: x[0],
                                        from: new Date(data.records.periodsFromTo[x[0]].from + 'T00:00:00'),
                                        to: new Date(data.records.periodsFromTo[x[0]].to + 'T00:00:00'),
                                        onClick: () => this.handleDetailedPurchaseListPeriodSelected(x[0])
                                    })),
                                cardTypes: Object.entries(data.records.cardTypes).map(x =>
                                    ({ text: x[1], value: x[0], onClick: () => this.handleDetailedPurchaseListCardTypeSelected(x[0]) })),
                                productTypes: Object.entries(data.records.productTypes).map(x =>
                                    ({ text: x[1], value: x[0], onClick: () => this.handleDetailedPurchaseListProductTypeSelected(x[0]) })),
                                pages: data.records.pages,
                                itemsPerPageOptions: data.records.itemsPerPageOptions,
                                stations: data.records.stations.map(x =>
                                    ({ text: x.value, value: String(x.key), onClick: () => this.handleDetailedPurchaseListStationSelected(x.key) })),
                                selectedPeriodId: String(data.records.selectedPeriodId),
                                selectedStationId: String(data.records.selectedStationId),
                                selectedProductTypeId: String(data.records.selectedProductTypeId),
                                selectedPage: data.records.selectedPage,
                                selectedItemsPerPage: data.records.selectedItemsPerPage,
                                selectedCardTypeId: data.records.selectedCardTypeId,
                                selectedOrderHow: data.records.selectedOrderHow,
                                selectedOrderWhat: data.records.selectedOrderWhat,
                                selectedOrderSearch: data.records.selectedOrderSearch,
                                invoices: data.records.invoices && typeof data.records.invoices[0] !== "string" ? data.records.invoices : [],
                            },
                            dateRange: {
                                startDate: prevState.dateRange.startDate || (data.records.selectedPeriodId ? new Date(data.records.periodsFromTo[String(data.records.selectedPeriodId)].from + 'T00:00:00') : null),
                                endDate: prevState.dateRange.endDate || (data.records.selectedPeriodId ? new Date(data.records.periodsFromTo[String(data.records.selectedPeriodId)].to + 'T00:00:00') : null),
                                isVisible: prevState.dateRange.isVisible,
                                selectsStart: false,
                                selectionStart: true,
                                selectedPeriodId: String(data.records.selectedPeriodId) || null
                            }
                        }));
                    },
                    error => {
                        const _userId = this.props.login && this.props.login.currentRole && this.props.login.currentRole.userId;
                        if (!_userId || _userId !== userId) {
                            return; //role changed
                        }
                        this.setDetailedPurchaseListState({ isDownloadingData: false });
                    });
            });
        }
    };

    componentDidMount() {
        if (this.props.isMobileLayout) {
            this.displayTypeSelected(this.displayTypeEnumeration.list);
        } else {
            this.downloadData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.isMobileLayout && this.props.isMobileLayout) {
            if (this.state.lastScrollEvent) { //recalculate scroll hiding header
                this.onScrollListener(this.state.lastScrollEvent);
            }
            this.displayTypeSelected(this.displayTypeEnumeration.list);
        } else {
            if (roleOrLanguageChanged(prevProps, this.props)) {
                this.downloadData();
            }
        }
    }

    setPurchaseListState(purchaseList, callback) {
        this.setState(state => ({
            purchaseList: {
                ...state.purchaseList,
                ...purchaseList,
            },
        }), callback);
    }

    setDetailedPurchaseListState(detailedPurchaseList, callback) {
        this.setState(state => ({
            detailedPurchaseList: {
                ...state.detailedPurchaseList,
                ...detailedPurchaseList,
            },
        }), callback);
    }

    currentFilterTitle = (filters, filterValue) => {
        if (filters) {
            const filter = filters.find(x => String(x.value) === String(filterValue));
            if (filter) {
                return filter.text;
            }
        }
        return "";
    };

    handlePageSelected = (selectedPage) => {
        if (this.isListDisplayTypeSelected) {
            this.setPurchaseListState({ selectedPage }, this.downloadData);
        } else {
            this.setDetailedPurchaseListState({ selectedPage }, this.downloadData);
        }
    };

    onExpandListSmallTable = (title, index, isExpanded) => {
        const invoices = [...this.state.purchaseList.invoices].map(x => ({ ...x }));
        let current = invoices.find(x => x.title === title);
        if (current && current.items) {
            current.items = current.items.map(x => ({ ...x }));
            current.items[index].isExpanded = isExpanded;
        }
        this.setPurchaseListState({ invoices });
    };

    handleDateChange = (dateRange) => {
        if (this.isListDisplayTypeSelected) {
            this.setState(state => ({
                dateRange,
                purchaseList: {
                    ...state.purchaseList,
                    selectedPage: 1,
                    selectedPeriodId: dateRange.selectedPeriodId,
                },
            }), () => {
                if (dateRange.selectedPeriodId || dateRange.endDate) {
                    this.downloadData();
                }
            });
        } else {
            this.setState(state => ({
                dateRange,
                detailedPurchaseList: {
                    ...state.detailedPurchaseList,
                    selectedPage: 1,
                    selectedPeriodId: dateRange.selectedPeriodId,
                },
            }), () => {
                if (dateRange.selectedPeriodId || dateRange.endDate) {
                    this.downloadData()
                }
            });
        }
    };

    render() {
        const isLoaderVisible = ((this.isListDisplayTypeSelected && this.state.purchaseList.isDownloadingData) ||
            (this.isTableDisplayTypeSelected && this.state.detailedPurchaseList.isDownloadingData));
        const selectedDisplayTypeState = this.isListDisplayTypeSelected ? this.state.purchaseList : this.state.detailedPurchaseList;
        const selectedStationTitle = this.currentFilterTitle(selectedDisplayTypeState.stations, selectedDisplayTypeState.selectedStationId);
        const selectedPeriodTitle = this.currentFilterTitle(selectedDisplayTypeState.periods, selectedDisplayTypeState.selectedPeriodId);
        const selectedCardTypeTitle = this.currentFilterTitle(selectedDisplayTypeState.cardTypes, selectedDisplayTypeState.selectedCardTypeId);
        const isCardTypeFilterVisible = this.props.login.currentRole && this.props.login.currentRole.isIdCredit === "1";
        const selectedProductTypeTitle = this.currentFilterTitle(selectedDisplayTypeState.productTypes, selectedDisplayTypeState.selectedProductTypeId);
        const listItems = this.state.purchaseList.invoices;
        const tableItems = this.state.detailedPurchaseList.invoices;
        const isNoContentVisible = (
            (this.isListDisplayTypeSelected && listItems && listItems.length <= 0) ||
            (this.isTableDisplayTypeSelected && tableItems && tableItems.length <= 0));
        const isListVisible = this.isListDisplayTypeSelected && listItems && listItems.length > 0;
        const isTableVisible = this.isTableDisplayTypeSelected && tableItems;
        const filters = [
            {
                leftImg: IconFuelDarker20px,
                text: selectedStationTitle,
                items: selectedDisplayTypeState.stations,
                type: FilterTypeEnum.dropdown
            },
            {
                leftImg: IconTimeDarker20px,
                text: selectedPeriodTitle,
                items: selectedDisplayTypeState.periods,
                type: FilterTypeEnum.calendar,
                onChange: this.handleDateChange,
                dateRange: this.state.dateRange,
            },
            ...[isCardTypeFilterVisible && {
                leftImg: IconCardDarker16px,
                text: selectedCardTypeTitle,
                items: selectedDisplayTypeState.cardTypes,
                type: FilterTypeEnum.dropdown
            }],
            ...[this.isTableDisplayTypeSelected && {
                leftImg: IconCartDarker20px,
                text: selectedProductTypeTitle,
                items: selectedDisplayTypeState.productTypes,
                type: FilterTypeEnum.dropdown
            }]
        ].filter(x => x); //filter undefineds

        const headerClass = `${this.props.isHeaderFixed ? "fixed" : ""} ${this.props.isHeaderHiding ? "hiding" : ""} ${this.props.isHeaderAnimated ? "animated" : ""}`;
        const tableHeaderClass = `${this.props.isHeaderFixed ? "fixed" : "hidden"} ${this.props.isHeaderHiding ? "alt" : ""} ${this.props.isHeaderAnimated ? "animated" : ""}`;
        return (
            <div className="transactions">
                <div className="transactions-header-placeholder"></div>
                <div className={`transactions-header-static ${headerClass}`}>
                    <div className="transactions-header-background"></div>
                    <div className="transactions-header">
                        <h1 className="transactions-header-title">{this.props.translation.title}</h1>
                        <div className="transactions-header-items">
                            <TransactionsFilters {...{ items: filters }} />
                            <ToolbarSeparator />
                            <TransactionsSwitches {...{
                                onListClick: () => this.displayTypeSelected(this.displayTypeEnumeration.list),
                                isListDisplayTypeSelected: this.isListDisplayTypeSelected,
                                onTableClick: () => this.displayTypeSelected(this.displayTypeEnumeration.table),
                                isTableDisplayTypeSelected: this.isTableDisplayTypeSelected
                            }} />
                        </div>
                    </div>
                    <MobileFiltersButton {...{
                        className: "transactions-header-mobile-filters",
                        onClick: () => this.props.onMobileFiltersOpen([{
                            text: selectedStationTitle,
                            items: selectedDisplayTypeState.stations
                        }, {
                            text: selectedPeriodTitle,
                            items: selectedDisplayTypeState.periods
                        }, {
                            text: selectedProductTypeTitle,
                            items: selectedDisplayTypeState.productTypes
                        }])
                    }} />
                </div>

                <TransitionGroup component={null}>
                    {isTableVisible &&
                        <CSSTransition key={"table-background"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <div className="transactions-body-background">
                                <BackgroundTable {...{
                                    items: this.state.detailedPurchaseList.invoices,
                                    hovered: this.props.hoveredIndex,
                                    stickyHeaderClass: tableHeaderClass
                                }} />
                            </div>
                        </CSSTransition>}
                    {isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <Loader className={"transactions-loader"} />
                    </CSSTransition>}

                    <CSSTransition key={"body-content"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                        <div className="transactions-body">
                            <TransitionGroup component={null}>
                                {isListVisible &&
                                    <CSSTransition key={"list-content"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                        <>{listItems.map((item, index) => <TransactionsContent {...{
                                            ...item,
                                            key: index,
                                            onExpandListSmallTable: this.onExpandListSmallTable,
                                            downloadTooltip: this.props.generalTranslation.downloadFile
                                        }} />)}</>
                                    </CSSTransition>}
                                {isTableVisible &&
                                    <CSSTransition key={"table-content"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                        <TransactionsTable {...{
                                            items: tableItems,
                                            selectedOrderHow: selectedDisplayTypeState.selectedOrderHow,
                                            selectedOrderWhat: selectedDisplayTypeState.selectedOrderWhat,
                                            selectedOrderSearch: selectedDisplayTypeState.selectedOrderSearch,
                                            onTableColumnFilterSelected: this.handleDetailedPurchaseOrderSelected,
                                            onMouseOverElement: this.props.onMouseOverTableRow,
                                            onMouseOutElement: this.props.onMouseOutTableRow,
                                            stickyHeaderClass: tableHeaderClass
                                        }} />
                                    </CSSTransition>}
                                {isNoContentVisible &&
                                    <CSSTransition key={"no-content"} timeout={{ enter: 250 }} classNames={"fast-fade"}>
                                        <NoContent {...{ text: this.props.translation.noContent }} />
                                    </CSSTransition>}
                                {(isListVisible || isTableVisible) && !isNoContentVisible &&
                                    <CSSTransition key={"pagination"} timeout={{ enter: 250 }} classNames={"fast-fade"}>
                                        <Pagination {...{
                                            className: "transactions-pagination",
                                            selectedPage: selectedDisplayTypeState.selectedPage,
                                            pages: selectedDisplayTypeState.pages,
                                            handlePageSelected: this.handlePageSelected
                                        }} />
                                    </CSSTransition>}
                            </TransitionGroup>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        );
    }
}

export default connect(
    state => ({
        login: state.login,
        isMobileLayout: state.size.isMobileLayout,
        translation: state.translation.transactions,
        generalTranslation: state.translation.general
    }),
    dispatch => ({ transactionsRequest: (body, success, error) => privateTransactionsRequest(dispatch, body, success, error) }))(
        withBackgroundTableHighlighting(withScrollingHeader(Transactions, 0, 96, 80, 52)));
