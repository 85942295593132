import React from 'react';
import { connect } from 'react-redux';

import PageHeader from '../../../components/PageHeader';
import styles from './Header.module.scss';

class Header extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { isSearchOpen: false };
    }

    onSearchFormSubmit = (e) => {
        e.preventDefault();
    }

    onSearchInputValueChange = (e) => {
        this.setState({ searchInputValue: e.target.value });
    }

    render() {
        let links = [];

        if (this.props.pathname !== '/invoicing' && this.props.isMobileLayout) {
            links = [
                { text: this.props.translation.incomingsTabMobile, to: '/invoicing/invoices/incomings', exact: true },
                { text: this.props.translation.invoicesTabMobile, to: '/invoicing/invoices', exact: true },
            ];
        } else if (!this.props.isMobileLayout) {
            links = [
                { text: this.props.translation.invoicingTab, to: '/invoicing', exact: true },
                { text: this.props.translation.invoicesTab, to: '/invoicing/invoices', exact: false },
            ];
        }

        return (
            <PageHeader {...{
                className: styles.header,
                title: this.props.translation.title,
                pathname: this.props.pathname,
                links,
                search: /\/invoicing\/invoices(\/|$)/.test(this.props.pathname) ? {
                    onSearchFormSubmit: this.onSearchFormSubmit,
                    inputPlaceholder: this.props.translation.searchPlaceholder,
                    onInputValueChange: this.onSearchInputValueChange,
                    inputValue: this.state.searchInputValue
                } : undefined,
            }} />
        );
    }
}

export default connect(state => ({
    isMobileLayout: state.size.isMobileLayout,
    translation: state.translation.invoicing,
}), null)(Header);
