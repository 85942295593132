export const TYPE_WINDOW_SIZE_CHANGED = "TYPE_WINDOW_SIZE_CHANGED";

export const windowSizeChanged = (width, height) => ({
    type: TYPE_WINDOW_SIZE_CHANGED,
    size: {
        width: width,
        height: height,
        isMobileLayout: width <= 600,
        isTabletLayout: (width <= 1024) && (width > 600)
    }
});

export const initialWindowSizeState = { width: 0, height: 0, isMobileLayout: false };

export default function size(state = initialWindowSizeState, action) {
    switch (action.type) {
        case TYPE_WINDOW_SIZE_CHANGED:
            return { ...state, ...action.size }
        default:
            return state;
    }
}
