import React from 'react';
import './Welcome.scss';

const Welcome = React.memo(props =>
    <div className="business-welcome">
        <h1 className="business-welcome-title">{props.welcomeText}</h1>
        {/* <button className="business-welcome-notification" onClick={props.navigateToInvoices}>
            <img className="business-welcome-notification-img" src={IconInvoicesUnpaid} alt="" />
            <span className="business-welcome-notification-title">{props.unpaidInvoices}</span>
        </button>
        <button className="business-welcome-notification" onClick={props.navigateToCards}>
            <img className="business-welcome-notification-img" src={IconCardsExpiring} alt="" />
            <span className="business-welcome-notification-title">{props.expiringCards}</span>
        </button> */}
        { props.notifications.map((notif, i) =>
            <button className="business-welcome-notification" key={'notif_' + i} onClick={() => props.navigateTo(notif.href)}>
                <img className="business-welcome-notification-img" src={notif.icon} alt="" />
                <span className="business-welcome-notification-title">{notif.text}</span>
            </button>
        )}
    </div>
);

export default Welcome;
