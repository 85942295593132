import React from 'react';
import { connect } from 'react-redux';
import ExpandableContent from '../../../components/ExpandableContent';
import BarCode from 'react-barcode';
import "./VouchersContent.scss";
import IconPrinterBlack24px from '../assets/Icon_Printer_Black-24px.svg';
import IconMailBlack24px from '../assets/Icon_Mail_Black-24px.svg';
import IconTimeGray16px from '../assets/Icon_Time_Gray_16px.svg';
import IconBarcodeGray16px from '../assets/Icon_Barcode_Gray-16px.svg';

const VoucherItem = connect(state => ({ translation: state.translation.vouchers.item }))(React.memo(props =>
    <div className="voucher-item">
        <div className="voucher-item_background"></div>
        <div className="voucher-item_background-border"></div>
        <img className="voucher-item_img" src={props.img} alt="" />
        <div className="voucher-item_img-hover">
            <button className="voucher-item_img-hover_button print" onClick={props.onPrintButtonClick}>
                <img className="voucher-item_img-hover_button_img" src={IconPrinterBlack24px} alt="print barcode" />
            </button>
            <div className="voucher-item_img-hover_divider"></div>
            <button className="voucher-item_img-hover_button email" onClick={props.onEmailButtonClick}>
                <img className="voucher-item_img-hover_button_img" src={IconMailBlack24px} alt="email barcode" />
            </button>
        </div>
        <h1 className="voucher-item_comment">{props.comment}</h1>
        <p className="voucher-item_value">{props.voucherValue}</p>
        <p className="voucher-item_info barcode">
            <img className="voucher-item_info_img" src={IconBarcodeGray16px} alt="barcode" />
            <span className="voucher-item_info_text">{props.voucherNr}</span>
        </p>
        <p className="voucher-item_info expiry">
            <img className="voucher-item_info_img" src={IconTimeGray16px} alt="" />
            <span className="voucher-item_info_text">{props.translation.validTo(props.validTo)}</span>
        </p>
        <div className="voucher-item_barcode">
            <BarCode value={props.voucherNr} format={"ean13"} />
        </div>
    </div>
));

const VouchersContent = React.memo(props => {
    const elementHeight = props.isMobileLayout ? 317 + 16 : 128 + 12;
    const height = props.vouchers ? (props.vouchers.length * elementHeight) + 16 : 0;
    return (
        <ExpandableContent className="vouchers_expandable-collection" {...{
            title: <h1 className="vouchers_expandable-collection_title">{props.description}</h1>,
            content: <div className="vouchers_collection">
                {props.vouchers && props.vouchers.map((voucher, index) => <VoucherItem {...{ ...voucher, key: index }} />)}
            </div>,
            height: height
        }} />
    )
});

export default VouchersContent;