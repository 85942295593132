import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContentContainer from '../../../components/ContentContainer';
import ExpandableContent from '../../../components/ExpandableContent';
import "./IdCreditContent.scss";
import IconInvoiceLate56px from '../assets/Icon_Invoice-Late_56px.svg';
import IconInvoicePaid56px from '../assets/Icon_Invoice-Paid_56px.svg';
import IconInvoiceUnpaid56px from '../assets/Icon_Invoice-Unpaid_56px.svg';
import IconTransferPaid56px from '../assets/Icon_Transfer-Paid_56px.svg';
import IconDownload from '../../../assets/Icon_Download.svg';
import Tippy from '@tippy.js/react';

const getBillTypeImage = (overDeadline, showPayBtn) => {
    if (overDeadline && showPayBtn) {
        return IconInvoiceLate56px;
    }
    if (showPayBtn) {
        return IconInvoiceUnpaid56px;
    }
    return IconInvoicePaid56px;
}

export const BillItem = connect(state => ({ translation: state.translation.idCredit.content.billItem, generalTranslation: state.translation.general }))(React.memo(props =>
    <div className="bill-item-outer">
        <div className={`bill-item ${props.fade ? "fade" : ""} ${(props.showPayBtn && props.payButtonClick) ? "pay-button" : ""}`}>
            <img className="bill-item_img" src={getBillTypeImage(props.overDeadline, props.showPayBtn)} alt="" />
            <p className="bill-item_type">
                <span className="bill-item_type-text">{props.type}</span>
                {props.pdfUploaded && <button className="bill-item_download-button" onClick={props.onDownloadButtonClick}>
                <Tippy arrow={true}
                  isEnabled={true}
                  content={props.generalTranslation.downloadFile || ""}
                >
                  <img className="bill-item_download-button_img" src={IconDownload} alt="download" />
                </Tippy>
                </button>}
            </p>
            <h1 className="bill-item_title">{props.number}</h1>
            <p className="bill-item_total-info">
                <span className="desktop-text">{props.translation.total}</span>
                <span className="mobile-text">{props.translation.totalMobile}</span></p>
            <h1 className="bill-item_total">{props.toPay}</h1>
            <p className="bill-item_date-info">
                <span className="desktop-text">{props.translation.date}</span>
                <span className="mobile-text">{props.translation.dateMobile}</span>
            </p>
            <p className="bill-item_date">{props.date}</p>
            <p className="bill-item_payment-date-info">
                <span className="desktop-text">{props.translation.paymentDate}</span>
                <span className="mobile-text">{props.translation.paymentDateMobile}</span>
            </p>
            <p className="bill-item_payment-date">{props.deadline}</p>
            <p className="bill-item_paid-info">{props.translation.paid}</p>
            <p className="bill-item_paid">{props.paid}</p>
            {!props.fade && props.showPayBtn && props.payButtonClick ? <div className="bill-item_hover">
                <button className="bill-item_pay-button" onClick={props.payButtonClick}>{props.translation.payButton}</button>
                <p className="bill-item_pay-info">{props.translation.hoverInfo}</p>
            </div> : ""}
        </div>
        {props.fade && <div className="bill-item-fade"></div>}
    </div>
));
BillItem.propTypes = {
    fade: PropTypes.any,
    type: PropTypes.any,
    paid: PropTypes.any,
    sum: PropTypes.any,
    onDownloadButtonClick: PropTypes.func,
    number: PropTypes.any,
    toPay: PropTypes.any,
    date: PropTypes.any,
    deadline: PropTypes.any,
    overDeadline: PropTypes.any,
    showPayBtn: PropTypes.any
};

const ReceivedItem = connect(state => ({ translation: state.translation.idCredit.content.receivedItem }))(React.memo(props =>
    <div className="received-item">
        <img className="received-item_img" src={IconTransferPaid56px} alt="" />
        <p className="received-item_type">{props.translation.type}</p>
        <h1 className="received-item_title">{props.description}</h1>
        <p className="received-item_sum-info">
            <span className="desktop-text">{props.translation.sum}</span>
            <span className="mobile-text">{props.translation.sumMobile}</span></p>
        <h1 className="received-item_sum">{props.sum}</h1>
        <p className="received-item_date-info">{props.translation.date}</p>
        <p className="received-item_date">{props.date}</p>
    </div>
));
ReceivedItem.propTypes = {
    description: PropTypes.any,
    sum: PropTypes.any,
    date: PropTypes.any
};

const IdCreditCollection = connect(state => ({ translation: state.translation.idCredit.content }))(React.memo(props =>
    <ContentContainer {...{
        className: `id-credit-collection ${props.className ? props.className : ""}`,
        title: <h1 className="id-credit-collection_title">{props.text}</h1>,
        action: <h1 className="id-credit-collection_total">{props.translation.total(props.incomingsThisMonth || props.invoicesTotalThisMonth || "0 €")}</h1>,
        content:
            <div className="id-credit-collection-items">
                {props.rows && props.rows.map((item, index) => props.text === props.translation.invoices ?
                    <BillItem {...{ ...item, key: index, payButtonClick: () => props.payButtonClick(item.number) }} /> : <ReceivedItem  {...{ ...item, key: index }} />)}
            </div>
    }} />
));

const IdCreditContent = React.memo(props => {
    const isMobileInvoicesUrl = props.pathname === "/invoicing/invoices" || props.pathname === "/idcredit";
    const isMobileIncomingsUrl = props.pathname === "/invoicing/invoices/incomings" || props.pathname === "/idcredit/incomings";
    const noInvoices = isMobileInvoicesUrl ? (props.invoices && props.invoices.rows && props.invoices.rows.length > 0 ? false : true) : false;
    const noIncomings = isMobileIncomingsUrl ? (props.incomings && props.incomings.rows && props.incomings.rows.length > 0 ? false : true) : false;
    const invoicesHeight = ((props.isMobileLayout && isMobileInvoicesUrl) || (props.invoices && props.invoices.rows)) ? (noInvoices ? 0 : props.invoices.rows.map(x => 165 + 8 + (props.isMobileLayout && x.showPayBtn ? 56 : 0)).reduce((x, y) => x + y, 0) - 8) : 0;
    const incomingsHeight = ((props.isMobileLayout && isMobileIncomingsUrl) || (props.incomings && props.incomings.rows)) ? (noIncomings ? 0 : (props.incomings.rows.length * (165 + 8)) - 8) : 0;
    const height = (props.isMobileLayout) ? (isMobileInvoicesUrl ? invoicesHeight : incomingsHeight) :
        Math.max(invoicesHeight, incomingsHeight) + 16 + 16 + 24; //extra 16+16+24 for invoices, incomings titles and margins in full width layout
    if (props.isMobileLayout && (noIncomings || noInvoices)) {
        return <></>; //nothing to show in mobile layout
    } else {
        return (<ExpandableContent {...{
            className: `id-credit_expandable-collection ${props.className ? props.className : ""}`,
            title: <h1 className="id-credit_expandable-collection_title">{props.text}</h1>,
            content: <div className="id-credit_collections">
                <IdCreditCollection {...{
                    className: `id-credit-collection-bills ${isMobileInvoicesUrl ? "" : "collection-hidden"}`,
                    text: props.translation.invoices,
                    payButtonClick: props.payButtonClick,
                    ...props.invoices,
                }} />
                <IdCreditCollection {...{
                    className: `id-credit-collection-invoices ${isMobileIncomingsUrl ? "" : "collection-hidden"}`,
                    text: props.translation.incomings,
                    ...props.incomings
                }} />
            </div>,
            height: height
        }} />);
    }
});


export default connect(state => ({
    pathname: state.router.location.pathname,
    isMobileLayout: state.size.isMobileLayout,
    translation: state.translation.idCredit.content
}), null)(IdCreditContent)