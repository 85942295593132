import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { businessManageRequest } from '../../../../Networking';
import Header from './components/Header';
import Address from './components/Address';
import Contacts from './components/Contacts';
import Users from './components/Users';
import Services from './components/Services';
import Manager from './components/Manager';
import { roleOrLanguageChanged } from '../../../../Utils';
import './Manage.scss';

class Manage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            address: null,
            contacts: null,
            users: null,
            userLevels: null,
            isLoaderVisible: false,
            selectedOrderHow: "ASC",
            selectedOrderWhat: "name"
        }
    }

    downloadData = () => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        this.setState({ isLoaderVisible: true }, async () => {
            // await new Promise(x => setTimeout(x, 5000));
            this.props.manageRequest(
                {
                    "action": "getData",
                    "accNr": accnr,
                    "token": this.props.login.token,
                    "orderUsersHow": this.state.selectedOrderHow,
                    "orderUsersByField": this.state.selectedOrderWhat
                },
                data => {
                    const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                    if (!_accnr || _accnr !== accnr) {
                        return; //role changed
                    }
                    const userLevels = data.records.response.userLevels;
                    const userLevelsReverse = {};
                    Object.entries(userLevels).forEach(x => userLevelsReverse[x[1]] = x[0]);
                    const users = data.records.response.users.map(user => ({
                        ...user,
                        userLevel: userLevelsReverse[user.role]
                    }));
                    this.setState({
                        address: data.records.response.address,
                        contacts: data.records.response.contacts,
                        users: users,
                        userLevels: data.records.response.userLevels,
                        partnerServices: data.records.response.partnerServices,
                        footerAdvertisement: data.records.response.footerAdvertisement,
                        managerContact: data.records.response.managerContact,
                        isLoaderVisible: false
                    });
                },
                error => {
                    this.setState({ isLoaderVisible: false });
                });
        });
    }

    componentDidMount() {
        this.downloadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (roleOrLanguageChanged(prevProps, this.props)) {
            this.downloadData();
        }
    }

    handleUserFilterSelected = (selectedOrderHow, selectedOrderWhat) => {
        this.setState({ selectedOrderHow: selectedOrderHow, selectedOrderWhat: selectedOrderWhat }, () => this.downloadData());
    }

    render() {
        let footerColor = this.state.footerAdvertisement && this.state.footerAdvertisement.backgroundHex;
        let footerUrl = this.state.footerAdvertisement && this.state.footerAdvertisement.imageUrl;
        let footerImg = this.state.footerAdvertisement && this.state.footerAdvertisement.image;
        const companyName = (this.props.login && this.props.login.currentRole && this.props.login.currentRole.name) || "";
        return (
            <div className="manage-page">
                <div className="manage-page-header-placeholder"></div>
                <div className="manage-header-static">
                    <div className="manage-page-header-background"></div>
                    <Header />
                </div>
                <div className="manage-page-body">
                    <Address {...{
                        ...this.props,
                        address: this.state.address,
                        isLoaderVisible: this.state.isLoaderVisible,
                        companyName: companyName
                    }} />
                    <Contacts {...{
                        ...this.props,
                        contacts: this.state.contacts,
                        isLoaderVisible: this.state.isLoaderVisible
                    }} />
                    <Users {...{
                        ...this.props,
                        users: this.state.users,
                        userLevels: this.state.userLevels,
                        isLoaderVisible: this.state.isLoaderVisible,
                        onDataChanged: () => this.downloadData(),
                        onTableColumnFilterSelected: this.handleUserFilterSelected,
                        selectedOrderHow: this.state.selectedOrderHow,
                        selectedOrderWhat: this.state.selectedOrderWhat
                    }} />
                    <Manager  {...{
                        ...this.props,
                        ...this.state.managerContact
                    }} />
                    {(!this.state.partnerServices || (Array.isArray(this.state.partnerServices) && this.state.partnerServices.length > 0)) && <Services {...{
                        ...this.props,
                        services: this.state.partnerServices,
                        isLoaderVisible: this.state.isLoaderVisible,
                        downloadData: this.downloadData,
                    }} />}
                </div>
                <div className="manage-page-ad-background" style={{ background: footerColor }}></div>
                <div className="manage-page-ad">
                    <a href={footerUrl} target="_blank" rel="noopener noreferrer">
                        <img className="manage-page-ad_img" src={footerImg} alt="advertisement" />
                    </a>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ login: state.login }),
    dispatch => ({ manageRequest: (body, success, error) => businessManageRequest(dispatch, body, success, error) }))(Manage);
