import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './NavUserControls.scss';
import notificationImg from './assets/Icon_Notification-Filled_White-16px.svg';
import userImgGray from './assets/Icon_User_Gray-16px.svg';
import userImgYellow from './assets/Icon_User_Yellow-16px.svg';
import chevronDownImg from './assets/Icon_Chevron-Down_Gray-6px.svg';
import chevronDownDarkImg from './assets/Icon_Chevron-Down_Darkgray-6px.svg';
import refreshImg from './assets/Icon_Refresh_Gray-16px.svg';
import logoutImg from './assets/Icon_Logout_Gray-16px.svg';

const UserNotifications = React.memo(props =>
    <div className="user-notifications">
        <img className="user-notifications-img" src={notificationImg} alt="notifications" />
    </div>
);

const UserDropdownButton = React.memo(props =>
    <div className={`user-dropdown-button ${props.isUserDropdownOpen ? "dropdown-open" : ""}`} onClick={props.onClick}>
        <p className="user-dropdown-button-content">
            <img className="user-dropdown-button-icon" src={props.isUserDropdownOpen ? userImgYellow : userImgGray} alt="" />
            <span className="user-dropdown-button-text">{props.name}</span>
            <img className="user-dropdown-button-chevron" src={props.isUserDropdownOpen ? chevronDownImg : chevronDownDarkImg} alt="" />
        </p>
    </div>
);

const UserDropdownItem = React.memo(props =>
    <div className="user-dropdown-item" onClick={props.onClick}>
        <div className="user-dropdown-item-img-container">
            <img className="user-dropdown-item-img" src={props.leftImg} alt="" />
        </div>
        <h1 className="user-dropdown-item-title">{props.title}</h1>
        {props.rightImg && <img className="user-dropdown-chevron" src={props.rightImg} alt="" />}
        {props.rightCount && <div className="user-dropdown-counter-container"><p className="user-dropdown-count">{props.rightCount}</p></div>}
    </div>
);

const UserDropdown = React.memo(props =>
    <div className={`user-dropdown`}>
        <TransitionGroup component={null}>
            {props.items && props.items.map((item, index) => <CSSTransition key={index} timeout={{ enter: 600, exit: 600 }} classNames={"nav-item"} appear>
                <UserDropdownItem {...item} />
            </CSSTransition>)}
        </TransitionGroup>
    </div>
);

const RoleName = (currentRole) => currentRole ? (currentRole.fullName || currentRole.name) : "";
const RoleCount = (privateClientData, businessClientData) => (privateClientData ? 1 : 0) + (Array.isArray(businessClientData) ? businessClientData.length : 0);

const NavUserControls = React.memo(props => {
    const language = (props.login.privateClientData && props.login.privateClientData.language) || "et";
    const leftImg = language === "et" ? "https://olerex.ee/olxweb/images/EE.png" : language === "en" ? "https://olerex.ee/olxweb/images/EN.png" : "https://olerex.ee/olxweb/images/RU.png";
    const roleCount = RoleCount(props.login.privateClientData, props.login.businessClientData);

    return (<div className={`nav-user-controls ${props.isDrawerOpen ? "drawer-open" : ""}`}>
        <UserNotifications />
        <UserDropdownButton
            isUserDropdownOpen={props.isUserDropdownOpen}
            onClick={props.onUserDropdownToggle}
            name={RoleName(props.login.currentRole)} />
        <TransitionGroup component={null}>
            {props.isUserDropdownOpen &&
                <CSSTransition key={"user-dropdown-fade"} timeout={{ enter: 600, exit: 600 }} classNames={"fade"}><UserDropdown
                    items={[
                        ...[props.login.privateClientData && { title: props.translation.language[language], leftImg: leftImg, rightImg: chevronDownImg, onClick: props.onLanguageChangeOpen }],
                        // { title: props.translation.settings, leftImg: settingsImg, rightImg: chevronRightImg }, //commented out to temprarily remove
                        ...[roleCount > 1 && { title: props.translation.roleChange, leftImg: refreshImg, rightCount: roleCount, onClick: props.onRoleChangeOpen }],
                        { title: props.translation.logout, leftImg: logoutImg, onClick: props.onLogoutButtonClick },
                    ].filter(x => x)} /></CSSTransition>}
        </TransitionGroup>
    </div>)
});

export default connect(state => ({
    login: state.login,
    translation: state.translation.navbar.userControls
}))(NavUserControls);
