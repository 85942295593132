import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ContentContainer from '../../../components/ContentContainer';
import Loader from '../../../components/Loader';
import { Modal, ModalText } from "./Modal";
import './Services.scss';
import IconSettingsYellow from '../../../assets/Icon_Settings_Yellow-38px.svg';

const EditService = connect(state => ({ translation: state.translation.manage.services.editActivationModal }))(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { id: props.id, isLoaderVisible: false, isDone: false, isError: false, result: null };
    }

    editActivation = (parnterId, partnerStatus) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        if (this.state.isLoaderVisible) {
            return;
        }
        this.setState({ isLoaderVisible: true }, async () => {
            // await new Promise(x => setTimeout(x, 500000));
            this.props.manageRequest(
                {
                    "action": "updatePartnerService",
                    "accNr": accnr,
                    "token": this.props.login.token,
                    "partnerId": parnterId,
                    "partnerStatus": partnerStatus
                },
                data => {
                    const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                    if (!_accnr || _accnr !== accnr) {
                        return; //role changed
                    }
                    this.setState({
                        result: data.records.result,
                        isDone: data.records.statusCode === 200,
                        isError: data.records.statusCode !== 200,
                        isLoaderVisible: false
                    });
                    if (data.records.statusCode === 200) {
                        this.props.downloadData && this.props.downloadData();
                    }
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        })
    }

    render() {
        return (
            <Modal {...{
                title: this.props.confirmationDialog.dialogTitle,
                onCloseButtonClick: this.props.onModalClose,
                content: <ModalText {...{ text: this.props.confirmationDialog.dialogText }} />,
                isSaveButton: !this.props.status,
                onActionButtonClick: () => this.editActivation(this.props.id, !this.props.status),
                actionButtonTitle: this.props.status ? this.props.translation.deactivateButton : this.props.translation.activateButton,
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: this.props.confirmationDialog.dialogHeight || "auto",
            }} />
        )
    }
});

const Item = connect(state => ({ translation: state.translation.manage.services }))(React.memo(props =>
    <div className="manage-services_collection-item-wrap">
        <div className="manage-services_collection-item" onClick={props.onClick} disabled={props.disabled}>
            <img className="manage-services_collection-item_img" src={props.imageUrl} alt="" />
            <p className="manage-services_collection-item_text-name">{props.name}</p>
            <p className="manage-services_collection-item_text-activated">{props.status ? props.translation.activated : props.translation.notActivated}</p>
            <p className="manage-services_collection-item_text-activated-hover">{props.status ? props.translation.deactivate : props.translation.activate}</p>
        </div>
    </div>
));

const Services = React.memo(props =>
    <div className="manage-services" id="services">
        <ContentContainer {...{
            className: "manage-services-container",
            title:
                <p className="manage-address_label">
                    <img className="manage-address_label-img" src={IconSettingsYellow} alt="" />
                    <span className="manage-address_label-text">{props.translation.title}</span>
                </p>,
            content:
                <div className="manage-services-content">
                    <TransitionGroup component={null}>
                        {props.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <Loader {...{ className: "manage-services-loader" }} />
                        </CSSTransition>}
                        {props.services && <CSSTransition key={"content-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                            <div className="manage-services_collection">
                                <TransitionGroup component={null}>
                                    {props.services && props.services.map((service, index) => <CSSTransition key={index} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                        <Item {...{
                                            ...service,
                                            onClick: () => props.onModalOpen(<EditService {...{ ...props, ...service, downloadData: props.downloadData }} />),
                                        }} />
                                    </CSSTransition>)}
                                </TransitionGroup>
                            </div>
                        </CSSTransition>}
                    </TransitionGroup>
                </div>
        }} />
    </div>
);
export default connect(state => ({ translation: state.translation.manage.services }))(Services);