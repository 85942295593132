import React, { Component } from 'react';
import {connect} from "react-redux";
import {SignContractModal} from "../../SignContractModal/SignContractModal";
import {ConfirmationModal} from "../ConfirmationModal";
import { Document, Page} from 'react-pdf/dist/entry.webpack';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import './ContractPreviewModal.scss';


export const ContractPreviewModal = connect(
    state => ({
        translation: state.translation.electricity,
        login: state.login
    }),
    undefined
)(
    class ContractPreviewModal extends Component {
        constructor(props) {
            super(props);
            this.state = {
                offer: {},
                pdfPreview: null,
                numPages: null,
                pageNumber: 1
            };
        }

        componentDidMount() {
            this.getOffer();
        }

        getOffer() {
            const userId =
                this.props.login &&
                this.props.login.currentRole &&
                this.props.login.currentRole.userId;
            if (!userId) {
                return;
            }

            const packageId = this.props.selectedPackage.id;
            const kwhPerYear = this.props.yearValue;
            const contractPeriod = this.props.periodValue;
            const usagePercentDay = this.props.dayValue;
            const usagePercentNight = 100 - usagePercentDay;
            const meteringPointCodes = this.props.selectedMeteringPoints.filter(mp => mp.selected).map(mp => mp.code);
            const requestParameters = {
                requestType: 'getOffer',
                userId: userId,
                token: this.props.login.token,
                packageId: packageId,
                kwhPerYear: kwhPerYear,
                contractPeriod: contractPeriod,
                usagePercentDay: usagePercentDay,
                usagePercentNight: usagePercentNight,
                meteringPoints: meteringPointCodes
            };
            if (this.props.contractIds && this.props.contractIds.length > 0) {
                requestParameters.contractIds = this.props.contractIds;
            }
            this.setState({ isLoaderVisible: true }, async () => {
                this.props.electricityRequest(
                    requestParameters,
                    data => {
                        this.setState({offer: data.records.result}, this.pdfPreview(data.records.result.fileUrl));
                    },
                    error => {
                        this.setState({ isLoaderVisible: false });
                    }
                );
            });
        }

        openSignContractModal = (event) => {
            const {yearValue, periodValue, dayValue, offer} = this.state;
            const {onCloseButtonClick, electricityRequest, selectedPackage, onSwitchDarkModal, refreshData, contractIds} = this.props;
            const passProps = {onCloseButtonClick, electricityRequest, yearValue, periodValue, dayValue, selectedPackage, offer, refreshData, contractIds};
            onSwitchDarkModal(
                <SignContractModal
                    {...passProps}
                />
            )
        };

        titleWithDownload = () => {
            const accept = this.props.translation.iAgree;
            const contract = this.props.translation.withContract;
            const url = this.state.offer.fileUrl;
            return <><span>{accept} </span><a className="black-link" href={url}>{contract}</a></>
        };

        pdfPreview(url) {
            let { pageNumber } = this.state;
            const onDocumentLoadSuccess = ({ numPages }) => {
                this.setState({ numPages: numPages });
                numPages = this.state.numPages;
                pageNumber = this.state.pagenumber;
            };

            this.setState({pdfPreview:
                <div className='pdf-viewer'>
                    <Document
                        file={url}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page width={1000} pageNumber={pageNumber} />
                    </Document>
                    {/*<p>Page {pageNumber} of {numPages}</p>*/}
                </div>
            });
        };

        render() {
            const {pdfPreview, offer} = this.state;

            const modalTitle = this.props.translation.contractPreviewTitle;
            const checkboxTitle = this.titleWithDownload();
            const modalContent = pdfPreview;
            const onConfirmationClick = this.openSignContractModal;
            const {onCloseButtonClick} = this.props;
            const confirmationText = this.props.translation.toSignPage;
            const maxWidth = '1200px';
            const width= '100%';

            if (!offer.contractId) {
                return null;
            }
            return (
                pdfPreview &&
                <ConfirmationModal {...{modalTitle, checkboxTitle, onConfirmationClick, onCloseButtonClick, confirmationText, modalContent, maxWidth, width}} />
            )
        }
    }
);
