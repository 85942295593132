import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalText } from '../../manage/components/Modal';

const AskPriceModal = connect(state => ({ translation: state.translation.overview.fuelPrices.askPriceModal }))(class extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { id: props.id, isLoaderVisible: false, isDone: false, isError: false, result: null };
    }

    postAskPrice = () => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        if (this.state.isLoaderVisible) {
            return;
        }
        this.setState({ isLoaderVisible: true }, async () => {
            // await new Promise(x => setTimeout(x, 500000));
            this.props.businessDashboardRequest(
                {
                    "action": "askPrice",
                    "accNr": accnr,
                    "token": this.props.login.token,
                    "country": this.props.country,
                    "fuel": this.props.fuel
                },
                data => {
                    const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                    if (!_accnr || _accnr !== accnr) {
                        return; //role changed
                    }
                    this.setState({
                        result: data.records.result,
                        isDone: data.records.statusCode === 200,
                        isError: data.records.statusCode !== 200,
                        isLoaderVisible: false
                    });
                }, error => {
                    this.setState({ isLoaderVisible: false });
                });
        })
    }

    render() {
        return (
            <Modal {...{
                title: this.props.askPriceText,
                onCloseButtonClick: this.props.onModalClose,
                content: <ModalText {...{ text: this.props.askPriceConfirmationTxt }} />,
                isSaveButton: true,
                onActionButtonClick: () => this.postAskPrice(),
                actionButtonTitle: this.props.askPriceText,
                isLoaderVisible: this.state.isLoaderVisible,
                isDone: this.state.isDone,
                isError: this.state.isError,
                result: this.state.result,
                height: "auto",
            }} />
        )
    }
});

export default AskPriceModal;
