import React  from 'react';
import { VictoryChart, VictoryAxis, VictoryBar, VictoryLabel, VictoryTooltip } from "victory";

const convertRange = (oldMin, oldMax, oldVal, newMin, newMax) => {
    if (oldVal <= 0) {
        return oldVal;
    }
    const oldRange = oldMax - oldMin;
    if (Math.abs(oldRange) < 0.1) {
        return Math.max(newMax, 0);
    } else {
        return Math.max((((oldVal - oldMin) * (newMax - newMin)) / oldRange) + newMin, 0);
    }
};

const calculateDataMin = (data) => {
    const dataCopy = [...data];
    const integers = data.map(column => column.y).filter(integer => integer > 0);
    const min = Math.min(...integers);
    const max = Math.max(...integers);
    const dataMin = dataCopy.map(x => ({ ...x, y: convertRange(min, max, x.y, 32, 100) })); //32 just above the month name text on the chart
    return dataMin;
};

export function ConsumptionChart(props) {
    const {
        columns,
        data,
        unit,
        isCurrentYear,
        className,
        currentValue,
        hideLabels,
        chartName,
        onClick
    } = props;
    const width = 1024;
    const spacer = 4;
    const spacing = spacer * (columns.length - 1);
    const barWidth = ((width - spacing) / columns.length) - 12;
    const padding = (barWidth + (2.5 * spacer)) / 2;
    const dataMin = calculateDataMin(data);

    const getAxisLabelColor = (column, value) => {
        return (value === 0) || (isCurrentYear && ((column === currentValue))) ? "#1e1e1e" : "white";
    };

    const getBarColor = (column, value) => {
        return (value === 0) ? "clear" : (isCurrentYear && column === currentValue ? "#FFCD00" : "#1e1e1e");
    };

    const getBarLabelColor = (column, value) => {
        return (value === 0) ? "clear" : (isCurrentYear && column === currentValue ? "#1e1e1e" : "#FFCD00");
    };

    const getValueForColumn = (column) => {
        const columnName = columns[column - 1];
        const columnData = data.find((row) => row.x === columnName);
        const value = columnData ? columnData.y : 0;
        return value;
    };

    const getHeightForColumn = (column) => {
        const columnName = columns[column - 1];
        const columnData = dataMin.find((row) => row.x === columnName);
        const value = columnData ? columnData.y : 0;
        return value;
    };

    const getTooltip = (column) => {
        return (column.tooltip ? Object.values(column.tooltip).join('\n'): '');
    };

    return (
        <div className={`consumption-chart ${className ? className : ''}`}>
            <VictoryChart
                width={1024}
                height={218}
                padding={{ top: 24, bottom: 8}}
                domainPadding={{ x: padding, y: 0 }}
            >
                <VictoryBar
                    name="bar"
                    barWidth={barWidth}
                    style={{
                        data: {
                            fill: d => getBarColor(d._x - 1, d._y),
                            strokeLinejoin: "round",
                            strokeWidth: 8
                        }
                    }}
                    events={[{
                        eventHandlers: {onClick: (event) => {return {mutation: (props) => {onClick(chartName, props.data[props.index])(event)}}}}
                    }]}
                    categories={{ x: columns }}
                    data={dataMin}
                    labels={d => d.y}
                    labelComponent={
                        <VictoryTooltip
                            cornerRadius={4}
                            style={{
                                padding: '4',
                                fill: 'white'
                            }}
                            flyoutStyle={{
                                fill: '#1e1e1e',
                                stroke: '#1e1e1e',
                                animationName: d => d.fadeOut ? 'graphTooltipClose' : '',
                                transitionTimingFunction: 'ease-out',
                                animationDuration: '0.4s',
                                display: d => (d._y > 0) ? 'block' : 'none',
                            }}
                            text={d => {
                                if (d._y) {
                                    return `${getTooltip(d)}`;
                                }
                            }}
                        />
                    }
                />
                <VictoryAxis
                    dependentAxis={false}
                    crossAxis={false}
                    style={{
                        axis: {
                            stroke: "clear"
                        },
                        tickLabels: {
                            fill: d => getBarLabelColor(d - 1,  getValueForColumn(d)),
                            fontSize: "18px",
                            letterSpacing: "0.7px",
                            fontFamily: "Bebas Neue",
                            fontWeight: "bold",
                        }
                    }}
                    tickCount={columns.length}
                    tickLabelComponent={<VictoryLabel
                        dy={d => {
                            const height = getHeightForColumn(d);
                            if (height > 0) {
                                return (-1.8 * getHeightForColumn(d)) - 8 ;
                            }
                            return 100;
                        }}
                        text={d => {
                        if (hideLabels) {
                            return null;
                        }
                        return `${getValueForColumn(d)} ${unit}`;
                    }} />}
                />
                <VictoryAxis
                    dependentAxis={false}
                    crossAxis={false}
                    style={{
                        axis: {
                            stroke: "clear"
                        },
                        tickLabels: {
                            fill: d => getAxisLabelColor(d - 1, getValueForColumn(d)),
                            fontSize: "15px",
                            letterSpacing: "0.7px",
                            fontFamily: "Bebas Neue",
                            fontWeight: "bold"
                        }
                    }}
                    tickCount={columns.length}
                    tickLabelComponent={<VictoryLabel dy={-32} dx={0} />}
                />
            </VictoryChart>
        </div>
    )
}
