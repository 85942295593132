import React from 'react';
export const ChevronGroup = () => {
    return (
        <div className="chevrons">
            <div className="chevron-right"></div>
            <div className="chevron-right"></div>
            <div className="chevron-right"></div>
            <div className="chevron-right"></div>
            <div className="chevron-right"></div>
        </div>
    );
};