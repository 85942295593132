import React from 'react';
import PropTypes from 'prop-types';

import IconModalCross16px from '../../assets/Icon_Modal-Cross_16px.svg';
import ContentContainer from '../../components/ContentContainer';

import styles from './MessageModal.module.scss';

const MessageModal = React.memo(props =>
    <ContentContainer {...{
        className: styles.modal,
        title: <h1 className={styles.title}>{props.title}</h1>,
        action:
            <button className={styles.close} onClick={props.onModalClose}>
                <img src={IconModalCross16px} alt="close" />
            </button>,
        content:
            <div className={styles.content}>
                <div className={styles.inner}>
                    <div className={`${styles.text} ${styles[props.kind]}`}>{props.text}</div>
                </div>
            </div>
    }} />
);

MessageModal.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    kind: PropTypes.oneOf(['success', 'error']),
};

MessageModal.defaultProps = {
    kind: 'success',
};

export default MessageModal;
