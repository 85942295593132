import React from 'react';
import { connect } from 'react-redux';
import ContentContainer from '../../../components/ContentContainer';
import { BillItem } from '../../../person/idcredit/components/IdCreditContent';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Loader from '../../../components/Loader';
import { businessBanklinksRequest } from '../../../../../Networking';
import BankLinks from '../../../modal/banklinks/BankLinks';
import './Bills.scss';
import ChevronRight from '../../../assets/Chevron_Right.svg';

const Collection = React.memo(props =>
    <div className="business-overview-bills-collection">
        <TransitionGroup component={null}>
            {props.items && props.items.map((item, index) => <CSSTransition key={index} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                <BillItem {...{ ...item, key: index, fade: index === props.items.length - 1, payButtonClick: null }} />
            </CSSTransition>)}
        </TransitionGroup>
    </div>
);

class Bills extends React.PureComponent {
    openBankLink = (invoiceId) => {
        const accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
        if (!accnr) {
            return; //not in business role
        }
        this.props.banklinksRequest(
            {
                "accNr": this.props.login.currentRole.accnr,
                "token": this.props.login.token,
                "invoiceId": invoiceId,
            },
            data => {
                const _accnr = this.props.login && this.props.login.currentRole && this.props.login.currentRole.accnr;
                if (!_accnr || _accnr !== accnr) {
                    return; //role changed
                }
                this.props.onModalOpen(<BankLinks {...{ items: data.records.response.banklink, onCloseButtonClick: this.props.onModalClose }} />);
            },
            error => {
            }
        )
    }

    render() {
        return (
            <ContentContainer className={`bills ${this.props.className ? this.props.className : ""}`}  {...{
                title: <h1 className="bills_title">{this.props.translation.title}</h1>,
                action: <button className="bills_show-all-button" onClick={this.props.navigateToInvoices}>
                    <span className="bills_show-all-button_text">{this.props.translation.showAllButton}</span>
                    <img className="bills_show-all-button_img" src={ChevronRight} alt="" /></button>,
                content:
                    <div className="business-overview-transactions-content">
                        <TransitionGroup component={null}>
                            {this.props.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                <Loader {...{ className: "business-overview-transactions-loader" }} />
                            </CSSTransition>}
                            {!this.props.isLoaderVisible && <CSSTransition key={"content-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                <Collection {...{ items: this.props.lastInvoices, onModalClose: this.props.onModalClose, payButtonClick: null }} />
                            </CSSTransition>}
                        </TransitionGroup>
                    </div>
            }} />
        );
    }
}

export default connect(
    state => ({ translation: state.translation.overview.bills, login: state.login }),
    dispatch => ({ banklinksRequest: (body, success, error) => businessBanklinksRequest(dispatch, body, success, error) }))(Bills);