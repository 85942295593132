import React from 'react';
import ReactSlider from "react-slider";

export const PacketCalculatorSection = (
    {
        scrollToSlidersRef,
        yearValue,
        dayValue,
        periodValue,
        sliderChange,
        contractDuration,
        predictedUsage,
        translation,
        periods,
        ...props}) => {

    return (
        <div className="packet-calculator-container" ref={scrollToSlidersRef}>
            <div className="packet-calculator-background"></div>
            <div className="packet-calculator">
                <div className="energy-consumption energy-consumption-year">
                    <span className="amount">{ yearValue }</span>
                    <div className="unit-container">
                        <span className="unit">{ contractDuration.text.split(' ')[0] }</span>
                        <span className="unit">{ contractDuration.text.split(' ')[1] }</span>
                    </div>
                    <ReactSlider
                        className="slider"
                        thumbClassName="slider-handle"
                        onChange={sliderChange('yearValue')}
                        value={yearValue}
                        max={predictedUsage.max}
                        min={predictedUsage.min}
                        step={predictedUsage.step}
                        renderThumb={(props) => <div {...props}></div>}
                    />
                </div>
                <div className="energy-consumption energy-consumption-day">
                    <div className="slider-result-container">
                        <div className="unit-amount-container text-right">
                            <div className="unit-container text-right">
                                <span className="unit text-right">%</span>
                                <span className="unit text-right">{translation.duringDay}</span>
                            </div>
                            <span className="amount">{100 - dayValue}</span>
                        </div>
                        <span className="divider">/</span>
                        <div className="unit-amount-container text-left">
                            <span className="amount">{dayValue}</span>
                            <div className="unit-container">
                                <span className="unit text-right">%</span>
                                <span className="unit text-right">{translation.atNight}</span>
                            </div>
                        </div>
                    </div>
                    <ReactSlider
                        className="slider"
                        thumbClassName="slider-handle"
                        onChange={sliderChange('dayValue')}
                        value={dayValue}
                        renderThumb={(props) => <div {...props}></div>}
                    />
                </div>
                <div className="energy-consumption energy-consumption-year">
                    <span className="amount">{ periods[periodValue].value }</span>
                    <div className="unit-container">
                        <span className="unit">{translation.period(periods[periodValue])}</span>
                    </div>
                    <ReactSlider
                        className="slider"
                        thumbClassName="slider-handle"
                        onChange={sliderChange('periodValue')}
                        value={periodValue}
                        max={8}
                        min={0}
                        step={1}
                        renderThumb={(props) => <div {...props}></div>}
                    />
                </div>
            </div>
        </div>
    )
};
