import React from 'react';
import "./MobileFiltersButton.scss";
import IconFilters20px from '../assets/Icon_Filters-20px.svg';
import IconChevronDownGray8px from "../assets/Icon_Chevron-Down_Gray-8px.svg";

const MobileFiltersButton = React.memo(props =>
    <button className={`filters-button-mobile ${props.className ? props.className : ""}`} onClick={props.onClick}>
        <img className="filters-button-mobile_left-img" src={IconFilters20px} alt="" />
        <span className="filters-button-mobile_text">Näita filtreid</span>
        <img className="filters-button-mobile_right-img" src={IconChevronDownGray8px} alt="" />
    </button>
);

export default MobileFiltersButton;