import React, { Component } from 'react';
import {withCloseOnEsc} from "../../../../components/withCloseOnEsc";
import IconModalCross16px from "../../../../assets/Icon_Modal-Cross_16px.svg";
import ContentContainer from "../../../../components/ContentContainer";
import './ConfirmationModal.scss';
import {CheckboxField} from "../../components/CheckboxField";
import {ReactComponent as IconChevronRightGray8px} from "../../../../assets/Icon_Chevron-Right_Gray-8px.svg";
import PropTypes from "prop-types";

export const ConfirmationModal = withCloseOnEsc((
    class ConfirmationModal extends Component {
        constructor(props) {
            super(props);
            this.state = {
                checked: false
            };
        }

        onEditingValueChanged = (event) => {
            if (event && event.target) {
                this.setState({checked: event.target.checked});
            }
        };
        

        render() {
            const {modalTitle, checkboxTitle, onConfirmationClick, confirmationText, onCloseButtonClick, modalContent, maxWidth, width} = this.props;
            const {checked} = this.state;
            const onEditingValueChanged = this.onEditingValueChanged;
            const style = {};
            if (maxWidth) {style.maxWidth = maxWidth};
            if (width) {style.width = width};
            return (
                <ContentContainer {...{
                    className: `confirmation-modal`,
                    style: style,
                    title: <h1 className="confirmation-modal_title">{modalTitle}</h1>,
                    action:
                        <button className="confirmation-modal_close-button" onClick={onCloseButtonClick}>
                            <img className="confirmation-modal_close-button_img" src={IconModalCross16px} alt="close" />
                        </button>,
                    content:
                        <>
                            <div className="confirmation-modal-content">
                                <div className="confirmation-modal-content_content">
                                    {
                                        modalContent && (
                                            <div className="confirmation-modal-content-inner">{modalContent}</div>
                                        )
                                    }

                                    <CheckboxField {...{
                                        className: `${modalContent ? 'pb-16px': ''}`,
                                        type: "acceptCheckbox", title: checkboxTitle,
                                        checked: checked, onChange: ($event) => {onEditingValueChanged($event)}
                                    }} />

                                    <button disabled={!checked} className="btn btn-confirmation">
                                        <span onClick={() => onConfirmationClick()} className="btn-confirmation-label">{confirmationText}</span>
                                        <span className="btn-confirmation-chevron"><IconChevronRightGray8px/></span>
                                    </button>
                                </div>
                            </div>
                        </>
                }} />
            )
        }
    }
));

ConfirmationModal.propTypes = {
    modalTitle: PropTypes.string,
    checkboxTitle: PropTypes.any,
    onConfirmationClick: PropTypes.func,
    onCloseButtonClick: PropTypes.func,
    confirmationText: PropTypes.string
};
