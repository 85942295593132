import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Loader from '../../../components/Loader';
import ContentContainer from '../../../components/ContentContainer';
import './Manager.scss';
import IconManager38px from '../../../assets/Icon_Manager-38px.svg';
import IconManagerEmail from '../../../assets/Icon_Manager_Email.svg';
import IconManagerPhone from '../../../assets/Icon_Manager_Phone.svg';
import IconManagerHours from '../../../assets/Icon_Manager_Hours.svg';

const RightItem = React.memo(props =>
    <div className="manage-manager-right_items-item">
        <img className="manage-manager-right_items-item-img" src={props.img} alt="" />
        <p className="manage-manager-right_items-item-top_text">{props.topText}</p>
        <p className="manage-manager-right_items-item-bot_text">{props.botText}</p>
    </div>
);

class Manager extends PureComponent {
    render() {
        return (
            <div className="manage-manager" id="manager">
                <ContentContainer {...{
                    className: "manage-manager-address-container",
                    title:
                        <p className="manage-manager_label">
                            <img className="manage-manager_label-img" src={IconManager38px} alt="" />
                            <span className="manage-manager_label-text">{this.props.translation.title}</span>
                        </p>,
                    action: <></>,
                    content:
                        <div className="manage-manager-content">
                            <TransitionGroup component={null}>
                                {this.props.isLoaderVisible && <CSSTransition key={"loader-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                    <Loader {...{ className: "manage-manager-loader" }} />
                                </CSSTransition>}
                                <CSSTransition key={"content-fade"} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                    <div className="manage-manager_profile">
                                        {this.props.imageUrl && <img className="manage-manager_profile-img" src={this.props.imageUrl} alt="" />}
                                        <p className="manage-manager_profile-name">{this.props.fullName}</p>
                                        <p className="manage-manager_profile-email">{this.props.email}</p>
                                        <p className="manage-manager_profile-phone">{[this.props.phone, this.props.mobile].filter(x => x).join(", ")}</p>
                                    </div>
                                </CSSTransition>
                            </TransitionGroup>
                        </div>
                }} />
                <div className="manage-manager-right_items">
                    <RightItem {...{ img: IconManagerEmail, topText: this.props.translation.sendEmail, botText: this.props.email }} />
                    <RightItem {...{ img: IconManagerPhone, topText: this.props.translation.call, botText: [this.props.phone, this.props.mobile].filter(x => x).join(", ") }} />
                    <RightItem {...{ img: IconManagerHours, topText: this.props.activeTime, botText: this.props.translation.weekdays }} />
                </div>
            </div>
        );
    }
}
Manager.propTypes = {
    activeTime: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    lastName: PropTypes.string,
    mobile: PropTypes.string,
    phone: PropTypes.string,
    imageUrl: PropTypes.string
}
Manager.defaultProps = {
    activeTime: "",
    email: "",
    firstName: "",
    fullName: "",
    lastName: "",
    mobile: "",
    phone: "",
    imageUrl: ""
}

export default connect(state => ({ translation: state.translation.manage.manager }), null)(Manager);