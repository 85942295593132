import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './ExpandableSection.scss';

export const ExpandableSection = ({children, isExpanded, height, className, ...props}) => {
    return (
        <TransitionGroup>
            {
                isExpanded &&
                <CSSTransition key={"content-container-content"} timeout={{ enter: 600, exit: 600 }} classNames={height ? "height" : "fade"}>
                    <div className="content-container-overflow" style={{ height: height ? `${height}px` : "auto" }}>
                        <div className={`${className ? className : ''}`}>
                            {children}
                        </div>
                    </div>
                </CSSTransition>
            }
        </TransitionGroup>
    );
};