import React, { Component } from 'react';
import { connect } from 'react-redux';
import { privateElectricityRequest } from '../../../../Networking';
import './Electricity.scss';
import SelectContractElectricity from "./SelectContractElectricity";
import ContractsElectricity from "./ContractsElectricity";

class Electricity extends Component {
    constructor(props) {
        super(props);
        this.getElectricityData = this.getElectricityData.bind(this);
        this.getMeteringPoints = this.getMeteringPoints.bind(this);
        this.refreshData = this.refreshData.bind(this);
    }

    state = {
        isLoaderVisible: false,
        data: null
    };

    periods = [
        {value: 1, type: 'month', key: 1},
        {value: 2, type: 'month', key: 2},
        {value: 3, type: 'month', key: 3},
        {value: 6, type: 'month', key: 6},
        {value: 1, type: 'year', key: 12},
        {value: 2, type: 'year', key: 24},
        {value: 3, type: 'year', key: 36},
        {value: 4, type: 'year', key: 48},
        {value: 5, type: 'year', key: 60}
    ];

    calculateElectricity = (stateYearValue, stateDayValue, periodValue) => {
        const step = this.state.data.priceParameters.contractDuration.steps[this.periods[periodValue].key];
        if (step) {
            const monthValue = (stateYearValue / 12);
            const dayValue = monthValue * (1 - (stateDayValue) / 100);
            const nightValue = monthValue * (((stateDayValue) / 100));
            const fixed1Parsed = parseFloat(step.fixed1);
            const dayParsed = parseFloat(step.fixed2.day);
            const nightParsed = parseFloat(step.fixed2.night);
            const stockParsed = parseFloat(step.stock);
            const fixed1 = Math.round(monthValue * fixed1Parsed) / 100;
            const fixed2 = Math.round((dayValue * dayParsed) + (nightValue * nightParsed)) / 100;
            const stock = Math.round(monthValue * stockParsed * step.stockPrice) / 100;
            const calculatedForMonth = {
                fixed1: fixed1.toFixed(2),
                fixed2: fixed2.toFixed(2),
                stock: stock.toFixed(2)
            };

            let recommended = [];
            const smallest = Math.min(fixed1, fixed2, stock);
            if (fixed1 === smallest) recommended.push(0);
            if (fixed2 === smallest) recommended.push(1);
            if (stock === smallest) recommended.push(2);
            return {step, calculatedForMonth, recommended}
        }
    };

    componentDidMount() {
        this.refreshData();
    }

    refreshData() {
        this.getElectricityData();
        this.getMeteringPoints();
    }

    getMeteringPoints() {
        const userId =
            this.props.login &&
            this.props.login.currentRole &&
            this.props.login.currentRole.userId;
        if (!userId) {
            return;
        }
        this.setState({ isLoaderVisible: true }, async () => {
            this.props.electricityRequest(
                {
                    requestType: 'getMeteringPoints',
                    userId: userId,
                    token: this.props.login.token
                },
                data => {
                    this.setState({
                        meteringPoints: data.records.result
                    });
                },
                error => {
                    this.setState({ isLoaderVisible: false });
                }
            );
        });
    }

    getElectricityData() {
        const userId =
            this.props.login &&
            this.props.login.currentRole &&
            this.props.login.currentRole.userId;
        if (!userId) {
            return;
        }
        this.setState({ isLoaderVisible: true }, async () => {
            this.props.electricityRequest(
                {
                    requestType: 'getContractData',
                    userId: userId,
                    token: this.props.login.token
                },
                data => {
                    const _userId =
                        this.props.login &&
                        this.props.login.currentRole &&
                        this.props.login.currentRole.userId;
                    if (!_userId || _userId !== userId) {
                        return; //role changed
                    }

                    this.setState({
                        data: data.records.result
                    });
                },
                error => {
                    this.setState({ isLoaderVisible: false }); //TOOD show error
                }
            );
        });
    }

    render() {
        const props = {
            ...this.props,
            data: this.state.data,
            meteringPoints: this.state.meteringPoints,
            getElectricityData: this.getElectricityData,
            calculateElectricity: this.calculateElectricity,
            periods: this.periods,
            getUsage: this.getUsage,
            isMobileLayout: this.props.isMobileLayout,
            isTabletLayout: this.props.isTabletLayout,
            width: this.props.width,
            isMobileDropdownOpen: this.props.isMobileDropdownOpen,
            refreshData: this.refreshData
        };
        if (!this.state.data || !this.state.meteringPoints) {
            return null;
        } else if (this.state.data.contracts.length === 0 ) {
            return (
                <SelectContractElectricity
                    behaviour={1}
                    meteringPointCodes={[]}
                    contractIds={[]}
                    {...props}
                 />
            )
        }
        return <ContractsElectricity {...props}/>
    }
}

export default connect(
    state => ({
        login: state.login,
        translation: state.translation.electricity,
        months: state.translation.overview.fuelUsage.months,
        isMobileLayout: state.size.isMobileLayout,
        isTabletLayout: state.size.isTabletLayout,
        width: state.size.width,
        router: state.router
    }),
    dispatch => ({
        electricityRequest: (body, success, error) =>
            privateElectricityRequest(dispatch, body, success, error)
    })
)(Electricity);
