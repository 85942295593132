import React from 'react';
import { connect } from 'react-redux';
import { TableDataCell, TableHeaderCell } from '../../../components/Table';
import PropTypes from 'prop-types';
import "./TransactionsTable.scss";

const TableDataRowSmall = React.memo(props =>
    <tr className="table-body-row small">
        <TableDataCell {...{ className: "left small-table-first-col", content: <span className="table-text-14px-bold">{props.itemName}</span> }} />
        <TableDataCell {...{
            className: "right",
            content: <><span className="table-text-14px-regular">{props.qty}</span><br />
                <span className="table-text-12px-subtitle">{props.perUnit} / tk</span></>
        }} />
        <TableDataCell {...{
            className: "right",
            content: <><span className="table-text-14px-regular">{props.amount}</span><br />
                <span className="table-text-12px-subtitle">+ {props.vat} km</span></>
        }} />
        <TableDataCell {...{ className: "right", content: <span className="table-text-24px-bold">{props.fullAmount}</span> }} />
    </tr>
);

const TableDataRow = React.memo(props =>
    <tr className="table-body-row" onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut}>
        <TableDataCell {...{
            className: "left card",
            content: <><span className="table-text-14px-bold">{props.cardName}</span><br />
                <span className="table-text-12px-subtitle">{props.cardNumber}</span></>
        }} />
        <TableDataCell {...{ className: "left product", content: <span className="table-text-14px-bold">{props.itemName}</span> }} />
        <TableDataCell {...{
            className: "left station", content: <><span className="table-text-14px-regular">{props.stationName}</span><br />
                <span className="table-text-12px-subtitle">{props.refuelingParameter}</span></>
        }} />
        <TableDataCell {...{
            className: "left time",
            content: <><span className="table-text-14px-regular">{`${props.saleDate}`}</span><br />
                <span className="table-text-12px-subtitle">{props.saleTime}</span></>
        }} />
        <TableDataCell {...{
            className: "right quantity",
            content: <><span className="table-text-14px-regular">{props.qty}</span><br />
                <span className="table-text-12px-subtitle">{props.perUnit} / tk</span></>
        }} />
        <TableDataCell {...{
            className: "right sum",
            content: <><span className="table-text-14px-regular">{props.amount}</span><br />
                <span className="table-text-12px-subtitle">+ {props.vat} km</span></>
        }} />
        <TableDataCell {...{ className: "right sumvat", content: <span className="table-text-24px-bold">{props.fullAmount}</span> }} />
    </tr>
);

const TableHeaderRowSmall = connect(state => ({ translation: state.translation.transactions.table }))(React.memo(props =>
    <tr className="table-header-row small">
        <TableHeaderCell {...{ className: "small left small-table-first-col", text: props.translation.productColumn }} />
        <TableHeaderCell {...{ className: "small right", text: props.translation.quantityColumn }} />
        <TableHeaderCell {...{ className: "small right", text: props.translation.sumColumn }} />
        <TableHeaderCell {...{ className: "small right", text: props.translation.sumVatColumn }} />
    </tr>
));

const TableHeaderRow = connect(state => ({ translation: state.translation.transactions.table }))(React.memo(props =>
    <tr className={`table-header-row ${props.className ? props.className : ""}`} style={props.style}>
        <TableHeaderCell {...{ ...props, className: "left card", text: props.translation.cardColumn, orderWhat: "card" }} />
        <TableHeaderCell {...{ ...props, className: "left product", text: props.translation.productColumn, orderWhat: "product" }} />
        <TableHeaderCell {...{ ...props, className: "left station", text: props.translation.stationColumn, orderWhat: "station" }} />
        <TableHeaderCell {...{ ...props, className: "left time", text: props.translation.timeColumn, orderWhat: "time" }} />
        <TableHeaderCell {...{ ...props, className: "right quantity", text: props.translation.quantityColumn, orderWhat: "quantity" }} />
        <TableHeaderCell {...{ ...props, className: "right sum", text: props.translation.sumColumn, orderWhat: "sum" }} />
        <TableHeaderCell {...{ ...props, className: "right sumvat", text: props.translation.sumVatColumn, orderWhat: "sumVat" }} />
    </tr>
));

export const TransactionsTableSmall = React.memo(props =>
    <table className="table small">
        <thead>
            <TableHeaderRowSmall />
        </thead>
        <tbody>
            {props.items && props.items.map((item, index) => <TableDataRowSmall {...{ ...item, ...{ key: index } }} />)}
        </tbody>
    </table>
);

//table-wrapper scrollable table
//table-margin-wrapper add 16px margins around the scrollable table for the mobile layout
export class TransactionsTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { scrollX: 16 }; //16px padding
    }

    onScroll = (e) => {
        this.setState({ scrollX: -e.target.scrollLeft + 16 }); //16px padding
    }

    render() {
        return (
            <div className="table-wrapper" onScroll={this.onScroll}>
                <div className="table-margin-wrapper">
                    <table className="table">
                        <thead>
                            <TableHeaderRow {...{
                                style: this.props.isMobileLayout ? { left: `${this.state.scrollX}px` } : {},
                                className: this.props.stickyHeaderClass,
                                selectedOrderHow: this.props.selectedOrderHow,
                                selectedOrderWhat: this.props.selectedOrderWhat,
                                selectedOrderSearch: this.props.selectedOrderSearch,
                                onTableColumnFilterSelected: this.props.onTableColumnFilterSelected
                            }} />
                            <TableHeaderRow {...{
                                selectedOrderHow: this.props.selectedOrderHow,
                                selectedOrderWhat: this.props.selectedOrderWhat,
                                selectedOrderSearch: this.props.selectedOrderSearch,
                                onTableColumnFilterSelected: this.props.onTableColumnFilterSelected
                            }} />
                        </thead>
                        <tbody>
                            {this.props.items && this.props.items.map((item, index) => <TableDataRow {...{
                                ...item,
                                key: index,
                                onMouseOver: (e) => this.props.onMouseOverElement(index),
                                onMouseOut: (e) => this.props.onMouseOutElement()
                            }} />)}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
TransactionsTable.propTypes = {
    onMouseOverElement: PropTypes.func.isRequired,
    onMouseOutElement: PropTypes.func.isRequired,
    stickyHeaderFixed: PropTypes.bool,
    stickyHeaderAltPosition: PropTypes.bool,
    onTableColumnFilterSelected: PropTypes.func,
    selectedOrderHow: PropTypes.string,
    selectedOrderWhat: PropTypes.string,
    selectedOrderSearch: PropTypes.string
};

export default connect(state => ({ isMobileLayout: state.size.isMobileLayout }), null)(TransactionsTable);