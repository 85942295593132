import React from 'react';
import { connect } from 'react-redux';
import {OutlineButton} from "./OutlineButton";
import IconElectricityGray from '../../../assets/Icon_Electricity_Gray.svg';
import IconElectricity from '../../../assets/Icon_Electricity.svg';
import './MeteringPoint.scss';

const MeteringPoint = connect(state => (
    {
        translation: state.translation.electricity,
        isMobileLayout: state.size.isMobileLayout
    }
    )) (React.memo(props => {

    const contractOnclick = (type: number) =>() => {
        switch(type) {
            case 1:
                props.editContract([parseInt(props.meteringPoint.contractId, 10)], [props.meteringPoint.code], 4);
                break;
            case 2:
                props.editContract([], [props.meteringPoint.code], 3);
                break;
            case 3:
                props.endContractHandler([parseInt(props.meteringPoint.contractId, 10)], [props.meteringPoint.code]);
                break;
            default:
                return;
        }
    };

    const outlineButtonItems = [
        {text: props.translation.editContract, onClick: contractOnclick(1)},
        {text: props.translation.endContract, onClick: contractOnclick(3)}
    ];

    return (
        <div className={`metering-point-item-container ${props.headerChecked ? 'checked' : ''}`}>
            <div className={`electricity-metering-points-checkbox card-checkbox ${props.headerChecked ? 'checked' : ''}`}>
                <div className="checkmark-circle"/>
                <div className="checkmark-img"/>
            </div>
            <div className="metering-point-item">
                <div className="metering-point-item-header">
                    <div className={`metering-point-item-icon ${(props.meteringPoint.contractId) ? 'icon-black' : 'icon-gray'}`}>
                        <img src={(props.meteringPoint.contractId) ? IconElectricity : IconElectricityGray} alt=""/>
                    </div>
                    <div className="metering-point-item-information">
                        <div className="metering-point-item-col-address">
                            <div className="metering-point-item-top-row">
                                <div>{props.meteringPoint.title}</div>
                            </div>
                            <div className="metering-point-item-bottom-row">
                                <div>{props.meteringPoint.info}</div>
                            </div>
                        </div>
                        {
                            props.meteringPoint.contractId && !props.isMobileLayout &&
                            <div className="metering-point-item-col-contract">
                                <div className="metering-point-item-top-row">
                                    <div>{props.translation.relatedContract}</div>
                                </div>
                                <div className="metering-point-item-bottom-row">
                                    <div>{props.meteringPoint.contractId}</div>
                                </div>
                            </div>
                        }
                        {
                            !props.isMobileLayout &&
                            <div className="metering-point-item-col-code">
                                <div className="metering-point-item-top-row">
                                    <div>{props.translation.meteringPointCode}</div>
                                </div>
                                <div className="metering-point-item-bottom-row">
                                    <div>{props.meteringPoint.code}</div>
                                </div>
                            </div>
                        }
                    </div>
                    {
                        (props.meteringPoint.contractId && !props.isMobileLayout) &&
                        <div className="metering-point-item-button-container">
                            <OutlineButton items={outlineButtonItems} {...props} className="metering-point-item-button" />
                        </div>
                    }
                    {
                        !props.meteringPoint.contractId && !props.isMobileLayout &&
                        <div className="metering-point-item-button-container">
                            <button onClick={contractOnclick(2)} className="btn btn-narrow">{props.translation.bringOver}</button>
                        </div>
                    }
                </div>
                {
                    props.isMobileLayout &&
                    <div className="metering-point-mobile-bottom-row">
                        {
                            props.meteringPoint.contractId ?
                                <div className="metering-point-item-col-contract">
                                    <div className="metering-point-item-top-row">
                                        <div>{props.translation.relatedContract}</div>
                                    </div>
                                    <div className="metering-point-item-bottom-row">
                                        <div>{props.meteringPoint.contractId}</div>
                                    </div>
                                </div>
                                :
                                <div className="metering-point-item-button-container">
                                    <button onClick={contractOnclick(2)} className="btn btn-narrow">{props.translation.bringOver}</button>
                                </div>
                        }
                        <div className="metering-point-item-col-code">
                            <div className="metering-point-item-top-row">
                                <div>{props.translation.meteringPointCode}</div>
                            </div>
                            <div className="metering-point-item-bottom-row">
                                <div>{props.meteringPoint.code}</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>

    )
}));

export default MeteringPoint;
