import PropTypes from 'prop-types';

import { Search } from '../business/components/Search';
import PageHeaderTabs from './PageHeaderTabs';
import styles from './PageHeader.module.scss';

const PageHeader = (props) => (
    <div className={`${styles.header} ${props.className || ''}`}>
        <div className={styles.inner}>
            <h1>{props.title}</h1>

            <PageHeaderTabs
                className={`${styles.tabs} ${props.search ? styles.hasSearch : ''}`}
                pathname={props.pathname}
                links={props.links} />

            {props.search && (
                <Search {...{ ...props.search, className: styles.search }} />
            )}
        </div>
    </div>
);

PageHeader.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    pathname: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.object),
    search: PropTypes.object,
};

export default PageHeader;
