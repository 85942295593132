import React, { Component } from 'react';
import './ContractsElectricity.scss';
import {CSSTransition, TransitionGroup} from "react-transition-group";
import TabLink from "../../business/components/TabLink";
import {withScrollingHeader} from "../../components/withScrollingHeader";
import IconPlusCircleWhite24px from '../../assets/Icon_Plus-Circle_White-24px.svg'
import {ContractsTab} from "./tabs/ContractsTab";
import {MeteringPointsTab} from "./tabs/MeteringPointsTab";
import ConsumptionTab from "./tabs/ConsumptionTab";
import {MobileDropdown, MobileDropdownButton} from "./components/MobileDropDown";
import SelectContractElectricity from "./SelectContractElectricity";
import {EndContractModal} from "./modal/ConfirmationModal/EndContractModal/EndContractModal";

function getPathVariable(props, variable) {
    const search = (props.location.search).split('?')[1] || '';
    const variables = search.split('&');
    for (let i = 0; i < variables.length; i++) {
        const pair = variables[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
}

const calculateHeight = (count, isMobileLayout) => {
    if (isMobileLayout) {
        return (count * 167) + 40;
    }
    return (count * 108) + 40;
};

class ContractsElectricity extends Component {
    constructor(props) {
        super(props);

        this.state = {
            skip: true,
            data: this.getData(),
            contractIds: [],
            meteringPointCodes: [],
            behaviour: 2
        };
    }

    componentDidMount(): void {
        this.setParameters();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        this.setParameters();
    }

    setParameters() {
        const tab = getPathVariable(this.props, 'tab');
        if (tab === 'contract') {
            const pointsString = getPathVariable(this.props,'points');
            const contractsString = getPathVariable(this.props,'contracts');
            const points = pointsString ? pointsString.split(',') : [];
            const contracts = contractsString ? contractsString.split(',').map(a => parseInt(a, 10)) : [];
            const redirect = this.checkPoints(points, contracts);
            if (redirect) this.props.history.push("/electricity?tab=contracts");
            const behaviour = (contracts.length > 0) ? 4 : (points.length > 0) ? 3 : 2;
            const cc = this.state.contractIds.join() !== contracts.join();
            const mc = this.state.meteringPointCodes.join() !== points.join();
            const bc = this.state.behaviour !== behaviour;
            if (cc || mc || bc) {
                this.setState({
                    contractIds: contracts,
                    meteringPointCodes: points,
                    behaviour: behaviour
                });
            };
        };
    }

    checkPoints(points, contracts) {
        const allContracts = this.props.data.contracts.map(a => a.id);
        const allPoints = (Object.assign({},...this.props.meteringPoints.map(a => ({[a.code]: a.contractId ? parseInt(a.contractId, 10) : null}))));
        const contractsOk = contracts.every(a => allContracts.indexOf(a) > -1);
        const pointsOk = points.every(
            a => contracts.length === 0 ? true : ((Object.keys(allPoints).indexOf(a) > -1) &&
                ((allContracts.indexOf(allPoints[a]) > -1) || (allContracts.indexOf(allPoints[a]) === null) )));
        return !(pointsOk && contractsOk);
    }

    getData() {
        const {data} = this.props;
        const {contractDuration, predictedUsage, predictedDistribution} = data.priceParameters;
        const periodValue = Object.keys(contractDuration.steps).indexOf((contractDuration.value).toString(10));
        const answer = this.props.calculateElectricity(predictedUsage.value, predictedDistribution.value, periodValue);
        data.electricityPackages.fixed1.invoicePrice.price = answer.calculatedForMonth.fixed1;
        data.electricityPackages.fixed2.invoicePrice.price = answer.calculatedForMonth.fixed2;
        data.electricityPackages.stock.invoicePrice.price = answer.calculatedForMonth.stock;
        data.electricityPackages.fixed1.unitPrice.price = answer.step.fixed1;
        data.electricityPackages.fixed2.unitPrice.day = answer.step.fixed2.day;
        data.electricityPackages.fixed2.unitPrice.night = answer.step.fixed2.night;
        data.electricityPackages.stock.unitPrice.price = answer.step.stock;
        (answer.recommended.indexOf(0) > -1) ? data.electricityPackages.fixed1.recommended = true : data.electricityPackages.fixed1.recommended = false;
        (answer.recommended.indexOf(1) > -1) ? data.electricityPackages.fixed2.recommended = true : data.electricityPackages.fixed2.recommended = false;
        (answer.recommended.indexOf(2) > -1) ? data.electricityPackages.stock.recommended = true : data.electricityPackages.stock.recommended = false;
        return data;
    }

    editContract = (contractIds: number[], meteringPointCodes: string[], behaviour: number) => {
        if (meteringPointCodes.length === 0) {
            const meteringPoints = this.props.meteringPoints.filter(meteringPoint => {
                return contractIds.indexOf(parseInt(meteringPoint.contractId, 10)) > -1;
            });
            meteringPointCodes = meteringPoints.map(meteringPoint => meteringPoint.code);
        }
        this.setState({
            contractIds: contractIds || [],
            meteringPointCodes: meteringPointCodes || [],
            behaviour: behaviour || 2
        });
        const redirect = `/electricity?tab=contract${meteringPointCodes.length > 0 ? ('&points=' + meteringPointCodes) + (contractIds.length > 0 ? '&contracts=' + contractIds : '') : ''}`;
        this.props.history.push(redirect);
    };

    endContractHandler = (contractIds, meteringPointCodes) => {
        if (contractIds && (contractIds.length > 0)) {
            const mpCount = this.props.meteringPoints.filter(meteringPoint => {
                return contractIds.indexOf(parseInt(meteringPoint.contractId, 10)) > -1;
            }).length;
            const newProps = {contractIds, meteringPointCodes, mpCount};
            newProps.onCloseButtonClick = this.props.onModalClose;
            newProps.onConfirmationClick = this.endContract;
            this.props.onModalOpen(<EndContractModal {...newProps}/>)
        }
    };

    endContract = (contractIds, callBack) => {
        const userId =
            this.props.login &&
            this.props.login.currentRole &&
            this.props.login.currentRole.userId;
        if (!userId) {
            return;
        }
        const requestData = {
            requestType: 'endContract',
            userId: userId,
            token: this.props.login.token,
            contractIds: contractIds
        };
        this.props.electricityRequest(
            requestData,
            data => {
                this.props.refreshData();
                callBack();
            },
            error => {
                this.setState({ isLoaderVisible: false });
            }
        );
    };

    render() {
        const {data, meteringPointCodes, contractIds, behaviour} = this.state;
        const {contractsTab, meteringPointsTab, consumptionTab} = this.props.translation.tabs;
        const {newContract, editContract} = this.props.translation;
        const tab = getPathVariable(this.props, 'tab') || 'contracts';
        const mpProps = {
            ...this.props,
            isMobileLayout: this.props.isMobileLayout,
            meteringPoints: this.props.meteringPoints,
            editContract: this.editContract,
            endContractHandler: this.endContractHandler
        };
        const headerClass = `${this.props.isHeaderFixed ? "fixed" : ""} ${this.props.isHeaderHiding ? "hiding" : ""} ${this.props.isHeaderAnimated ? "animated" : ""}`;
        const currentMonth = new Date().getMonth();
        const currentWeekDay = new Date().getDay() - 1;
        const currentDay = new Date().getDate() - 1;

        const leftElement = (
            <img className='white-circle-24px white-circle-24px-dark-outline' src={IconPlusCircleWhite24px} alt={''} />
        );

        const activeTabName =
            (tab === 'contracts') ? contractsTab :
            (tab === 'metering-points') ? meteringPointsTab :
            (tab === 'consumption') ? consumptionTab :
            ((tab === 'contract') && (behaviour === 4) ) ? editContract :
            newContract
        ;
        return (
            <div className={`electricity-page-contracts ${!data.chosenPacket ? '' : 'no-packet'}`}>
                <div className="electricity-header-placeholder"></div>
                <div className={`electricity-header ${headerClass}`}>
                    <div className="electricity-header-shadow"></div>
                    <div className="electricity-header-container">
                        <h1 className="electricity-header-title">{this.props.translation.title}</h1>
                        <div className="electricity-header-middle" >
                            <TabLink className={(tab === 'contracts' ? 'active': '')} {...{ text: contractsTab, to: "/electricity?tab=contracts" }} />
                            <TabLink className={(tab === 'metering-points' ? 'active': '')} {...{ text: meteringPointsTab, to: "/electricity?tab=metering-points" }} />
                            <TabLink className={(tab === 'consumption' ? 'active': '')} {...{ text: consumptionTab, to: "/electricity?tab=consumption" }} />
                        </div>
                        <div className="electricity-header-right">
                            <div onClick={() => this.editContract([], [], 2)} className='electricity-add-contract-button'>
                                <img className='white-circle-24px' src={IconPlusCircleWhite24px} alt={''} />
                                <div className='electricity-add-contract-text'>{newContract}</div>
                            </div>
                        </div>
                        {
                            this.props.isMobileLayout &&
                            <MobileDropdownButton
                                isMobileDropdownOpen={this.props.isMobileDropdownOpen}
                                onClick={() => this.props.onMobileDropdownToggle(
                                        <MobileDropdown
                                        onClose={this.props.onMobileDropdownClose}
                                        items={[
                                            { text: contractsTab, to: "/electricity?tab=contracts", onClick: this.props.onMobileDropdownClose },
                                            { text: meteringPointsTab, to: "/electricity?tab=metering-points", onClick: this.props.onMobileDropdownClose },
                                            { text: consumptionTab, to: "/electricity?tab=consumption", onClick: this.props.onMobileDropdownClose },
                                            { text: newContract, to: "/electricity?tab=contract", onClick: this.props.onMobileDropdownClose, leftElement: leftElement},
                                            // { text: newContract, onClick: this.closeMobileAndOpenNewContractModal, leftElement: leftElement}
                                        ]} />
                                    )}
                                text={activeTabName} />
                        }
                    </div>
                </div>
                <div className={`electricity-body-container ${this.props.isMobileLayout ? 'electricity-body-container-mobile': ''}`}>
                    <TransitionGroup component={null}>
                        {
                            (tab === 'contracts') &&
                            <CSSTransition key={'contracts'} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                <ContractsTab endContractHandler={this.endContractHandler} editContract={this.editContract} {...this.props}/>
                            </CSSTransition>
                        }
                        {
                            (tab === 'metering-points') &&
                                <CSSTransition key={'metering-points'} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                    <MeteringPointsTab
                                        props={{...this.props}}
                                        mpProps={mpProps}
                                        calculateHeight={calculateHeight}
                                    />
                                </CSSTransition>
                        }
                        {
                            (tab === 'consumption') &&
                            <CSSTransition key={'consumption'} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                <ConsumptionTab currentDay={currentDay} currentWeekDay={currentWeekDay} currentMonth={currentMonth} {...this.props}/>
                            </CSSTransition>
                        }
                        {
                            (tab === 'contract') &&
                            <CSSTransition key={'contract' + behaviour} timeout={{ enter: 250, exit: 250 }} classNames={"fast-fade"}>
                                <SelectContractElectricity behaviour={behaviour} contractIds={contractIds} meteringPointCodes={meteringPointCodes} {...this.props}/>
                            </CSSTransition>
                        }
                    </TransitionGroup>
                </div>
            </div>
        );
    }
}

export default withScrollingHeader(ContractsElectricity, 0, 96, 80, 52)
