import { getLatLng } from 'react-places-autocomplete';

function getAddressComponent(list, type) {
    const component = list.find(c => c.types.includes(type));
    return component?.long_name;
}

const parseAddress = list => ({
    street: [
        getAddressComponent(list, 'route'),
        getAddressComponent(list, 'street_number'),
    ].join(' '),
    city: getAddressComponent(list, 'city') ?? getAddressComponent(list, 'locality'),
    county: getAddressComponent(list, 'administrative_area_level_1'),
    country: getAddressComponent(list, 'country'),
});

export const parseGeocodeResult = async res => ({
    address: {
        formatted: res.formatted_address,
        ...parseAddress(res.address_components),
    },
    latLng: await getLatLng(res),
});

export const fmtPrice = (() => {
    const format = new Intl.NumberFormat('en', {
        minimumFractionDigits: 2,
    });

    return price => format.format(price).replace(/,/g, ' ');
})();
