import React from 'react';
import './OutlineButton.scss';
import DropdownFilter from "../../../components/DropdownFilter";
import IconFiltersDots from "../../../assets/Icon_Filters_Dots.svg";

export const OutlineButton = ({items, ...props}) => {
    return (
            <DropdownFilter {...{
                items: items,
                className: `co-btn-outline ${props.className ? props.className : ""}`,
                text: <img src={IconFiltersDots} alt=""/>,
                rightImg: null,
                disabled: false,
                distance: -56
            }} />
        )
};
