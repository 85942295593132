import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import TabLink from '../../components/TabLink';
import ImageButton24px from '../../components/ImageButton24px';
import { FamilyModal } from './FamilyModal';
import { LinkModal } from './LinkModal';
import './Header.scss';
import IconPlusCircleWhite24px from '../../../assets/Icon_Plus-Circle_White-24px.svg';
import IconDownload from '../../../assets/Icon_Download.svg';
import IconChevronDownBlack16px from '../../../assets/Icon_Chevron-Down_Black-16px.svg';

const NewButton = React.memo(props =>
    <button className="virtual_family_page-header-actions-new_button" onClick={props.onClick}>
        <img className="virtual_family_page-header-actions-new_button-img" src={IconPlusCircleWhite24px} alt="" />
        <span className="virtual_family_page-header-actions-new_button-text">{props.text}</span>
    </button>
);
NewButton.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string
};

export const Header = connect(state => ({ translation: state.translation.virtualFamily, generalTranslation: state.translation.general }))(React.memo(props => {
    const familySelected = props.familyId !== null && props.familyId !== undefined;
    const title = props.selectedFamily ? props.selectedFamily.familyName : props.translation.title;
    const invitesInPathname = props.pathname && props.pathname.includes("/invites");
    const membersSelected = familySelected && !invitesInPathname;
    const invitesSelected = familySelected && invitesInPathname;

    return (<div className="virtual_family_page-header">
        <NavLink className={`virtual_family_page-header-title ${props.selectedFamily ? "clickable" : ""}`}
            to={"/virtualfamily"}>
            <img className={`virtual_family_page-header-title-img ${familySelected ? "visible" : ""}`} src={IconChevronDownBlack16px} alt="back" />
            <span>{title}</span>
        </NavLink>
        {(familySelected && props.selectedFamily) && <div className="virtual_family_page-header-tabs">
            <TabLink {...{ text: props.translation.membersTab, to: `/virtualfamily/${props.selectedFamily.familyId}/`, exact: true }} />
            <TabLink {...{ text: props.translation.invitesTab, to: `/virtualfamily/${props.selectedFamily.familyId}/invites`, exact: true }} />
        </div>}
        <div className={"virtual_family_page-header-actions"}>
            {!familySelected && <NewButton {...{
                text: props.translation.newVirtualFamilyButton,
                onClick: () => props.onModalOpen(<FamilyModal {...{
                    onModalClose: props.onModalClose,
                    editing: false,
                    onFamilyModalRequestSuccess: props.onNewFamilyAdded,
                }} />)
            }} />}
            {(membersSelected && props.showDownloadExcelButton) && <>
                <ImageButton24px {...{ className: "cards_header-download_button", src: IconDownload, alt: "download", onClick: props.downloadExcel, tooltip: props.generalTranslation.downloadFile }} />
            </>}
            {invitesSelected && <>
                <NewButton {...{
                    text: props.translation.header.newLinkButton,
                    onClick: () => props.onModalOpen(<LinkModal {...{
                        editing: false,
                        familyId: props.familyId,
                        onLinkModalRequestSuccess: props.onNewLinkAdded,
                        onModalClose: props.onModalClose
                    }} />)
                }} />
            </>}
        </div>
    </div>);
}));
Header.propTypes = {
    selectedFamily: PropTypes.any,
    familyId: PropTypes.any,
    pathname: PropTypes.any,
    downloadExcel: PropTypes.func,
    onModalOpen: PropTypes.func,
    onModalClose: PropTypes.func,
    onNewLinkAdded: PropTypes.func,
    onNewFamilyAdded: PropTypes.func,
    showDownloadExcelButton: PropTypes.bool
};